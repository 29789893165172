import { apiRoutesPrefixes } from "@/commons/apiRoutesPrefixes";
import microservice from "@/services/axiosConectionMicroservice";
import monolith from "@/services/axiosConectionMonolith";
import { defineStore } from "pinia";

export const useGeneralStore = defineStore('general', {

    // state: () => ({
    //     solicitudesUDEV: []
    // }),

    // getters: {
    //     getSolicitudesUDEVStore: state => state.solicitudesUDEV
    // },

    actions: {  

        async crearRegistroDepartamentoDePersonal (datosRegistroDepartamentoDePersonal) {
            try {
                const registroDepartamentoDePersonal = await microservice.post(`${apiRoutesPrefixes.generalMicroservice}/form/savePersonalDepartmentRegistry`, datosRegistroDepartamentoDePersonal);
                return registroDepartamentoDePersonal.data;
            } catch (error) {
                throw error.response
            }
        },

        async crearEncuestaCaracterizacionDigital (datosEncuestaCaracterizacionDigital){
            try {

                const encuesta = await microservice.post( `${apiRoutesPrefixes.generalMicroservice}/survey/saveDigitalCharacterizationSurvey`, datosEncuestaCaracterizacionDigital);
                return encuesta.data;
            } catch (error) {
                throw error.message
            }
        },
        
        async createSolicitudUDEV ( datosSolicitudUDEV ) {
            try {
                const solicitudUDEV = await monolith.post(`${ apiRoutesPrefixes.general }/solicitudUDEV`, datosSolicitudUDEV, {
                    withCredentials: true
                });

                return solicitudUDEV.data;
            } catch (error) {
                throw error.message
            }
        },

        async crearEncuestaSatisfaccion (datosEncuestaSatisfaccion){
            try {
                const encuesta = await monolith.post(`${ apiRoutesPrefixes.general }/encuestaSatisfaccion`, datosEncuestaSatisfaccion);
                return encuesta.data;
            } catch (error) {
                throw error.message
            }
        },

        async getUltimaSolicitudUDEV () {
            try {

                const solicitudesUDEV = await monolith.get(`${ apiRoutesPrefixes.general }/ultimaSolicitudUDEV`, {}, {
                    withCredentials: true
                });

                return solicitudesUDEV.data;
            } catch (error) {
                throw error.message
            }
        },

        async updateEstados ( listaRegistros, nuevoEstado ) {
            try {

                const data = {
                    listaRegistros,
                    nuevoEstado
                }

                const respActualizacionEstado = await monolith.post( `${ apiRoutesPrefixes.general }/actualizarEstado`, data );
                
                return respActualizacionEstado.data;

            } catch (error) {
                throw error.message
            }
        },

    },
    persist: true,
})