import { defineStore } from "pinia";
import monolith from "@/services/axiosConectionMonolith";
import microservice from "@/services/axiosConectionMicroservice";

export const useMoodleStore = defineStore('moodle', {
    state: () => ({
        categories: [],
        courses: [],
        users: [],
        currentcategory: '',
        currentCourse: '',
    }),

    getters: {
        categoriesList: state => state.categories,
    },

    actions: {
        async categories() {
            try {
                const categorias = await monolith.post('/obtenerCategoriasMoodle')
                    .then(resp => {
                        const { data } = resp;
                        this.categories = data.categorias;
                        return data.categorias;
                    }).catch(function(e) {
                        console.log(e); 
                        return;
                    })
                return categorias;
            } catch (error) {
                throw error.message
            }
        },

        async envioEmailUsuariosMoodle(data) {
            try {
                const envioEmail = await monolith.post('/envioCorreoUsuariosMoodle', data, {
                    withCredentials: true
                });
                return envioEmail.data;
            } catch (error) {
                throw error.message
            }
        },
        // async login(userAuthData) {
        //    try {
        //         const { data } = await monolith.post('/auth/loginMoodle', userAuthData, {
        //             withCredentials: true
        //         });

        //         if (!data.message) {
        //             const { userInfo: { tokenInfo, ...user } } = data;
        //             this.user = user;
        //             this.accessToken = tokenInfo.token;
        //             this.authReady = true;
        //             axios.defaults.headers.common['Authorization'] = `Bearer ${tokenInfo.token}`
        //             return;   
        //         }
        //         return data;
            
        //    } catch (error) {
        //         throw error.message
        //    }
        // },


        async fetchMoodleCategoriesByServerName( serverName ) {
            try { 
                const moodleCategories = await microservice.get( `/moodle-service/categories/allCategories`,  {
                    params: {
                      serverName,
                      onlyCategoriesWithCourses: true
                    },
                })

                return moodleCategories.data;
            } catch (error) {
                if (error.response && error.response.data) {
                  // Accede al response.data del error
                  console.log('Error al obtener las categorías:', error.response.data);
                  return {
                    errorMessage: error.response.data.error || 'No se pudo obtener la información del servidor.',
                  };
                } else {
                  // Error sin response (por ejemplo, problemas de red)
                  console.error('Error inesperado:', error.message);
                  return {
                    errorMessage: 'Ocurrió un error inesperado. Inténtalo de nuevo más tarde.',
                  };
                }
              }
        },

        async fetchMoodleCoursesByServersAndCategories(selectedCategoriesByServerName) { 
            const serverNames = Object.keys(selectedCategoriesByServerName);
            const coursesbyServersAndCategories = {};

            await Promise.all(serverNames.map(async (serverName) => {
                const { data } = await microservice.get( `/moodle-service/courses/coursesByCategoriesIds`,  {
                    params: {
                        serverName,
                        categoriesIds: selectedCategoriesByServerName[serverName]
                    },
                });
                coursesbyServersAndCategories[serverName] = data.coursesByServerAndCategory;
            }));

            return coursesbyServersAndCategories;
        }
    },
    persist: true,
})