<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Banner_Requisitos-de-graduacion.png" class="img-fluid d-flex mx-auto vw-100" alt="">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 mt-3">
                    <p>El objetivo de la siguiente encuesta es conocer qué tanto saben los estudiantes de los programas virtuales del ITM sobre los requisitos necesarios para su graduación. De esta manera, los podremos orientar de una forma más personalizada para lograr el objetivo de graduarse</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtNombre">Nombre completo <span class="text-danger"><b>*</b></span></label><br>
                    <input v-model.trim="usuario.nombre" id="txtNombre" type="text" class="form-control">
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtCorreo">Correo institucional <span class="text-danger"><b>*</b></span></label><br>
                    <input v-model.trim="usuario.correo" id="txtCorreo" type="text" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtTelefono">Teléfono <span class="text-danger"><b>*</b></span></label><br>
                    <input v-model.trim="usuario.telefono" id="txtTelefono" type="number" class="form-control">
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtDocumentoIdentidad">Documento de identidad <span class="text-danger"><b>*</b></span></label><br>
                    <input v-model.trim="usuario.documento" id="txtDocumentoIdentidad" type="number" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="programaAcademico">Programa académico <span class="text-danger"><b>*</b></span></label><br>
                    <input v-model.trim="usuario.programaAcademico" id="programaAcademico" type="text" class="form-control">
                </div>
                <div class="col-lg-6 col-12">
                    <label for="txtSemestreActual">Nivel (semestre en el que se encuentra) <span class="text-danger"><b>*</b></span></label><br>
                    <input v-model.trim="usuario.semestre" id="txtSemestreActual" type="number" class="form-control">
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <p>A continuación, encontrarás los requisitos para la graduación de los programas de modalidad virtual de la Facultad de Ciencias Económicas y Administrativas. Por favor, lee cada enunciado o pregunta y selecciona la respuesta que más se aproxime a tu realidad.</p>
                </div>
            </div>
            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>Curso <em>Cátedra Abierta de Ciudadanía y Paz</em>.</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion1" id="radioBtn1_1" v-model="respuestas.resp1" v-bind:value="'Ya lo realicé'">
                        <label class="form-check-label" for="radioBtn1_1">
                            Ya lo realicé. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion1" id="radioBtn1_2" v-model="respuestas.resp1" v-bind:value="'No tengo conocimiento'">
                        <label class="form-check-label" for="radioBtn1_2">
                            No tengo conocimiento. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion1" id="radioBtn1_3" v-model="respuestas.resp1" v-bind:value="'Estoy pendiente de realizarlo'">
                        <label class="form-check-label" for="radioBtn1_3">
                            Estoy pendiente de realizarlo. 
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>Curso <em>Cátedra de Deporte y Cultura</em>.</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion2" id="radioBtn2_1" v-model="respuestas.resp2" v-bind:value="'Ya lo realicé'">
                        <label class="form-check-label" for="radioBtn2_1">
                            Ya lo realicé. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion2" id="radioBtn2_2" v-model="respuestas.resp2" v-bind:value="'No tengo conocimiento'">
                        <label class="form-check-label" for="radioBtn2_2">
                            No tengo conocimiento. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion2" id="radioBtn2_3" v-model="respuestas.resp2" v-bind:value="'Estoy pendiente de realizarlo'">
                        <label class="form-check-label" for="radioBtn2_3">
                            Estoy pendiente de realizarlo. 
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>Curso <em>Estrategias de Información Científica</em>.</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion3" id="radioBtn3_1" v-model="respuestas.resp3" v-bind:value="'Ya lo realicé'">
                        <label class="form-check-label" for="radioBtn3_1">
                            Ya lo realicé. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion3" id="radioBtn3_2" v-model="respuestas.resp3" v-bind:value="'No tengo conocimiento'">
                        <label class="form-check-label" for="radioBtn3_2">
                            No tengo conocimiento. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion3" id="radioBtn3_3" v-model="respuestas.resp3" v-bind:value="'Estoy pendiente de realizarlo'">
                        <label class="form-check-label" for="radioBtn3_3">
                            Estoy pendiente de realizarlo. 
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>¿Conoces la resolución de modalidades de trabajo de grado de tu facultad?</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion4" id="radioBtn4_1" v-model="respuestas.resp4" v-bind:value="'Conozco todos los requisitos para cada una de las 5 modalidades, pero aún no decido cuál modalidad escoger'">
                        <label class="form-check-label" for="radioBtn4_1">
                            Conozco todos los requisitos para cada una de las 5 modalidades, pero aún no decido cuál modalidad escoger. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion4" id="radioBtn4_2" v-model="respuestas.resp4" v-bind:value="'Conozco las modalidades, pero no sus requisitos'">
                        <label class="form-check-label" for="radioBtn4_2">
                            Conozco las modalidades, pero no sus requisitos. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion4" id="radioBtn4_3" v-model="respuestas.resp4" v-bind:value="'Desconozco cuáles son las modalidades'">
                        <label class="form-check-label" for="radioBtn4_3">
                            Desconozco cuáles son las modalidades.  
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>¿Te encuentras inscrito en alguna modalidad de trabajo de grado de tu facultad?</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion5" id="radioBtn5_1" v-model="respuestas.resp5" v-bind:value="'Ya estoy inscrito'">
                        <label class="form-check-label" for="radioBtn5_1">
                            Ya estoy inscrito.
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion5" id="radioBtn5_2" v-model="respuestas.resp5" v-bind:value="'No tenía conocimiento / No conozco las fechas de inscripción / No estoy inscrito'">
                        <label class="form-check-label" for="radioBtn5_2">
                            No tenía conocimiento / No conozco las fechas de inscripción / No estoy inscrito.
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion5" id="radioBtn5_3" v-model="respuestas.resp5" v-bind:value="'Ya me inscribí y estoy a la espera de la aprobación'">
                        <label class="form-check-label" for="radioBtn5_3">
                            Ya me inscribí y estoy a la espera de la aprobación.  
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>¿Tienes matriculada la asignatura <em>Trabajo de grado</em>?</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion6" id="radioBtn6_1" v-model="respuestas.resp6" v-bind:value="'La tengo matriculada este semestre, pero me falta más del 50 % del trabajo de grado'">
                        <label class="form-check-label" for="radioBtn6_1">
                            La tengo matriculada este semestre, pero me falta más del 50 % del trabajo de grado.
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion6" id="radioBtn6_2" v-model="respuestas.resp6" v-bind:value="'Tengo muy adelantado el trabajo de grado, pero no matriculé la asignatura este semestre'">
                        <label class="form-check-label" for="radioBtn6_2">
                            Tengo muy adelantado el trabajo de grado, pero no matriculé la asignatura este semestre. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion6" id="radioBtn6_3" v-model="respuestas.resp6" v-bind:value="'La tengo matriculada, pero no alcancé a inscribirme en la modalidad de trabajo de grado'">
                        <label class="form-check-label" for="radioBtn6_3">
                            La tengo matriculada, pero no alcancé a inscribirme en la modalidad de trabajo de grado. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion6" id="radioBtn6_4" v-model="respuestas.resp6" v-bind:value="'No tengo conocimiento sobre esa asignatura'">
                        <label class="form-check-label" for="radioBtn6_4">
                            No tengo conocimiento sobre esa asignatura. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion6" id="radioBtn6_5" v-model="respuestas.resp6" v-bind:value="'Apenas voy a iniciar la modalidad el próximo semestre'">
                        <label class="form-check-label" for="radioBtn6_5">
                            Apenas voy a iniciar la modalidad el próximo semestre.  
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>¿Te encuentras inscrito para las pruebas TyT?</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion7" id="radioBtn7_1" v-model="respuestas.resp7" v-bind:value="'Ya realicé las pruebas'">
                        <label class="form-check-label" for="radioBtn7_1">
                            Ya realicé las pruebas. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion7" id="radioBtn7_2" v-model="respuestas.resp7" v-bind:value="'Me encuentro inscrito y estoy a la espera de la citación'">
                        <label class="form-check-label" for="radioBtn7_2">
                            Me encuentro inscrito y estoy a la espera de la citación. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion7" id="radioBtn7_3" v-model="respuestas.resp7" v-bind:value="'Se me pasó la fecha de inscripción'">
                        <label class="form-check-label" for="radioBtn7_3">
                            Se me pasó la fecha de inscripción. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion7" id="radioBtn7_4" v-model="respuestas.resp7" v-bind:value="'No tengo conocimiento sobre las pruebas'">
                        <label class="form-check-label" for="radioBtn7_4">
                            No tengo conocimiento sobre las pruebas. 
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="border p-3 rounded shadow mb-3">
                    <p>¿Te encuentras inscrito a la ceremonia de grados?</p>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion8" id="radioBtn8_1" v-model="respuestas.resp8" v-bind:value="'Todavía no cumplo todos los requisitos'">
                        <label class="form-check-label" for="radioBtn8_1">
                            Todavía no cumplo todos los requisitos.  
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion8" id="radioBtn8_2" v-model="respuestas.resp8" v-bind:value="'No tengo conocimiento de la inscripción ni las fechas'">
                        <label class="form-check-label" for="radioBtn8_2">
                            No tengo conocimiento de la inscripción ni las fechas. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion8" id="radioBtn8_3" v-model="respuestas.resp8" v-bind:value="'Ya estoy inscrito y a la espera de respuesta'">
                        <label class="form-check-label" for="radioBtn8_3">
                            Ya estoy inscrito y a la espera de respuesta. 
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioBtnSeccion8" id="radioBtn8_4" v-model="respuestas.resp8" v-bind:value="'Tengo la información clara pero aún no me inscribo'">
                        <label class="form-check-label" for="radioBtn8_4">
                            Tengo la información clara pero aún no me inscribo. 
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Observaciones y comentarios: déjanos aquí cualquier inquietud que presentes sobre tu proceso de graduación.</p>
                    <textarea v-model.trim="respuestas.observacion" name="" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
            </div>

            <div class="col-12 my-4">
                <div v-if="faltantes" class="alert alert-danger" role="alert">
                    <b>Todos los campos son de carácter obligatorio</b>
                </div>
                <div v-if="correoErrado" class="alert alert-danger" role="alert">
                    <b>El correo electrónico del estudiante debe ser el correo institucional <b>'ejemplo@correo.itm.edu.co'</b></b>
                </div>
                <div v-if="mensajeError !== null" class="alert alert-danger" role="alert">
                    <b>{{ mensajeError }}</b>
                </div>
                <div class="d-flex justify-content-center" v-if="loading">
                    <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                </div>
                <button class="btn btn-enviar d-flex mx-auto mt-3" @click="validaCampos">Enviar respuestas</button>
            </div>
        </div>
    </div>
</template>

<script>
import { sweetAlertConfirm, sweetAlertSuccess } from '@/commons/SweetAlert';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import API from '../../../api';

export default {
    name: 'rutaDeGraduacion',

    data() {
        return {
            usuario: {
                nombre: '',
                correo: '',
                telefono: '',
                documento: '',
                programaAcademico: '',
                semestre: ''
            },

            respuestas: {
                resp1: '',
                resp2: '',
                resp3: '',
                resp4: '',
                resp5: '',
                resp6: '',
                resp7: '',
                resp8: '',
                observacion: ''
            },

            faltantes: false,
            correoErrado: false,
            mensajeError: null,
            loading: false,
        }
    },
    methods: {
        validaCampos: async function() {
            this.faltantes = false;
            this.correoErrado = false;
            this.mensajeError = null;

            Object.keys(this.usuario).forEach(attr => {
                if(this.usuario[attr] === '') this.faltantes = true
            });
            Object.keys(this.respuestas).forEach(attr => {
                if(this.respuestas[attr] === '') this.faltantes = true
            });

            if(this.faltantes) return;

            this.correoErrado = this.validaCorreoInstitucional();
            if(this.correoErrado) return;

            this.usuario.nombre = this.usuario.nombre.toUpperCase();
            this.usuario.programaAcademico = this.usuario.programaAcademico.toUpperCase();


            sweetAlertConfirm(
                '¿Desea enviar la información diligenciada?',
                'Los datos diligenciados se enviarán para su respectiva revisión.',
                'warning',
                'Enviar'
              ).then(async (result) => {
                if (result.isConfirmed) {

                    this.loading = true;

                    const data = {
                        usuario: this.usuario,
                        respuestas: this.respuestas
                    }
                    
                    const registroRutaGraduacion = await API.crearRutaGraduacion(data);                    
                  

                    if(registroRutaGraduacion.message){
                        this.mensajeError = registroRutaGraduacion.message;
                    }else{
                        this.loading = false;
                        sweetAlertSuccess(
                        '¡Datos enviados!',
                        'Los datos diligenciados se han enviado de manera exitosa',
                        'success'
                        ).then(() => {
                            location.reload();
                        });
                    }
                  } else  (
                    result.dismiss === Swal.DismissReason.cancel
                  ) 
                })

      


        },

        validaCorreoInstitucional: function() {
            if(this.usuario.correo.split('@')[1] !== "correo.itm.edu.co") return true;
        },
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>