import { createApp } from 'vue';
// import '@fortawesome/fontawesome-free/css/all.min.css';

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router'

// Interceptors de axios
import './interceptors/axios'

// Import css
import '../public/css/main.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import '../public/css/bootstrap5.css'
import '../public/js/bootstrap5.js'


import { VueCookies } from 'vue-cookies';

import PrimeVue from 'primevue/config';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

//-------SELECT CON FILTRO------->

// import vSelect from "vue-select";
// Vue.component("v-select", vSelect);
// import "vue-select/dist/vue-select.css";


createApp(App)
.use(PrimeVue)
.use(VueCookies)
.use(router)
.use(pinia)
.mount('#app')
