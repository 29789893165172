<template>
    <div>
        <div class="container-fluid bg-dark">
            <div class="row p-5">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <h1 class="text-white">Aca va el banner</h1>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 mt-3">
                    <h1>Moodle backups - Cursos / Elección de categoría</h1>
                </div>
                <div class="col-12">
                    <p>Por favor seleccione un servidor</p>
                    <select name="" id="" class="form-control" @change="fetchCategoriesByServerName" v-model="serverName">
                        <option value="">Seleccione una opción</option>
                        <option value="campusvirtual_4_3">Campus Virtual</option>
                        <option value="cvirtual">CVirtual</option>
                        <option value="saberes">Saberes</option>
                        <option value="moodlepruebas">Entorno de pruebas</option>
                    </select>
                </div>
                <div class="col-12 mt-3" v-if="responseError">
                    <div class="alert alert-danger" role="alert">
                        <b>{{responseError}}</b>
                    </div>
                </div>
                <div class="col-12 mt-3" v-else-if="moodleCategories.length !== 0">
                    <h4>Categorias del servidor</h4>
                    <p><b>Por favor seleccione las categorias a las que desea realizar respaldo</b></p>
                    <table class="table table-striped table-bordered display">
                        <thead class="text-center">
                            <td><b>ID</b></td>
                            <td><b>NOMBRE</b></td>
                            <td><b>RUTA EN MOODLE</b></td>
                            <td><b>RESPALDAR TODA LA CATEGORÍA</b></td>
                            <td><b>RESPALDAR POR CURSOS</b></td>
                        </thead>
                        <tbody>
                            <tr v-for="category in moodleCategories" :key="category.id">
                                <td>{{ category.id }}</td>
                                <td>{{ category.categoryName }}</td>
                                <td>{{ category.path }}</td>
                                <td>
                                    <div class="d-flex justify-content-center align-content-center"><input @change="setCategorySesionBackupState(category.id, category.categoryName, 1)" :id="`rdBtn${category.id}-1`" type="radio" :name="`rdBtnCategoria${category.id}`"></div>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center align-content-center"><input @change="setCategorySesionBackupState(category.id, category.categoryName, 2)" :id="`rdBtn${category.id}-2`" type="radio" :name="`rdBtnCategoria${category.id}`"></div>
                                </td>
                                <td>
                                    <button class="btn d-flex mx-auto" @click="deselectRadioButtons(category.id)">Deseleccionar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div v-if="noCategoriesSelected" class="alert alert-danger" role="alert">
                        <b>Debe seleccionar almenos una categoria de la lista</b>
                    </div>
                    <button class="btn" @click="fetchMoodleCoursesBySelectedCategoriesIds">Siguiente!!</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useMoodleStore } from '@/stores/moodle';
    import { useAdministrationStore } from '@/stores/administration';
        
    const route = useRoute();
    const router = useRouter(); 
    const moodleStore = ref(null);
    const administrationStore = ref(null);
    const noCategoriesSelected = ref(false);
    const serverName = ref('');
    const responseError = ref(null);

    const moodleCategories = ref([]);

    const fetchCategoriesByServerName = async () => {
        responseError.value = null;
        moodleCategories.value = [];
        moodleCategories.value = await moodleStore.value.fetchMoodleCategoriesByServerName(serverName.value);
        if(moodleCategories.value.errorMessage){
            responseError.value = moodleCategories.value.errorMessage;
            return;
        }
    
        const currentTableSelectedCategories = await administrationStore.value.selectedOptionsCategoryBackupSection[serverName.value];
        activateSelectedRadioButtons(currentTableSelectedCategories);
    }

    const fetchMoodleCoursesBySelectedCategoriesIds = async () => {
        const selectedCategoriesByServerName = await administrationStore.value.selectedOptionsCategoryBackupSection;
        if(Object.keys(selectedCategoriesByServerName).length === 0) {
            noCategoriesSelected.value = true;
            return;
        }
        noCategoriesSelected.value = false;

        const filteredCategories = getOnlyCategoriesToBackupByCourses(selectedCategoriesByServerName);
        
        if(filteredCategories) {
            const moodleCoursesByServersAndCategories = await moodleStore.value.fetchMoodleCoursesByServersAndCategories(selectedCategoriesByServerName);
            administrationStore.value.setSelectedCategoriesWithOwnCourses(moodleCoursesByServersAndCategories);
        } 

        router.push({name: 'backupsMoodleCourseChoose'});
    }

    const getOnlyCategoriesToBackupByCourses = (selectedCategoriesByServerName) => {
        const categories = {};
        Object.keys(selectedCategoriesByServerName).forEach((key) => {
            const data = selectedCategoriesByServerName[key];
            const filter = data.filter( category => category.backupType === 2 );
            if(filter.length > 0) {
                categories[key] = filter;
            }
        });
        return Object.keys(categories).length === 0 ? null : categories;
    }   

    const activateSelectedRadioButtons = (currentTableSelectedCategories) => {
        if(currentTableSelectedCategories) {
            currentTableSelectedCategories.forEach(category => {
                const radioButton = document.getElementById(`rdBtn${category.categoryId}-${category.backupType}`);
                radioButton.checked = true;
            });
        }
    }

    const setCategorySesionBackupState = async (categoryId, categoryName, backupType) => {
        await administrationStore.value.addOrUpdateBackupCategorySectionOption({
            serverName: serverName.value, 
            categoryId: categoryId, 
            categoryName: categoryName,
            backupType: backupType
        });
    }

    const deselectRadioButtons = async (categoryId) => {
        await administrationStore.value.deleteBackupCategorySectionOption({ serverName: serverName.value, categoryId: categoryId});
        const radioButtons = document.getElementsByName(`rdBtnCategoria${categoryId}`);
        radioButtons.forEach(radioButton => radioButton.checked = false);
    }

    onMounted(() => {
        moodleStore.value = useMoodleStore();
        administrationStore.value = useAdministrationStore();
        document.title = route.meta.title;
    });
</script>