<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los docentes mediante el formulario de <em><b>Asistencia a momentos de inducción docente</b></em>.</p>
          </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
            <div v-if="infoAsistenciaInduccionDocentes.length != 0" class="">
              <DataTable v-if="infoAsistenciaInduccionDocentes.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoAsistenciaInduccionDocentes" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>

<script>

//Importando API que conecta front con back
// import API from '../../../api'
import { useGestorStore } from '@/stores/gestores';
import DataTable from '../../../components/DataTable'

export default {
  name: 'infoAsistenciaInduccionDocentes',

  data() {
    return{
        gestorStore: null,

        btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
        infoAsistenciaInduccionDocentes: [],
        noData: false,
        cabeceraDatatable: [
            {text: 'N°'},
            {text: 'Nombre Docente'},
            {text: 'Apellido Docente'},
            {text: 'Correo Docente'},
            {text: 'Modalidad'},
            {text: 'Programa Académico'},
            {text: 'Asignatura'},
            {text: 'Facultad'},
            {text: 'Momento de Inducción al que Asistió'},
            {text: 'Tipo de Docente'},
            {text: 'Fecha de Registro'}
        ],
        tituloReporteDataTable: 'Reporte de asistencia a momentos de inducción docente'
    }
  },
  components: {
    DataTable
  },
  created() {
    this.verInfo()
  },
  methods: {
    verInfo: async function() {
        this.noData = false;
        this.gestorStore = useGestorStore();
        this.infoAsistenciaInduccionDocentes = await this.gestorStore.fetchInduccionDocente();
        if(this.infoAsistenciaInduccionDocentes.length === 0) return this.noData = true;
    }
  },
  mounted() {
    document.title = this.$route.meta.title // Cambiar el título de la página
  }
}
</script>
