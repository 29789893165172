<template>
    <div class="container pb-5">
      <div class="row">
          <div class="pt-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los docentes mediante el formulario de  
              <em><b>Recepción de Guía Didactica y Compromiso Académico</b></em>.</p>
          </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-6 col-12">
          <label for="ddlSemestre">Seleccione semestre:</label>
          <select name="" class="form-control" id="ddlSemestre" v-model.trim="semestre">
            <option value="0" selected>Selecione una opción</option>
            <option value="2023-1">2023-1</option>
            <option value="2023-2">2023-2</option>
            <option value="2024-1">2024-1</option>
            <option value="2024-2">2024-2</option>
          </select>
        </div>
      </div>
      <div class="row" v-if="sinRegistros">
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            <b>No existen registros para el semestre {{ semestre }}</b>
          </div>
        </div>
      </div>
      <div class="row" v-if="!sinRegistros">
        <div class="col-12">
            <DataTable v-if="infoRecepcionesGDCA.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoRecepcionesGDCA" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
        </div>  
      </div> 
    </div>
  </template>
<script>
  
  import API from '../../../api'
  import DataTable from '../../../components/DataTable'

  export default {
    name: 'infoRecepcionGDCA',
    data() {
      return{
          semestre: 0,
          sinRegistros: false,
          btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
          infoRecepcionesGDCA: [],
          cabeceraDatatable: [
          {text: 'N°'},
          {text: 'Nombre docente'},
          {text: 'Cédula'},
          {text: 'Correo'},
          {text: 'Tipo asignatura'},
          {text: 'Código asignatura'},
          {text: 'Nombre archivo'},
          {text: 'Fecha de registro'}
        ],
        tituloReporteDataTable: 'Reporte de recepcón GDCA'
      }
    },
    methods: {
        verInfo: async function(value) {
          this.sinRegistros = false;
          let recepciones = await API.fetchInfoRecepcionesGDCA({semestre: value});
          if(recepciones.length === 0) return this.sinRegistros = true;

          this.infoRecepcionesGDCA = recepciones.map(recepcion => {
            return {
              ...recepcion,
              fechaRegistro: recepcion.fechaRegistro.split('T')[0]
            }
          })
        }
    },
    watch: {
      semestre (value) {
        this.verInfo(value)
      }
    },   
    components: {
      DataTable
    }, 
    mounted() {
      document.title = this.$route.meta.title // Cambiar el título de la página
    }
  }
</script>
<style>
@import 'datatables.net-bs5';
</style>
  