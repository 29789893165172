<template>
    <div class="container pb-5">
      <div class="row">
          <div class="pt-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los docentes mediante el formulario de
              <em><b>Notaría por parte del docente</b></em>.</p>
          </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-6 col-12">
          <label for="ddlSemestre">Seleccione semestre:</label>
          <select name="" class="form-control" id="ddlSemestre" v-model.trim="semestre">
            <option value="0" selected>Selecione una opción</option>
            <option value="2023-1">2023-1</option>
            <option value="2023-2">2023-2</option>
            <option value="2024-1">2024-1</option>
            <option value="2024-2">2024-2</option>
          </select>
        </div>
      </div>
      <div class="row" v-if="sinRegistros">
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            <b>No existen registros para el semestre {{ semestre }}</b>
          </div>
        </div>
      </div>
      <div class="row" v-if="!sinRegistros">
        <div class="col-12">
          <div v-if="infoNotariaDocente.length != 0">
              <DataTable v-if="infoNotariaDocente.length !== 0" :info="infoNotariaDocente" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
          </div>
        </div>  
      </div> 
    </div>
  </template>
  <script>
  
  import API from '../../../api'
  import DataTable from '../../../components/DataTable'
  
  export default {
    name: 'infoRecepcionGDCA',
  
    data() {
      return{
        semestre: 0,
        sinRegistros: false,
        infoNotariaDocente: [],
        cabeceraDatatable: [
          {text: 'N°'},
          {text: 'Nombre docente'},
          {text: 'Cédula'},
          {text: 'Correo'},
          {text: 'Tipo asignatura'},
          {text: 'Código asignatura'},
          {text: 'El estudiante debe'},
          {text: 'Nombre estudiante'},
          {text: 'Cédula estudiante'},
          {text: 'Correo estudiante'},
          {text: 'Fecha registro'},
        ],
        tituloReporteDataTable: 'Reporte de notaría por parte del docente'
      }
    },
     methods: {
          verInfo: async function(value) {
            this.sinRegistros = false;
            const infoNotariaDocente = await API.fetchInfoNotariaDocente({semestre: value});
            if(infoNotariaDocente.length === 0) return this.sinRegistros = true;
            this.infoNotariaDocente = infoNotariaDocente;
          }
      },
      watch: {
        semestre (value) {
          this.verInfo(value)
        }
      },
      components: {
        DataTable
      },
      mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
      }
  }
  </script>
  