<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Generales/Banner_Departamento_de_Personal.png"
                class="img-fluid d-flex mx-auto vw-100" alt="">
        </div>
        <div class="container">
            <div class="row mt-3">
                <div class="col-12">
                    <p>Formulario de inscripción a los cursos de SG-SST del Departamento de personal</p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtNombres">Nombres <b class="text-danger">*</b></label> <br>
                    <input class="form-control" type="text" id="txtNombres"
                        v-model.trim="datosRegistro.nombres"
                        @input="datosRegistro.nombres = datosRegistro.nombres.toUpperCase()">
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtApellidos">Apellidos <b class="text-danger">*</b></label> <br>
                    <input class="form-control" type="text" id="txtApellidos"
                        v-model.trim="datosRegistro.apellidos"
                        @input="datosRegistro.apellidos = datosRegistro.apellidos.toUpperCase()">
                </div>
            </div>

            <div class="row mt-lg-3 mt-0">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtDocumentoIdentidad">Número de documento de Identidad <b class="text-danger">*</b></label> <br>
                    <input class="form-control" type="text" id="txtDocumentoIdentidad" v-model.trim="datosRegistro.documentoIdentidad">
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtCorreoElectronico">Correo electrónico <b class="text-danger">*</b></label> <br>
                    <input class="form-control" type="email" id="txtCorreoElectronico" v-model.trim="datosRegistro.correoElectronico">
                </div>
            </div>

            <div class="row mt-lg-3 mt-0">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="slcCargo">Cargo <b class="text-danger">*</b></label> <br>
                    <select class="form-select" id="slcCargo" v-model="datosRegistro.cargo">
                        <option v-for="cargo in cargos" :value="cargo.id" :key="cargo.id" :disabled="cargo.inactivo">
                            {{ cargo.nombre }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mt-lg-3 mt-0">
                <div class="col-lg-6 col-12">
                    <p>Antes de elegir el nombre del curso, por favor tenga en cuenta lo siguiente:</p>
                    <ul>
                        <li><b>SG-SST Contratista:</b> Si usted es contratista, debe realizar el curso de inducción al SG-SST y actualizarlo anualmente</li>
                        <li><b>SG-SST Docente o Empleado:</b> Si usted es docente ocasional o de cátedra, debe realizar el curso de inducción al SG-SST y actualizarlo anualmente</li>
                        <li><b>SG-SST Talleres y Laboratorios:</b> Si usted realiza funciones dentro de los Talleres y Laboratorios debe realizar el curso de SST Talleres y Laboratorios, este curso no tiene vigencia.</li>
                    </ul>
                    <label for="slcCurso">Curso <b class="text-danger">*</b></label> <br>
                    <select class="form-select" id="slcCurso" v-model="datosRegistro.curso">
                        <option v-for="curso in cursos" :value="curso.id" :key="curso.id" :disabled="curso.inactivo">
                            {{ curso.nombre }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mt-lg-3 mt-0">
                <p v-if="errorCampoVacio" class="text-danger"><b>Todos los campos marcados con * son obligatorios</b></p>
                <p v-if="errorCorreoElectronico" class="text-danger"><b>El correo electrónico no es válido</b></p>
            </div>

            <div class="row my-4">
                <div class="col-12" v-if="errorRespuestaAPI">
                    <p class="text-danger"><b>{{ mensajeErrorRespuestaAPI }}</b></p>
                </div>
                <div class="col-12">
                    <button class="btn btn-enviar d-flex mx-auto" @click="realizarRegistro">Realizar registro</button>
                </div>
            </div>
        </div>

        <!-- SweetAlert Component -->
        <SweetAlert 
            v-if="showAlert"
            v-bind="alertData"
            @result="handleSweetAlertResult"
        />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { validarEmailCorrecto } from '@/helpers/functions';
import { useGeneralStore } from '../../../stores/generales';
import SweetAlert from '@/components/SweetAlert.vue';

const showAlert = ref(false);
const alertData = ref({});
const route = useRoute();
const generalStore = useGeneralStore();
const errorCampoVacio = ref(false);
const errorCorreoElectronico = ref(false);
const errorRespuestaAPI = ref(false);
const mensajeErrorRespuestaAPI = ref(''); 

const datosRegistro = ref({
    nombres: '',
    apellidos: '',
    documentoIdentidad: '',
    correoElectronico: '',
    cargo: 0,
    curso: 0,
}); 

const cargos = ref([
    { id: 0, nombre: 'Seleccione cargo', inactivo: true},
    { id: 1, nombre: 'Docente' },
    { id: 2, nombre: 'Empleado' },
    { id: 3, nombre: 'Contratista' },
    { id: 4, nombre: 'Laboratorista' }
]);

const cursos = ref([
    { id: 0, nombre: 'Seleccione curso', inactivo: true},
    { id: 1, nombre: 'SG-SST Contratista' },
    { id: 2, nombre: 'SG-SST Docente o Empleado' },
    { id: 3, nombre: 'SG-SST Talleres y Laboratorios' },
    { id: 4, nombre: 'Curso B -Learning' },
    { id: 5, nombre: 'Curso virtual "Apropiación del Proyecto Educativo Institucional (PEI)' }
]);

const realizarRegistro = () => {
    validarCampos();
    if (errorCampoVacio.value) return;
    
    alertData.value = {
        title: '¿Desea enviar la información diligenciada?',
        text: 'Los datos diligenciados se enviarán a la base de datos.',
        icon: 'warning',
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
    };
    showAlert.value = true;
};

const handleSweetAlertResult = async (result) => {
    showAlert.value = false; 

    if (result.isConfirmed) {
        
        try {

            const cargoSeleccionado = cargos.value.find(c => c.id === datosRegistro.value.cargo);
            const cursoSeleccionado = cursos.value.find(c => c.id === datosRegistro.value.curso);

            const datosParaEnviar = {
                ...datosRegistro.value,
                cargo: cargoSeleccionado ? cargoSeleccionado.nombre : '',
                curso: cursoSeleccionado ? cursoSeleccionado.nombre : '',
            };
            
            const res = await generalStore.crearRegistroDepartamentoDePersonal(datosParaEnviar);
            
            if (res) {
                Swal.fire({
                    title: 'Registro exitoso',
                    text: 'Los datos se han guardado correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload(); 
                    }
                });
            }
        } catch (error) {
            const errorMessage = error.data.error || 'Ocurrió un error inesperado';
            mensajeErrorRespuestaAPI.value = errorMessage;
            errorRespuestaAPI.value = true;
        }
    }
};

const validarCampos = () => {
    errorCampoVacio.value = Object.values(datosRegistro.value).some(val => val === '' || val === 0);
    errorCorreoElectronico.value = !validarEmailCorrecto(datosRegistro.value.correoElectronico);
};

onMounted(() => {
    document.title = route.meta.title;
});
</script>
