import PaginaNoEncontrada from '../views/Error/PaginaNoEncontrada.vue';

export default [
    {
        path: '/*/',
        name: 'PaginaNoEncontrada',
        component: PaginaNoEncontrada,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Error 404 - Página no encontrada',
            hideNavbar: true
        }
    },
]
