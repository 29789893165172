<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante los formularios de cátedras.</p>
          </div>
      </div>
      <div class="row">
            <div class="col-12">
                <select id="selectCatedra" class="form-select form-select-lg mb-3" v-model.trim="catedraSeleccionada" @change="verInfo(catedraSeleccionada)" aria-label="form-select-lg example">
                    <option selected disabled value="">Seleccione una opción</option>
                    <option v-for="(catedra, index) of optionsCatedras" :key="index" :value="catedra.value">{{catedra.text}}</option>
                </select>
            </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12" v-if="infoIncripcionCatedras.length > 0">
            <DataTable :btnsDisable="btnsDisableToTable" :info="infoIncripcionCatedras" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable" />
        </div>  
      </div> 
    </div>
</template>

<script>

import { useGestorStore } from '@/stores/gestores';
import DataTable from '../../../components/DataTable'
import { getCatedrasAbiertas } from '@/commons/diccionarios';

export default {
    name: "InscripcionCatedras",
    data() {
        return {
            gestorStore: null,
            btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
            infoIncripcionCatedras: [],
            noData: false,
            catedraSeleccionada: '',
            optionsCatedras: getCatedrasAbiertas(),
            cabeceraDatatable: [
                {text: 'N°'},
                {text: 'Nombres'},
                {text: 'Apellidos'},
                {text: 'N° Documento'},
                {text: 'Correo'},
                {text: 'Celular'},
                {text: 'Programa académico'},
                {text: 'Semestre'},
                {text: 'Fecha de Registro'}
            ],
            tituloReporteDataTable: 'Reporte de inscripción a cátedras'
        }
    },
    components: {
        DataTable
    },
    methods: {
        verInfo: async function(catedraSeleccionada) {
            this.noData = false;
            this.gestorStore = useGestorStore();
            this.infoIncripcionCatedras = [];
            
            this.infoIncripcionCatedras = await this.gestorStore.fetchInscripcionCatedra(catedraSeleccionada);
            if(this.infoIncripcionCatedras.length === 0) {
                this.noData = true;
            } else {
                this.noData = false;
            }
        }
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }

}
</script>