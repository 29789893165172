<template>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="">
            <div class="table-responsive">
              <DataTable v-if="info.length !== 0" ref="table" class="table table-striped table-bordered display"
              :data="registrosDataTable" 
              :columns="columns" 
              :options="{
                  responsive:true, 
                  select: true, 
                  select: {
                  style: 'multi+shift'
                  },
                  autoWidth: false, 
                  dom: 'Bfrtip', 
                  language: {
                    search: 'Buscar', 
                    zeroRecords: 'No hay registros para mostrar', 
                    info: 'Mostrando del _START_ a _END_ de _TOTAL_ registros', 
                    buttons: {selectAll: 'Seleccionar todo',selectNone: 'Deseleccionar todo'},
                    infoFiltered: '(Filtrados de _MAX_ registros.)',
                    paginate: { first:'Primero', previous: 'Anterior', next: 'Siguiente', last: 'Último'}
                  }, 
                  buttons: botones
                }">
                <thead>
                  <tr >
                    <th class="border text-center" v-for="(item, index) in cabecera" :key="index">{{item.text}}</th>
                  </tr> 
                </thead>
              </DataTable>
            </div>
          </div>
        </div>  
      </div>
      <DataTableEmailModal /> 
      <DataTableChangeStateModalVue />
    </div>
  </template>
  <script>

  import DataTable from 'datatables.net-vue3'
  import DataTableLib from 'datatables.net-bs5'
  // import Buttons from 'datatables.net-buttons-bs5'
  import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
  import Select from 'datatables.net-select';
  import print from 'datatables.net-buttons/js/buttons.print'
  import pdfmake from 'pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  pdfmake.vfs = pdfFonts.pdfMake.vfs
  import 'datatables.net-responsive-bs5'
  import JsZip from 'jszip'
  window.JSZip = JsZip
  DataTable.use(DataTableLib)
  DataTable.use(pdfmake)
  DataTable.use(ButtonsHtml5)
  DataTable.use(Select);
  import DataTableEmailModal from '@/components/DataTableEmailModal.vue'
  import DataTableChangeStateModalVue from '@/components/DataTableChangeStateModal.vue';
  import { esURL } from '@/helpers/functions';
  const { openDataTableEmailModal } = DataTableEmailModal.setup();
  const { openDataTableChangeStateModal } = DataTableChangeStateModalVue.setup();
  

  // import API from '../api'

  export default {
    name: 'dataTable',
  
    data() {
      return{
        filasSeleccionadas: [],
        columns: [
          { 
            data: null,
            render: function(data, type, row, meta) {
              return `${meta.row + 1}`
            }
          }
        ],
        select: true,
        botones: [
          {
            extend: 'selectAll',
            text: '<i class="fa solid fa-check-square-o"></i> Seleccionar todo',
            className: 'btn btnSelectAll btnDataTable'
          },
          {
            extend: 'selectNone',
            text: '<i class="fa solid fa-times"></i> Deseleccionar todo',
            className: 'btn btnDisSelectAll btnDataTable'
          },
          { 
              text: '<i class="fa solid fa-exchange"></i> Cambiar estado',
              className: 'btn btnCambiarEstado btnDataTable',
              action: (e, dt, node, config) => {

                let rowData = dt.rows('.selected').data().toArray();
                if (rowData.length !== 0) {
                  this.seleccionadas = JSON.stringify(rowData);
                  localStorage.setItem('filasSeleccionadasDTCambioEstado', this.seleccionadas);
                  this.openModaCambiarEstado();
                } else {
                  localStorage.setItem('filasSeleccionadasDTCambioEstado', null);
                }
              },
            //enabled: this.btnDisable.includes('sendEmail') ? false : true 
          },
          { 
              text: '<i class="fa solid fa-envelope"></i> Enviar correo',
              className: 'btn btnSendEmail btnDataTable',
              action: (e, dt, node, config) => {
                let rowData = dt.rows('.selected').data().toArray();
                if (rowData.length !== 0) {
                  this.seleccionadas = JSON.stringify(rowData);
                  localStorage.setItem('filasSeleccionadasDTEnvioEmail', this.seleccionadas);
                  this.openModalEnviarEmail();
                } else {
                  localStorage.setItem('filasSeleccionadasDTEnvioEmail', null);
                }
              },
            //enabled: this.btnDisable.includes('sendEmail') ? false : true 
          },
          {
            //title: this.titulo,
            extend: 'excelHtml5',
            text: '<i class="fa solid fa-file-excel-o"></i> Excel',
            className: 'btn btnDataTable btnExcel'
          },
          {
            //title: this.titulo,
            extend: 'pdfHtml5',
            text: '<i class="fa solid fa-file-pdf-o"></i> PDF',
            className: 'btn btnDataTable btnPdf'
          },
          {
            //title: this.titulo,
            extend: 'print',
            text: '<i class="fa solid fa-print"></i> Imprimir',
            className: 'btn btnDataTable btnPrint'
          },
          {
            //title: this.titulo,
            extend: 'copy',
            text: '<i class="fa solid fa-copy"></i> Copiar',
            className: 'btn btnDataTable btnCopy' 
          }
        ],
        campos: [],

        registrosDataTable: [],
        infoDataTable: [],
      }
    },
    props: {
        info: {
            type: Array
        },
        cabecera: {
            type: Array
        },
        titulo: {
            type: String
        },
        btnsDisable : {
          type: Array
        }
    },
    created() {
      this.registrosDataTable = this.info.map(obj => {
          const {id, ...infoRestante} = obj;

          const urlProps = Object.keys( infoRestante ).filter( key => typeof infoRestante[key] === 'string' && esURL( infoRestante[key]))

          urlProps.forEach(prop => {
            infoRestante[prop] = `<a href='${ infoRestante[prop] }' target='_blank'>Aquí</a>`
          })

          return {
            ...infoRestante
          }
      })
    
      Object.keys(this.registrosDataTable[0]).forEach((campo, index) => {
          this.columns.push({data: campo})
      }) 
    },
    components: {
      DataTable,
      DataTableEmailModal,
      DataTableChangeStateModalVue
    },  
    methods: {
      openModalEnviarEmail() {
        openDataTableEmailModal();
      },
      openModaCambiarEstado() {
        openDataTableChangeStateModal();
      }
    },
    mounted() {
      if(this.btnsDisable){
        this.btnsDisable.forEach(btn => {
          document.querySelector('.' + btn).classList.add('d-none')
        })
      }
    },
  }
  </script>
<style>
  @import 'datatables.net-bs5';
</style>
  