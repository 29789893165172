import monolith from "@/services/axiosConectionMonolith";
import { defineStore } from "pinia";

export const useEstrategiaStore = defineStore('estrategia', {

    state: () => ({

    }),

    actions: {
        async createInscripcionEstrategia(inscripcionEstrategia) {
            try {
                const inscripcionEstrategiaResp = await monolith.post('/crearInscripcionEstrategia', inscripcionEstrategia, {
                    withCredentials: true
                });
                return inscripcionEstrategiaResp.data;
            } catch (error) {
                throw error.message
            }
        },
    },
    persist: true,
})