// import HabilitacionAsignaturas from '../views/Formularios/Estudiantes/HabilitacionAsignaturas.vue';
// import CancelacionAsignaturas from '../views/Formularios/Estudiantes/CancelacionAsignaturas.vue';
import EncuestaDocente from '../views/Formularios/Estudiantes/EncuestaDeDesempenoDocente.vue';
import InscripcionHBV from '../views/Formularios/Estudiantes/InscripcionHBV.vue';
import RutaGraduacion from '../views/Formularios/Estudiantes/RutaGraduacion.vue';

export default [
    // {
    //     path: '/estudiantes/habilitacionAsignaturas',
    //     name: 'habilitaAsignaturas',
    //     component: HabilitacionAsignaturas,
    //     meta : {
    //         title: 'Gestión Curricular - Unidad Virtual / Habilitaciones',
    //         allowedRoles: ['manager','student']
    //     }
    // },
    
    // {
    //     path: '/estudiantes/cancelacionAsignaturas',
    //     name: 'cancelaAsignaturas',
    //     component: CancelacionAsignaturas,
    //     meta : {
    //         title: 'Gestión Curricular - Unidad Virtual / Cancelaciones',
    //         allowedRoles: ['manager','student']
    //     }
    // },

    {
        path: '/estudiantes/encuestaDeDesempenoDocente',
        name: 'encuestaDocente',
        component: EncuestaDocente,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Encuesta de desempeño docentes',
            // allowedRoles: ['manager','student']
            hideNavbar: true
        }
    },

    // {
    //     path: '/estudiantes/inscripcionHBV',
    //     name: 'inscripcionHerramientasBasicasVirtualidad',
    //     component: InscripcionHBV,
    //     meta : {
    //         title: 'Gestión Curricular - Unidad Virtual / Inscripción al curso de Habilidades Básicas para la Virtualidad',
    //         hideNavbar: true
    //     }
    // },  

    {
        path: '/estudiantes/rutaGraduacion',
        name: 'rutaDeGraduacion',
        component: RutaGraduacion,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Requisitos de graduación para programas de modalidad virtual',
            // allowedRoles: ['manager','student']
            hideNavbar: true
        }
    }
]