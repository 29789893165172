import monolith from "@/services/axiosConectionMonolith";
import { defineStore } from "pinia";

export const useCatedraStore = defineStore('catedra', {

    state: () => ({

    }),

    actions: {
        async createInscripcionCatedra(inscripcionCatedra) {
            try {
                const inscripcionCatedraResp = await monolith.post('/crearInscripcionCatedra', inscripcionCatedra, {
                    withCredentials: true
                });
                return inscripcionCatedraResp.data;
            } catch (error) {
                throw error.message
            }
        },
    },
    persist: true,
})