
<template>
    <div class="container pb-5 mt-5">
        <div class="row">
            <div class="col-12">
                <h1>Gestión de cursos</h1>
                <hr>
                <h6>Acciones:</h6>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="rbtnAccion" id="rbtnAccion1">
                    <label class="form-check-label" for="rbtnAccion1">
                        Copias de seguridad por categoría
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="rbtnAccion" id="rbtnAccion2">
                    <label class="form-check-label" for="rbtnAccion2">
                        Reinicio de cursos por categoria
                    </label>
                </div>
                <hr>
                <h6>Plataforma:</h6>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="rbtnPlataforma" id="rbtnPlataforma1">
                    <label class="form-check-label" for="rbtnPlataforma1">
                        Campus Virtual
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="rbtnPlataforma" id="rbtnPlataforma2">
                    <label class="form-check-label" for="rbtnPlataforma2">
                        Pruebas Virtual
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="rbtnPlataforma" id="rbtnPlataforma3">
                    <label class="form-check-label" for="rbtnPlataforma3">
                        UnVirtual
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="rbtnPlataforma" id="rbtnPlataforma4">
                    <label class="form-check-label" for="rbtnPlataforma4">
                        Cvirtual
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <select v-model="idCategoria" class="form-control" @change="cargaCursos($event)">
                    <option value="0" selected>Seleccione categoría</option>
                    <option v-for="(categoria, index) in infoCategoriasMoodle" :key="index" :value="categoria.id">{{ categoria.nombreCategoria }}</option>
                </select>
            </div>
        </div>
    
    </div>
</template>

<script>

import { useMoodleStore } from '@/stores/moodle'
import API from '../../../api'

export default {
    name: 'GestionCursosMoodle',
    data() {
        return {
            moodleStore: null,
            infoCategoriasMoodle: [],
        }
    },
    methods: {
        cargaCursos: function() {
            alert('sd')
        }
    },
    async mounted() {
        this.moodleStore = useMoodleStore();
        this.infoCategoriasMoodle = await this.moodleStore.categories();

        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>