<template>
  <div>
    <div class="container py-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-12 p-5">
          <img v-if="estrategiaActual === 'informacionCientifica'"
            src="../../../../public/img/Estrategias/informacionCientificaFormulario2024.png"
            class="img-fluid align-self-center d-flex" alt="">
          <img v-if="estrategiaActual === 'citasReferenciasAPA'"
            src="../../../../public/img/Estrategias/citasReferenciasAPAFormulario2024.png"
            class="img-fluid align-self-center d-flex" alt="">
          <img v-if="estrategiaActual === 'visibilidadCientifica'"
            src="../../../../public/img/Estrategias/visibilidadCientificaFormulario2024.png"
            class="img-fluid align-self-center d-flex" alt="">

        </div>
        <div class="col-lg-8 col-12">
          <div>
            <h3 class="text-center">Departamento de Biblioteca y Extensión Cultural</h3>
            <h4 class="text-center" v-if="estrategiaActual === 'citasReferenciasAPA'">“Citas y referencias en el estilo
              APA”</h4>
            <h4 class="text-center" v-if="estrategiaActual === 'visibilidadCientifica'">“Visibilidad Científica”</h4>
            <h4 class="text-center" v-if="estrategiaActual === 'informacionCientifica'">“Estrategias de información
              científica”</h4>

            <p>Los cursos virtuales del programa de Alfabetización Informacional (ALFIN) son una estrategia de formación
              que hemos desarrollado pensando en la alta demanda y en la necesidad de que los estudiantes puedan gestionar
              su propia formación, según sus tiempos y necesidades.</p>
            <p v-if="estrategiaActual === 'citasReferenciasAPA'">El curso de “Citas y referencias en el estilo APA” está
              diseñado para dar a conocer los parámetros del estilo de citación de las normas APA (7.a ed.) para la
              presentación de trabajos académicos y de investigación científica. Tiene una duración de 4 horas de trabajo
              autónomo.</p>
            <p v-if="estrategiaActual === 'visibilidadCientifica'">El curso de “Visibilidad Científica” busca desarrollar
              habilidades en estrategias, herramientas tecnológicas y skills comunicativas para aumentar el impacto
              académico de las publicaciones y perfiles de investigadores y docentes. Tiene una duración de 20 horas de
              trabajo autónomo.</p>
            <div v-if="estrategiaActual === 'informacionCientifica'">
              <p>El curso de “Estrategias de información científica” tiene una duración de 12 horas de trabajo autónomo y
                busca desarrollar competencias en el uso de las diferentes herramientas informacionales para apoyar los
                procesos académicos y de investigación. <b>Te recordamos que este curso es de carácter obligatorio para
                  matricular cualquier modalidad de trabajo de grado</b>.</p>
              <h4><b>Importante:</b></h4>
              <p>Recuerde que para lograr aprobar el curso debe cumplir con el <b>80%</b> de las actividades y <b>12
                  horas</b> de navegación dentro del mismo.</p>
            </div>
          </div>
          <!-- <img src="../../../../public/img/itmLogo.png" class="img-fluid d-flex mx-auto" alt=""> -->
        </div>
      </div>

      <div class="row">

        <div class="col-12">
          <!-- <div class="row">
                        <div class="col-12">
                            <div>
                                <h3 class="text-center">Departamento de Biblioteca y Extensión Cultural</h3>
                                <h4 class="text-center" v-if="estrategiaActual === 'citasReferenciasAPA'">“Citas y referencias en el estilo APA”</h4>
                                <h4 class="text-center" v-if="estrategiaActual === 'visibilidadCientifica'">“Visibilidad Científica”</h4>
                                <h4 class="text-center" v-if="estrategiaActual === 'informacionCientifica'">“Estrategias de información científica”</h4>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <p>Los cursos virtuales del programa de Alfabetización Informacional (ALFIN) son una estrategia de formación que hemos desarrollado pensando en la alta demanda y en la necesidad de que los estudiantes puedan gestionar su propia formación, según sus tiempos y necesidades.</p>
                            <p v-if="estrategiaActual === 'citasReferenciasAPA'">El curso de “Citas y referencias en el estilo APA” está diseñado para dar a conocer los parámetros del estilo de citación de las normas APA (7.a ed.) para la presentación de trabajos académicos y de investigación científica. Tiene una duración de 4 horas de trabajo autónomo.</p>
                            <p v-if="estrategiaActual === 'visibilidadCientifica'">El curso de “Visibilidad Científica” busca desarrollar habilidades en estrategias, herramientas tecnológicas y skills comunicativas para aumentar el impacto académico de las publicaciones y perfiles de investigadores y docentes. Tiene una duración de 20 horas de trabajo autónomo.</p>
                            <p v-if="estrategiaActual === 'informacionCientifica'">El curso de “Estrategias de información científica” tiene una duración de 20 horas de trabajo autónomo y busca desarrollar competencias en el uso de las diferentes herramientas informacionales para apoyar los procesos académicos y de investigación. <b>Te recordamos que este curso es de carácter obligatorio para matricular cualquier modalidad de trabajo de grado</b>.</p>
                        </div>
                    </div> -->

          <div class="row">
            <div class="col-12 text-center">
              <p class=""><strong>Diligencie el siguiente formulario, para la inscripción al curso</strong> </p>
            </div>
            <div class="col-lg-6 col-12">

              <label for="txtNombres">Nombres<b class="text-danger">*</b></label> <br>
              <input :oninput="() => (estudiante.nombres = estudiante.nombres.toUpperCase())" class="form-control"
                type="text" id="txtNombres" v-model.trim="estudiante.nombres">
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 mt-3">
              <label for="txtApellidos">Apellidos<b class="text-danger">*</b></label> <br>
              <input :oninput="() => (estudiante.apellidos = estudiante.apellidos.toUpperCase())" class="form-control"
                type="text" id="txtApellidos" v-model.trim="estudiante.apellidos">
            </div>
          </div>
          <div class="row mt-lg-3">
            <div class="col-lg-6 col-12 mt-lg-0 mt-3">
              <label for="ddlTipoDocumento">Tipo de documento de identidad <b class="text-danger">*</b></label> <br>
              <select id="ddlTipoDocumento" class="form-select form-select-lg mb-3"
                v-model.trim="estudiante.tipoDocumentoIdentidad" aria-label=".form-select-lg example">
                <option selected value="">Seleccione una opción</option>
                <option selected value="T.I.">T.I.</option>
                <option selected value="C.C.">C.C.</option>
                <option selected value="C.E.">C.E.</option>
                <option selected value="Pasaporte">Pasaporte</option>
                <option selected value="Otro">Otro</option>
              </select>
            </div>
            <div class="col-lg-6 col-12">
              <label for="txtNumDocumento">Número de documento de identidad <b class="text-danger">*</b></label> <br>
              <input class="form-control" type="number" id="txtNumDocumento" v-model.trim="estudiante.numDocumento">
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mt-lg-0 mt-3">
              <label for="txtCorreo">Correo institucional <b class="text-danger">*</b></label> <br>
              <input class="form-control" type="text" id="txtCorreo" v-model.trim="estudiante.correo">
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 mt-3">
              <label for="txtCelular">Teléfono móvil <b class="text-danger">*</b></label> <br>
              <input class="form-control" type="text" id="txtCelular" v-model.trim="estudiante.celular">
            </div>
          </div>
          <div class="row mt-lg-3">
            <div class="col-lg-6 col-12 mt-lg-0 mt-3">
              <label for="txtTelefonoFijo">Teléfono fijo <b class="text-danger">*</b></label> <br>
              <input class="form-control" type="text" id="txtTelefonoFijo" v-model.trim="estudiante.telefonoFijo">
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 mt-3">
              <label for="ddlProgramaAcademico">Programa académico <b class="text-danger">*</b></label> <br>
              <select id="ddlProgramaAcademico" class="form-select form-select-lg mb-3"
                v-model.trim="estudiante.programaAcademico" aria-label=".form-select-lg example">
                <option selected value="">Seleccione una opción</option>
                <option v-for="(programaAcademico, index) of programasAcademicos" :key="index">{{ programaAcademico.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <div v-if="faltantes" class="alert alert-danger" role="alert">
                <b>Todos los campos son de carácter obligatorio</b>
              </div>
            </div>
            <div class="col-12">
              <div v-if="correoErrado" class="alert alert-danger" role="alert">
                <b>El correo electrónico debe ser el institucional</b> <b>'ejemplo@correo.itm.edu.co'</b>
              </div>
            </div>
            <div class="col-12">
              <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                <b>{{ mensajeError }}</b>
              </div>
            </div>
            <div class="col-12">
              <button class="btn d-flex mx-auto btnanimacion btn-enviar2"
                @click="crearRegistro"><b>Registrarme</b></button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-lg-4">
              <img src="../../../../public/img/Catedras/LOGO_80.png" class="img-fluid d-flex mx-auto" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>

import { useEstrategiaStore } from '@/stores/estrategias';

import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert'
import { obtenerSemestreActual } from '@/helpers/functions';

export default {

  name: 'formularioConversemos',

  data() {
    return {
      estrategiaStore: null,

      registrosCatedras: [],
      estudiante: {
        nombres: "",
        apellidos: "",
        tipoDocumentoIdentidad: "",
        numDocumento: "",
        correo: "",
        celular: "",
        telefonoFijo: "",
        programaAcademico: "",
        semestre: obtenerSemestreActual(),
      },
      estrategiaActual: '',
      pathsValidosEstrategias: [
        'citasReferenciasAPA',
        'visibilidadCientifica',
        'informacionCientifica'
      ],
      programasAcademicos: [
        { value: 'Administración Tecnológica', text: 'Administración Tecnológica' },
        { value: 'Artes de la Grabación y Producción Musical', text: 'Artes de la Grabación y Producción Musical' },
        { value: 'Artes Visuales', text: 'Artes Visuales' },
        { value: 'Cine', text: 'Cine' },
        { value: 'Contaduría Pública', text: 'Contaduría Pública' },
        { value: 'Desarrollo de Aplicaciones para dispositivos móviles - Virtual', text: 'Desarrollo de Aplicaciones para dispositivos móviles - Virtual' },
        { value: 'Tecnología en Análisis de Costos y Presupuestos', text: 'Tecnología en Análisis de Costos y Presupuestos' },
        { value: 'Tecnología en Calidad', text: 'Tecnología en Calidad' },
        { value: 'Tecnología en Construcción de Acabados Arquitectónicos', text: 'Tecnología en Construcción de Acabados Arquitectónicos' },
        { value: 'Tecnología en Diseño Industrial', text: 'Tecnología en Diseño Industrial' },
        { value: 'Tecnología en Diseño y programación de soluciones de software como servicio SAAS', text: 'Tecnología en Diseño y programación de soluciones de software como servicio SAAS' },
        { value: 'Tecnología en Electromecánica', text: 'Tecnología en Electromecánica' },
        { value: 'Tecnología en Electrónica', text: 'Tecnología en Electrónica' },
        { value: 'Tecnología en Gestión Administrativa', text: 'Tecnología en Gestión Administrativa' },
        { value: 'Tecnología en Informática Musical', text: 'Tecnología en Informática Musical' },
        { value: 'Tecnología en Mantenimiento en Equipo Biomédico', text: 'Tecnología en Mantenimiento en Equipo Biomédico' },
        { value: 'Tecnología en Producción', text: 'Tecnología en Producción' },
        { value: 'Tecnología en Sistemas de Información ', text: 'Tecnología en Electromecánica' },
        { value: 'Tecnología en Desarrollo de Software', text: 'Tecnología en Desarrollo de Software' },
        { value: 'Tecnología en Telecomunicaciones', text: 'Tecnología en Telecomunicaciones' },
        { value: 'Ingeniería Biomédica', text: 'Ingeniería Biomédica' },
        { value: 'Ingeniería de Producción', text: 'Ingeniería de Producción' },
        { value: 'Ingeniería de Sistemas', text: 'Ingeniería de Sistemas' },
        { value: 'Ingeniería de Telecomunicaciones', text: 'Ingeniería de Telecomunicaciones' },
        { value: 'Ingeniería Electromecánica', text: 'Ingeniería Electromecánica' },
        { value: 'Ingeniería Electrónica', text: 'Ingeniería Electrónica' },
        { value: 'Ingeniería en Calidad', text: 'Ingeniería en Calidad' },
        { value: 'Ingeniería en Diseño Industrial', text: 'Ingeniería en Diseño Industrial' },
        { value: 'Ingeniería en Mantenimiento de Equipo Biomédico', text: 'Ingeniería en Mantenimiento de Equipo Biomédico' },
        { value: 'Ingeniería Financiera y de Negocios', text: 'Ingeniería Financiera y de Negocios' },
        { value: 'Ingeniería Mecatrónica', text: 'Ingeniería Mecatrónica' },
        { value: 'Interpretación y Traducción Lengua de Señas Colombiana - español', text: 'Interpretación y Traducción Lengua de Señas Colombiana - español' },
        { value: 'Especialización en Finanzas', text: 'Especialización en Finanzas' },
        { value: 'Especialización en Formulación y Evaluación de Proyectos', text: 'Especialización en Formulación y Evaluación de Proyectos' },
        { value: 'Especialización en Infraestructura Hospitalaria', text: 'Especialización en Infraestructura Hospitalaria' },
        { value: 'Maestría en Estudios de Ciencia, Tecnología, Sociedad e Innovación', text: 'Maestría en Estudios de Ciencia, Tecnología, Sociedad e Innovación' },
        { value: 'Maestría en Artes Digitales', text: 'Maestría en Artes Digitales' },
        { value: 'Maestría en Gestión de la Innovación Tecnológica, Cooperación y Desarrollo Regional', text: 'Maestría en Gestión de la Innovación Tecnológica, Cooperación y Desarrollo Regional' },
        { value: 'Maestría en Ciencias: Innovación en Educación', text: 'Maestría en Ciencias: Innovación en Educación' },
        { value: 'Maestría en Gestión de las Organizaciones', text: 'Maestría en Gestión de las Organizaciones' },
        { value: 'Maestría en Desarrollo Sostenible', text: 'Maestría en Desarrollo Sostenible' },
        { value: 'Maestría en Ingeniería Biomédica', text: 'Maestría en Ingeniería Biomédica' },
        { value: 'Maestría en Automatización y Control Industrial', text: 'Maestría en Automatización y Control Industrial' },
        { value: 'Maestría en Gestión Energética Industrial', text: 'Maestría en Gestión Energética Industrial' },
        { value: 'Maestría en Seguridad Informática', text: 'Maestría en Seguridad Informática' },
        { value: 'Química Industrial', text: 'Química Industrial' },
        { value: 'Ciencias Ambientales', text: 'Ciencias Ambientales' },
        //   { value: 'Tecnología en Control de Calidad', text: 'Tecnología en Control de Calidad' },
        //   { value: 'Tecnología en Automatización Electrónica', text: 'Tecnología en Electromecánica' },
        //   { value: 'Tecnología en Automatización Electrónica', text: 'Tecnología en Automatización Electrónica' },
        //   { value: 'Tecnología en desarrollo de aplicaciones para dispositivos móviles', text: 'Tecnología en desarrollo de aplicaciones para dispositivos móviles' },
        //   { value: 'Tecnología en Diseño y Programación de Soluciones de Software como Servicio - SaaS - Virtual', text: 'Tecnología en Diseño y Programación de Soluciones de Software como Servicio - SaaS - Virtual' },
        //   { value: 'Lengua de Señas Colombiana', text: 'Lengua de Señas Colombiana' },
      ],
      faltantes: false,
      correoErrado: false,
      mensajeError: null
    }
  },
  methods: {
    crearRegistro: function () {
      this.faltantes = false;
      this.mensajeError = null;

      if (this.estudiante.nombres === "" ||
        this.estudiante.apellidos === "" ||
        this.estudiante.numDocumento === "" ||
        this.estudiante.correo === "" ||
        this.estudiante.celular === "" ||
        this.estudiante.telefonoFijo === "" ||
        this.estudiante.programaAcademico === "" ||
        this.estrategiaActual === "") { return this.faltantes = true; }

      const correoIncorrecto = this.validaCorreoInstitucional();
      if (correoIncorrecto) return this.correoErrado = true;

      sweetAlertConfirm(
        '¿Desea enviar la información diligenciada?',
        'Los datos diligenciados se enviarán para su respectiva inscripción al curso.',
        'warning',
        'Enviar'
      ).then(async (result) => {
        if (result.isConfirmed) {
          this.estudiante.estrategia = this.estrategiaActual;

          const inscripcion = await this.estrategiaStore.createInscripcionEstrategia(this.estudiante);

          if (inscripcion.message) {
            this.mensajeError = inscripcion.message;
          } else {
            sweetAlertSuccess(
              '¡Datos enviados!',
              'Los datos diligenciados se han enviado de manera exitosa',
              'success'
            ).then(() => {
              location.reload();
            });
          }
        } else (
          result.dismiss === Swal.DismissReason.cancel
        )
      })
    },

    validaCorreoInstitucional: function () {
      if (this.estudiante.correo.split('@')[1] !== "correo.itm.edu.co") return true;
    },
  },
  async mounted() {
    const rutaActual = this.$route.params.estrategia;
    !this.pathsValidosEstrategias.includes(rutaActual)
      ? this.$router.push({ path: '/estrategias' })
      : this.estrategiaActual = rutaActual

    this.estrategiaStore = useEstrategiaStore();

    document.title = this.$route.meta.title // Cambiar el título de la página
  }
}
</script>
        
        
      