<template>
    <div class="container pb-5">
      <div class="row">
          <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante el formulario de  
              <em><b>Requisitos de graduación para programas de modalidad virtual</b></em>.</p>
          </div>
        </div>
      <div class="row">
        <div class="col-12">
            <DataTable v-if="infoRutasGraduaciones.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoRutasGraduaciones" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
        </div>  
      </div> 
    </div>
</template>


<script>
//Importando API que conecta front con back
import API from '../../../api'
import DataTable from '../../../components/DataTable'

export default {
    name: 'infoRutasGraduaciones',

    data() {
        return{
            btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
            infoRutasGraduaciones: [],
            noData: false,
            cabeceraDatatable: [
                {text: 'N°'},
                {text: 'Nombre Estudiante'},
                {text: 'Correo Estudiante'},
                {text: 'Teléfono Estudiante'},
                {text: 'Documento Estudiante'},
                {text: 'Programa Académico'},
                {text: 'Semestre'},
                {text: 'Curso Cátedra Abierta de Ciudadanía y Paz'},
                {text: 'Curso Cátedra de Deporte y Cultura'},
                {text: 'Curso Estrategias de Información Científica'},
                {text: '¿Conoces la resolución de modalidades de trabajo de grado de tu facultad?'},
                {text: '¿Te encuentras inscrito en alguna modalidad de trabajo de grado de tu facultad?'},
                {text: '¿Tienes matriculada la asignatura Trabajo de grado?'},
                {text: '¿Te encuentras inscrito para las pruebas TyT?'},
                {text: '¿Te encuentras inscrito a la ceremonia de grados?'},
                {text: 'Observaciones y comentarios'},
                {text: 'Fecha de Registro'}
            ],
            tituloReporteDataTable: 'Reporte de requisitos de graduación para programas de modalidad virtual'
        }
    },
    components: {
        DataTable
    },
    created() {
        this.verInfo()
    },
    methods: {
        verInfo: async function() {
            this.infoRutasGraduaciones = await API.fetchInfoRutasGraduaciones();
            this.infoRutasGraduaciones.length === 0 ? this.noData = true : this.noData = false;
        }
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>