import microservice from "@/services/axiosConectionMicroservice";
import monolith from "@/services/axiosConectionMonolith";

const setupInterceptors = (instance) => {
    let refresh = false;
  
    instance.interceptors.response.use(
      (resp) => resp,
      async (error) => {
        if (error.response.status === 401 && !refresh) {
          refresh = true;
          const { status, data } = await instance.post('/auth/refreshToken', {}, {
            withCredentials: true,
          });
  
          if (status === 200) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
            refresh = false; // Reset refresh flag
            return instance(error.config); // Reintentar la solicitud original
          }
        }
        refresh = false;
        return Promise.reject(error);
      }
    );
  };
  

  setupInterceptors(microservice);
  setupInterceptors(monolith);