import CargaMasivaUsuarios from '../views/Formularios/Gestores/CargaMasivaUsuarios.vue';
import GestionCursosMoodle from '../views/Formularios/Gestores/GestionCursosMoodle.vue';
import AcompanamientoDocente from '../views/Formularios/Gestores/AcompanamientoDocente.vue'

import InformacionHabilitaciones from '../views/Informes/Gestores/InfoHabilitaciones.vue';
import InformacionCancelaciones from '../views/Informes/Gestores/InfoCancelaciones.vue';
import InformacionEncuesta from '../views/Informes/Gestores/InfoEncuesta.vue';
import InformacionInscripcionHBV from '../views/Informes/Gestores/InfoInscripcionHBV.vue';
import InformacionRecepcionesGDCA from '../views/Informes/Gestores/InfoRecepcionGDCA.vue';
import InformacionNotariaDocente from '../views/Informes/Gestores/InfoNotariaDocente.vue';
import InformacionUsuarioCurso from '../views/Informes/Gestores/InfoUsuarioCurso.vue';
import InformacionApoyoExamenesHabilitaciones from '../views/Informes/Gestores/InfoApoyoExamenesHabilitaciones.vue';
import InformacionRutaGraduacion from '../views/Informes/Gestores/InfoRutaGraduacion.vue';
import InformacionAsistenciaInduccionDocente from '../views/Informes/Gestores/InfoAsistenciaInduccionDocente.vue';
import InformacionInscripcionCatedras from '../views/Informes/Gestores/InfoInscripcionCatedras.vue'
import InformacionInscripcionEstrategias from '../views/Informes/Gestores/InfoInscripcionEstrategias.vue'
import InformacionAcompanamientoDocente from '../views/Informes/Gestores/InfoAcompanamientoDocente.vue'
import InformacionSolicitudAulaVirtual from '../views/Informes/Gestores/InfoSolicitudAulaVirtual.vue'
import InformacionSolicitudesUDEV from '../views/Informes/Gestores/InfoSolicitudesUDEV.vue'
import InformacionEncuestaSatisfaccion from '../views/Informes/Gestores/InfoEncuestaSatisfaccion.vue'
import InfoEncuestaCaracterizacionDigital from '@/views/Informes/Gestores/InfoEncuestaCaracterizacionDigital.vue';
import InfoDepartamentoDePersonal from '@/views/Informes/Gestores/InfoDepartamentoDePersonal.vue';
import InfoDocenteCurso from '@/views/Informes/Gestores/InfoDocenteCurso.vue';

export default [
    {
        path: '/gestores/cargaMasivaUsuarios',
        name: 'CargaMasivaUsuariosMoodle',
        component: CargaMasivaUsuarios,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Carga masiva de usuarios Moodle',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/gestionCursos',
        name: 'gestionCursosMoodle',
        component: GestionCursosMoodle,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Gestor',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionEncuestaDocente',
        name: 'infoEncuesta',
        component: InformacionEncuesta,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Encuesta Docente',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionInscripcionHBV',
        name: 'infoInscripcionHBV',
        component: InformacionInscripcionHBV,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe inscripciones para el curso HBV',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionRecepcionGDCA',
        name: 'infoRecepcionGDCA',
        component: InformacionRecepcionesGDCA,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Guías Didácticas y Compromisos Académicos',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionNotariaDocente',
        name: 'infoNotariaDocente',
        component: InformacionNotariaDocente,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Notaría Docentes',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionHabilitaciones',
        name: 'infoHabilitaciones',
        component: InformacionHabilitaciones,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Habilitaciones',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionCancelaciones',
        name: 'infoCancelaciones',
        component: InformacionCancelaciones,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Cancelaciones',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionUsuarioCurso',
        name: 'infonUsuarioCurso',
        component: InformacionUsuarioCurso,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Usuarios por Curso',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/apoyoExamenesHabilitaciones',
        name: 'infoApoyoExamanesDeHabilitaciones',
        component: InformacionApoyoExamenesHabilitaciones,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Apoyo para exámenes de habilitacione APV',
            allowedRoles: ['manager','editingteacher']
        }
    },

    {
        path: '/gestores/informes/informacionRutasGraduaciones',
        name: 'infoRutasGraduaciones',
        component: InformacionRutaGraduacion,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe - Requisitos de graduación para programas de modalidad virtual',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionAsistenciaInduccionDocentes',
        name: 'infoAsistenciaInduccionDocentes',
        component: InformacionAsistenciaInduccionDocente,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe - Asistencia momentos de inducción docente',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionInscripcionCatedras',
        name: 'infoInscripcionCatedras',
        component: InformacionInscripcionCatedras,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe - Inscripciones para cátedras',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionInscripcionEstrategias',
        name: 'infoInscripcionEstrategias',
        component: InformacionInscripcionEstrategias,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe - Inscripciones para estrategias',
            allowedRoles: ['manager']
        }
    },
    
    {
        path: '/gestores/formularios/acompanamientoDocentes',
        name: 'acompanamientoDocentes',
        component: AcompanamientoDocente,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Formulario - Acompañamiento docentes',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/acompanamientoDocentes',
        name: 'infoAcompanamientoDocentes',
        component: InformacionAcompanamientoDocente,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe - Acompañamiento docentes',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/solicitudAulaVirtual',
        name: 'infoSolicitudAulaVirtual',
        component: InformacionSolicitudAulaVirtual,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe - Solicitud aula virtual',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/solicitudesUnidadDeEducacionVirtual',
        name: 'infoSolicitudesUnidadDeEducacionVirtual',
        component: InformacionSolicitudesUDEV,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Solicitudes a Unidad de Educación Virtual',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionEncuestaSatisfaccion',
        name: 'infoEncuestaSatisfaccion',
        component: InformacionEncuestaSatisfaccion,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Informe Encuesta de Satisfacción',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionCaracterizacionDigital',
        name: 'informacionEncuestaCD',
        component: InfoEncuestaCaracterizacionDigital,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Encuesta de caracterización digital',
            allowedRoles: ['manager']
        }
    },
    
    {
        path: '/gestores/informes/informacionDepartamentoDePersonal',
        name: 'informacionDepartamentoPersonal',
        component: InfoDepartamentoDePersonal,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Departamento de personal',
            allowedRoles: ['manager']
        }
    },

    {
        path: '/gestores/informes/informacionDocenteCurso',
        name: 'informacionDocenteCurso',
        component: InfoDocenteCurso,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Información docente-curso',
            allowedRoles: ['manager']
        }
    }
]