<template>
    <div class="container-fluid homePage pt-5">
        <div class="row justify-content-center align-items-center mt-4">
            <div class="col-lg-4 col-md-12 col-12 d-lg-block d-none">
                <img src="img/img-estudiantes.png" class="img-fluid d-flex mx-auto animated fadeInUp"
                    alt="Imagen formulario Gestión Curricular ITM">
            </div>
            <div class="col-lg-8 col-md-12 col-12 mt-lg-4 mt-0 mt-lg-0 animated fadeInLeft">
                <h1 class="text-center text-white">Gestión curricular - Educación Virtual</h1>
                <h3 class="text-white">Informes:</h3>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionHabilitaciones" class="botonanimado my-2 text-decoration-none text-white">Habilitaciones</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionCancelaciones" class="botonanimado my-2 text-decoration-none text-white">Cancelaciones</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionEncuestaDocente" class="botonanimado my-2 text-decoration-none text-white">Encuesta Docente</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionNotariaDocente" class="botonanimado my-2 text-decoration-none text-white">Notaría Docente</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionInscripcionHBV" class="botonanimado my-2 text-decoration-none text-white">Curso de herramientas básicas para estar en la virtualidad</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionRecepcionGDCA" class="botonanimado my-2 text-decoration-none text-white">Guías Didacticas y Compromiso Académico</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionUsuarioCurso" class="botonanimado my-2 text-decoration-none text-white">Acompañamiento estudiantes</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/apoyoExamenesHabilitaciones" class="botonanimado my-2 text-decoration-none text-white">Exámenes de habilitación APV</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionRutasGraduaciones" class="botonanimado my-2 text-decoration-none text-white">Requisitos de graduación para APV</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionAsistenciaInduccionDocentes" class="botonanimado my-2 text-decoration-none text-white">Asistencia a momentos de inducción docente</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionInscripcionCatedras" class="botonanimado my-2 text-decoration-none text-white">Inscripciones a cátedras</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionInscripcionEstrategias" class="botonanimado my-2 text-decoration-none text-white">Inscripciones a estrategias</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/solicitudAulaVirtual" class="botonanimado my-2 text-decoration-none text-white">Solicitud de aula virtual</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/solicitudesUnidadDeEducacionVirtual" class="botonanimado my-2 text-decoration-none text-white">Solicitudes a Unidad de Educación Virtual</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionEncuestaSatisfaccion" class="botonanimado my-2 text-decoration-none text-white">Encuestas de satisfaccion UDEV</router-link></h4></li>

                <li class="my-3"><h4><router-link to="/gestores/formularios/acompanamientoDocentes" class="botonanimado my-2 text-decoration-none text-white">Acompañamiento docentes - Formulario</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/acompanamientoDocentes" class="botonanimado my-2 text-decoration-none text-white">Acompañamiento docentes - Informe</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionCaracterizacionDigital" class="botonanimado my-2 text-decoration-none text-white">Encuesta de caracterización digital - Informe</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionDepartamentoDePersonal" class="botonanimado my-2 text-decoration-none text-white">Departamento de personal - Informe</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/gestores/informes/informacionDocenteCurso" class="botonanimado my-2 text-decoration-none text-white">Información docente/curso - Informe</router-link></h4></li>
                
                <h3 class="text-white mt-5">Enlaces publicos a formularios:</h3>
                <li class="my-3"><h4><router-link to="/docentes/induccionDocente" class="botonanimado my-2 text-decoration-none text-white">Asistencia a momentos de inducción docente</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/estudiantes/rutaGraduacion" class="botonanimado my-2 text-decoration-none text-white">Requisitos de graduación para programas de modalidad virtual</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/estudiantes/inscripcionHBV" class="botonanimado my-2 text-decoration-none text-white">Herramientas básicas para estar en la virtualidad</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/estudiantes/encuestaDeDesempenoDocente" class="botonanimado my-2 text-decoration-none text-white">Encuesta de desempeño docente</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/generales/encuestaSatisfaccion" class="botonanimado my-2 text-decoration-none text-white">Encuesta de satisfacción UDEV</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/docentes/recepcionGDCA" class="botonanimado my-2 text-decoration-none text-white">Recepción de guías didácticas y compromiso académico</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/docentes/notariaDocente" class="botonanimado my-2 text-decoration-none text-white">Notaría por parte del docente</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/docentes/solicitudAulaVirtual" class="botonanimado my-2 text-decoration-none text-white">Solicitud de aula virtual</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/generales/solicitudUnidadDeEducacionVirtual" class="botonanimado my-2 text-decoration-none text-white">Solicitudes a la Unidad de Educación Virtual</router-link></h4></li>
                <li class="my-3"><h4><router-link to="/generales/encuestaCaracterizacionDigital" class="botonanimado my-2 text-decoration-none text-white">Encuesta de caracterización digital</router-link></h4></li>
                
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>

import Footer from '@/components/Footer.vue'

export default {
    name: 'inicioGestores',
    components: {
        Footer
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>
