const swal = require('sweetalert2');
window.Swal = swal;

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btnConfirmSweetAlert mx-2',
      cancelButton: 'btn btnCancelSweetAlert'
    },
    buttonsStyling: false
})

const sweetAlertConfirm = async (title, text, icon, txtButtonAceptar, textoAviso = '') => {
    const alert = await swalWithBootstrapButtons.fire({
        title: title,
        html: textoAviso + text,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: txtButtonAceptar,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    })

    return alert;
}

const sweetAlertSuccess = async (title, text, icon) => {
    const alert = await swalWithBootstrapButtons.fire(
        title,
        text,
        icon
    )
}

module.exports = {
    sweetAlertConfirm,
    sweetAlertSuccess
}