<template>
    <div class="container-fluid backloguin">

        <div class="bg-white p-5 rounded-3 text-secondary shadow animated fadeInUp">    
            <div class="d-flex justify-content-center"> <img src="../../../public/img/IconLoguin.png" alt="login-icon"> </div>
            <form @submit.prevent="login" class="mt-4">
                <div class="mb-3">
                    <label for="txtNombreUsuario" class="form-label">Nombre de usuario (Username)</label>
                    <input v-model.trim="authData.username" type="text" class="form-control" id="txtNombreUsuario" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="txtPassword" class="form-label">Password</label>
                    <input v-model.trim="authData.password" type="password" class="form-control" id="txtPassword">
                </div>
                <div class="mb-3 form-check">
                    <input v-model="verPassword" type="checkbox" class="form-check-input" id="chkVerPassword">
                    <label class="form-check-label" for="chkVerPassword">Ver contraseña</label>
                </div>
                <div class="alert alert-danger" role="alert" v-if="error !== null">
                    {{ error }}
                </div>
                <button type="submit" class="btn btningresar text-white w-100 mt-4 fw-semibold shadow-sm">Ingresar</button>            
            </form>
        </div>
        
    </div>
</template>

<script>
    // import API from '@/api';
    import { useAuthStore } from '@/stores/auth';
    import { pathsLogin } from '../../commons/pathsLogin';
   
    export default {
        name: "loginUsuarios",
        data () {
            return {
                authData: {
                    username: '',
                    password: ''
                },
                error: null,
                verPassword: false,
                authStore: null
            }
        },
        methods: {
            login: async function (event) {

                this.error = null;
                const loginUsuario = await this.authStore.login(this.authData)
                
                if(loginUsuario && loginUsuario.message) {
                    return this.error = loginUsuario.message;
                }

                const { roles } = this.authStore.userDetail;

                const numRoles = Object.values(roles).length;

                // Si tiene varios perfiles se redirecciona a la vista de seleccion de perfil
                if(numRoles > 1) return this.$router.push({path: '/inicio'});

                // Si solo tiene un perfil se redirecciona a la vista correspondiente y se setea ese role en el store
                this.authStore.setCurrentRole(roles[0])
                this.$router.push({path: pathsLogin[roles[0]]});
            },
        },
        mounted () {
            this.authStore = useAuthStore();
            document.title = this.$route.meta.title // Cambiar el título de la página
        },
        watch: {
            verPassword (valor) {
                let inputPass = document.querySelector('#txtPassword');
                valor ? inputPass.type = 'text' : inputPass.type = 'password'
            }
        }
    }
</script>