<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los docentes mediante el formulario de <em><b>Solicitud de aula virtual en la plataforma Cvirtual</b></em>.</p>
          </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
            <div v-if="infoSolicitudAulaVirtual.length != 0" class="">
              <DataTable v-if="infoSolicitudAulaVirtual.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoSolicitudAulaVirtual" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>

<script>

//Importando API que conecta front con back
// import API from '../../../api'
import { useGestorStore } from '@/stores/gestores';
import DataTable from '../../../components/DataTable'

export default {
  name: 'infoSolicitudAulaVirtual',

  data() {
    return{
        gestorStore: null,

        btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
        infoSolicitudAulaVirtual: [],
        noData: false,
        cabeceraDatatable: [
            {text: 'N°'},
            {text: 'Nombre'},
            {text: 'Documento'},
            {text: 'correo'},
            {text: 'Nombre Asignatura'},
            {text: 'Codigo Grupo Asignatura'},
            {text: 'Facultad'},
            {text: 'Programa Académico'},
            {text: 'Formato de Aula'},
            {text: 'Matriculado en Curso Docentes'},
            {text: 'Horario de Capacitación'},
            {text: 'Nombre Código Aula Origen'},
            {text: 'Nombre Código Aula Destino'},
            {text: 'Fecha de Registro'}
        ],
        tituloReporteDataTable: 'Reporte de solicitudes de aulas virtuales en Cvirtual'
    }
  },
  components: {
    DataTable
  },
  created() {
    this.verInfo()
  },
  methods: {
    verInfo: async function() {
        this.noData = false;
        this.gestorStore = useGestorStore();
        this.infoSolicitudAulaVirtual = await this.gestorStore.fetchSolicitudAulaVirtual();
        if(this.infoSolicitudAulaVirtual.length === 0) return this.noData = true;
    }
  },
  mounted() {
    document.title = this.$route.meta.title // Cambiar el título de la página
  }
}
</script>
