const Compressor = require('compressorjs');
const html2canvas = require('html2canvas');


const generarIdAleatorioAlfaNumerico = ( longitud ) => {
    let caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';

    for( let i = 0; i < longitud; i++ ) {
        let indice = Math.floor(Math.random() * caracteres.length);
        id += caracteres.charAt(indice);
    }

    return id;
}

const generarIdNumericoConsecutivo = ( consecutivo, longitud ) => {
    let id = consecutivo.toString().padStart( longitud, '0' );
    return id;
}

const extraerConsecutivoIdAletorioNumerico = ( consecutivo ) => {
    return parseInt( consecutivo );
}

const validarEmail = (email) => {
    let mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (email.match(mailformat)) {
        return true;
    }
    else {
        return false;
    }
}

const validarEmailCorrecto = (email) => {
    const regex = /^(?=[a-zA-Z0-9._%+-]{1,64}@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

const verificarEmail = (email1, email2) => {
    if (email1 === email2) return true;
    else return false;
}
const validarEmailInstitucional = (email) => {
    if(email.split('@')[1] !== "correo.itm.edu.co" && email.split('@')[1] !== "itm.edu.co") return false;
    return true;
}

const cadenaToUpperCase = (cadena) => {
    let cadenaUppercase = cadena.trim().toUpperCase();
    return cadenaUppercase;
}

const obtenerSemestreActual = () => {
    let fechaActual = obtenerFechaActual();
    let ano = fechaActual.getFullYear();
    let mes = fechaActual.getMonth() + 1;
    return `${ano}-${ mes > 6 ? 2 : 1 }`
}

const obtenerFechaActual = () => {
    return new Date()
}

const transformaArrayPorCamposEspecificos = ( array = [], campos = []) => {
    const nuevoArray = array.map( obj => {
        
        let nuevoObjeto = {};

        Object.keys( obj ).forEach( key => {
            if( campos.includes( key ) ) {
                nuevoObjeto[ key ] = obj[ key ];
            }
        })

        return nuevoObjeto;
    })

    return nuevoArray;
}

const ordenarAlfabeticamenteArrayPorPropiedad = (array, propiedad) => {
    return array.sort((a, b) => {
        const propiedadA = a[propiedad].toLowerCase();
        const propiedadB = b[propiedad].toLowerCase();
    
        if (propiedadA < propiedadB) return -1;
        if (propiedadA > propiedadB) return 1;
        return 0;
    });
}

// Function to convert a data URL to a Blob
const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

const comprimirArchivo = (archivo) => {
    return new Promise((resolve, reject) => {
        new Compressor(archivo, {
            quality: 0.7, 
            maxWidth: 800, 
            maxHeight: 800, 
            success: (result) => {
                const compressedBlob = result instanceof Blob ? result : new Blob([result]);

                // Create a File object with the desired file name
                const compressedFile = new File([compressedBlob], archivo.name, {
                    type: archivo.type // Adjust the MIME type if needed
                });

                resolve(compressedFile)
            },
            error: (error) => {
                // console.log('Error al comprimir la imagen:', error.message);
                reject(error.message)
            },
        });
    })
}
const validaTamanoArchivo = ( archivo ) => {
    return archivo.size / 1024 / 1024 < 2;
}

const validaExtensionArchivo = ( archivo, extensionesValidas ) => {
     // Valida extensión de archivo
    const nombreArchivo = archivo.name.split('.');
    const extensionArchivo = nombreArchivo[ nombreArchivo.length - 1 ];

    if( !extensionesValidas.includes(extensionArchivo) ) {
        return false;
    }

    return true;
}

const obtenerExtensionArchivo = ( archivo ) => {
    const nombreArchivo = archivo.name.split('.');
    return nombreArchivo[ nombreArchivo.length - 1 ];
}

const esURL = ( cadena ) => {
    const expresionRegularURL = /^(ftp|http|https):\/\/[^ "]+$/;
    const cadenaSinEspacios = cadena.replace(/ /g, "");
    return expresionRegularURL.test( cadenaSinEspacios );
}; 

const capturaSnapshot = async (contenidoParaCapturar, nombreParaArchivo) => {
    const canvas = await html2canvas(contenidoParaCapturar);

    // Convirtiendo la imagen capturada a Blob
    let snapshotUrl = canvas.toDataURL('image/png');
    const blob = dataURItoBlob(snapshotUrl);

    // Creando archivo desde blob creado
    let snapshotFile = new File([blob], nombreParaArchivo, { type: 'image/png' });
    return snapshotFile;
}

const obtenerFacultades = () => {
    return facultades = [
        {value: 'Facultad de Ingenierías', text: 'Facultad de Ingenierías'},            
        {value: 'Facultad de Artes y Humanidades', text: 'Facultad de Artes y Humanidades'},       
        {value: 'Facultad de Ciencias Económicas y Administrativas', text: 'Facultad de Ciencias Económicas y Administrativas'},       
        {value: 'Faculta de Ciencias Exactas y Aplicadas', text: 'Faculta de Ciencias Exactas y Aplicadas'},       
        {value: 'Facultad de Prueba', text: 'Facultad de Prueba'},       
    ]
}


module.exports = {
    cadenaToUpperCase,
    capturaSnapshot,
    comprimirArchivo,
    dataURItoBlob,
    esURL,
    extraerConsecutivoIdAletorioNumerico,
    generarIdAleatorioAlfaNumerico,
    generarIdNumericoConsecutivo,
    obtenerExtensionArchivo,
    obtenerFacultades,
    obtenerSemestreActual,
    ordenarAlfabeticamenteArrayPorPropiedad,
    transformaArrayPorCamposEspecificos,
    validaExtensionArchivo,
    validarEmail,
    validarEmailInstitucional,
    validaTamanoArchivo,
    verificarEmail,
    validarEmailCorrecto
}