<template>
  <div class="container pb-5">
    <div class="row">
      <div class="p-4 mt-4">
          <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante el formulario de  
          <em><b>Cancelaciones</b></em>.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
          <div v-if="infoCancelaciones.length != 0">
                <DataTable v-if="infoCancelaciones.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoCancelaciones" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
          </div>
      </div>  
    </div> 
  </div>
</template>
<script>

//Importando API que conecta front con back
import API from '../../../api'
import DataTable from '../../../components/DataTable'

export default {
  name: 'infoCancelaciones',

  data() {
    return{
      btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
      infoCancelaciones: [],
      cabeceraDatatable: [
        {text: 'N°'},
        {text: 'Código Sia'},
        {text: 'Asignatura'},
        {text: 'Docente'},
        {text: 'Cédula estudiante'},
        {text: 'Nombre estudiante'},
        {text: 'Fecha cancelación'},
        {text: 'Fecha registro'},
      ],
      tituloReporteDataTable: 'Reporte de cancelaciones'
    }
  },
  components: {
      DataTable
  },  
  created() {
      this.verInfo()
  },
  methods: {
    verInfo: async function() {
      this.infoCancelaciones = await API.fetchInfoCancelaciones();

      this.infoCancelaciones = this.infoCancelaciones.map(cancelacion => { 
        const {nombreEstudiante,apellidoEstudiante, tipoAsignatura , estadoAsignatura,...resto } = cancelacion;
        const nombreEst = nombreEstudiante + " " + apellidoEstudiante;

        return {
          codigoSia: resto.codigoSia,
          programa: resto.programa,
          docente: resto.docente,
          documentoEstudiante: resto.documentoEstudiante,
          nombreEstudiante: nombreEst,
          fechaCancelacion: resto.fechaCancelacion,
          fechaRegistro: resto.fechaRegistro.split('T')[0],
        };
      })
    }
  },
  mounted() {
    document.title = this.$route.meta.title // Cambiar el título de la página
  },
}
</script>
