
import axios from 'axios';

const monolith = axios.create({
  baseURL: 'https://gestionvirtual.itm.edu.co/api',
  //baseURL: 'http://localhost:5003/api',
  timeout: 1000000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default monolith;







