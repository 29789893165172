<template>
    <div>
       <div class="container-fluid m-0 p-0">
        <img src="../../../../public/img/Banner_Recepcion-Guias-AMV-APV.png" class="img-fluid d-flex mx-auto vw-100" alt=""> 
      </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12">
                <h4 class="mt-3">Apreciado docente:</h4>
                <p>Apreciado docente: La revisión de guías didácticas y compromisos académicos es una de las buenas prácticas que venimos ejerciendo desde la Unidad de Educación Virtual con el fin de garantizar en la planeación de las asignaturas unos lineamientos mínimos para la práctica curricular de las AMV y las APV.</p>
                <p>En ese sentido, lo invitamos a adjuntar un archivo en Word de la guía didáctica o compromiso académico por cada Asignatura Mediada por la Virtualidad AMV y/o Asignatura de Programas Virtuales APV que usted tenga programada para este semestre, es decir, que si tiene asignaturas diferentes debe adjuntar un Word por cada una, pero si tiene varios grupos de la misma asignatura solo deberá adjuntar un archivo.</p>
                <p>Tenga en cuenta que no debe esperar nuestra revisión para publicar la guía o compromiso en el aula, usted debe agregar la guía al aula y promover su aceptación desde el primer día de clase. Si luego de recibir nuestra revisión encuentra ajustes sustanciales debe modificar la guía y socializar con sus estudiantes los cambios realizados.</p>
            </div>
          </div>
          <hr>
          <div class="row mb-3">
            <div class="col-12">
                <h5>Datos del docente:</h5>
              </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtNombreCompleto">Nombres y apellidos docente <b class="text-danger">*</b></label> <br>
                <input :oninput="() => (docente.nombreCompleto = docente.nombreCompleto.toUpperCase())" class="form-control datosPersonalesDocente" type="text" id="txtNombreCompleto" v-model.trim="docente.nombreCompleto">
            </div>
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtNumDocumento">Número de documento de identidad docente<b class="text-danger">*</b></label> <br>
                <input class="form-control datosPersonalesDocente" type="number" id="txtNumDocumento" v-model.trim="docente.numDocumento">
            </div>
          </div>
          <div class="row mt-lg-3 mt-0">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtCorreo">Correo institucional docente <b class="text-danger">*</b></label> <br>
                <input class="form-control datosPersonalesDocente" type="text" id="txtCorreo" v-model.trim="docente.correo">
            </div>
          </div>
          <hr class="my-4">
          <div class="row mb-3">
            <div class="col-12">
                <h5>Datos de la asignatura:</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtCodigoAsignatura">Código de la asignatura <b class="text-danger">*</b></label> <br>
                <input :oninput="() => (codigoAsignatura = codigoAsignatura.toUpperCase())" class="form-control" type="text" id="txtCodigoAsignatura" placeholder="Ejemplo: ENX22-2" v-model.trim="codigoAsignatura">
            </div>
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="ddlTipoAsignatura">Tipo de asignatura <b class="text-danger">*</b></label> <br>
                <select id="ddlTipoAsignatura" class="form-select form-select-lg mb-3" v-model.trim="tipoAsignatura" aria-label=".form-select-lg example">
                  <option selected value="">Seleccione una opción</option>
                  <option v-for="(tipoAsignatura, index) of tiposAsignatura" :key="index">{{tipoAsignatura.text}}</option>
                </select>
            </div>
          </div>
          <div class="row mt-lg-3 mt-0" v-if="tipoAsignatura !== ''">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="archivo">{{txtArchivo}} <b class="text-danger">*</b></label> <br>
                <input class="form-control" type="file" id="archivo" ref="archivoAdj" @change="imgOnChange">
            </div>
          </div>
          <div class="row mt-3" v-if="tipoAsignatura !== ''">
            <div class="col-md-6 col-12" >
                <button class="btn btn-enviar" @click="agregarRegistro">Agregar</button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
                <div v-if="faltantes" class="alert alert-danger" role="alert">
                    <b>Todos los campos son de carácter obligatorio</b>
                </div>
            </div>
            <div class="col-12">
                <div v-if="correoErrado" class="alert alert-danger" role="alert">
                    <b>El correo electrónico del docente debe ser el correo institucional <b>'ejemplo@correo.itm.edu.co'</b> ó <b>'ejemplo@itm.edu.co'</b></b>
                </div>
            </div>
            <div class="col-12">
                <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                    <b>{{mensajeError}}</b>
                </div>
            </div>

          <h4>Asignaturas agregadas</h4>
          <div v-if="asignaturas.length !== 0">
            <div class="table-responsive" >
              <table id="tblCancelacionAsignaturas" class="table table-striped" border="1">
                <thead class="tableHeadInfo p-4">
                  <th>Tipo asignatura</th>
                  <th>Código de asignatura</th>
                  <th>Nombre del archivo</th>
                  <th>Acciones</th>
                </thead>
                <tbody>
                  <tr v-for="(asignatura, index) of asignaturas" :key="index">
                    <td class="text-center">{{asignatura.tipoAsignatura}}</td>
                    <td class="text-center">{{asignatura.codigoAsignatura}}</td>
                    <td class="text-center">{{asignatura.nuevoNombre}}</td>
                    <td><button class="btn btnEliminar d-flex mx-auto" @click="eliminaregistroTabla(index)">Eliminar</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="pb-3" v-else>
            <b class="text-center text-danger">Aún no hay asignaturas de tipo AMV agregadas para mostrar</b>
          </div>
            <div class="col-12" v-if="asignaturas.length != 0">
              <p class="text-center" v-if="loading"><b>Enviando información, por favor espere...</b></p> 
              <div class="d-flex justify-content-center" v-if="loading">
                <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
              </div>
                <button class="btn btn-enviar d-flex mx-auto mt-3" @click="crearRegistro">Realizar registro</button>
            </div> 
          </div>
        </div>
    </div>
  </template>
  <script>
      //Importando API que conecta front con back
      import API from '../../../api';

      import {sweetAlertConfirm, sweetAlertSuccess} from '../../../commons/SweetAlert'
      import DataTable from '../../../components/DataTable'
      import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

      export default {
        name: 'formularioRecepcionGDCA',
      
        data() {
          return{
            loading: false,
            
            codigoAsignatura: "",
            tipoAsignatura: "",
            archivoAdjunto: "",
            docente: {
                nombreCompleto: "",
                correo: "",
                numDocumento: ""
            },

            asignaturas: [],
            archivos: [],

            tiposAsignatura: [
                {value: 'AMV', text: 'AMV'},            
                {value: 'APV', text: 'APV'},            
            ],
            txtArchivo: "",
            faltantes: false,
            correoErrado: false,
            mensajeError: null
          }
        },
        components: {
          DataTable,
          PulseLoader
        },
        methods: {

            crearRegistro:  function() {
               this.limpiarAlertas();

               Object.keys(this.docente).forEach(key => {
                    if(this.docente[key] == "") {
                      this.faltantes = true
                    }
               });
               const correoIncorrecto = this.validaCorreoInstitucional();

               if(this.faltantes) return;
               if(correoIncorrecto) return this.correoErrado = true;

              sweetAlertConfirm(
                '¿Desea enviar la información diligenciada?',
                'Los datos diligenciados se enviarán para su respectiva revisión.',
                'warning',
                'Enviar'
              ).then(async (result) => {
                  if (result.isConfirmed) {

                      this.loading = true;

                      const data = new FormData();
                      data.append('infoDocente', JSON.stringify(this.docente));
                      data.append('infoAsignaturas', JSON.stringify(this.asignaturas));
                      data.append('cantArchivos', this.archivos.length);
                      this.archivos.map(archivo => data.append('archivo', archivo));
                  
                      const registroRecepcion = await API.registroRecepcionGDCA(data);

                      if(registroRecepcion.message){
                          this.mensajeError = registroRecepcion.message;
                      }else{
                          this.loading = false;
                          sweetAlertSuccess(
                            '¡Datos enviados!',
                            'Los datos diligenciados se han enviado de manera exitosa',
                            'success'
                          ).then(() => {
                              location.reload();
                          });
                      }
                  } else  (
                    result.dismiss === Swal.DismissReason.cancel
                  ) 
                })
            },

            agregarRegistro: function() {
                this.limpiarAlertas();
                const faltanCampos = this.validaCampos();
                const correoIncorrecto = this.validaCorreoInstitucional();

                if(faltanCampos) return this.faltantes = true;
                if(correoIncorrecto) return this.correoErrado = true;
              
                let nombreArchivoArray = this.archivoAdjunto.name.split('.');
                let extensionArchivo = nombreArchivoArray[nombreArchivoArray.length - 1];

                this.asignaturas.push({
                    tipoAsignatura: this.tipoAsignatura,
                    codigoAsignatura: this.codigoAsignatura,
                    nuevoNombre: `${this.docente.nombreCompleto}-${this.codigoAsignatura}.${extensionArchivo}`
                })
                this.archivos.push(this.archivoAdjunto)
                this.limpiarCampos();
            },

            validaCampos: function() {
              if(this.docente.nombreCompleto === "" ||
                  this.docente.correo === "" ||
                  this.docente.numDocumento === "" || 
                  this.codigoAsignatura === "" ||
                  this.tiposAsignatura === "" ||
                  this.$refs.archivoAdj.files.length === 0
              ){ return true}
            },

            validaCorreoInstitucional: function() {
              if(this.docente.correo.split('@')[1] !== "correo.itm.edu.co" && this.docente.correo.split('@')[1] !== "itm.edu.co") return true;
            },

            limpiarCampos: function() {
              this.txtArchivo = "";
              this.codigoAsignatura = "";
              this.tipoAsignatura = "";
              this.archivoAdjunto = "";
              this.$refs.archivoAdj.value=null;
            },

            limpiarAlertas: function() {
              this.faltantes = false;
              this.correoErrado = false;
              this.mensajeError = null
            },

            eliminaregistroTabla: function(indexFilter) {
              const asignaturasFiltradas = this.asignaturas.filter((elemento, index) => index != indexFilter);
              const archivosFiltrados = this.archivos.filter((elemento, index) => index != indexFilter);
              this.asignaturas = asignaturasFiltradas;
              this.archivos = archivosFiltrados;
            },

            imgOnChange: function(){
              this.archivoAdjunto = this.$refs.archivoAdj.files[0];
            }
        },
        watch: {
            //Watch que valida el cambio que pueda tener la variable tipoAsignatura
            tipoAsignatura: function(val) {
                if(val === "") return;
                if(val === "AMV") {
                    this.txtArchivo = 'Seleccione guía didáctica'
                }else {
                    this.txtArchivo = 'Seleccione compromiso académico'
                }
                if(this.$refs.archivoAdj){
                    this.$refs.archivoAdj.value=null;
                }
            },
        },
        mounted() {
            document.title = this.$route.meta.title // Cambiar el título de la página
        }
    }
      </script>
      