<template>
  <div class="container pb-5">
    <div class="row">
      <div class="p-4 mt-4">
        <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante el
          formulario
          <em><b>Encuesta de caracterización digital</b></em>.
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-6 col-12">
        <label for="ddlSemestre">Seleccione semestre:</label>
        <select name="" class="form-control" id="ddlSemestre" v-model.trim="semestre">
          <option value="0" selected>Selecione una opción</option>
          <option value="2024-2">2024-2</option>
          <option value="2025-1">2025-1</option>
          <!-- <option value="2025-2">2024-2</option> -->
        </select>
      </div>
    </div>
    <div class="row" v-if="sinRegistros">
      <div class="col-12">
        <div class="alert alert-danger" role="alert">
          <b>No existen registros para el semestre {{ semestre }}</b>
        </div>
      </div>
    </div>
    <div class="row" v-if="!sinRegistros">
      <div class="col-12">
        <div v-if="encuestaCaracterizacionDigital.length != 0">
          <DataTable v-if="encuestaCaracterizacionDigital.length !== 0" :btnsDisable="btnsDisableToTable"
            :info="encuestaCaracterizacionDigital" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

//Importando API que conecta front con back
import { useGestorStore } from '@/stores/gestores';
import DataTable from '../../../components/DataTable';

export default {
  name: 'EncuestaCaracterizacionDigital',

  data() {
    return {
      generalStore: useGestorStore(),
      semestre: 0,
      sinRegistros: false,
      btnsDisableToTable: ['btnCambiarEstado', 'btnSendEmail'],
      encuestaCaracterizacionDigital: [],
      cabeceraDatatable: [



        { text: 'N°' },
        { text: 'Nombre estudiante' },
        { text: 'Cédula' },
        { text: 'Correo' },
        { text: 'Teléfono' },
        { text: 'Programa académico' },
        { text: 'Region' },
        { text: 'Fecha registro' },
        //Esto no se muestran
        { text: 'Autorizacion tratamiento datos' },
        { text: 'Tipo documento' },
        { text: 'Edad' },
        { text: 'Programa academico especificar' },
        { text: 'Genero' },
        { text: 'estrato' },
        { text: 'Condicion discapacidad' },
        { text: 'Nivel criticidad' },

        { text: 'Equipo propio' },
        { text: 'Tipo equipo' },
        { text: 'Otro equipo' },
        { text: 'Recurso estudio' },
        { text: 'Camara' },
        { text: 'Conexion' },
        { text: 'Tipo conexion' },
        { text: 'Otro tipo conexion' },
        { text: 'Condicion de estudio' },
        { text: 'Nivel criticidad' },

        { text: 'Uso de internet' },
        { text: 'Comunicacion' },
        { text: 'Entretenimiento' },
        { text: 'Tramites administrativos' },
        { text: 'Busqueda de informacion' },
        { text: 'Trabajo colaborativo' },
        { text: 'Educacion' },
        { text: 'Lectura' },
        { text: 'opiniones' },
        { text: 'Ventas o compras' },
        { text: 'Otra actividad' },

        { text: 'Uso del correo' },
        { text: 'Bibloteca digital' },
        { text: 'Plataformas educativas' },
        { text: 'Suite ofimatica' },
        { text: 'Almacenamiento en nube' },
        { text: 'Videoconferencias' },
        { text: 'Motores de bsuqueda' },
        { text: 'Office 365' },
        { text: 'Aplicaciones graficos' },
        { text: 'Otra herramienta' },
        { text: 'Nivel criticidad' },

        { text: 'Conocimiento tecnologia' },
        { text: 'Utilidad tecnologia' },
        { text: 'Confiabilidad tecnologia' },
        { text: 'Capacidades tecnologicas' },
        { text: 'Otras capacidades' },
        { text: 'Nivel criticidad' },

        { text: 'Tiempo sin estudiar' },
        { text: 'Motivo de virtualidad' },
        { text: 'Especificar motivo' },
        { text: 'Preferencias de estudio' },
        { text: 'Espeficicar preferencia' },
        { text: 'Dedicacion de estudio' },
        { text: 'Nivel criticidad' },

        { text: 'Semestre' },
      ],
      tituloReporteDataTable: 'Reporte de encuestas CD'
    };
  },
  methods: {

    verInfo: async function (value) {
      this.sinRegistros = false;
      const response = await this.generalStore.fetchEncuestaCaracterizacionDigital();
      const encuestaCaracterizacionDigital = response.data;
      if (encuestaCaracterizacionDigital.length === 0) return this.sinRegistros = true;

      this.encuestaCaracterizacionDigital = encuestaCaracterizacionDigital.map(inscripcion => {

        const ul = {
          //DatosBasicos
          nombreApellido: inscripcion.datosBasicos.nombreCompleto,
          numDocumento: inscripcion.datosBasicos.numeroDocumento,
          correo: inscripcion.datosBasicos.correoElectronico,
          telefono: inscripcion.datosBasicos.telefonoContacto,
          programaAcademico: inscripcion.datosBasicos.programaAcademico,
          region: inscripcion.datosBasicos.URegion,
          fechaRegistro: inscripcion.fecha.fechaCreacion,

          autorizacion: inscripcion.datosBasicos.autorizacionTratamientoDatos,
          tipoDocumento: inscripcion.datosBasicos.tipoDocumento,
          edad: inscripcion.datosBasicos.edad,
          programaAcademicoEspecificar: inscripcion.datosBasicos.programaAcademicoEspecificar,
          genero: inscripcion.datosBasicos.genero,
          estratoSocioeconomico: inscripcion.datosBasicos.estratoSocioeconomico,
          condicionDiscapacidad: inscripcion.datosBasicos.condicionDiscapacidad,
          nivelCriticidadBAsicos: inscripcion.datosBasicos.nivelCriticidad,

          equipoPropio: inscripcion.accesoTecnologia.equipoPropio,
          tipoEquipoPropio: inscripcion.accesoTecnologia.tipoEquipoPropio.join("-"),
          equipoPropioOtroEspecificar: inscripcion.accesoTecnologia.equipoPropioOtroEspecificar,
          recursoTecnologicoEstudio: inscripcion.accesoTecnologia.recursoTecnologicoEstudio,
          camaraDisponible: inscripcion.accesoTecnologia.camaraDisponible,
          conexionInternet: inscripcion.accesoTecnologia.conexionInternet,
          tipoConexionInternet: inscripcion.accesoTecnologia.tipoConexionInternet.join("-"),
          tipoConexionInternetEspecificar: inscripcion.accesoTecnologia.tipoConexionInternetEspecificar,
          condicionesEstudio: inscripcion.accesoTecnologia.condicionesEstudio.join("-"),
          nivelCriticidadAcceso: inscripcion.accesoTecnologia.nivelCriticidad,

          frecuenciaUsoInternet: inscripcion.usoTecnologia.frecuenciaUsoInternet,
          comunicacion: inscripcion.usoTecnologia.actividadesInternet.comunicacion,
          entretenimiento: inscripcion.usoTecnologia.actividadesInternet.entretenimiento,
          tramites_administrativos: inscripcion.usoTecnologia.actividadesInternet.tramites_administrativos,
          busqueda_informacion: inscripcion.usoTecnologia.actividadesInternet.busqueda_informacion,
          trabajo_colaborativo: inscripcion.usoTecnologia.actividadesInternet.trabajo_colaborativo,
          educacion: inscripcion.usoTecnologia.actividadesInternet.educacion,
          lectura: inscripcion.usoTecnologia.actividadesInternet.lectura,
          publicacion_opiniones: inscripcion.usoTecnologia.actividadesInternet.publicacion_opiniones,
          ventas_compras: inscripcion.usoTecnologia.actividadesInternet.ventas_compras,
          otro_actividad: inscripcion.usoTecnologia.actividadesInternet.otro_actividad,

          correo_electronico: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.correo_electronico,
          biblioteca_digital: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.biblioteca_digital,
          plataformas_educativas: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.plataformas_educativas,
          suite_ofimatica: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.suite_ofimatica,
          almacenamiento_nube: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.almacenamiento_nube,
          videoconferencia: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.videoconferencia,
          motores_busqueda: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.motores_busqueda,
          office_365: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.office_365,
          aplicaciones_graficos: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.aplicaciones_graficos,
          otro_herramienta: inscripcion.usoTecnologia.frecuenciaHerramientasDigitales.otro_herramienta,
          nivelCriticidadUso: inscripcion.usoTecnologia.nivelCriticidad,

          nivelConocimientoTecnologia: inscripcion.apropiacionTecnologia.nivelConocimientoTecnologia,
          percepcionUtilidadTecnologia: inscripcion.apropiacionTecnologia.percepcionUtilidadTecnologia,
          confiabilidadTecnologia: inscripcion.apropiacionTecnologia.confiabilidadTecnologia,
          capacidadesTecnologicas: inscripcion.apropiacionTecnologia.capacidadesTecnologicas.join("-"),
          otrasCapacidadesEspecificar: inscripcion.apropiacionTecnologia.otrasCapacidadesEspecificar,
          nivelCriticidadApropiacion: inscripcion.apropiacionTecnologia.nivelCriticidad,

          tiempoSinEstudiar: inscripcion.habitosEstudio.tiempoSinEstudiar,
          motivoEducacionVirtual: inscripcion.habitosEstudio.motivoEducacionVirtual.join("-"),
          motivoEspecificar: inscripcion.habitosEstudio.motivoEspecificar,
          preferenciaEstudio: inscripcion.habitosEstudio.preferenciaEstudio.join("-"),
          preferenciaEspecificar: inscripcion.habitosEstudio.preferenciaEspecificar,
          dedicacionEstudio: inscripcion.habitosEstudio.dedicacionEstudio,
          nivelCriticidadHabitos: inscripcion.habitosEstudio.nivelCriticidad,

          semestre: inscripcion.fecha.semestre

        };
        console.log(ul);
        return ul;
      });
    }
  },
  watch: {
    semestre(value) {
      this.verInfo(value);
    }
  },
  components: {
    DataTable
  },
  mounted() {
    document.title = this.$route.meta.title; // Cambiar el título de la página
  },
};
</script>