<template>
    <div class="pb-4">
        <nav class="navbar navbar-expand-md menunavuser fixed-top px-md-5 px-2">
            <a class="navbar-brand" href="https://www.itm.edu.co/" target="_blank"><img src="../../public/img/LogoITM-Nav.png" alt="ITM"></a>
            <button class="navbar-toggler burguermenu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end align-items-center navCollapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item navigationItem">
                        <a @click="redireccionaInicioPorRole" class="nav-link" aria-current="page" href="#"><i class="fa fa-home"></i> <span class="d-md-none d-lg-none d-xl-none">Inicio</span></a>
                    </li>
                    <li class="nav-item navigationItem">
                        <a class="nav-link" href="#"><i class="fa fa-bell"></i> <span class="d-md-none d-lg-none d-xl-none">Notificaciones</span></a>
                    </li>
                    <li class="nav-item navigationItem">
                        <a class="nav-link" href="#"><i class="fa fa-comment"></i> <span class="d-md-none d-lg-none d-xl-none">Alertas</span></a>
                    </li>
                    <li class="nav-item dropdown d-md-flex">
                        <div class="imgloguin mt-2">
                            <div v-if="userInfo.imgPerfil !== 'http://172.30.0.163/theme/image.php/moove/core/1733799887/u/f1'">
                                <img :src="userInfo.imgPerfil" class="imgloguin" alt="Usuario">
                            </div>
                            <div v-else>
                                <img src="../../public/img/IconUser.png" class="imgloguin" alt="Usuario">
                            </div>
                        </div>
                        <a @click="actualizaRoleNav" class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuUser" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ userInfo.nombreUsuario }}
                        </a>
                        <ul class="dropdown-menu animated fadeInDown" aria-labelledby="navbarDropdownMenuUser">
                            <li v-if="currentRole !== null"><a class="dropdown-item" href="#">Perfil actual:    
                                {{ 
                                    currentRole === 'manager' ? 'Gestor' : 
                                    currentRole === 'editingteacher' ? 'Docente' : 
                                    currentRole === 'student' ? 'Estudiante' :
                                    'Sin role'
                                }}</a></li>
                            <li v-if="currentRole !== null" class="navigationItem">
                                <a class="dropdown-item d-flex" href="/inicio">Cambiar perfil</a>
                                <!-- <router-link to="/inicio" class="dropdown-item navigationItem">Cambiar perfil</router-link> -->
                            </li>
                            <li><a class="dropdown-item d-flex" href="#" @click="logout"><h5><i class="fa fa-sign-out me-2"></i></h5> Salir</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>

import { pathsLogin } from '@/commons/pathsLogin';
import { useAuthStore } from '@/stores/auth';
   
export default {
    name: "navBar",
    data () {
        return {
            authStore: null,
            userInfo: {},
            currentRole: null
        }
    },
    methods: {
        logout: async function() {
            await this.authStore.logout();
            this.$router.push({path: '/'});
            // setInterval("location.reload()",1000);
        },
        actualizaRoleNav: function () {
            this.currentRole = this.authStore.userCurrentRole;
        },
        redireccionaInicioPorRole: function () {
            this.$router.push({path: pathsLogin[this.currentRole]});
        }
    },
    mounted () {
        const navigationItems = document.querySelectorAll('.navigationItem');
        navigationItems.forEach(item => item.addEventListener('click', () => {
            document.querySelector('.navCollapse').classList.toggle('show');
        }))

        this.authStore = useAuthStore();
        this.userInfo = this.authStore.userDetail;
        this.currentRole = this.authStore.userCurrentRole;
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>