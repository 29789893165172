import RecepcionGDCA from '../views/Formularios/Docentes/RecepcionGDCA.vue';
import NotariaDocente from '../views/Formularios/Docentes/NotariaDocente.vue';
// import ApoyoExamenesHabilitaciones from '../views/Formularios/Docentes/ApoyoExamenesHabilitaciones.vue';
import InduccionDocente from '../views/Formularios/Docentes/InduccionDocente.vue';
import CancelacionAsignaturas from '../views/Formularios/Docentes/CancelacionAsignaturas.vue';
import SolicitudAulaVirtual from '../views/Formularios/Docentes/SolicitudAulaVirtual.vue'

export default [
    // {
    //     path: '/docentes/recepcionGDCA',
    //     name: 'RecepcionGuiasDidacticasCompromisoAcademico',
    //     component: RecepcionGDCA,
    //     meta: {
    //         title: 'Gestión Curricular - Unidad Virtual / Recepción de Guías Didácticas (AMV) y Compromiso Académico (APV)',
    //         hideNavbar: true
    //     }
    // },

    {
        path: '/docentes/notariaDocente',
        name: 'notariaPorParteDelDocente',
        component: NotariaDocente,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Notaría por parte del Docente',
            hideNavbar: true
        }
    },

    // {
    //     path: '/docentes/apoyoExamenesHabilitaciones',
    //     name: 'apoyoExamanesDeHabilitaciones',
    //     component: ApoyoExamenesHabilitaciones,
    //     meta : {
    //         title: 'Gestión Curricular - Unidad Virtual / Apoyo para Exámenes de Habilitaciones APV',
    //         allowedRoles: ['manager','editingteacher']
    //     }
    // },

    {
        path: '/docentes/induccionDocente',
        name: 'induccionDocentes',
        component: InduccionDocente,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Inducción Docentes',
            hideNavbar: true
        }
    },

    {
        path: '/docentes/cancelacionAsignaturas',
        name: 'cancelaAsignaturas',
        component: CancelacionAsignaturas,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Cancelaciones',
            allowedRoles: ['manager','editingteacher']
        }
    },

    {
        path: '/docentes/solicitudAulaVirtual',
        name: 'solicitudAulaVirtual',
        component: SolicitudAulaVirtual,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Solicitud de aula virtual',
            hideNavbar: true
        }
    },
]