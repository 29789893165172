<template>
  <div>
     <div class="container-fluid m-0 p-0">
      <img src="../../../../public/img/Banner_Reporte_Cancelaciones.jpg" class="img-fluid d-flex mx-auto vw-100" alt="">
    </div>
      <div class="container">
        <div class="row">
          <div class="mt-3">
            <p>Los estudiantes de modalidad virtual o con asignaturas mediadas por la virtualidad pueden cancelar dichos cursos de manera voluntaria desde el día en que los matriculan hasta cinco (5) días hábiles antes de la fecha estipulada para la presentación del examen final. Los estudiantes que cancelen asignaturas AMV o APV deben ser suspendidos de las plataformas para que no tengan acceso a los contenidos, interacciones y actividades formativas y evaluativas.</p>
            <p>Dentro de la gestión de usuarios que le corresponde como docente de educación virtual, le solicitamos amablemente diligenciar este formulario reportando periódicamente los estudiantes que han cancelado sus asignaturas. Desde la Unidad de Educación Virtual los suspenderemos de las plataformas los días 15 y 30 de cada mes.</p>
          </div>
          
          <div class="col-12 pb-3">
              <p><b>Código de asignatura:</b></p>
              <Select2 id="ddlTipoAsignatura" v-model.trim="codigoSeleccionado" :options="codigosAsignaturas" placeholder="Seleccione una opción"/>
      
              <div v-if="codigoSeleccionado != ''" class="mt-4">
                  <p><b>Importante:</b> Si el curso que eligió no es el correcto por favor de click <a @click="reestableceValores" href="#">aquí</a>.</p>
                  <hr>
                  <p><b>Código Sia:</b> {{codigoSia}}</p>
                  <p><b>Facultad:</b> {{facultad}}</p>
                  <p><b>Programa:</b> {{programa}}</p>
                  <p><b>Docente:</b> {{docente}}</p>
                  <p><b>Tipo de asignatura:</b> {{tipoAsignatura}}</p>
                  <p><b>Semestre:</b> {{semestre}}</p>
                  <p v-if="habilitable == 0"><b class="text-danger">La asignatura elegida no es habilitable.</b></p>
              </div>
            </div>
        </div>
        <div v-if="codigoSeleccionado != '' && habilitable !== 0">
          <div class="row mt-4 mb-3">
            <div class="col-md-6 col-12">
              <label for="lblDocumento">Documento</label> <br>
              <input class="form-control" type="number" id="lblDocumento" v-model.trim="documentoEstudiante">
              </div>
          </div>
          <div class="row mb-3">
              <div class="col-md-6 col-12 mb-md-0 mb-3">
                <label for="txtNombre">Nombre estudiante</label> <br>
                <input oninput="this.value = this.value.toUpperCase()" class="form-control" type="text" id="txtNombre" v-model.trim="nombreEstudiante">
              </div>
              <div class="col-md-6 col-12 ">
                <label for="txtApellido">Apellido estudiante</label> <br>
                <input oninput="this.value = this.value.toUpperCase()" class="form-control" type="text" id="txtApellido" v-model.trim="apellidoEstudiante">
              </div>
          </div>
          <div class="row">
              <div class="col-md-6 col-12 mb-md-0 mb-3">
                <label for="selectEstadoAsignatura">Estado de asignatura</label> <br>
                <select class="form-select" aria-label="Default select example" id="selectEstadoAsignatura" v-model="estadoAsignatura">
                  <option value="Cancelado">Cancelado</option>
                  <option value="Provisional">Provisional</option>
                </select>
              </div>
              <div class="col-md-6 col-12 mb-md-0 mb-3">
                <label for="selectDateFechaCancelacion">Fecha de cancelación</label> <br>
                <input class="form-control" type="date" id="selectDateFechaCancelacion" v-model="fechaCancelacion">
              </div>
          </div>
          <div class="row mb-3 mt-xl-3 mt-lg-3 mt-md-3 mt-0">
              <div class="col-md-6 col-12">
                <label for="txtCorreoInstitucional">Correo institucional</label> <br>
                <input class="form-control" type="email" id="txtCorreoInstitucional" v-model="correoInstitucional" placeholder="nombre@correo.itm.edu.co">
              </div>
          </div>
          <div class="alert alert-danger" v-if="faltantes" role="alert">
            <b>Todos los campos son obligatorios</b>
          </div>
          <div class="row mt-3" v-if="correoErroneo">
            <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  <b>El correo electrónico del estudiante debe ser el correo institucional <b>'ejemplo@correo.itm.edu.co'</b> ó <b>'ejemplo@itm.edu.co'</b></b>
                </div>
            </div>
          </div>
          <div class="row">
              <div class="col-md-6 col-12">
                  <button class="btn btn-enviar" @click="agregarAlumno">Agregar</button>
              </div>
          </div>
          <hr>
          
          <h4>Alumnos agregados</h4>
          <div v-if="alumnosAgregados.length !== 0">
            <div class="table-responsive" >
              <table id="tblCancelacionAsignaturas" class="table table-striped" border="1">
                <thead class="tableHeadInfo p-4">
                  <th>Documento</th>
                  <th>Nombre estudiante</th>
                  <th>Apellido estudiante</th>
                  <th>Estado asignatura</th>
                  <th>Fecha cancelación</th>
                  <th>Correo institucional</th>
                  <th>Acciones</th>
                </thead>
                <tbody>
                  <tr v-for="(alumno, index) of alumnosAgregados" :key="index">
                    <td>{{alumno.documentoEstudiante}}</td>
                    <td>{{alumno.nombreEstudiante}}</td>
                    <td>{{alumno.apellidoEstudiante}}</td>
                    <td>{{alumno.estadoAsignatura}}</td>
                    <td>{{alumno.fechaCancelacion}}</td>
                    <td>{{alumno.correoInstitucional}}</td>
                    <td><button class="btn btnEliminar" @click="eliminaregistroTabla(index)">Eliminar</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="btn btn-enviar d-flex mx-auto mb-5 mt-4" @click="crearCancelacion()">Realizar registro</button>
          </div>
         
          <div class="pb-3" v-else>
            <b class="text-center text-danger">Aún no hay alumnos agregados para poder realizar el registro</b>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
    
    import API from '../../../api';
    import {sweetAlertConfirm, sweetAlertSuccess} from '../../../commons/SweetAlert'
    import Select2 from 'vue3-select2-component';
    import { obtenerSemestreActual } from '../../../helpers/functions';

    export default {
      name: 'formularioCancelacionAsignatura',
    
      data() {
        return{
          data: [],
          codigosAsignaturas: [],
          codigoSia: '',
          facultad: '',
          codigoSeleccionado: '', 
          programa: "",
          docente: "",
          tipoAsignatura: "",
          semestre: '',

          documentoEstudiante: "",
          nombreEstudiante: "",
          apellidoEstudiante: "",
          estadoAsignatura: "",
          fechaCancelacion: "",
          correoInstitucional: "",

          alumnosAgregados: [],
          faltantes: false,
          correoErroneo: false
        }
      },

      async created(){
        this.data = await API.fetchCodigosAsignaturas(obtenerSemestreActual());
        this.data = this.data.map(function(item) {
          item.codigoAsignatura = item.codigoAsignatura.trim()
          return {
            ...item
          };
        });
        this.codigosAsignaturas = this.data.map(elemento => {
          return elemento.codigoAsignatura 
        })
      },
      components: {Select2},  
      mounted: function (event) {
        var alumnosAgregadosLocalStorage = JSON.parse(localStorage.getItem('alumnosAgregadosLocalStorage'));
        if(alumnosAgregadosLocalStorage !== null) {
            this.alumnosAgregados = alumnosAgregadosLocalStorage
        }
      },
      watch: {
      //Watch que valida el cambio que pueda tener la variable codigoSeleccionado
      codigoSeleccionado: function(value) {

            let filteredData = this.data.find(elemento => elemento.codigoAsignatura === value);

            this.codigoSia = filteredData.codigoAsignatura.split(' ')[0];
            this.facultad = filteredData.facultad;
            this.programa = filteredData.programaAcademico;
            this.docente = filteredData.docente;
            this.tipoAsignatura = filteredData.tipoAsignatura;
            this.semestre = filteredData.semestre;

            this.tipoAsignaturaAbreviado = this.tipoAsignatura;
            this.habilitable = filteredData.habilitable;

            if(this.tipoAsignatura == "AMV"){
              this.tipoAsignatura = "Asignatura mediada por la virtualidad (AMV)";
            }else{
              this.tipoAsignatura = "Asignatura de programas virtuales (APV)";
            }
   
        },

    },
    methods: {

      crearCancelacion: function(){
          sweetAlertConfirm(
              '¿Desea enviar la información diligenciada?',
              'Los datos diligenciados se enviarán para su respectiva revisión.',
              'warning',
              'Enviar'
            ).then(async (result) => {
                if (result.isConfirmed) {

                    this.loading = true;
                                      
                    const data = {
                      codigoSia: this.codigoSia,
                      programa: this.programa,
                      docente: this.docente,
                      tipoAsignatura: this.tipoAsignatura,
                      alumnosAgregados: this.alumnosAgregados,
                      semestre: this.semestre
                    }

                    const addInfo = API.createCancelacion(data);
      
                    if(addInfo.message){
                        this.mensajeError = addInfo.message;
                    }else{
                        this.loading = false;
                        sweetAlertSuccess(
                          '¡Datos enviados!',
                          'Los datos diligenciados se han enviado de manera exitosa',
                          'success'
                        ).then(() => {
                            this.reestableceValores();
                        });
                    }
                } else  (
                  result.dismiss === Swal.DismissReason.cancel
                ) 
            })
        },

        eliminaregistroTabla: function(indexFilter) {
          const alumnosFiltrados = this.alumnosAgregados.filter((elemento, index) => index != indexFilter)
          this.alumnosAgregados = alumnosFiltrados;
        },

        reestableceValores: function() {
          localStorage.clear();
          location.reload();
        },

        exportExcel: function(tableID) {
            var table= document.getElementById(tableID);
            var html = table.outerHTML;
            window.open('data:application/vnd.ms-excel,' + escape(html));
        },
    
        agregarAlumno: function() {
          this.faltantes = false;
          this.correoErroneo = false;
          

          if(this.documentoEstudiante === "" || this.nombreEstudiante === ""
          || this.apellidoEstudiante === "" || this.estadoAsignatura === ""
          || this.fechaCancelacion === "" || this.correoInstitucional === "") {
              this.faltantes = true;
              return;
          }

          //Validamos el correo
          let dominioCorreo =  this.correoInstitucional.split('@')[1];
          if(dominioCorreo != "itm.edu.co" && dominioCorreo != "correo.itm.edu.co"){
            this.correoErroneo = true;
            return 
          }

          const alumno = {
            documentoEstudiante: this.documentoEstudiante,
            nombreEstudiante: this.nombreEstudiante.toUpperCase(),
            apellidoEstudiante: this.apellidoEstudiante.toUpperCase(),
            estadoAsignatura: this.estadoAsignatura,
            fechaCancelacion: this.fechaCancelacion,
            correoInstitucional: this.correoInstitucional,
          }
    
          this.alumnosAgregados.push(alumno);
          localStorage.setItem("alumnosAgregadosLocalStorage", JSON.stringify(this.alumnosAgregados));
        
          this.limpiarCampos();
        },

        limpiarCampos: function() {
          this.documentoEstudiante = "";
          this.nombreEstudiante = "";
          this.apellidoEstudiante = "";
          this.estadoAsignatura = "";
          this.fechaCancelacion = "";
          this.correoInstitucional = "";
        }
      },
      mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
      },
    }
    </script>
    