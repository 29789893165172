<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los docentes o estudiantes mediante el formulario de <em><b>Solicitudes a la Unidad de Educación Virtual</b></em>.</p>
          </div>
      </div>
      <div class="row p-3">
        <p>Use alguno de los botones para poder obtener la información filtrada.</p>
        <div class="col-lg-3 col-12 d-flex justify-content-center">
            <div class="form-check">
                <input @click="filtraDatos('todos')" class="form-check-input" type="radio" name="flexRadioEstado" id="flexRadioEstadoSolicitud1">
                <label class="form-check-label" for="flexRadioEstadoSolicitud1">
                    Todos
                </label>
            </div>
        </div>
        <div class="col-lg-3 col-12 d-flex justify-content-center">
            <div class="form-check">
                <input @click="filtraDatos('abierto')" class="form-check-input" type="radio" name="flexRadioEstado" id="flexRadioEstadoSolicitud2">
                <label class="form-check-label" for="flexRadioEstadoSolicitud2">
                    Abiertos
                </label>
            </div>
        </div>
        <div class="col-lg-3 col-12 d-flex justify-content-center">
            <div class="form-check">
                <input @click="filtraDatos('cerrado')" class="form-check-input" type="radio" name="flexRadioEstado" id="flexRadioEstadoSolicitu3">
                <label class="form-check-label" for="flexRadioEstadoSolicitu3">
                    Cerrados
                </label>
            </div>
        </div>
        <div class="col-lg-3 col-12 d-flex justify-content-center">
            <div class="form-check">
                <input @click="filtraDatos('pendiente')" class="form-check-input" type="radio" name="flexRadioEstado" id="flexRadioEstadoSolicitu3">
                <label class="form-check-label" for="flexRadioEstadoSolicitu3">
                    Pendientes
                </label>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="isLoading">
            <p class="text-center"><b>Cargando información, por favor espere...</b></p> 
            <div class="d-flex justify-content-center">
                <pulse-loader :loading="isLoading" :color="color" :size="size"></pulse-loader>
            </div>
        </div>
        <div class="col-12" v-if="!hasData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
            <div v-if="solicitudesUDEV.length !== 0" class="">
              <DataTable v-if="solicitudesUDEV.length !== 0" :btnsDisable="btnsDisableToTable" :info="solicitudesUDEV" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from "vue";
import { useGestorStore } from "@/stores/gestores";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import DataTable from '../../../components/DataTable'

const instance = getCurrentInstance();
const gestorStore = ref( null ); 
const solicitudesUDEV = ref([]);
const isLoading = ref( false );
const hasData = ref( true );

const cabeceraDatatable = [
            {text: 'N°'},
            {text: 'idSolicitud'},
            {text: 'Nombres'},
            {text: 'Apellidos'},
            {text: 'Tipo de documento'},
            {text: 'Número de documento'},
            {text: 'Correo institucional'},
            {text: 'Correo alterno'},
            {text: 'Tipo de usuario'},
            {text: 'Pregunta'},
            {text: 'Ruta de evidencia'},
            {text: 'estado'},
            {text: 'Fecha de Registro'}
];
const tituloReporteDataTable = 'Reporte de solicitudes a la Unidad de Educación Virtual';
const btnsDisableToTable = ['btnSendEmail'];

const filtraDatos = async ( filtro ) => {
    
    solicitudesUDEV.value = [];
    hasData.value = true;

    isLoading.value = true;
    const { solicitudes } = await gestorStore.value.fetchSolicitudesUDEV( filtro );
    isLoading.value = false;

    if( solicitudes.length === 0 ) return hasData.value = false;

    solicitudesUDEV.value = solicitudes;
}
onMounted(() => {
    if (instance) {
        const { $route } = instance.appContext.config.globalProperties;
        document.title = $route.meta.title;
    }
    gestorStore.value = useGestorStore();
})

components: {
    DataTable 
}

</script>