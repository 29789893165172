<template>
    <div>
       <div class="container-fluid m-0 p-0">
        <img src="../../../../public/img/Banner_NotariaDocente.png" class="img-fluid d-flex mx-auto vw-100" alt=""> 
      </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 mt-3">
                <p>Apreciado docente de <em>asignaturas mediadas por la virtualidad</em> AMV y <em>asignaturas para programas virtuales</em> APV:</p>
                <p>Después del inicio del semestre académico los ajustes de matrícula generan actualizaciones de los estudiantes en los cursos. Con el propósito de mejorar la matrícula de estudiantes en las plataformas, amablemente le solicitamos revisar su listado de estudiantes del SIA en contraste con los que aparecen en cada una de sus aulas y reportar cuáles se deben matricular y cuáles retirar.</p>
            </div>
          </div>
          <hr>
          <div class="row mb-3">
            <div class="col-12">
                <h5>Datos del docente:</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtNombreCompleto">Nombres y apellidos docente <b class="text-danger">*</b></label> <br>
                <input oninput="this.value = this.value.toUpperCase()" class="form-control datosPersonalesDocente" type="text" id="txtNombreCompleto" v-model.trim="docente.nombreCompleto">
            </div>
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtNumDocumento">Número de documento de identidad docente<b class="text-danger">*</b></label> <br>
                <input class="form-control datosPersonalesDocente" type="number" id="txtNumDocumento" v-model.trim="docente.numDocumento">
            </div>
          </div>
          <div class="row mt-lg-3 mt-0">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtCorreo">Correo institucional docente <b class="text-danger">*</b></label> <br>
                <input class="form-control datosPersonalesDocente" type="text" id="txtCorreo" v-model.trim="docente.correo">
            </div>
          </div>
          <hr class="my-4">
          <div class="row mb-3">
            <div class="col-12">
                <h5>Datos de la asignatura:</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtCodigoAsignatura">Código de la asignatura <b class="text-danger">*</b></label> <br>
                <input oninput="this.value = this.value.toUpperCase()" class="form-control datosAsignatura" type="text" id="txtCodigoAsignatura" placeholder="Ejemplo: ENX22-2" v-model.trim="codigoAsignatura">
            </div>
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="ddlTipoAsignatura">Tipo de asignatura <b class="text-danger">*</b></label> <br>
                <select id="ddlTipoAsignatura" class="form-select form-select-lg mb-3" v-model.trim="tipoAsignatura" aria-label=".form-select-lg example">
                  <option selected value="">Seleccione una opción</option>
                  <option v-for="(tipo, index) of tiposAsignatura" :key="index">{{tipo.text}}</option>
                </select>
                <!-- <b-form-select id="ddlTipoAsignatura" class="form-control datosAsignatura" v-model="tipoAsignatura" :options="tiposAsignatura"></b-form-select> -->
            </div>
          </div>
          <hr>
          <div class="row mb-3">
            <div class="col-12">
                <h5>Datos del estudiante:</h5>
            </div>
          </div>
          <div class="row mt-lg-3 mt-0">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">

                <div class="col-12 mt-3">
                    <div v-if="mensajeMismoCodigo" class="alert alert-danger" role="alert">
                        <b>Debe agregar el código y el tipo de asignatura</b>
                    </div>
                </div>
                
                <label for="ddlAccionEstudiante" class="mt-3">El estudiante que va a reportar a continuación se debe: <b class="text-danger">*</b></label> <br>
                <select id="ddlAccionEstudiante" class="form-select form-select-lg mb-3" v-model.trim="accionEstudiante" aria-label=".form-select-lg example">
                  <option selected value="">Seleccione una opción</option>
                  <option v-for="(accion, index) of accionesEstudiante" :key="index">{{accion.text}}</option>
                </select>
            </div>
          </div>
          <div class="row mt-lg-3 mt-0">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtNombreEstudiante">Nombre completo del estudiante <b class="text-danger">*</b></label> <br>
                <input oninput="this.value = this.value.toUpperCase()" type="text" id="txtNombreEstudiante" class="form-control" v-model.trim="nombreEstudiante">
            </div>
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtNumDocumentoEstudiante">Número de documento de identidad estudiante<b class="text-danger">*</b></label> <br>
                <input class="form-control datosPersonalesEstudiante" type="number" id="txtNumDocumentoEstudiante" v-model.trim="cedulaEstudiante">
            </div>
          </div>
          <div class="row mt-lg-3 mt-0">
            <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                <label for="txtCorreoEstudiante">Correo institucional estudiante <b class="text-danger">*</b></label> <br>
                <input class="form-control datosPersonalesEstudiante" type="text" id="txtCorreoEstudiante" v-model.trim="correoEstudiante">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6 col-12" >
                <button class="btn btn-enviar" @click="agregarRegistro">Agregar</button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
                <div v-if="faltantes" class="alert alert-danger" role="alert">
                    <b>Todos los campos son de carácter obligatorio</b>
                </div>
            </div>
            <div class="col-12">
                <div v-if="correoErrado" class="alert alert-danger" role="alert">
                    <b>El correo electrónico del docente y del estudiante deben ser correos institucionales <b>'ejemplo@correo.itm.edu.co'</b> ó <b>'ejemplo@itm.edu.co'</b></b>
                </div>
            </div>
            <div class="col-12">
                <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                    <b>{{mensajeError}}</b>
                </div>
            </div>

          <h4>Estudiantes agregados</h4>
          <div v-if="infoEstudiantes.length !== 0">
            <div class="table-responsive" >
              <table id="tblCancelacionAsignaturas" class="table table-striped" border="1">
                <thead class="tableHeadInfo p-4">
                  <th>Tipo asignatura</th>
                  <th>Código de asignatura</th>
                  <th>El estudiante debe</th>
                  <th>Nombre estudiante</th>
                  <th>N° Documento estudiante</th>
                  <th>Correo estudiante</th>
                  <th>Acciones</th>
                </thead>
                <tbody>
                  <tr v-for="(estudiante, index) of infoEstudiantes" :key="index">
                    <td class="text-center">{{estudiante.tipoAsignatura}}</td>
                    <td class="text-center">{{estudiante.codigoAsignatura}}</td>
                    <td class="text-center">{{estudiante.accionEstudiante}}</td>
                    <td class="text-center">{{estudiante.nombreEstudiante}}</td>
                    <td class="text-center">{{estudiante.cedulaEstudiante}}</td>
                    <td class="text-center">{{estudiante.correoEstudiante}}</td>
                    <td><button class="btn btnEliminar d-flex mx-auto" @click="eliminaregistroTabla(index)">Eliminar</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="pb-3" v-else>
            <b class="text-center text-danger">Aún no hay estudiantes agregados</b>
          </div>
            <div class="col-12" v-if="infoEstudiantes.length != 0">
                <p class="text-center" v-if="loading"><b>Enviando información, por favor espere...</b></p> 
                <div class="d-flex justify-content-center"  v-if="loading">
                  <cube-spin></cube-spin>
                </div>
                <button class="btn btn-enviar d-flex mx-auto mt-3" @click="crearRegistro">Realizar registro</button>
            </div> 
          </div>
        </div>
    </div>
  </template>
  
  <script>
      //Importando API que conecta front con back
      import API from '../../../api';
      import {sweetAlertConfirm, sweetAlertSuccess} from '../../../commons/SweetAlert'

      //import CubeSpin from 'vue-loading-spinner/src/components/Circle'

      export default {
        name: 'formularioNotariaDocente',
      
        data() {
          return{
            loading: false,
            
            codigoAsignatura: "",
            tipoAsignatura: "",
            accionEstudiante: "",
            nombreEstudiante: "",
            cedulaEstudiante: "",
            correoEstudiante: "",
            rbtnMismoCodigo: "A",
            docente: {
                nombreCompleto: "",
                correo: "",
                numDocumento: ""
            },

            infoEstudiantes: [],

            accionesEstudiante: [
                {value: 'Matricular en el curso', text: 'Matricular en el curso'},            
                {value: 'Retirar del curso', text: 'Retirar del curso'},            
            ],
            tiposAsignatura: [
                {value: 'AMV', text: 'AMV'},            
                {value: 'APV', text: 'APV'},            
            ],

            faltantes: false,
            correoErrado: false,
            mensajeMismoCodigo: false,
            mensajeError: null
          }
        },
        components: {
          //CubeSpin
        },
        methods: {
            mismoCodigoSelected: function() {
                this.limpiarAlertas();
                this.codigoAsignatura = "";
                this.tipoAsignatura = "";
                
            },
        
            crearRegistro:  function() {
               this.limpiarAlertas();

               Object.keys(this.docente).forEach(key => {
                    if(this.docente[key] == "") {
                      this.faltantes = true
                    }
               });
               const correoIncorrecto = this.validaCorreoInstitucional(true);

               if(this.faltantes) return;
               if(correoIncorrecto) return this.correoErrado = true;

               sweetAlertConfirm(
                '¿Desea enviar la información diligenciada?',
                'Los datos diligenciados se enviarán para su respectiva revisión.',
                'warning',
                'Enviar'
              ).then(async (result) => {
                  if (result.isConfirmed) {

                    this.loading = true;

                    const data = new FormData();
                    data.append('infoDocente', JSON.stringify(this.docente));
                    data.append('infoEstudiantes', JSON.stringify(this.infoEstudiantes));

                    const registroNotariaDocente = await API.registroNotariaDocente(data);
      
                    if(registroNotariaDocente.message){
                        this.mensajeError = registroNotariaDocente.message;
                    }else{
                      this.loading = false;
                      sweetAlertSuccess(
                        '¡Datos enviados!',
                        'Los datos diligenciados se han enviado de manera exitosa',
                        'success'
                      ).then(() => {
                        location.reload();
                      });
                    }
                  } else  (
                    result.dismiss === Swal.DismissReason.cancel
                  ) 
              })
            },

            agregarRegistro: function() {
                this.limpiarAlertas();
                const faltanCampos = this.validaCampos();
                const correoIncorrecto = this.validaCorreoInstitucional();

                if(faltanCampos) return this.faltantes = true;
                if(correoIncorrecto) return this.correoErrado = true;
                

                // let datosPersonales = document.querySelectorAll(".datosPersonalesDocente");
                // datosPersonales.forEach(input => input.setAttribute("disabled", true));

                this.infoEstudiantes.push({
                    tipoAsignatura: this.tipoAsignatura,
                    codigoAsignatura: this.codigoAsignatura.toUpperCase(),
                    accionEstudiante: this.accionEstudiante,
                    nombreEstudiante: this.nombreEstudiante.toUpperCase(),
                    cedulaEstudiante: this.cedulaEstudiante,
                    correoEstudiante: this.correoEstudiante
                })
                this.limpiarCampos();
            },

            validaCampos: function(camposAsignatura = false) {

                    if(this.docente.nombreCompleto === "" ||
                    this.docente.correo === "" ||
                    this.docente.numDocumento === "" || 
                    this.codigoAsignatura === "" ||
                    this.tiposAsignatura === "" ||
                    this.accionEstudiante === "" || 
                    this.nombreEstudiante === "" ||
                    this.cedulaEstudiante === "" ||
                    this.correoEstudiante === "" 
                   ){ return true}
            
            },

            validaCorreoInstitucional: function(creaRegistroFinal = false) {
              if(creaRegistroFinal) {
                if(this.docente.correo.split('@')[1] !== "correo.itm.edu.co" && this.docente.correo.split('@')[1] !== "itm.edu.co") return true;
              }else{
                if((this.docente.correo.split('@')[1] !== "correo.itm.edu.co" && this.docente.correo.split('@')[1] !== "itm.edu.co") || 
                   (this.correoEstudiante.split('@')[1] !== "correo.itm.edu.co" && this.correoEstudiante.split('@')[1] !== "itm.edu.co")) return true;
              }
            },

            limpiarCampos: function() {

                this.accionEstudiante = "";
                this.nombreEstudiante = "";
                this.cedulaEstudiante = "";
                this.correoEstudiante = "" 

                if(this.rbtnMismoCodigo !== "B"){
                    this.codigoAsignatura = "";
                    this.tipoAsignatura = "";
                }
           
            },

            limpiarAlertas: function() {
                this.faltantes = false;
                this.correoErrado = false;
                this.mensajeMismoCodigo = false;
                this.mensajeError = null
            },

            eliminaregistroTabla: function(indexFilter) {
                const asignaturasFiltradas = this.infoEstudiantes.filter((elemento, index) => index != indexFilter);
                this.infoEstudiantes = asignaturasFiltradas;
            },

        },
        watch: {
            codigoAsignatura: function(val) {
                if(this.codigoAsignatura !== "" && this.tipoAsignatura !== "") {
                    this.limpiarAlertas();
                }
            },
            tipoAsignatura: function(val) {
                if(this.codigoAsignatura !== "" && this.tipoAsignatura !== "") {
                    this.limpiarAlertas();
                }
            },
        },
        mounted() {
            document.title = this.$route.meta.title // Cambiar el título de la página
        }
    }
      </script>
      