<template>
    <div class="container mb-5">
        <div class="row">
            <div class="p-4 mt-4">
                <p>En esta sección usted podrá visualizar todos los registros realizados por los usuarios mediante el
                formulario <em><b>Departamento de personal</b></em>.
                </p>
            </div>
        </div>
        <div class="row" >
            
            <div class="col-12">
                <p class="text-center" v-if="loading"><b>Cargando información, por favor espere...</b></p> 
                <div class="d-flex justify-content-center" v-if="loading">
                    <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                </div>
                <div v-if="sinRegistros" class="alert alert-danger" role="alert">
                    <b>Aún no se han realizado registros</b>
                </div>
                <div v-if="registrosDepartamentoDePersonal.length != 0">
                <DataTable v-if="registrosDepartamentoDePersonal.length !== 0" :btnsDisable="btnsDisableToTable"
                    :info="registrosDepartamentoDePersonal" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useGestorStore } from '@/stores/gestores';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import DataTable from '../../../components/DataTable';

const route = useRoute();
const gestorStore = useGestorStore();
const loading = ref(true);
const sinRegistros = ref(false);
const btnsDisableToTable = ['btnCambiarEstado', 'btnSendEmail'];
const registrosDepartamentoDePersonal = ref([]);
const cabeceraDatatable = ref([
    { text: 'N°' },
    { text: 'Nombres' },
    { text: 'Apellidos' },
    { text: 'Documento de identidad' },
    { text: 'Correo electrónico' },
    { text: 'Cargo' },
    { text: 'Curso' },
    { text: 'Fecha registro' },
    { text: 'Semestre' },
]);

onMounted(() => {
    document.title = route.meta.title;
    gestorStore.fetchDepartamentoDePersonal().then((response) => {
        registrosDepartamentoDePersonal.value = response;
        sinRegistros.value = registrosDepartamentoDePersonal.value.length === 0;
        loading.value = false;
    });
});
</script>