<template>
    <div>
        <div class="container-fluid bg-dark">
            <div class="row p-5">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <h1 class="text-white">Aca va el banner</h1>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 mt-3">
                    <h1>Moodle backups - Cursos / Elección de cursos</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useMoodleStore } from '@/stores/moodle';
    import { useAdministrationStore } from '@/stores/administration';
        
    const route = useRoute();
    const router = useRouter(); 
    const moodleStore = ref(null);
    const administrationStore = ref(null);

    onMounted(async () => {
        moodleStore.value = useMoodleStore();
        administrationStore.value = useAdministrationStore();
        document.title = route.meta.title;

        const selectedCategoriesByServerName = await administrationStore.value.selectedOptionsCategoryBackupSection;
        if(Object.keys(selectedCategoriesByServerName).length === 0) {
            router.push({name: 'backupsMoodleCategoryChoose'});
            return;
        }
    });
</script>