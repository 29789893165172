<template>
   <div class="container">
        <div ref="modalElement" class="modal fade" id="mdlCambiarEstado" tabindex="-1" aria-labelledby="mdlCambiarEstadoLabel" aria-modal="true" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mdlCambiarEstadoLabel">Cambiar estado</h5>
              </div>
              <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-12">
                        <input @click="setEstadoACambiar('abierto')" class="form-check-input" type="radio" name="flexRadioNuevoEstado" id="flexRadioEstado1">
                        <label class="form-check-label" for="flexRadioEstado1">
                            Cambiar a abierto
                        </label>
                    </div>
                    <div class="col-lg-4 col-12">
                        <input @click="setEstadoACambiar('cerrado')" class="form-check-input" type="radio" name="flexRadioNuevoEstado" id="flexRadioEstado2">
                        <label class="form-check-label" for="flexRadioEstado2">
                            Cambiar a cerrado
                        </label>
                    </div>
                    <div class="col-lg-4 col-12">
                        <input @click="setEstadoACambiar('pendiente')" class="form-check-input" type="radio" name="flexRadioNuevoEstado" id="flexRadioEstado3">
                        <label class="form-check-label" for="flexRadioEstado3">
                            Cambiar a pendiente
                        </label>
                    </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <div class="row">
                  <div class="alert alert-danger mt-4" role="alert" v-if="mensajeError !== ''">
                    {{ mensajeError }}
                  </div>
                  <div v-if="isLoading">
                    <div class="d-flex justify-content-center">
                      <p><b>Cambiando estados, por favor espere...</b></p>
                    </div>
                    <div class="d-flex justify-content-center">
                      <pulse-loader :loading="isLoading" :color="color" :size="size"></pulse-loader>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                 <div class="col-12 d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary m-1" id="carrarModal" @click="cierraModal()">Cerrar</button>
                    <button type="button" class="btn btnDataTable m-1" @click="cambiarEstados">Cambiar estado</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show" id="backdrop"  style="display: none;"></div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { sweetAlertConfirm, sweetAlertSuccess } from '../commons/SweetAlert'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { useGeneralStore } from '@/stores/generales';

export default {
    setup() {
        const generalStore = ref(null);

        const isLoading = ref(false);
        const mensajeError = ref("");

        const estado = ref( null );

        const openDataTableChangeStateModal = () => {
            document.getElementById("backdrop").style.display = "block"
            document.querySelector("#mdlCambiarEstado").style.display = "block"
            document.getElementById("mdlCambiarEstado").className += "show"
        }

        const cierraModal = () => {
          isLoading.value = false;
          document.getElementById("backdrop").style.display = "none"
          document.getElementById("mdlCambiarEstado").style.display = "none"
          document.getElementById("mdlCambiarEstado").className += document.getElementById("mdlCambiarEstado").className.replace("show", "")
        }

        const setEstadoACambiar = ( nuevoEstado ) => {
            estado.value = nuevoEstado;
        }

        const cambiarEstados = () => {

            if( !estado.value ) return;

            sweetAlertConfirm(
                '¿Desea cambiar el estado a las filas seleccionadas?',
                'Se cambiará el estado a cada registro por el estado seleccionado.',
                'warning',
                'Aceptar')
            .then(async (result) => {
                if (result.isConfirmed) {

                    isLoading.value = true;

                    let filasLocalStorage = localStorage.getItem('filasSeleccionadasDTCambioEstado');
                    const actualizacionEstados =  await generalStore.value.updateEstados( filasLocalStorage, estado.value );
                    
                    if(actualizacionEstados.message){
                        mensajeError.value = actualizacionEstados.message;
                    }else{
                        
                        isLoading.value = false;
                        sweetAlertSuccess(
                            '¡Estados actualizados!',
                            'Los estados se han actualizado de manera exitosa',
                            'success'
                        ).then(() => {
                            localStorage.setItem('filasSeleccionadasDTCambioEstado', '');
                            location.reload();
                        });
                    }
                } else  (
                    result.dismiss === Swal.DismissReason.cancel
                ) 
            })
        }

        onMounted(() => {
            generalStore.value = useGeneralStore();
        })

        return {
            cambiarEstados,
            cierraModal,
            isLoading,
            mensajeError,
            openDataTableChangeStateModal,
            setEstadoACambiar,
        }
    },
    components: {
      PulseLoader,
    },
}

</script>