<template>
    <div class="container-fluid p-3 bg-naranja">
        <div class="container pb-3">
            <div class="row">
                <div class="col-12 m-0 p-0">
                    <img class="img-fluid d-flex mx-auto" src="../../../../public/img/BannerFormularioSolicitudAulas.png"
                        alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-3" style="background-color: #2d2f59; ">
                    <h1 class="text-center text-white">Formulario de solicitud <br> Aula virtual en la plataforma Cvirtual
                    </h1>
                </div>
            </div>
            <div class="row bg-light p-lg-3 pt-3">
                <div class="col-lg-7 col-12">
                    <p>Cvirtual es una plataforma Moodle, que acompaña los procesos de enseñanza-aprendizaje de la modalidad
                        presencial del ITM. Esta le permite tener y desarrollar en un mismo <em>Ambiente Virtual de
                            Aprendizaje</em> (AVA) material de estudio y hacer seguimiento a sus estudiantes.</p>
                    <p>Para empezar a utilizarla y tener sus grupos allí, por favor diligencie los siguientes campos:</p>
                    <small><b>Nota:</b> esta solicitud solo puede ser diligenciada por docentes del ITM. Tenga en cuenta que
                        el tiempo de respuesta no depende de la cantidad de veces que envíe la solicitud, sino del orden de
                        llegada.</small>
                </div>
                <div class="col-lg-4 col-12 offset-lg-1">
                    <p>Para una mejor experiencia de usuario, se recomienda usar los siguientes navegadores en su versión
                        más reciente: Chrome, Mozilla, Edge y Brave.</p>
                    <img class="img-fluid d-flex mx-auto" src="../../../../public/img/Browsers.png" alt="">
                </div>
                <hr>
                <div class="col-12">
                    <h3>Datos personales</h3>
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtNombreCompleto">Nombre completo <b class="text-danger">*</b></label>
                    <input :oninput="() => (datosDocente.nombreDocente = datosDocente.nombreDocente.toUpperCase())"
                        v-model.trim="datosDocente.nombreDocente" class="form-control" id="txtNombreCompleto" type="text"
                        placeholder="Nombre completo">
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtCedula">Cédula <b class="text-danger">*</b></label>
                    <input v-model.trim="datosDocente.cedulaDocente" class="form-control" id="txtCedula" type="text"
                        placeholder="Cédula">
                </div>

                <div class="col-12 mt-lg-3">
                    <label for="txtCorreo">Correo institucional <b class="text-danger">*</b></label>
                    <input v-model.trim="datosDocente.correoDocente" class="form-control" id="txtCorreo" type="text"
                        placeholder="Correo institucional">
                </div>
                <hr class="my-3">

                <div class="col-12">
                    <h3>Datos de asignatura</h3>
                    <p>Diligencie los datos requeridos y dé clic en <em><b>agregar</b></em> . Repita este procedimiento por
                        cada asignatura (aula) que desee solicitar.</p>
                </div>

                <div class="col-lg-6 col-12 mb-3">
                    <label for="txtNombreAsignatura">Nombre de la asignatura <b class="text-danger">*</b></label>
                    <input
                        :oninput="() => (datosAsignatura.nombreAsignatura = datosAsignatura.nombreAsignatura.toUpperCase())"
                        v-model.trim="datosAsignatura.nombreAsignatura" class="form-control" id="txtNombreAsignatura"
                        type="text" placeholder="Nombre de la asignatura">
                </div>
                <div class="col-lg-6 col-12 mb-3">
                    <label for="txtCodigoGrupoAsignatura">Código y grupo de la asignatura <b
                            class="text-danger">*</b></label>
                    <input
                        :oninput="() => (datosAsignatura.codigoGrupoAsignatura = datosAsignatura.codigoGrupoAsignatura.toUpperCase())"
                        v-model.trim="datosAsignatura.codigoGrupoAsignatura" class="form-control"
                        id="txtCodigoGrupoAsignatura" type="text" placeholder="Código y grupo de la asignatura">
                </div>
                <div class="col-lg-6 col-12 mb-3">
                    <label for="txtFacultad">Facultad <b class="text-danger">*</b></label>
                    <select v-model.trim="datosAsignatura.facultadAsignatura" class="form-control" id="txtFacultad"
                        type="text">
                        <option value="" selected>Seleccione una facultad</option>
                        <option value="Facultad de Artes y Humanidades">Facultad de Artes y Humanidades</option>
                        <option value="Facultad de Ciencias Económicas y Administrativas">Facultad de Ciencias Económicas y
                            Administrativas</option>
                        <option value="Facultad de Ciencias Exactas y Aplicadas">Facultad de Ciencias Exactas y Aplicadas
                        </option>
                        <option value="Facultad de Ingenierías">Facultad de Ingenierías</option>
                    </select>
                </div>
                <div class="col-lg-6 col-12">
                    <label for="txtPrograma">Programa <b class="text-danger">*</b></label>
                    <select v-model.trim="datosAsignatura.programaAsignatura" class="form-control" id="txtPrograma"
                        type="text">
                        <option value="">Seleccione un programa</option>
                        <option value="ADMINISTRACIÓN TECNOLÓGICA">ADMINISTRACIÓN TECNOLÓGICA</option>
                        <option value="ANÁLISIS DE COSTOS Y PRESUPUESTOS">ANÁLISIS DE COSTOS Y PRESUPUESTOS</option>
                        <option value="ARTES DE LA GRABACIÓN Y PRODUCCIÓN MUSICAL">ARTES DE LA GRABACIÓN Y PRODUCCIÓN MUSICAL</option>
                        <option value="ARTES VISUALES">ARTES VISUALES</option>
                        <option value="AUTOMATIZACIÓN ELECTRÓNICA">AUTOMATIZACIÓN ELECTRÓNICA</option>
                        <option value="CALIDAD">CALIDAD</option>
                        <option value="CIENCIAS AMBIENTALES">CIENCIAS AMBIENTALES</option>
                        <option value="CINE">CINE</option>
                        <option value="CONSTRUCCIÓN DE ACABADOS ARQUITECTÓNICOS">CONSTRUCCIÓN DE ACABADOS ARQUITECTÓNICOS</option>
                        <option value="CONTADURÍA PÚBLICA">CONTADURÍA PÚBLICA</option>
                        <option value="CONTROL DE LA CALIDAD">CONTROL DE LA CALIDAD</option>
                        <option value="DESARROLLO DE SOFTWARE">DESARROLLO DE SOFTWARE</option>
                        <option value="DISEÑO INDUSTRIAL">DISEÑO INDUSTRIAL</option>
                        <option value="ELECTROMECÁNICA">ELECTROMECÁNICA</option>
                        <option value="ELECTRÓNICA">ELECTRÓNICA</option>
                        <option value="ESPECIALIZACIÓN EN CIBERSEGURIDAD - PRESENCIAL">ESPECIALIZACIÓN EN CIBERSEGURIDAD - PRESENCIAL</option>
                        <option value="ESPECIALIZACIÓN EN FINANZAS">ESPECIALIZACIÓN EN FINANZAS</option>
                        <option value="ESPECIALIZACIÓN EN FORMULACIÓN Y EVALUACIÓN DE PROYECTOS">ESPECIALIZACIÓN EN FORMULACIÓN Y EVALUACIÓN DE PROYECTOS</option>
                        <option value="ESPECIALIZACIÓN EN GESTIÓN DE SISTEMAS ENERGÉTICOS">ESPECIALIZACIÓN EN GESTIÓN DE SISTEMAS ENERGÉTICOS INDUSTRIALES</option>
                        <option value="ESPECIALIZACIÓN EN GESTIÓN DEL MANTENIMIENTO INDUSTRIAL">ESPECIALIZACIÓN EN GESTIÓN DEL MANTENIMIENTO INDUSTRIAL</option>
                        <option value="ESPECIALIZACIÓN EN INGENIERÍA DE SOFTWARE - PRESENCIAL">ESPECIALIZACIÓN EN INGENIERÍA DE SOFTWARE - PRESENCIAL</option>
                        <option value="FACULTAD DE ARTES Y HUMANIDADES">FACULTAD DE ARTES Y HUMANIDADES</option>
                        <option value="FACULTAD DE CIENCIAS ECONÓMICAS Y ADMINISTRATIVAS">FACULTAD DE CIENCIAS ECONÓMICAS Y ADMINISTRATIVAS</option>
                        <option value="FACULTAD DE CIENCIAS EXACTAS Y APLICADAS">FACULTAD DE CIENCIAS EXACTAS Y APLICADAS</option>
                        <option value="FACULTAD DE INGENIERÍAS">FACULTAD DE INGENIERÍAS</option>
                        <option value="GESTIÓN ADMINISTRATIVA">GESTIÓN ADMINISTRATIVA</option>
                        <option value="GESTIÓN DE REDES DE TELECOMUNICACIONES">GESTIÓN DE REDES DE TELECOMUNICACIONES</option>
                        <option value="INFORMÁTICA MUSICAL">INFORMÁTICA MUSICAL</option>
                        <option value="INGENIERÍA BIOMÉDICA">INGENIERÍA BIOMÉDICA</option>
                        <option value="INGENIERÍA DE LA CALIDAD">INGENIERÍA DE LA CALIDAD</option>
                        <option value="INGENIERÍA DE PRODUCCIÓN">INGENIERÍA DE PRODUCCIÓN</option>
                        <option value="INGENIERÍA DE SISTEMAS">INGENIERÍA DE SISTEMAS</option>
                        <option value="INGENIERÍA DE TELECOMUNICACIONES">INGENIERÍA DE TELECOMUNICACIONES</option>
                        <option value="INGENIERÍA ELECTROMECÁNICA">INGENIERÍA ELECTROMECÁNICA</option>
                        <option value="INGENIERÍA ELECTRÓNICA">INGENIERÍA ELECTRÓNICA</option>
                        <option value="INGENIERÍA EN DISEÑO INDUSTRIAL">INGENIERÍA EN DISEÑO INDUSTRIAL</option>
                        <option value="INGENIERÍA FINANCIERA">INGENIERÍA FINANCIERA</option>
                        <option value="INGENIERÍA MECATRÓNICA">INGENIERÍA MECATRÓNICA</option>
                        <option value="MAESTRÍA EN ARTES DIGITALES">MAESTRÍA EN ARTES DIGITALES</option>
                        <option value="MAESTRÍA EN AUTOMATIZACIÓN Y CONTROL INDUSTRIAL">MAESTRÍA EN AUTOMATIZACIÓN Y CONTROL INDUSTRIAL</option>
                        <option value="MAESTRÍA EN CIENCIAS: INNOVACIÓN EN EDUCACIÓN">MAESTRÍA EN CIENCIAS: INNOVACIÓN EN EDUCACIÓN</option>
                        <option value="MAESTRÍA EN DESARROLLO SOSTENIBLE">MAESTRÍA EN DESARROLLO SOSTENIBLE</option>
                        <option value="MAESTRÍA EN ESTUDIOS DE CTS E INNOVACIÓN - PRESENCIAL">MAESTRÍA EN ESTUDIOS DE CTS E INNOVACIÓN - PRESENCIAL</option>
                        <option value="MAESTRÍA EN GESTIÓN DE LA INNOVACIÓN TECNOLÓGICA COOPERACIÓN Y DESARROLLO REGIONAL">MAESTRÍA EN GESTIÓN DE LA INNOVACIÓN TECNOLÓGICA COOPERACIÓN Y DESARROLLO REGIONAL</option>
                        <option value="MAESTRÍA EN GESTIÓN DE ORGANIZACIONES">MAESTRÍA EN GESTIÓN DE ORGANIZACIONES</option>
                        <option value="MAESTRÍA EN GESTIÓN ENERGÉTICA INDUSTRIAL">MAESTRÍA EN GESTIÓN ENERGÉTICA INDUSTRIAL</option>
                        <option value="MAESTRÍA EN INGENIERÍA BIOMÉDICA">MAESTRÍA EN INGENIERÍA BIOMÉDICA</option>
                        <option value="MAESTRÍA EN METROLOGÍA">MAESTRÍA EN METROLOGÍA</option>
                        <option value="MAESTRÍA EN SEGURIDAD INFORMÁTICA">MAESTRÍA EN SEGURIDAD INFORMÁTICA</option>
                        <option value="MANTENIMIENTO DE EQUIPO BIOMÉDICO">MANTENIMIENTO DE EQUIPO BIOMÉDICO</option>
                        <option value="PRODUCCIÓN">PRODUCCIÓN</option>
                        <option value="QUÍMICA INDUSTRIAL">QUÍMICA INDUSTRIAL</option>
                        <option value="SISTEMAS DE INFORMACIÓN">SISTEMAS DE INFORMACIÓN</option>
                        <option value="SISTEMAS DE PRODUCCIÓN">SISTEMAS DE PRODUCCIÓN</option>
                        <option value="SISTEMAS ELECTROMECÁNICOS">SISTEMAS ELECTROMECÁNICOS</option>
                        <option value="TELECOMUNICACIONES">TELECOMUNICACIONES</option>
                    </select>
                </div>
                <div class="col-lg-6 col-12 mt-3">
                    <div v-if="camposAsignaturaFaltantes" class="alert alert-danger" role="alert">
                        ¡Todos los campos de asignatura son obligatorios!
                    </div>
                    <button class="btn bg-azulOscuro text-white" @click="agregarCurso">Agregar</button>
                </div>

                <div class="col-12 my-3" v-if="listaCursosAgregados.length !== 0">
                    <h5>Lista de cursos agregados</h5>
                    <div class="table-responsive">
                        <table class="table table-striped border">
                            <thead>
                                <th scope="col" class="p-3">Nombre de la asignatura</th>
                                <th scope="col" class="p-3">Código y grupo</th>
                                <th scope="col" class="p-3">Facultad</th>
                                <th scope="col" class="p-3">Programa</th>
                                <th scope="col" class="p-3">Eliminar</th>
                            </thead>
                            <tbody>
                                <tr v-for="(curso, index) in listaCursosAgregados" :key="index">
                                    <td class="p-3">{{ curso.nombreAsignatura }}</td>
                                    <td class="p-3">{{ curso.codigoGrupoAsignatura }}</td>
                                    <td class="p-3">{{ curso.facultadAsignatura }}</td>
                                    <td class="p-3">{{ curso.programaAsignatura }}</td>
                                    <td class="text-center p-3"><i @click="eliminarAsignatura(index)" class="fa fa-trash-o"
                                            style="font-size:24px; cursor: pointer;"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr class="mt-3">

                <div class="col-lg-6 col-12">
                    <h5>Formato de aula <b class="text-danger">*</b></h5>
                    <div class="form-check">
                        <input v-model.trim="informacionAdicionalSolicitud.formatoAula" value="Temas (navegación vertical)"
                            class="form-check-input" type="radio" name="rbtnFormatoAula" id="rbtnFormatoAula1">
                        <label class="form-check-label" for="rbtnFormatoAula1">
                            Temas (navegación vertical)
                        </label>
                    </div>
                    <div class="form-check">
                        <input v-model.trim="informacionAdicionalSolicitud.formatoAula"
                            value="Pestañas (17 semanas - navegación horizontal)" class="form-check-input" type="radio"
                            name="rbtnFormatoAula" id="rbtnFormatoAula2">
                        <label class="form-check-label" for="rbtnFormatoAula2">
                            Pestañas (17 semanas - navegación horizontal)
                        </label>
                    </div>
                </div>
                <div class="col-lg-6 col-12 mt-lg-0 mt-3">
                    <p>Indique si le gustaría estar matriculado en el curso Moodle para Docentes, el cual puede utilizar
                        como material de consulta para el manejo de las diferentes herramientas de esta plataforma. <b
                            class="text-danger">*</b></p>
                    <div class="form-check">
                        <input v-model.trim="informacionAdicionalSolicitud.matriculadoCursoDocentes"
                            class="form-check-input" type="radio" name="rbtnMatriculaCursoMoodle"
                            id="rbtnMatriculaCursoMoodle1" value="Sí">
                        <label class="form-check-label" for="rbtnMatriculaCursoMoodle1">
                            Sí
                        </label>
                    </div>
                    <div class="form-check">
                        <input v-model.trim="informacionAdicionalSolicitud.matriculadoCursoDocentes"
                            class="form-check-input" type="radio" name="rbtnMatriculaCursoMoodle"
                            id="rbtnMatriculaCursoMoodle2" value="No">
                        <label class="form-check-label" for="rbtnMatriculaCursoMoodle2">
                            No
                        </label>
                    </div>
                </div>

                <div class="col-12 mt-3 mb-3">
                    <h5 class="text-center">Importante:</h5>
                </div>

                <div class="col-lg-6 col-12">
                    <p>En caso de requerir capacitación, por favor confirmar uno de los horarios para enviarle la invitación
                        al encuentro sincrónico.</p>
                    <select v-model.trim="informacionAdicionalSolicitud.horarioCapacitacion" class="form-select"
                        aria-label="Default select example">
                        <option value="" selected>Seleccione un horario</option>
                        <option value="Lunes 8:00 a.m. a 10:00 a.m. Moodle básico">Lunes 8:00 a.m. a 10:00 a.m. Moodle
                            básico</option>
                        <option value="Martes 2:00 p.m. a 3:00 p.m. Cuestionario">Martes 2:00 p.m. a 3:00 p.m. Cuestionario
                        </option>
                        <option value="Miercoles 8:00 a.m. a 9:00 a.m. Cuestionario">Miercoles 8:00 a.m. a 9:00 a.m.
                            Cuestionario</option>
                        <option value="Jueves 2:00 p.m. a 4:00 p.m. Moodle básico">Jueves 2:00 p.m. a 4:00 p.m. Moodle
                            básico</option>
                        <option value="Viernes 8:00 a.m. a 10:00 a.m. Moodle básico">Viernes 8:00 a.m. a 10:00 a.m. Moodle
                            básico</option>
                    </select>
                </div>
                <div class="col-lg-6 col-12 mt-lg-0 mt-3">
                    <p>Si usted es docente antiguo en Cvirtual y requiere migración de contenidos del semestre anterior, dé
                        clic aquí</p>
                    <h6>Diligencie los siguientes campos:</h6>
                    <label for="txtNombreCodigoAulaOrigen">Nombre y código del aula origen:</label>
                    <textarea v-model.trim="informacionAdicionalSolicitud.nombreCodigoAulaOrigen" class="form-control"
                        id="txtNombreCodigoAulaOrigen" name="" cols="30" rows="5"></textarea>
                    <label for="txtNombreCodigoAulaDestino" class="mt-3">Nombre(s) y código(s) del(as) aula(s)
                        destino:</label>
                    <textarea v-model.trim="informacionAdicionalSolicitud.nombreCodigoAulaDestino" class="form-control"
                        id="txtNombreCodigoAulaDestino" name="" cols="30" rows="5"></textarea>
                </div>
                <hr class="mt-3">
                <div class="col-12">
                    <div v-if="camposGeneralesFaltantes" class="alert alert-danger" role="alert">
                        ¡Todos los campos marcados con <b class="text-danger">*</b> son obligatorios!
                    </div>
                    <div v-if="listaCursosVacia" class="alert alert-danger" role="alert">
                        ¡Debe agregar almenos una asignatura para realizar el registro!
                    </div>
                    <div v-if="emailInvalido" class="alert alert-danger" role="alert">
                        El correo electrónico del docente debe ser el correo institucional
                        <b>'ejemplo@correo.itm.edu.co'</b> ó <b>'ejemplo@itm.edu.co'</b>
                    </div>
                    <p class="text-center" v-if="loading"><b>Enviando información, por favor espere...</b></p>
                    <div class="d-flex justify-content-center mb-3" v-if="loading">
                        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    </div>
                    <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                        <b>{{ mensajeError }}</b>
                    </div>
                    <button class="btn bg-azulOscuro text-white d-flex mx-auto mb-lg-0 mb-3"
                        @click="enviarInformacion">Enviar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useDocenteStore } from '@/stores/docentes';
import { useRoute } from 'vue-router';


import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert'
import { validarEmailInstitucional } from "@/helpers/functions";

const docenteStore = ref(null);
const route = useRoute();

const camposAsignaturaFaltantes = ref(false);
const camposGeneralesFaltantes = ref(false);
const listaCursosVacia = ref(false);
const emailInvalido = ref(false);
const loading = ref(false);
const mensajeError = ref(null);

const datosDocente = ref({
    nombreDocente: '',
    cedulaDocente: '',
    correoDocente: ''
});

const datosAsignatura = ref({
    nombreAsignatura: '',
    codigoGrupoAsignatura: '',
    facultadAsignatura: '',
    programaAsignatura: ''
});

const informacionAdicionalSolicitud = ref({
    formatoAula: '',
    matriculadoCursoDocentes: '',
    horarioCapacitacion: '',
    nombreCodigoAulaOrigen: '',
    nombreCodigoAulaDestino: ''
})

const listaCursosAgregados = ref([]);


const validaCamposFaltantes = (objeto) => {
    const camposNoObligatorios = [
        "horarioCapacitacion",
        "nombreCodigoAulaOrigen",
        "nombreCodigoAulaDestino"
    ];

    const camposValidados = Object.keys(objeto).map(key => objeto[key] === '' && !camposNoObligatorios.includes(key) ? true : false)
    return camposValidados;
}

const limpiaCampos = (objeto) => {
    Object.keys(objeto).map(key => objeto[key] = "")
}

const agregarCurso = () => {
    limpiaAlertas();
    const camposValidados = validaCamposFaltantes(datosAsignatura.value);
    if (camposValidados.includes(true)) {
        return camposAsignaturaFaltantes.value = true;
    }
    const nuevaAsignatura = { ...datosAsignatura.value };
    listaCursosAgregados.value = [...listaCursosAgregados.value, nuevaAsignatura];
    limpiaCampos(datosAsignatura.value)
}

const eliminarAsignatura = (indexEliminar) => {
    const listaFiltrada = listaCursosAgregados.value.filter((_, index) => index !== indexEliminar);
    listaCursosAgregados.value = listaFiltrada;
}

const enviarInformacion = () => {
    limpiaAlertas();

    const camposFaltantesDocente = validaCamposFaltantes(datosDocente.value);
    const camposFaltantesAdicionales = validaCamposFaltantes(informacionAdicionalSolicitud.value);

    if (camposFaltantesDocente.includes(true) || camposFaltantesAdicionales.includes(true)) {
        return camposGeneralesFaltantes.value = true;
    }

    const emailValido = validarEmailInstitucional(datosDocente.value['correoDocente'])
    if (!emailValido) return emailInvalido.value = true;

    if (listaCursosAgregados.value.length === 0) return listaCursosVacia.value = true;

    sweetAlertConfirm(
        '¿Desea enviar la información diligenciada?',
        'Los datos diligenciados se enviarán para su respectiva revisión.',
        'warning',
        'Enviar'
    ).then(async (result) => {
        if (result.isConfirmed) {

            loading.value = true;

            Object.keys(informacionAdicionalSolicitud.value).forEach(key => {
                if (informacionAdicionalSolicitud.value[key] === '') {
                    informacionAdicionalSolicitud.value[key] = 'N/A';
                }
            });

            const datosSolicitud = {
                datosDocente: datosDocente.value,
                listaCursosAgregados: listaCursosAgregados.value,
                informacionAdicionalSolicitud: informacionAdicionalSolicitud.value
            };

            const solicitudAulaCreada = await docenteStore.value.createSolicitudAulaVirtual(datosSolicitud)

            if (solicitudAulaCreada.message) {
                mensajeError.value = solicitudAulaCreada.message;
            } else {
                loading.value = false;
                sweetAlertSuccess(
                    '¡Datos enviados!',
                    'Los datos diligenciados se han enviado de manera exitosa',
                    'success'
                ).then(() => {
                    location.reload();
                });
            }
        } else (
            result.dismiss === Swal.DismissReason.cancel
        )
    })
}

const limpiaAlertas = () => {
    camposAsignaturaFaltantes.value = false
    camposGeneralesFaltantes.value = false;
    listaCursosVacia.value = false;
    emailInvalido.value = false;
}

onMounted(() => {
    docenteStore.value = useDocenteStore();
    document.title = route.meta.title;
}) 
</script>



