<template>
    <div></div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits, onMounted } from 'vue';
  import Swal from 'sweetalert2';
  
  const props = defineProps({
    title: { type: String, required: true },
    text: { type: String, required: true },
    icon: { type: String, default: 'info' },
    confirmButtonText: { type: String, default: 'Aceptar' },
    cancelButtonText: { type: String, default: 'Cancelar' },
    showCancelButton: { type: Boolean, default: false },
    customHtml: { type: String, default: '' }
  });
  
  const emit = defineEmits(['result']);
  
  const showAlert = async () => {
    const result = await Swal.fire({
      title: props.title,
      html: props.customHtml + props.text,
      icon: props.icon,
      showCancelButton: props.showCancelButton,
      confirmButtonText: props.confirmButtonText,
      cancelButtonText: props.cancelButtonText,
      customClass: {
        confirmButton: 'btn btnConfirmSweetAlert mx-2',
        cancelButton: 'btn btnCancelSweetAlert'
      },
      buttonsStyling: false,
      reverseButtons: true
    });
  
    emit('result', result); // Emitir el resultado al padre
  };
  
  onMounted(() => {
    showAlert();
  });
  </script>
  