<template>
    <div class="container pb-5">
      <div class="row">
         <div class="col-12 p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los gestores mediante el formulario de <em><b>Acompañamiento docente</b></em>.</p>
          </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
            <label for="ddlFacultad">Facultad <b class="text-danger">*</b></label> <br>
            <select id="ddlFacultad" class="form-select form-select-lg mb-3" v-model.trim="nombreFacultad" aria-label=".form-select-lg example">
                <option selected value="">Seleccione una opción</option>
                <option v-for="(facultad, index) of facultades" :key="index">{{facultad.text}}</option>
            </select>
        </div>
    </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
            <div v-if="registrosAcompanamientoDocente.length != 0" class="">
              <DataTable v-if="registrosAcompanamientoDocente.length !== 0" :btnsDisable="btnsDisableToTable" :info="registrosAcompanamientoDocente" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>


<script>
import { useGestorStore } from '@/stores/gestores';
import { obtenerFacultades } from '../../../helpers/functions';
import DataTable from '../../../components/DataTable'

export default {
    name: 'infoAcompanamientoDocentes',
    data() {
        return {
            gestorStore: null,
            noData: false,
            registrosAcompanamientoDocente: [],
            facultades: obtenerFacultades(),
            nombreFacultad: null,
            btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
            cabeceraDatatable: [
                {text: 'N°'},
                {text: 'Criterio'},
                {text: 'Nombre Docente'},
                {text: 'Descriptor'},
                {text: 'Total'},
                {text: 'Fecha de Registro'}
            ],
            tituloReporteDataTable: 'Reporte de acompañamiento docente',
        }
    },
    components: {
        DataTable
    },
    watch: {
        nombreFacultad: async function(valor){
            this.noData = false;
            let registrosDB = []

            if(valor) {
                this.registrosAcompanamientoDocente = await this.gestorStore.fetchAcompanamientoDocente(valor)
                this.registrosAcompanamientoDocente.length === 0 ? this.noData = true : null;

               Array.from(new Set(this.registrosAcompanamientoDocente.map(registro => registro.docente))).map(nombreDocente => {

                    let registroPorDocente = this.registrosAcompanamientoDocente.filter(registro => registro.docente === nombreDocente);

                    registroPorDocente.map(registro => {
                        let obj = {
                            criterio: registro.criterio,
                            docente: registro.docente,
                            descriptor: registro.descriptor,
                            total: registroPorDocente.reduce((acc, current) => acc += parseInt(current.descriptor) / registroPorDocente.length , 0),
                            fechaRegistro: registro.fechaRegistro.split('T')[0],
                        }
                        registrosDB = [ ...registrosDB, obj ]
                    })
                })

                this.registrosAcompanamientoDocente = registrosDB
            }
        }
    },
    async mounted() {
        this.gestorStore = useGestorStore();
    }
}
</script>