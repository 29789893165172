<template>
     <div class="container">
        <div ref="modalElement" class="modal fade" id="mdlEnvioCorreo" tabindex="-1" aria-labelledby="mdlEnvioCorreoLabel" aria-modal="true" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mdlEnvioCorreoLabel">Enviar email</h5>
              </div>
              <div class="modal-body">
                <div class="mb-3" v-if="tipoCorreo !== null">
                  <label for="txtAsuntoCorreo">Asunto:</label>
                  <input v-model="correo.asunto" id="txtAsuntoCorreo" name="asuntoCorreo" type="text" placeholder="Asunto" class="form-control">
                </div>
                <div>
                  <div class="form-check">
                    <input v-model="tipoCorreo" value="1" class="form-check-input" type="radio" name="rbtnTipoCorreo" id="rbtnTipoCorreo1">
                    <label class="form-check-label" for="rbtnTipoCorreo1">
                      Desde plantilla
                    </label>
                  </div>
                  <div class="form-check">
                    <input v-model="tipoCorreo" value="2" class="form-check-input" type="radio" name="rbtnTipoCorreo" id="rbtnTipoCorreo2">
                    <label class="form-check-label" for="rbtnTipoCorreo2">
                      Crear cuerpo de correo
                    </label>
                  </div>
                </div>
                <div class="mt-3" v-if="tipoCorreo === '1'">
                  <label for="imgCuerpoCorreo" class="mb-3">Plantilla para correo <b>(Documento de word)</b></label>
                  <input type="file" name="plantillaEmail" @change="archivoOnChange"  id="imgCuerpoCorreo">
                </div>
                <div class="mt-3" v-if="tipoCorreo === '2'">
                  <label for="txtCuerpoCorreo">Cuerpo:</label>
                  <textarea v-model="correo.cuerpoCorreo" id="txtCuerpoCorreo" name="cuerpoCorreo" cols="30" rows="10" placeholder="Cuerpo del correo" class="form-control"></textarea>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <div class="row">
                  <div class="alert alert-danger mt-4" role="alert" v-if="mensajeError !== ''">
                    {{ mensajeError }}
                  </div>
                  <div v-if="loading">
                    <div class="d-flex justify-content-center">
                      <p><b>Enviando correos, por favor espere...</b></p>
                    </div>
                    <div class="d-flex justify-content-center">
                      <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                 <div class="col-12 d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary m-1" id="carrarModal" @click="cierraModal()">Cerrar</button>
                    <button type="button" class="btn btnDataTable m-1" @click="enviaEmail">Enviar Email</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show" id="backdrop"  style="display: none;"></div>
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { sweetAlertConfirm, sweetAlertSuccess } from '../commons/SweetAlert'
import { useMoodleStore } from '@/stores/moodle';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    components: {
      PulseLoader,
    },
    setup() {
        const moodleStore = ref(null);
        const correo = ref({
          asunto: '',
          cuerpoCorreo: '',
          archivoPlantillaCorreo: '',
        });

        const tipoCorreo = ref(null);
        const loading = ref(false);
        const mensajeError = ref("");

        const openDataTableEmailModal = () => {
          document.getElementById("backdrop").style.display = "block"
          document.getElementById("mdlEnvioCorreo").style.display = "block"
          document.getElementById("mdlEnvioCorreo").className += "show"
        }

        const enviaEmail = () => {
          mensajeError.value = '';
          let filasLocalStorage = localStorage.getItem('filasSeleccionadasDTEnvioEmail');
          let filasLocalStorageArray = JSON.parse(filasLocalStorage);
          let filasModificadas = filasLocalStorageArray.map(fila => {
            return {
              nombreUsuario: fila.nombre + ' ' + fila.apellido,
              email: fila.email,
              rol: fila.rol,
            }
          }); 

          if(tipoCorreo.value == 1) {
            if(correo.value['asunto'] === '' || correo.value['archivoPlantillaCorreo'] === '') {
              mensajeError.value = '¡Debe diligenciar todos los campos referentes al correo a enviar!';
              return;
            }
            let nombreArchivoArray = correo.value['archivoPlantillaCorreo'].name.split('.');
            let extensionArchivo = nombreArchivoArray[nombreArchivoArray.length - 1];

            if(extensionArchivo !== 'docx' && extensionArchivo !== 'DOCX'){
              mensajeError.value = '¡El archivo adjunto debe ser un archivo de Word!';
              return;
            }
          
          }else{
            if(correo.value['asunto'] === '' || correo.value['cuerpoCorreo'] === '') {
              mensajeError.value = '¡Debe diligenciar todos los campos referentes al correo a enviar!';
              return;
            }
          }

          sweetAlertConfirm(
                  '¿Desea enviar el correo electrónico diligenciado a los usuarios elegidos?',
                  'Se enviará un correo electrónico a cada usuario.',
                  'warning',
                  'Enviar')
          .then(async (result) => {
            if (result.isConfirmed) {

              loading.value = true;

              if(filasLocalStorage.length === 0) {
                return;
              }

              if(filasModificadas.length > 100) {
                const PART_SIZE = 100;
                const totalParts = Math.ceil(filasModificadas.length / PART_SIZE);
                let emailPromises = [];
      
                for (let i = 0; i < totalParts; i++) {
                  let part = filasModificadas.slice(i * PART_SIZE, (i + 1) * PART_SIZE);
                  let filasString =  JSON.stringify(part);

                  let data = new FormData();
                  data.append('tipoCorreo', tipoCorreo.value)
                  data.append('asunto', correo.value['asunto']);
                  data.append('cuerpoCorreo', correo.value['cuerpoCorreo']);
                  data.append('archivoPlantillaCorreo', correo.value['archivoPlantillaCorreo']);
                  data.append('filasSeleccionadas', filasString)
           
                  emailPromises.push(moodleStore.value.envioEmailUsuariosMoodle(data));
                  // const envioCorreoUsuariosMoodle = await API.envioCorreoUsuariosMoodle(data);
                  // const envioCorreoUsuariosMoodle = await moodleStore.value.envioEmailUsuariosMoodle(data);
                  // if(envioCorreoUsuariosMoodle.message){
                  //   return mensajeError.value = envioCorreoUsuariosMoodle.message;
                  // }
                }
                const results = await Promise.allSettled(emailPromises);

                results.forEach((result, index) => {
                  if(result.status === 'rejected') {
                    console.error(`Error en el envío de la parte ${index + 1}:`, result.reason);
                  } else {
                    console.log(`Parte ${index + 1} enviada con éxito.`);
                  }
                })
              } else {
                  let data = new FormData();
                  data.append('tipoCorreo', tipoCorreo.value)
                  data.append('asunto', correo.value['asunto']);
                  data.append('cuerpoCorreo', correo.value['cuerpoCorreo']);
                  data.append('archivoPlantillaCorreo', correo.value['archivoPlantillaCorreo']);
                  data.append('filasSeleccionadas', filasModificadas)
                  await moodleStore.value.envioEmailUsuariosMoodle(data);
              }

              loading.value = false;
                sweetAlertSuccess(
                  '¡Correos enviados!',
                  'Los correos electrónicos se han enviado de manera exitosa',
                  'success'
                ).then(() => {
                    localStorage.setItem('filasSeleccionadasDTEnvioEmail', '');
                    location.reload();
              });

            } else  (
              result.dismiss === Swal.DismissReason.cancel
            ) 
          })
        }

        const cierraModal = () => {
          loading.value = false;
          document.getElementById("backdrop").style.display = "none"
          document.getElementById("mdlEnvioCorreo").style.display = "none"
          document.getElementById("mdlEnvioCorreo").className += document.getElementById("mdlEnvioCorreo").className.replace("show", "")
        }

        const archivoOnChange = () => {
          correo.value['archivoPlantillaCorreo'] = document.getElementById("imgCuerpoCorreo").files[0];
        }

        watch(tipoCorreo , ( newValue, oldValue ) => {
          loading.value = false;
          mensajeError.value = '';
          correo.value['asunto'] = '';
          correo.value['cuerpoCorreo'] = '';
          correo.value['archivoPlantillaCorreo'] = '';
        })

        onMounted(() => {
          moodleStore.value = useMoodleStore();
        }) 

        return {
          correo,
          loading,
          mensajeError,
          openDataTableEmailModal,
          tipoCorreo,
          cierraModal,
          enviaEmail,
          archivoOnChange,
        }
    }
}

</script>