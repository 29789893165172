<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los docentes mediante el formulario de <em><b>Apoyo para exámenes de habilitación APV</b></em>.</p>
          </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
            <div v-if="infoApoyoExamenesHabilitaciones.length != 0" class="">
              <DataTable v-if="infoApoyoExamenesHabilitaciones.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoApoyoExamenesHabilitaciones" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>

<script>

//Importando API que conecta front con back
import API from '../../../api'
import DataTable from '../../../components/DataTable'

export default {
  name: 'infoApoyoExamenesHabilitaciones',

  data() {
    return {
      btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
      infoApoyoExamenesHabilitaciones: [],
      noData: false,
      cabeceraDatatable: [
        {text: 'N°'},
        {text: 'Observación'},
        {text: 'Nombre Docente'},
        {text: 'Opción Habilitación'},
        {text: 'Fecha de Registro'}
      ],
      tituloReporteDataTable: 'Reporte de apoyo para exámenes de habilitación APV'
    }
  },
  components: {
    DataTable
  },
  created() {
    this.verInfo()
  },
  methods: {
    verInfo: async function() {
      const { info } = await API.fetchInfoApoyoExamenesHabilitaciones();
      this.infoApoyoExamenesHabilitaciones = info;
      this.infoApoyoExamenesHabilitaciones.length === 0 ? this.noData = true : this.noData = false;
    }
  },
  mounted() {
    document.title = this.$route.meta.title // Cambiar el título de la página
  }
}
</script>
