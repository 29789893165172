export const getCatedrasAbiertas = () => {
    return [
        { modalidad: 'Virtual',conCupos: true, value: "Ciudadanía y Paz", text: "Ciudadanía y Paz" },
        { modalidad: 'Virtual',conCupos: true, value: "Deporte y cultura", text: "Deporte y cultura" },
        // { modalidad: 'Virtual',conCupos: false, value: "Ciudadanía y Derechos Humanos", text: "Ciudadanía y Derechos Humanos" },
        { modalidad: 'Virtual',conCupos: true, value: "Cine y Psicoterapia", text: "Cine y Psicoterapia" },
        { modalidad: 'Virtual',conCupos: true, value: "Contracultura y movimientos no violentos a lo largo de la historia", text: "Contracultura y movimientos no violentos a lo largo de la historia" },
        { modalidad: 'Virtual',conCupos: true, value: "Revolución 4.0, entre la tecnología y el humanismo", text: "Revolución 4.0, entre la tecnología y el humanismo" },
        { modalidad: 'Virtual',conCupos: true, value: "Medellín, Ciudad ocultada", text: "Medellín, Ciudad ocultada" },
        { modalidad: 'Virtual',conCupos: true, value: "Ciudades Inteligentes", text: "Ciudades Inteligentes" },
        { modalidad: 'Virtual',conCupos: true, value: "Cine e Identidades", text: "Cine e Identidades" },
        { modalidad: 'Virtual',conCupos: true, value: "La locura en el Cine", text: "La locura en el Cine" },
        { modalidad: 'Virtual',conCupos: true, value: "Cátedra PazLab", text: "Cátedra PazLab" },
        { modalidad: 'Virtual',conCupos: false, value: "Cátedra de Ciencia, Tecnología e Investigación. Los retos de América Latina", text: "Cátedra de Ciencia, Tecnología e Investigación. Los retos de América Latina" },
        // { modalidad: 'Virtual',conCupos: false, value: "Innovación social y desarrollo sostenible", text: "Innovación social y desarrollo sostenible" },
        { modalidad: 'Presencial',conCupos: false, value: "Fotografía Digital", text: "Fotografía Digital" },
        { modalidad: 'Presencial',conCupos: false, value: "Dibujo y técnicas graficas", text: "Dibujo y técnicas graficas" },
        { modalidad: 'Presencial',conCupos: true, value: "Cátedra Distopia, La Era de la Extinción", text: "Cátedra Distopia, La Era de la Extinción" },
        { modalidad: 'Presencial',conCupos: true, value: "Bichos de otros mundos", text: "Bichos de otros mundos" },
        { modalidad: 'Presencial',conCupos: false, value: "Cátedra ¿Creer es más fácil que pensar?", text: "Cátedra ¿Creer es más fácil que pensar?" },
        { modalidad: 'Presencial',conCupos: false, value: "Escuela de liderazgo", text: "Escuela de liderazgo" },
        { modalidad: 'Virtual',conCupos: true, value: "Laboratorio de Cine y Creatividad", text: "Laboratorio de Cine y Creatividad" }
      ]
}