<template>
    <div class="container-fluid homePage pt-5">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-md-12 col-12 d-lg-block d-none text-center">
                <img src="img/img-estudiantes.png" class="img-fluid d-flex mx-auto animated fadeInUp"
                    alt="Imagen formulario Gestión Curricular ITM">
            </div>
            <div class="col-lg-8 col-md-12 col-12 mt-4 mt-lg-0 mt-lg-4 mt-0 mt-lg-0 animated fadeInLeft">
                <h1 class="text-center text-white">Gestión curricular - Educación Virtual</h1>
                <p class="text-white mt-3">El equipo de Gestión Curricular de la Unidad de Educación Virtual acompaña el
                    desarrollo microcurricular de las asignaturas mediadas por las TIC, que comprende las actividades
                    que se ejecutan antes, durante y después del periodo académico para llevar a cabo el proceso
                    integral que exige el objeto de conocimiento y la competencia de cada asignatura. </p>
                <p class="text-white">Los siguientes formularios recogen información para los diferentes procesos de la
                    gestión curricular de la enseñanza-aprendizaje mediada por TIC.</p>
                <ul class="text-white list-unstyled">
                    <!-- <li class="my-3"><h4><router-link to="/docentes/recepcionGDCA" class="botonanimado my-2 text-decoration-none text-white">Guías Didacticas y Compromiso Académico</router-link></h4></li> -->
                    <li class="my-3"><h4><router-link to="/docentes/notariaDocente" class="botonanimado my-2 text-decoration-none text-white">Notaría por parte del docente</router-link></h4></li>
                    <li class="my-3"><h4><router-link to="/docentes/cancelacionAsignaturas" class="botonanimado my-2 text-decoration-none text-white">Cancelaciones</router-link></h4></li>
                    <!-- <li class="my-3"><h4><router-link to="/docentes/apoyoExamenesHabilitaciones" class="botonanimado my-2 text-decoration-none text-white">Exámenes de habilitación APV</router-link></h4></li> -->
                </ul>
            </div>
        </div>
        <!-- Footer -->
        <footer class="footer mt-5">
            <div class="container-fluid">
                <div class="row m-0 p-0">
                    <div class="col-12 d-flex justify-content-end">
                        <img src="img/Logo-Alcaldia.png" alt="Logo Alcaldía de Medellín" class="img-fluid">
                    </div>
                </div>
            </div>
        </footer>
        <!-- Footer -->
    </div>
</template>
<script>

// import API from '../api';

export default {
    name: 'inicioDocentes',
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>

