import EncuestaSatisfaccion from "@/views/Formularios/Generales/EncuestaSatisfaccion.vue"
import SolicitudUDEV from "../views/Formularios/Generales/SolicitudUDEV.vue"
import EncuestaCaracterizacionDigital from "@/views/Formularios/Generales/EncuestaCaracterizacionDigital.vue";
import DepartamentoDePersonal from "@/views/Formularios/Generales/DepartamentoDePersonal.vue";

export default [
    {
        path: '/generales/solicitudUnidadDeEducacionVirtual',
        name: 'solicitudUDEV',
        component: SolicitudUDEV,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Solicitud a la Unidad de Educación Virtual',
            hideNavbar: true
        }
    },
    {
        path: '/generales/encuestaSatisfaccion',
        name: 'encuestaSatisfaccion',
        component: EncuestaSatisfaccion,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Encuesta de Satisfacción',
            hideNavbar: true
        }
    },
    {
        path: '/generales/encuestaCaracterizacionDigital',
        name: 'encuestaCaracterizacionDigital',
        component: EncuestaCaracterizacionDigital,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Encuesta de Caracterización digital',
            hideNavbar: true
        }
    },
    {
        path: '/generales/departamentoDePersonal',
        name: 'departamentoDePersonal',
        component: DepartamentoDePersonal,
        meta : {
            title: 'Gestión Curricular - Unidad Virtual / Encuesta de Caracterización digital',
            hideNavbar: true
        }
    },
]