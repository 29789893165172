<template>
    <div class="container-fluid backgroundITM_blanco p-4">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-12">
                <img src="img/Estrategias/LOGO_80.png" class="img-fluid d-flex mx-auto" alt="">
                
            </div>  
        </div> 
        <div class="row justify-content-center align-items-center my-3">
            <h2 class="text-center my-4"><b>Haz clic en el curso que te deseas inscribir</b></h2>
            <div class="col-lg-4 col-12 text-center">
                <router-link to="/estrategias/informacionCientifica" class="btnanimacion my-2" ><img src="img/Estrategias/informacionCientifica2024.png" class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}" alt=""></router-link>
            </div>  
            <div class="col-lg-4 col-12 text-center">
                <router-link to="/estrategias/citasReferenciasAPA" class="btnanimacion my-2" ><img src="img/Estrategias/citasReferenciasAPA2024.png" class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}" alt=""></router-link>
            </div>  
            
        </div>
        <div class="row justify-content-center align-items-center my-3">
            <div class="col-lg-4 col-12 text-center">
                <router-link to="/estrategias/visibilidadCientifica" class="btnanimacion my-2" ><img src="img/Estrategias/visibilidadCientifica2024.png" class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}" alt=""></router-link>
            </div>  
        </div>
        <!-- <div class="row justify-content-center align-items-center my-3">
            
        </div> -->
    </div>
</template>
<script>

export default {
    name: 'inicioEstrategias',
    components: {
      
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>

