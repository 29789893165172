<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Banner_FormularioCatedrasAbiertas.jpg" class="img-fluid d-flex mx-auto vw-100" alt="">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 mt-3">
                    <p>Las Cátedras Abiertas son una oportunidad que tienes para invertir en tu formación integral con un sentido profundo de humanidad y ciudadanía.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-12 mt-lg-2 mt-0">
                  <label for="txtNombres">Nombres<b class="text-danger">*</b></label> <br>
                  <input :oninput="() => (estudiante.nombres = estudiante.nombres.toUpperCase())" class="form-control"
                    type="text" id="txtNombres" v-model.trim="estudiante.nombres">
                </div>
                <div class="col-xl-6 col-12 mt-2">
                  <label for="txtApellidos">Apellidos<b class="text-danger">*</b></label> <br>
                  <input :oninput="() => (estudiante.apellidos = estudiante.apellidos.toUpperCase())" class="form-control"
                    type="text" id="txtApellidos" v-model.trim="estudiante.apellidos">
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-12 mt-2">
                  <label for="txtNumDocumento">Número de documento de identidad <b class="text-danger">*</b></label> <br>
                  <input class="form-control" type="number" id="txtNumDocumento" v-model.trim="estudiante.numDocumento">
                </div>
                <div class="col-xl-6 col-12 mt-2">
                  <label for="txtCorreo">Correo institucional <b class="text-danger">*</b></label> <br>
                  <input class="form-control" type="text" id="txtCorreo" v-model.trim="estudiante.correo">
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-12 mt-2">
                    <label for="nombreCatedra">Nombre de la cátedra <b class="text-danger">*</b></label> <br>
                    <select id="nombreCatedra" class="form-select form-select-lg mb-3" v-model.trim="estudiante.catedra"
                        aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(catedra, index) of catedras" :key="index" :disabled="!catedra.conCupos" :value="catedra">{{ catedra.text }}</option>
                    </select>
                    <p class="text-danger" v-if="estudiante.catedra">Estudiante, ten presente que esta cátedra es de modalidad <b>{{ estudiante.catedra.modalidad }}</b></p>
                </div>
                <div class="col-xl-6 col-12 mt-2">
                    <label for="txtcelular">Celular <b class="text-danger">*</b></label> <br>
                    <input class="form-control" type="text" id="txtcelular" v-model.trim="estudiante.celular">
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-12 mt-2">
                    <label for="ddlProgramaAcademico">Programa académico en el que se encuentra matriculado <b class="text-danger">*</b></label> <br>
                    <select id="ddlProgramaAcademico" class="form-select form-select-lg mb-3"
                        v-model.trim="estudiante.programaAcademico" aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(programaAcademico, index) of programasAcademicos" :key="index">{{ programaAcademico.text}}</option>
                    </select>
                </div>
                <div class="col-xl-6 col-12 mt-2">
                    <label for="ddlsemestre">Semestre en curso <b class="text-danger">*</b></label> <br>
                    <select id="ddlsemestre" class="form-select form-select-lg mb-3" v-model.trim="estudiante.semestre"
                        aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(semestre, index) of semestre" :key="index">{{ semestre.text }}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div v-if="faltantes" class="alert alert-danger" role="alert">
                        <b>Todos los campos son de carácter obligatorio</b>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="correoErrado" class="alert alert-danger" role="alert">
                        <b>El correo electrónico debe ser el institucional</b> <b>'ejemplo@correo.itm.edu.co'</b>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                        <b>{{ mensajeError }}</b>
                    </div>
                </div>
                <div class="col-12">
                    <p class="text-center mt-3" v-if="isLoading"><b>Enviando información, por favor espere...</b></p> 
                    <div class="d-flex justify-content-center mb-3" v-if="isLoading">
                        <pulse-loader :loading="isLoading" :color="color" :size="size"></pulse-loader>
                    </div>
                </div>
                <div class="col-12 mb-5">
                    <button class="btn d-flex mx-auto btnanimacion btn-enviar2"
                    @click="crearRegistro"><b>Registrarme</b></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCatedraStore } from '@/stores/catedras';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert'
import { getCatedrasAbiertas } from '@/commons/diccionarios';
import { obtenerSemestreActual } from '@/helpers/functions';

const catedraStore = ref(null);
const route = useRoute();

const isLoading = ref(false);

const estudiante = ref({
  nombres: "",
  apellidos: "",
  numDocumento: "",
  correo: "",
  celular: "",
  programaAcademico: "",
  semestre: "",
  catedra: "",
});

const catedras = ref(getCatedrasAbiertas());

const semestre = ref([
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: '6', text: '6' },
    { value: '7', text: '7' },
    { value: '8', text: '8' },
    { value: '9', text: '9' },
    { value: '10', text: '10' },
]);

const programasAcademicos = ref([
    { value: 'Administración Tecnológica', text: 'Administración Tecnológica' },
    { value: 'Artes de la Grabación y Producción Musical', text: 'Artes de la Grabación y Producción Musical' },
    { value: 'Artes Visuales', text: 'Artes Visuales' },
    { value: 'Ciencias Ambientales', text: 'Ciencias Ambientales' },
    { value: 'Cine', text: 'Cine' },
    { value: 'Contaduría Pública', text: 'Contaduría Pública' },
    { value: 'Especialización en Finanzas', text: 'Especialización en Finanzas' },
    { value: 'Especialización en Formulación y Evaluación de Proyectos', text: 'Especialización en Formulación y Evaluación de Proyectos' },
    { value: 'Ingeniería Biomédica', text: 'Ingeniería Biomédica' },
    { value: 'Ingeniería de Producción', text: 'Ingeniería de Producción' },
    { value: 'Ingeniería de Sistemas', text: 'Ingeniería de Sistemas' },
    { value: 'Ingeniería de Telecomunicaciones', text: 'Ingeniería de Telecomunicaciones' },
    { value: 'Ingeniería Electromecánica', text: 'Ingeniería Electromecánica' },
    { value: 'Ingeniería Electrónica', text: 'Ingeniería Electrónica' },
    { value: 'Ingeniería en Calidad', text: 'Ingeniería en Calidad' },
    { value: 'Ingeniería en Diseño Industrial', text: 'Ingeniería en Diseño Industrial' },
    { value: 'Ingeniería en Mantenimiento de Equipo Biomédico', text: 'Ingeniería en Mantenimiento de Equipo Biomédico' },
    { value: 'Ingeniería Financiera y de Negocios', text: 'Ingeniería Financiera y de Negocios' },
    { value: 'Ingeniería Mecatrónica', text: 'Ingeniería Mecatrónica' },
    { value: 'Interpretación y Traducción Lengua de Señas Colombiana - Español', text: 'Interpretación y Traducción Lengua de Señas Colombiana - Español' },
    { value: 'Maestría en Artes Digitales', text: 'Maestría en Artes Digitales' },
    { value: 'Maestría en Autorización y Control Industrial', text: 'Maestría en Autorización y Control Industrial' },
    { value: 'Maestría en Ciencias: Innovación en Educación', text: 'Maestría en Ciencias: Innovación en Educación' },
    { value: 'Maestría en Desarrollo Sostenible', text: 'Maestría en Desarrollo Sostenible' },
    { value: 'Maestría en Estudios de Ciencia, Tecnología, Sociedad e Innovación', text: 'Maestría en Estudios de Ciencia, Tecnología, Sociedad e Innovación' },
    { value: 'Maestría en Gestión de la Innovación Tecnológica, Cooperación y Desarrollo Regional', text: 'Maestría en Gestión de la Innovación Tecnológica, Cooperación y Desarrollo Regional' },
    { value: 'Maestría en Gestión de las Organizaciones', text: 'Maestría en Gestión de las Organizaciones' },
    { value: 'Maestría en Gestión Energética Industrial', text: 'Maestría en Gestión Energética Industrial' },
    { value: 'Maestría en Ingeniería Biomédica', text: 'Maestría en Ingeniería Biomédica' },
    { value: 'Maestría en Seguridad Informática', text: 'Maestría en Seguridad Informática' },
    { value: 'Química Industrial', text: 'Química Industrial' },
    { value: 'Tecnología  en Construcción de Acabados Arquitectónicos', text: 'Tecnología  en Construcción de Acabados Arquitectónicos' },
    { value: 'Tecnología en Análisis de Costos y Presupuestos', text: 'Tecnología en Análisis de Costos y Presupuestos' },
    { value: 'Tecnología en Automatización Electrónica', text: 'Tecnología en Automatización Electrónica' },
    { value: 'Tecnología en Automatización Electrónica', text: 'Tecnología en Electromecánica' },
    { value: 'Tecnología en Control de Calidad', text: 'Tecnología en Control de Calidad' },
    { value: 'Tecnología en desarrollo de aplicaciones para dispositivos móviles', text: 'Tecnología en desarrollo de aplicaciones para dispositivos móviles' },
    { value: 'Tecnología en Desarrollo de Software', text: 'Tecnología en Desarrollo de Software' },
    { value: 'Tecnología en Diseño Industrial', text: 'Tecnología en Diseño Industrial' },
    { value: 'Tecnología en Diseño y Programación de Soluciones de Software como Servicio - SaaS - Virtual', text: 'Tecnología en Diseño y Programación de Soluciones de Software como Servicio - SaaS - Virtual' },
    { value: 'Tecnología en Electrónica', text: 'Tecnología en Electrónica' },
    { value: 'Tecnología en Gestión Administrativa', text: 'Tecnología en Gestión Administrativa' },
    { value: 'Tecnología en Informática Musical', text: 'Tecnología en Informática Musical' },
    { value: 'Tecnología en Mantenimiento en Equipo Biomédico', text: 'Tecnología en Mantenimiento en Equipo Biomédico' },
    { value: 'Tecnología en Producción', text: 'Tecnología en Producción' },
    { value: 'Tecnología en Sistemas de Información ', text: 'Tecnología en Electromecánica' },
    { value: 'Tecnología en Telecomunicaciones', text: 'Tecnología en Telecomunicaciones' },
]);

const faltantes = ref(false);
const correoErrado = ref(false);
const mensajeError = ref(null);

const crearRegistro = () => {
    ocultaAlertas()

    for(let key of Object.keys(estudiante.value)) {
        if(estudiante.value[key] === '') return faltantes.value = true;
    }

    const correoIncorrecto = validaCorreoInstitucional();
    if (correoIncorrecto) return correoErrado.value = true;

    sweetAlertConfirm(
        // '¿Desea enviar la información diligenciada?',
        'Cordial saludo, estudiante:',
        'Los datos diligenciados se enviarán para su respectiva inscripción al curso.',
        'warning',
        'Enviar',
    ).then(async (result) => {
        if (result.isConfirmed) {
            isLoading.value = true;
            
            const datosRegistro = {...estudiante.value, ['catedra']: estudiante.value.catedra.text, semestreActual: obtenerSemestreActual()};
            const inscripcion = await catedraStore.value.createInscripcionCatedra(datosRegistro);

            if (inscripcion.message) {
                mensajeError.value = inscripcion.message;
                isLoading.value = false;
            } else {
            sweetAlertSuccess(
                '¡Datos enviados!',
                'Los datos diligenciados se han enviado de manera exitosa',
                'success'
            ).then(() => {
                location.reload();
            });
            }
        } else (
            result.dismiss === Swal.DismissReason.cancel
        )
    })
};

const validaCorreoInstitucional = () => {
    if (estudiante.value.correo.split('@')[1] !== "correo.itm.edu.co") return true;
}

const ocultaAlertas = () => {
    faltantes.value = false;
    correoErrado.value = false;
    mensajeError.value = null;
}

onMounted(() => {
    catedraStore.value = useCatedraStore();
    document.title = route.meta.title;
});
</script>