<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante el formulario de habilitaciones.</p>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div v-if="infoHabilitaciones.length != 0">
              <DataTable v-if="infoHabilitaciones.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoHabilitaciones" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>
<script>

//Importando API que conecta front con back
import API from '../../../api'
import DataTable from '../../../components/DataTable'

export default {
  name: 'infoHabilitaciones',

  data() {
    return{
      btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
      infoHabilitaciones: [],
      cabeceraDatatable: [
        {text: 'N°'},
        {text: 'Código asignatura'},
        {text: 'Programa'},
        {text: 'Docente'},
        {text: 'Tipo asignatura'},
        {text: 'Nombre estudiante'},
        {text: 'Cédula'},
        {text: 'Correo'},
        {text: 'Número recibo de pago'},
        {text: 'Nombre recibo de pago'}
      ],
      tituloReporteDataTable: 'Reporte de habilitaciones'
    }
  },
  components: {
    DataTable
  },
  created() {
    this.verInfo()
  },
  methods: {
    verInfo: async function() {
      this.infoHabilitaciones = await API.fetchInfoHabilitaciones();
    }
  },
  mounted() {
    document.title = this.$route.meta.title // Cambiar el título de la página
  }
}
</script>
