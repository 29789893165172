<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los cursos asociados a los docentes en diferentes períodos académicos.</p>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
            <p class="text-center" v-if="isLoading"><b>Cargando información, por favor espere...</b></p> 
            <div class="d-flex justify-content-center">
                <pulse-loader :loading="isLoading" :color="color" :size="size">{{  }}</pulse-loader>
            </div>
            <div v-if="sinRegistros" class="alert alert-danger" role="alert">
                <b>{{ mensajeError }}</b>
            </div>
            <div v-if="registrosDocenteCurso.length != 0">
              <DataTable v-if="registrosDocenteCurso.length !== 0" :btnsDisable="btnsDisableToTable" :info="registrosDocenteCurso" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
          </div>  
      </div> 
    </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useGestorStore } from '@/stores/gestores';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import DataTable from '../../../components/DataTable';

const route = useRoute();
const gestorStore = useGestorStore();
const mensajeError = ref('');
const isLoading = ref(true);
const sinRegistros = ref(false);
const btnsDisableToTable = ['btnCambiarEstado', 'btnSendEmail'];
const registrosDocenteCurso = ref([]);
const cabeceraDatatable = ref([
    { text: 'N°' },
    { text: 'Docente' },
    { text: 'Documento Identidad' },
    { text: 'Correo electrónico' },
    { text: 'Asignatura' },
    { text: 'Metodología' },
    { text: 'Semestre' },
    { text: 'Fecha registro' },
]);

onMounted(() => {
    document.title = route.meta.title;

    gestorStore.fetchInfoDocenteCurso().then((response) => {
        const mappedResponse = response.map((item, index) => {
            return {
                docente: item.docente.nombre,
                documentoIdentidad: item.docente.documentoIdentidad,
                correoElectronico: item.docente.correoElectronico,
                asignatura: item.asignatura.nombre,
                metodologia: item.asignatura.metodologia,
                semestre: item.semestre,
                fechaRegistro: new Date(item.fechaRegistro).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                }),
            }
        });
        registrosDocenteCurso.value = mappedResponse;
        sinRegistros.value = false;
        isLoading.value = false;
    }).catch((error) => {
        isLoading.value = false;
        sinRegistros.value = true;
        mensajeError.value = error;
    });
});


</script>
