import MoodleBackupsCategoryChoose from "@/views/Admin/Backups/MoodleCourseBackupsCategoryChoose.vue";
import MoodleBackupsCourseChoose  from "@/views/Admin/Backups/MoodleCourseBackupsCourseChoose.vue";

export default [
    {
        path: '/administracion/moodleBackups/categoryChoose',
        name: 'backupsMoodleCategoryChoose',
        component: MoodleBackupsCategoryChoose,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Backups Moodle',
            allowedRoles: ['manager']
        }
    },
    {
        path: '/administracion/moodleBackups/courseChoose',
        name: 'backupsMoodleCourseChoose',
        component: MoodleBackupsCourseChoose,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Backups Moodle',
            allowedRoles: ['manager']
        }
    }
]
