<template>
  <div class="home pb-5 container">
    <div class="row pt-5">
          <div class="col 12">
            <h2>Informe de registros <em>"Encuesta de Desempeño Docentes de AMV y APV"</em></h2>
            <hr>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-xl-6 col-lg-6 col-12 mb-xl-0 mb-lg-0 mb-3">
            <select id="selectTipoAsignatura" class="form-select form-select-lg mb-3" v-model.trim="tipoAsignatura" aria-label="form-select-lg example">
                <option selected value="">Seleccione una opción</option>
                <option v-for="(tipoAsignatura, index) of optionsTipoAsignatura" :key="index" :value="tipoAsignatura.value">{{tipoAsignatura.text}}</option>
            </select>
            
            <!-- <b-form-select class="form-control" id="selectTipoAsignatura" v-model="tipoAsignatura" :options="optionsTipoAsignatura"></b-form-select> -->
        </div>
        <div class="col-xl-6 col-lg-6 col-12 mb-xl-0 mb-lg-0 mb-3">
            <select id="selectSemestre" class="form-select form-select-lg mb-3" v-model.trim="semestre" aria-label=".form-select-lg example">
                <option selected value="">Seleccione una opción</option>
                <option v-for="(semestre, index) of semestres" :key="index" :value="semestre.value">{{semestre.text}}</option>
            </select>
            <!-- <b-form-select class="form-control" id="selectSemestre" v-model="semestre" :options="semestres"></b-form-select> -->
        </div>
    </div>
    <div class="row">
      <div class="col-12"  v-if="tipoAsignatura != '' && encuestasFiltradas.length != 0">
        <button class="btn btn-success d-flex mx-auto mt-4 mb-4" @click="exportExcel('encuestasTable')" v-if="tipoAsignatura != '' && encuestasFiltradas.length != 0">Exportar a Excel</button>
        <p class="fw-bold text-danger">Por favor espere a que la información se cargue correctamente dentro de la tabla.</p>
    
        <div class="table-responsive" style="height: 600px;">
              <table id="encuestasTable" class="table table-striped" style="width:100%">
               <thead>
                   <tr>
                       <th colspan="6"></th>
                       <th colspan="5" class="border text-center">ESTRUCTURA Y CONTENIDOS DEL CURSO</th>
                       <th colspan="5" class="border text-center">COMUNICACIONES Y RETROALIMENTACIÓN</th>
                       <th colspan="4" class="border text-center">METODOLOGÍA PARA EVALUAR</th>
                       <th colspan="4" class="border text-center">ATENCIÓN DE LA MESA DE AYUDA</th>

                   </tr>
                   <tr>
                       <th class="border">Semestre</th>
                       <th class="border">Código de asignatura</th>
                       <th class="border">Programa</th>
                       <th class="border">Docente</th>
                       <th class="border">Tipo de Asignatura</th>
                       <th class="border">N.° Participantes</th>
                        <!--Estructura y contenidos del curso-->
                       <th class="border text-center">1</th>
                       <th class="border text-center">2</th>
                       <th class="border text-center">3</th>
                       <th class="border text-center">4</th>
                       <th class="border text-center">5</th>
                        <!--Comunicaciones y retroalimentación-->
                       <th class="border text-center">1</th>
                       <th class="border text-center">2</th>
                       <th class="border text-center">3</th>
                       <th class="border text-center">4</th>
                       <th class="border text-center">5</th>
                       <!--Metodología para evaluar-->
                       <th class="border text-center">1</th>
                       <th class="border text-center">2</th>
                       <th class="border text-center">3</th>
                       <th class="border text-center">4</th>
                       <!--Atención de la mesa de ayuda-->
                       <th class="border text-center">1</th>
                       <th class="border text-center">2</th>
                       <th class="border text-center">3</th>
                       <th class="border text-center">4</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for="encuesta of encuestasFiltradas" :key="encuesta.id">
                       <td class="border">{{encuesta.semestre}}</td>
                       <td class="border">{{encuesta.codigoAsig}}</td>
                       <td class="border">{{encuesta.programa}}</td>
                       <td class="border">{{encuesta.docente}}</td>
                       <td class="border">{{encuesta.tipoAsignatura}}</td>
                       <td class="border">{{encuesta.cantidadEstudiantesParticiparon}}</td>
                       <!--Estructura y contenidos del curso-->
                       <td class="border w-100">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente: </b>  {{encuesta.cantDeficientesECC1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular: </b>  {{encuesta.cantRegularesECC1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesECC1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosECC1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesECC1}}</span>
                       </td>
                       <td class="border w-100">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesECC2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesECC2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesECC2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosECC2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesECC2}}</span>
                        </td>
                       <td class="border w-100">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesECC3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesECC3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesECC3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosECC3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesECC3}}</span>
                        </td>
                       <td class="border w-100">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesECC4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesECC4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesECC4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosECC4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesECC4}}</span>
                       </td>
                       <td class="border w-100">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesECC5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesECC5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesECC5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosECC5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesECC5}}</span>   
                       </td>
                       <!--Comunicaciones y retroalimentación-->
                       <td class="border w-100">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesCR1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesCR1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesCR1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosCR1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesCR1}}</span>   
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesCR2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesCR2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesCR2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosCR2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesCR2}}</span>  
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesCR3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesCR3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesCR3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosCR3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesCR3}}</span>    
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesCR4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesCR4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesCR4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosCR4}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesCR4}}</span>
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesCR5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesCR5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesCR5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosCR5}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesCR5}}</span>
                       </td>
                       <!--Metodología para evaluar-->
                       <td class="border">
                        <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesME1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesME1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesME1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosME1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesME1}}</span>
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesME2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesME2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesME2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosME2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesME2}}</span>
                        </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesME3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesME3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesME3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosME3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesME3}}</span>
                       </td>
                       <td class="border">{{encuesta.AM_MetodologiaDocente}}</td>
                       <!--Atención ed la mesa de ayuda-->
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesAMA1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesAMA1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesAMA1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosAMA1}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesAMA1}}</span>
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesAMA2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesAMA2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesAMA2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosAMA2}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesAMA2}}</span>
                       </td>
                       <td class="border">
                           <span class="d-flex"><b class="itemTablaCalificacion">Deficiente:</b>  {{encuesta.cantDeficientesAMA3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Regular:</b>  {{encuesta.cantRegularesAMA3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Aceptable:</b>  {{encuesta.cantAceptablesAMA3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Bueno:</b>  {{encuesta.cantBuenosAMA3}}</span> <br>
                           <span class="d-flex"><b class="itemTablaCalificacion">Excelente:</b>  {{encuesta.cantExcelentesAMA3}}</span>
                       </td>
                       <td class="border">{{encuesta.SC_MejoramientoServicios}}</td>
                   </tr>
               </tbody>
           </table>
       </div>
      </div>
    </div>
        <div class="alert alert-danger alert-dismissible fade show mt-3" role="alert" v-if="tipoAsignatura != '' && encuestasFiltradas.length == 0 && this.semestre !== ''">
            <strong>No se ha encontrado ningún registro para el tipo de asignatura {{tipoAsignatura}} para el semestre {{semestre}}</strong>, inténtelo nuevamente
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>

</template>

<script>


//Importamos el axios para realizar las peticiones al server
import API from '../../../api';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import $ from 'jquery';

export default {
    name: 'infoEncuesta',
    data() {
      return {
          docentes: [],
          programas: [],
          
          encuestasFiltradas: [],
          tipoAsignatura: '',
          semestre: '',

          optionsTipoAsignatura: [
              {value: 'AMV', text: 'Asignatura mediada por la virtualidad (AMV)'},
              {value: 'APV', text: 'Asignatura de programas virtuales (APV)'},
              {value: 'ALL', text: 'Todos'}
          ],
          semestres: [
              {value: '2022-1', text: '2022-1'},
              {value: '2022-2', text: '2022-2'},
              {value: '2023-1', text: '2023-1'},
              {value: '2023-2', text: '2023-2'},
              {value: '2024-1', text: '2024-1'},
              {value: '2024-2', text: '2024-2'},
          ]
      }
    },
    methods: {
        exportExcel: function(tableID) {
            let table= document.getElementById(tableID);

            // Crear un objeto workbook
            var wb = XLSX.utils.table_to_book(table);

            // Convertir el workbook a un array de buffers
            var wbArray = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Crear un Blob a partir del array de buffers
            var blob = new Blob([wbArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

            // Guardar el Blob como archivo Excel usando FileSaver.js
            saveAs(blob, `informe-encuesta-docente-${ this.tipoAsignatura }-${ this.semestre }.xls`);
        },

        fetchEncuestas: async function() {

            let alertas = document.querySelectorAll(".alert");
            alertas.forEach(alert => {
                alert.classList.add("d-none");
            })

            if(this.semestre !== '' && this.tipoAsignatura !== '') {
                this.encuestasFiltradas = [''];
                let semestre = this.semestre;
                let tipoAsignatura = this.tipoAsignatura;
                let data = {tipoAsignatura, semestre}
                this.encuestasFiltradas = await API.fetchEncuestasFiltradasTipoAsignatura(data);
            }
        }
    },
    watch: {
        tipoAsignatura: async function(){
            this.semestre = '';
            this.encuestasFiltradas = new Array();
            this.fetchEncuestas();
        },
        semestre: async function () {
            this.fetchEncuestas();
        }
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>
