<template>
  <div id="app">
    <div class="container-fluid m-0 p-0">
      <NavBar v-if="!$route.meta.hideNavbar"/>
      <router-view :class="{'pt-4':!$route.meta.hideNavbar}" />
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
export default {
    name: "App",
    components: {
      NavBar
    }
}
</script>
  


