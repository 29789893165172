<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Banner_Acompanamiento_Docente.png" class="img-fluid d-flex mx-auto vw-100" alt=""> 
        </div>

        <div class="container mb-5">
            <div class="row mt-5">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="ddlNombreDocente">Nombre del docente<b class="text-danger">*</b></label> <br>
                
                    <select id="ddlNombreDocente" class="form-select form-select-lg mb-3 searchableSelect" v-model.trim="datosDocente" aria-label=".form-select-lg example">
                        <option v-for="(datos, index) of quitarDocentesDuplicados()" :key="index" :value="datos">
                           {{datos.nombreDocente}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3" v-if="datosDocente !== null">
                    <label for="ddlTipoModalidad">Asignatura<b class="text-danger">*</b></label> <br>
                    <select id="ddlTipoModalidad" class="form-select form-select-lg mb-3" v-model="datosAsignatura" aria-label=".form-select-lg example">
                        <option v-for="(datos, index) of obtenerAsignaturaPorDocente(datosDocente.nombreDocente)" :key="index" :value="datos">{{datos.codigoAsignatura}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12">
                    <span>La siguiente opción es usada para evitar enviar el email saliente al docente elegido, y en su defecto enviar el email a un correo especificado dentro del campo <em>Email alterno</em>.</span>

                    <label for="checkOtro" class="mt-3">
                        <input type="checkbox" name="checkOtro" id="checkOtro" @change="validaCheck($event)">
                        Activar opción (Si la opción es activada, el campo email alterno es de caracter obligatorio  <b class="text-danger">*</b>)
                    </label>
                    <input disabled placeholder="Email alterno" id="txtOtroEmail" type="text" class="form-control" v-model.trim="otroEmail">
                </div>
            </div>
            <hr class="my-lg-5 my-4" v-if="datosDocente !== null && datosAsignatura !== null">

            <div class="row" v-if="datosDocente !== null && datosAsignatura !== null">
                <div class="col-lg-4 col-12 mb-lg-0 mb-3">
                    <label for="txtSemana">Número de semana <b class="text-danger">*</b></label>
                    <input id="txtSemana" type="number" class="form-control" v-model="semana">
                </div>
                <div class="col-lg-4 col-12 mb-lg-0 mb-3">
                    <label for="imgSemanal">Imagen semanal <b class="text-danger">*</b></label><br>
                    <input id="imgSemanal" type="file" ref="imgSemanal" @change="cargarImagen('semanal')" accept="image/*" />
                </div>
                <div class="col-lg-4 col-12 mb-lg-0 mb-3">
                    <label for="imgFirmaDigital">Firma digital <b class="text-danger">*</b></label><br>
                    <input id="imgFirmaDigital" type="file" ref="imgFirmaDigital" @change="cargarImagen('firma')" accept="image/*" />
                </div>
                <div class="col-12 mt-3">
                    <label for="">Correos para enviar copia en correo electrónico</label>
                    <Multiselect
                        v-model="emailUsuarios.emailUsuariosList"
                        placeholder="Elije los email a los que se les enviará copia"
                        mode="tags"
                        searchable="true"
                        :min-chars="1"
                        :resolve-on-load="false"
                        :close-on-select="false"
                        :options="emailCopyList"
                     />
                </div>
                <div class="col-12 mt-3">
                    <label for="txtCorreosAdicionalesCopia">Añada aquí mas correos para enviar copia <b>(Deben ser correos válidos y estar separados por coma)</b></label><br>
                    <label for="txtCorreosAdicionalesCopia"><b class="text-danger">Nota:</b> para agregarlos a la lista debe presionar la tecla <em><b>enter</b></em></label>
                    <input id="txtCorreosAdicionalesCopia" @keyup.enter="seteaCorreosAdicionalesParaCopia($event)" class="form-control" type="text">
                    <ul class="mt-3">
                        <li v-for="(correoAdicional, index) in listaCorreosAdicionalesParaCopia" :key="index">
                            <div class="col-lg-6 d-flex justify-content-between">
                                <p>{{ correoAdicional }}</p>
                                <button class="btn-close" @click="eliminaEmailAdicionalParaCopia(index)"></button>
                            </div>
                        </li>
                    </ul>
                    <div v-if="!esEmailValido" class="alert alert-danger" role="alert">
                        <b>Los correos electrónicos deben ser correos válidos (<b>ejemplo@itm.edu.co ó ejemplo@correo.itm.edu.co</b>) y deben estar separados por <b><em>coma</em></b> en caso de que sean mas de uno.</b>
                    </div>
                </div>
            </div>
            <hr class="my-lg-5 my-4" v-if="datosDocente !== null && datosAsignatura !== null">

            <div class="row" v-if="datosDocente !== null && datosAsignatura !== null" ref="contenedorAcompanamiento" id="contenedorAcompanamiento">
                <div class="col-12">

                    <img style="width: 50%;" v-if="datosAsignatura.tipoAsignatura === 'AMV'" src="../../../../public/img/img-AMV.jpeg" class="img-fluid d-flex mx-auto" alt="">
                    <img style="width: 50%;" v-if="datosAsignatura.tipoAsignatura === 'APV'" src="../../../../public/img/img-APV.jpeg" class="img-fluid d-flex mx-auto" alt="">
                    <p class="text-center fw-bold">Acompañamiento semana {{ semana }} semestre  {{ datosAsignatura.semestre }} 
                    </p>
                    <p>Cordial saludo, profesor {{ datosDocente.nombreDocente }}
                        ; Nos encontramos en la semana {{ semana }} 
                        {{  
                            datosAsignatura.tipoAsignatura === 'AMV' 
                            ? 'correspondiente a las asignaturas mediadas por la virtualidad' 
                            : 'correspondiente a los cursos de programas virtuales'
                        }}.</p>
                    <p>Le comparto los ítems revisados en su curso 
                        {{ datosAsignatura.codigoAsignatura }} y algunas observaciones del acompañamiento que desde 
                        la Unidad de Educación Virtual hacemos a todos los docentes y que aplican para todos sus grupos.</p>
                </div>
                <div class="col-12">
                    <div class="row my-4 border p-3 contenedorCriterioDescripcion" v-for="(informacionCriterio, indexInfoGeneral) in criteriosAcompanamientoDocente" :key="indexInfoGeneral">
                        <div class="col-12 d-flex justify-content-end">
                            <!-- <button type="button" class="btn-close" aria-label="Close" @click="eliminarCriterio(indexInfoGeneral)"></button> -->
                            <button type="button" class="btn-close" aria-label="Close" @click="eliminarCriterio($event, indexInfoGeneral, informacionCriterio.criterio)"></button>
                            
                        </div>
                        <div class="col-lg-4 col-12 contenedorCriterioEvaluacion">
                            <h6>Criterio de valoración a la gestión docente {{ datosAsignatura.tipoAsignatura }} </h6>
                            <p>{{ informacionCriterio.criterio }}</p>
                        </div>
                        <div class="col-lg-4 col-12 contenedorDescriptores">
                            <h6>Descriptor</h6>
                            <div :class="`d-flex contenedorDescripciones descriptor${indexInfoGeneral}-item${indexItem}`" v-for="(informacionDescriptor, indexItem) in informacionCriterio.descriptores" :key="`checkDescriptor${indexInfoGeneral}-${indexItem}`">
                               <div class="contenedorRadioBtns">
                                    <input class="form-check-input mx-1" 
                                        style="height: 16px !important;"
                                        :value="informacionDescriptor.valoracion" type="radio" 
                                        :ref="`descripcionCriterio${indexInfoGeneral}-${indexItem}`"
                                        :name="`rbtnDescriptorCriterio${indexInfoGeneral}`" 
                                        :id="`descriptor${indexInfoGeneral}-item${indexItem}`"
                                        :class="`rbtnGroup descriptorCriterio-${indexInfoGeneral}`"
                                        data-prev-state="false"
                                        @click="toggleRadio($event, informacionCriterio.criterio)">
                               </div>
                                <p>{{ informacionDescriptor.descripcion }}</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12 contenedorObservaciones">
                            <h6>Observaciones</h6>
                            <div :class="`d-flex contenedorDescripciones observacion${indexInfoGeneral}-item${indexItem}`" v-for="(informacionObservacion, indexItem) in informacionCriterio.observaciones" :key="`checkObservacion${indexInfoGeneral}-${indexItem}`">
                                <div class="contenedorRadioBtns">
                                    <input class="form-check-input mx-1" 
                                        style="height: 16px !important;"
                                        :value="`${informacionObservacion.valoracion} - ${informacionObservacion.observacion}`" type="radio" 
                                        :ref="`observacionCriterio${indexInfoGeneral}-${indexItem}`"
                                        :name="`rbtnObservacionCriterio${indexInfoGeneral}`" 
                                        :id="`observacion${indexInfoGeneral}-item${indexItem}`"
                                        :class="`rbtnGroup observacionCriterio-${indexInfoGeneral}`"
                                        data-prev-state="false"
                                        @click="toggleRadio($event, informacionCriterio.criterio)">
                                </div>
                                <p>{{ informacionObservacion.observacion }}</p>
                            </div>
                            <div :class="`contenedor_txtAreaObservacion${indexInfoGeneral}`">
                                <h6 :class="`txtAreaObservacion  m-0 p-0`">Observación adicional:</h6> <br>
                                <div :class="`div_txtAreaObservacion${indexInfoGeneral} divTextArea d-none`"></div>
                                <textarea 
                                    style="overflow-y: auto;"
                                    placeholder="Añade alguna observación adicional (Opcional)" 
                                    class="form-control txtAreaObservacion m-0 p-2 textAreaInput" 
                                    :id="`txtAreaObservacion${indexInfoGeneral}`"
                                    @keyup="adicionaInformacionAcompanamiento($event, informacionCriterio.criterio, indexInfoGeneral)" ></textarea>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-12 contenedor_txtAreaObservacionGeneral">
                    <h6 class="txtAreaObservacionGeneral">Observación general:</h6>
                    <div class="div_txtAreaObservacionGeneral divTextArea d-none mt-2"></div>
                    <textarea 
                        @keyup="adicionaInformacionAcompanamiento($event, null, indexInfoGeneral)" 
                        v-model="observacionGeneral" 
                        class="form-control txtAreaObservacionGeneral textAreaInput" 
                        id="txtAreaObservacionGeneral" 
                        placeholder="Observaciones generales derivadas del acompañamiento"></textarea>
                </div>
                <div class="col-12 mt-3">
                    <img v-if="imgSemanal" :src="imgSemanal" alt="Imagen semanal publicada" class="mt-3 d-flex mx-auto img-fluid" />
                </div>
                <div class="col-12 mt-3">
                    <img v-if="imgFirmaDigital" :src="imgFirmaDigital" alt="Imagen firma digital publicada" class="mt-3 d-flex mx-auto img-fluid" />
                </div>
            </div>
            <div class="row" v-if="datosDocente !== null && datosAsignatura !== null">
                <div class="col-12 mt-3">
                    <p class="text-center" v-if="isLoading"><b>Enviando información, por favor espere...</b></p> 
                    <div class="d-flex justify-content-center" v-if="isLoading">
                        <pulse-loader :isLoading="isLoading" :color="color" :size="size"></pulse-loader>
                    </div>
                    <div v-if="faltantesFormulario" class="alert alert-danger" role="alert">
                        <b>Todos los campos marcados con <b class="text-danger">*</b> son de carácter obligatorio</b>
                    </div>
                    <button class="btn btn-enviar d-flex mx-auto mt-3" @click="crearRegistro">Realizar registro</button>
                </div> 
                  <!-- Display the captured snapshot -->
                  <!-- <img v-if="snapshotUrl" :src="snapshotUrl" alt="Captured Snapshot" /> -->
            </div>
        </div>
    </div>
</template>

<script>

import { useGestorStore } from '@/stores/gestores';
import { useAuthStore } from '@/stores/auth';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { capturaSnapshot, comprimirArchivo, obtenerFacultades, obtenerSemestreActual, ordenarAlfabeticamenteArrayPorPropiedad, validaTamanoArchivo, validarEmailInstitucional } from '../../../helpers/functions';
import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert';
import Multiselect from '@vueform/multiselect'

export default {
    name: 'AcompanamientoDocente',
    data() {
        return {
            gestorStore: null,
            authStore: null,
            self: this,
            facultades: obtenerFacultades(),

            esEmailValido: true,
            listaCorreosAdicionalesParaCopia: [],
            
            sendEmailCopyList: [],
            emailCopyList: [
                "andresescobarv@itm.edu.co",
                "waltercastro@itm.edu.co",
                "camilovalencia@itm.edu.co",
                "nancyhamid@itm.edu.co",
                "ericcastaneda@tm.edu.co",
                "dianarico@itm.edu.co",
                "dianarueda7028@correo.itm.edu.co",
                "pablorivera@itm.edu.co",
                "juanhurtado7456@correo.itm.edu.co",
                "aliciaosorio@itm.edu.co",
                "julioserna@itm.edu.co",
                "isabelsoto@itm.edu.co",
                "franciscocordoba@itm.edu.co",
                "juancsanchez@itm.edu.co",
                "linavalencia@itm.edu.co",
                "mariamesap@itm.edu.co",
                "linamendez@itm.edu.co",
            ],

            isCheckedOtroEmail: false,
            otroEmail: '',
            datosDocente: null,
            datosAsignatura: null,
            semana: 1,
            faltantesFormulario: false,
            isLoading: false,

            imgSemanal: null,
            imgFirmaDigital: null,

            snapshotUrl: null,
            snapshotFile: null,

            criteriosAcompanamientoDocente: [],
            resultadoAcompanamiento: [],
            docenteAsignatura: [],
            emailUsuarios: {
                emailUsuarioAuth: null,
                emailUsuariosList: []
            },
        }
    },
    async mounted () {
        this.gestorStore = useGestorStore();
        this.authStore = useAuthStore();
        this.emailUsuarios.emailUsuarioAuth = this.authStore.userDetail.email;

        this.docenteAsignatura = await this.gestorStore.fetchCodigosAsignaturas(obtenerSemestreActual());
        
        document.title = this.$route.meta.title // Cambiar el título de la página
    },
    methods: {

        eliminarCriterio({ target }, indexCriterio, criterio) {
           
            // Uncheck radio buttons en la tarjeta a eliminar
            const card = target.closest(".contenedorCriterioDescripcion");
            const radioButtons = card.querySelectorAll("input[type='radio']");
            radioButtons.forEach(radio => {
                radio.checked = false;
            });

            this.resultadoAcompanamiento = this.resultadoAcompanamiento.filter((res) => res.criterio !== criterio)
            // Removiendo la tarjeta
            card.remove();
        },

        quitarDocentesDuplicados: function () {
            let docentes = ([...new Map(this.docenteAsignatura.map(v => [v.docente, v])).values()])
            docentes = ordenarAlfabeticamenteArrayPorPropiedad(docentes, 'docente')
            return docentes.map(doc => {
                return {
                    nombreDocente: doc.docente,
                    correo: doc.correo
                }
            })
        },

        obtenerAsignaturaPorDocente: function(nombreDocente) {
            let asignaturas = this.docenteAsignatura.filter(obj => obj.docente === nombreDocente).map(asignatura => {
                return {
                    codigoAsignatura: asignatura.codigoAsignatura,
                    tipoAsignatura: asignatura.tipoAsignatura,
                    facultad: asignatura.facultad,
                    semestre: obtenerSemestreActual(),
                    semana: 0
                }
            })
            return asignaturas = ordenarAlfabeticamenteArrayPorPropiedad(asignaturas, 'codigoAsignatura')
        },

        toggleRadio( event, criterio ) {
            const { target } = event
            const prevState = target.getAttribute("data-prev-state");
            target.checked = prevState === "true" ? false : true;
            target.setAttribute("data-prev-state", target.checked.toString());
            this.adicionaInformacionAcompanamiento( event, criterio )
        },

        adicionaInformacionAcompanamiento: function(event, criterio = null , indexInfoGeneral) {
            const { target } = event;
            const { value, id } = target

            let index = null;
            if(criterio) {
                index = this.resultadoAcompanamiento.findIndex(res => res.criterio === criterio)
            }

            if(id.startsWith('descriptor')) {
                target.checked ? this.resultadoAcompanamiento[index].descriptor = value :  this.resultadoAcompanamiento[index].descriptor = 'N/A'
                return;
            }
            if(id.startsWith('observacion')) {
                target.checked ? this.resultadoAcompanamiento[index].observacion = value :  this.resultadoAcompanamiento[index].observacion = 'N/A'
                return;
            }
            if(id === 'txtAreaObservacionGeneral'){
                let divTxtAreObservacionGeneral = document.querySelector(`.div_txtAreaObservacionGeneral`);
                this.seteaTextoDivObservacion(divTxtAreObservacionGeneral, value)
                return
            }
            if(id.startsWith('txtAreaObservacion')) {
                this.resultadoAcompanamiento[index].observacionAdicional = value
                let divTxtAreObservacion = document.querySelector(`.div_txtAreaObservacion${indexInfoGeneral}`)
                this.seteaTextoDivObservacion(divTxtAreObservacion, value);
                return;
            }
            
        },

        validaCheck(event) {
            this.isCheckedOtroEmail = event.target.checked;
            const txtOtroEmail = document.querySelector('#txtOtroEmail');
            this.otroEmail = '';
            if(!this.isCheckedOtroEmail) return txtOtroEmail.disabled = true
            txtOtroEmail.disabled = false;
        },

        seteaCorreosAdicionalesParaCopia({ target }) {
            const { value } = target;
            this.esEmailValido = true; 

            let emails = value.split(',');
       
            emails.forEach(email => {
                if(!validarEmailInstitucional(email)) return this.esEmailValido = false;
            })
            if(!this.esEmailValido) return;

            emails = emails.map(email => {
                const primerFragmentoEmail = email.split('@')[0].trim();
                const segundoFragmentoEmail = email.split('@')[1].trim();
                return `${primerFragmentoEmail}@${segundoFragmentoEmail}`
            });

            emails.forEach(email => {
                if(!this.listaCorreosAdicionalesParaCopia.includes(email)) this.listaCorreosAdicionalesParaCopia = [ ...this.listaCorreosAdicionalesParaCopia, ...emails ];
            })

            this.esEmailValido = true;
            document.querySelector('#txtCorreosAdicionalesCopia').value = "";
        },

        eliminaEmailAdicionalParaCopia(indexEliminar) {
            this.listaCorreosAdicionalesParaCopia = 
                this.listaCorreosAdicionalesParaCopia.filter((_, index) => 
                    index !== indexEliminar
                )
        },

        // Function to escape HTML entities
        seteaTextoDivObservacion(div, value) {
            let htmlValue = value.replace(/\n/g, "<br>");
            div.innerHTML = htmlValue;

            div.style.height = "auto"; // Reset height to auto
            div.style.width = "auto"; // Reset width to auto
            const maxHeight = window.innerHeight;
            const maxWidth = window.innerWidth; 
            div.style.maxHeight = maxHeight + "px"; // Set maximum height
            div.style.maxWidth = maxWidth + "px"; // Set maximum width
        },

        obtenerDescripcionCriteriosActuales: function() {
            return this.criteriosAcompanamientoDocente.map(criterioActual => {
                return{ criterio: criterioActual.criterio }
            })
        },
    
        cargarImagen(tipoImagen) {
            let archivo = tipoImagen === 'semanal' ? this.$refs.imgSemanal.files[0] : this.$refs.imgFirmaDigital.files[0];
            if (archivo) {
                const reader = new FileReader();
                reader.onload = ({ target }) => {
                    const { result } = target;
                    tipoImagen === 'semanal' ? this.imgSemanal = result : this.imgFirmaDigital = result;
                };
                reader.readAsDataURL(archivo);
            }
        },

        async captureSnapshot() {
            try {
                let snapshotFile = await capturaSnapshot(this.$refs.contenedorAcompanamiento, `AD-${this.datosDocente.nombreDocente}.png`);

                // if(!validaTamanoArchivo(snapshotFile)){
                //     await comprimirArchivo(snapshotFile)
                //         .then((compressedFile) => {
                //             this.snapshotFile = compressedFile;
                //         })
                //         .catch((error) => {
                //             console.log(`Error de compresión: ${error.message}`)
                //         })
                // }else{
                    this.snapshotFile = snapshotFile;
                // }

            } catch (error) {
                console.error('Error capturing snapshot:', error);
            }
        },

        crearRegistro: async function() {

            this.faltantesFormulario = false;

            if(this.semana <= 0 || this.semana === "") this.faltantesFormulario = true;
            if(!this.imgSemanal || !this.imgFirmaDigital) this.faltantesFormulario = true;
            if(this.isCheckedOtroEmail && this.otroEmail === "") this.faltantesFormulario = true;

            this.resultadoAcompanamiento.map(resultado => {
                if(!resultado.descriptor){
                   this.faltantesFormulario = true;
                }

                if(!resultado.observacion && (!resultado.observacionAdicional || resultado.observacionAdicional === "")){
                    this.faltantesFormulario = true;
                }
            })
            if(this.faltantesFormulario) return;
    
            sweetAlertConfirm(
              '¿Desea enviar la información diligenciada?',
              'Los datos diligenciados se enviarán para su respectiva revisión.',
              'warning',
              'Enviar'
            ).then(async (result) => {
                if (result.isConfirmed) {
                  
                    this.isLoading = true;

                    // Ocultando columna de descriptores para snapshot
                    let contenedorDescriptores = document.querySelectorAll('.contenedorDescriptores');
                    let contenedorObservaciones = document.querySelectorAll('.contenedorObservaciones');
                    let contenedorCriterioEvaluacion =  document.querySelectorAll('.contenedorCriterioEvaluacion');

                    contenedorDescriptores.forEach(contenedor => contenedor.classList.add('d-none'));
                    contenedorObservaciones.forEach(contenedor => contenedor.classList.replace('col-lg-4', 'col-lg-6'));
                    contenedorCriterioEvaluacion.forEach(contenedor => contenedor.classList.replace('col-lg-4', 'col-lg-6'));
                    
                     // Eliminando radioBotones no seleccionados para el Snapshot
                    let radioBtns = document.querySelectorAll(".contenedorDescripciones .contenedorRadioBtns .form-check-input");
                    
                    radioBtns.forEach((radioBtn, index) => {
                        if(!radioBtn.checked) document.querySelector(`.${radioBtn.id}`).classList.add('d-none');
                    })

                    // Eliminando textAreas no diligenciados
                    let textAreasObaservaciones = document.querySelectorAll(".textAreaInput");
                    textAreasObaservaciones.forEach(textAreaObservacion => {
                        if(textAreaObservacion.value === "") {
                            document.querySelector(`.contenedor_${textAreaObservacion.id}`).classList.add('d-none');
                        }else{
                            textAreaObservacion.classList.add("d-none");
                            document.querySelector(`.div_${textAreaObservacion.id}`).classList.remove('d-none');
                        }
                    })

                    const informacionAcompanamiento = new FormData();

                    await this.captureSnapshot()

                    informacionAcompanamiento.append('informacionDocente', JSON.stringify(this.datosDocente));

                    this.datosAsignatura['semana'] = this.semana;
                    informacionAcompanamiento.append('informacionAsignatura', JSON.stringify(this.datosAsignatura));
                    informacionAcompanamiento.append('informacionAcompanamiento', JSON.stringify(this.resultadoAcompanamiento));
                    
                    this.emailUsuarios['emailUsuariosList'] = 
                        [ ...this.emailUsuarios['emailUsuariosList'], ...this.listaCorreosAdicionalesParaCopia ]
                    informacionAcompanamiento.append('emailUsuarios', JSON.stringify(this.emailUsuarios));
                    informacionAcompanamiento.append('emailAlterno', this.otroEmail.trim());
                    informacionAcompanamiento.append('imgResultadoAcompanamiento', this.snapshotFile);


                    const acompanamientoDocenteCreado = await this.gestorStore.createAcompanamientoDocente(informacionAcompanamiento);
                    
                    if(acompanamientoDocenteCreado.message){
                        this.mensajeError = acompanamientoDocenteCreado.message;
                    }else{
                        this.isLoading = false;
                        sweetAlertSuccess(
                          '¡Datos enviados!',
                          'Los datos diligenciados se han enviado de manera exitosa',
                          'success'
                        ).then(() => {
                           location.reload();
                        });
                    }
                  
                } else  (
                  result.dismiss === Swal.DismissReason.cancel
                ) 
            })
        },
    },
    components: {
      PulseLoader,
      Multiselect 
    }, 
    watch : {
        datosDocente: function (valor) {
            this.datosAsignatura = null;
        },
        datosAsignatura: async function (datosAsignatura) {
            if(datosAsignatura) {
                const { tipoAsignatura } = datosAsignatura;
                this.criteriosAcompanamientoDocente = await this.gestorStore.fetchCriteriosAcompanamientoDocente(tipoAsignatura);
                this.resultadoAcompanamiento = this.obtenerDescripcionCriteriosActuales();
            }
        }
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
