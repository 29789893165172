const descriptoresAMV = {
    descriptores1: [
        { descripcion: 'Participa en los 5 momentos', valoracion: 5 },
        { descripcion: 'Participa en 4 de los 5 momentos', valoracion: 4 },
        { descripcion: 'Participa en 3 de los 5 momentos', valoracion: 3 },
        { descripcion: 'Participa en 2 de los 5 momentos', valoracion: 2 },
        { descripcion: 'Participa en 1 de los 5 momentos', valoracion: 1 },
        { descripcion: 'No participa en ningún momento', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores2: [
        { descripcion: 'Revisa el material y confirma por correo', valoracion: 5 },
        { descripcion: 'No hay confirmación de revisión', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores3: [
        { descripcion: 'Envía el compromiso, publica la última versión y publica el microcurrículo vigente en el aula', valoracion: 5 },
        { descripcion: 'Publica el compromiso sin revisión y el microcurrículo vigente', valoracion: 4 },
        { descripcion: 'Publica uno de los dos documentos solicitados en su versión vigente', valoracion: 3 },
        { descripcion: 'Publica ambos documentos solicitados desactualizados', valoracion: 2 },
        { descripcion: 'Publica uno de los dos documentos desactualizado', valoracion: 1 },
        { descripcion: 'No publica los documentos', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores4: [
        { descripcion: 'Gestiona la tabla identificadora del curso, cuenta con video de presentación y actualiza la foto de perfil', valoracion: 5 },
        { descripcion: 'No gestiona la tabla identificadora, cuenta con video de presentación y foto de perfil', valoracion: 4 },
        { descripcion: 'Gestiona la tabla identificadora, cuenta con video de presentación, no tiene foto de perfil', valoracion: 3 },
        { descripcion: 'No gestiona la tabla identificadora, no cuenta con video de presentación, actualiza foto de perfil', valoracion: 2 },
        { descripcion: 'Gestiona la tabla identificadora, no cuenta con video de presentación ni foto de perfil', valoracion: 1 },
        { descripcion: 'No gestiona ninguno de los elementos requeridos', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //------ Compromiso Académico ------>

    descriptores5: [
        { descripcion: 'El docente promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación de la guía didáctica y se evidencia participación masiva por parte de los estudiantes en El foro de aceptación de guía didáctica', valoracion: 5 },
        { descripcion: 'El docente promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación de la guía didáctica y se evidencia poca participación por parte de los estudiantes en El foro de aceptación de guía didáctica', valoracion: 4 },
        { descripcion: 'El docente no promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación de la guía didáctica y se evidencia participación masiva por parte de los estudiantes en El foro de aceptación de guía didáctica', valoracion: 3 },
        { descripcion: 'El docente no promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación de la guía didáctica y se evidencia poca participación por parte de los estudiantes en El foro de aceptación de guía didáctica', valoracion: 2 },
        { descripcion: 'El docente no promueve por medio de foros de discusión o avisos en plataforma la lectura y no hay evidencia de aceptación por parte de los estudiantes', valoracion: 1 },
        { descripcion: 'No se evidencia publicación de guía didáctica', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //----- Contenidos de Aprendizaje --->

    descriptores6: [
        { descripcion: 'Brinda instrucciones que permiten que el estudiante aborde los contenidos y recursos digitales de apoyo', valoracion: 5 },
        { descripcion: 'No brinda instrucciones', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores7: [
        { descripcion: 'El aula cuenta con diversos recursos digitales y contenidos de aprendizaje visibles para los estudiantes', valoracion: 5 },
        { descripcion: 'El aula cuenta con pocos recursos digitales y contenidos de aprendizaje', valoracion: 3 },
        { descripcion: 'El aula no cuenta con recursos digitales ni contenidos de aprendizaje', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores8: [
        { descripcion: 'El contenido y las actividades están alineadas con la competencia del microcurrículo', valoracion: 5 },
        { descripcion: 'El contenido y las actividades no están alineadas con la competencia del microcurrículo', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores9: [
        { descripcion: 'Realiza actualizaciones a partir de las observaciones', valoracion: 5 },
        { descripcion: 'Realiza actualizaciones parciales a partir de las observaciones', valoracion: 3 },
        { descripcion: 'No realiza actualizaciones', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores10: [
        { descripcion: 'Conserva la estructura del curso sin omitir secciones y agrega información en cada sección', valoracion: 5 },
        { descripcion: 'Omite secciones pero agrega información a las secciones visibles', valoracion: 4 },
        { descripcion: 'Conserva la estructura pero no agrega información en todas las seccione', valoracion: 2 },
        { descripcion: 'No se observa contenido del curso', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
        
    //------ Interacción comunicativa en el curso ----->

    descriptores11: [
        { descripcion: 'Deja evidencia de la solución de inquietudes en el tiempo establecido', valoracion: 5 },
        { descripcion: 'Deja evidencia de la solución de inquietudes después del tiempo establecido', valoracion: 3 },
        { descripcion: 'No da respuesta a las solicitudes depositadas en el foro de dudas', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores12: [
        { descripcion: 'Deja evidencia de la solución de inquietudes en el tiempo establecido', valoracion: 5 },
        { descripcion: 'Deja evidencia de la solución de inquietudes después del tiempo establecido', valoracion: 3 },
        { descripcion: 'No da respuesta a las solicitudes depositadas en la mensajería privada', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores13: [
        { descripcion: 'Deja evidencia de la grabación de los encuentros sincrónicos cuando se llevan a cabo de manera virtual', valoracion: 5 },
        { descripcion: 'No deja evidencia de la grabación de los encuentro sincrónicos cuando se llevan a cabo de manera virtual', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //----- Evaluación ------->

    descriptores14: [
        { descripcion: 'Utiliza más de 5 recursos o actividades que ofrece la plataforma para realizar actividades cada semana', valoracion: 5 },
        { descripcion: 'Utiliza entre 1 y 5 recursos o actividades que ofrece la plataforma para realizar actividades cada semana', valoracion: 3 },
        { descripcion: 'No utiliza recursos o actividades que ofrece la plataforma para realizar actividades cada semana', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores15: [
        { descripcion: 'Deja evidencia de la retroalimentación dentro de las actividades tipo tarea después de la fecha de cierre de la actividad o deja evidencia de las asesorías con descripción en enlace del video', valoracion: 5 },
        { descripcion: 'En la retroalimentación se observan los mismos comentario en mas de 2 estudiantes', valoracion: 3 },
        { descripcion: 'Se observan comentarios de retroalimentación sin argumentos', valoracion: 2 },
        { descripcion: 'No se observan comentarios de retroalimentación', valoracion: 2 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores16: [
        { descripcion: 'Actualiza las notas del 60% en plataforma y SIA dentro de la fecha establecida', valoracion: 5 },
        { descripcion: 'Se evidencia un porcentaje menor al 60% en plataforma y SIA dentro de la fecha establecida', valoracion: 3 },
        { descripcion: 'No actualiza las notas del 60% en plataforma y SIA dentro de la fecha establecida', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores17: [
        { descripcion: 'Actualiza las notas del 40% en plataforma y SIA dentro de la fecha establecida', valoracion: 5 },
        { descripcion: 'Se evidencia un porcentaje menor al 40% en plataforma y SIA dentro de la fecha establecida', valoracion: 3 },
        { descripcion: 'No actualiza las notas del 40% en plataforma y SIA dentro de la fecha establecida', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores18: [
        { descripcion: 'Cumple con la planeación establecida en la Guía Didáctica', valoracion: 5 },
        { descripcion: 'Cumple parcialmente con la planeación establecida en la Guía Didáctica', valoracion: 3 },
        { descripcion: 'No cumple con la planeación establecida en la Guía Didáctica', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //---- Evaluación Final y Habilitaciones ----------->

    descriptores19: [
        { descripcion: 'Publica en plataforma fecha, hora y lugar para la realización del examen final en la semana 17', valoracion: 5 },
        { descripcion: 'No publica en plataforma fecha, hora y lugar para la realización del examen final en la semana 17', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores20: [
        { descripcion: 'Publica en plataforma fecha, hora y lugar para la realización del examen de habilitación en la pestaña de habilitación', valoracion: 5 },
        { descripcion: 'No publica en plataforma fecha, hora y lugar para la realización del examen de habilitación en la pestaña de habilitación', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    descriptores21: [
        { descripcion: 'Deja registro en ambas plataforma de la nota de habilitación obtenida por el estudiante', valoracion: 5 },
        { descripcion: 'Deja registro en el SIA pero no en plataforma de la nota de habilitación obtenida por el estudiante', valoracion: 3 },
        { descripcion: 'No hay evidencia de la nota de habilitación obtenida por el estudiante', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
}





const descriptoresAPV = {
    
    descriptores1: [
        { descripcion: 'Participa en los 5 momentos', valoracion: 5 },
        { descripcion: 'Participa en 4 de los 5 momentos', valoracion: 4 },
        { descripcion: 'Participa en 3 de los 5 momentos', valoracion: 3 },
        { descripcion: 'Participa en 2 de los 5 momentos', valoracion: 2 },
        { descripcion: 'Participa en 1 de los 5 momentos', valoracion: 1 },
        { descripcion: 'No participa en ningún momento', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores2: [
        { descripcion: 'Revisa el material y confirma por correo', valoracion: 5 },
        { descripcion: 'No hay confirmación de revisión', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores3: [
        { descripcion: 'Envía el compromiso, publica la última versión y publica el microcurrículo vigente en el aula', valoracion: 5 },
        { descripcion: 'Publica el compromiso sin revisión y el microcurrículo vigente', valoracion: 4 },
        { descripcion: 'Publica uno de los dos documentos solicitados en su versión vigente', valoracion: 3 },
        { descripcion: 'Publica ambos documentos solicitados desactualizados', valoracion: 2 },
        { descripcion: 'Publica uno de los dos documentos desactualizado', valoracion: 1 },
        { descripcion: 'No publica los documentos', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores4: [
        { descripcion: 'Gestiona la tabla identificadora del curso, cuenta con video de presentación, actualiza la foto de perfil y cuenta con la presentación docente', valoracion: 5 },
        { descripcion: 'No gestiona la tabla identificadora, cuenta con video de presentación, foto de perfil y presentación docente', valoracion: 4 },
        { descripcion: 'Gestiona la tabla identificadora, cuenta con video de presentación, no tiene foto de perfil, cuenta con presentación docente', valoracion: 3 },
        { descripcion: 'No gestiona la tabla identificadora, no cuenta con video de presentación, actualiza foto de perfil y no tiene presentación docente', valoracion: 2 },
        { descripcion: 'Gestiona la tabla identificadora, no cuenta con video de presentación, no tiene foto de perfil, cuenta con presentación docente', valoracion: 1 },
        { descripcion: 'No gestiona ninguno de los elementos requeridos', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //------ Compromiso Académico ------>

    descriptores5: [
        { descripcion: 'El docente promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación del compromiso académico y se evidencia participación masiva por parte de los estudiantes en el foro de aceptación del compromiso académico', valoracion: 5 },
        { descripcion: 'El docente promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación del compromiso académico y se evidencia poca participación por parte de los estudiantes en El foro de aceptación de guía didáctica', valoracion: 4 },
        { descripcion: 'El docente no promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación del compromiso académico y se evidencia participación masiva por parte de los estudiantes en El foro de aceptación del compromiso académico', valoracion: 3 },
        { descripcion: 'El docente no promueve por medio de los foros de discusión o avisos en plataforma la lectura y aceptación del compromiso académico y se evidencia poca participación por parte de los estudiantes en El foro de aceptación de compromiso académico', valoracion: 2 },
        { descripcion: 'El docente no promueve por medio de foros de discusión o avisos en plataforma la lectura y aceptación del compromiso académico y no hay evidencia de aceptación por parte de los estudiantes', valoracion: 1 },
        { descripcion: 'No se evidencia publicación del compromiso académico', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //------- Contenidos de Aprendizaje ------>

    descriptores6: [
        { descripcion: 'Brinda instrucciones que permiten que el estudiante aborde los contenidos y recursos digitales de apoyo', valoracion: 5 },
        { descripcion: 'No brinda instrucciones', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores7: [
        { descripcion: 'El OVA se encuentra visible y el aula cuenta con material complementario', valoracion: 5 },
        { descripcion: 'El OVA se encuentra visible pero no el aula no cuenta con material complementario', valoracion: 3 },
        { descripcion: 'El OVA se encuentra oculto o eliminado y no se evidencia material de estudio complementario', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores8: [
        { descripcion: 'El contenido y las actividades están alineadas con la competencia del microcurrÍculo', valoracion: 5 },
        { descripcion: 'El contenido y las actividades no están alineadas con la competencia del microcurrículo', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores9: [
        { descripcion: 'Realiza actualizaciones a partir de las observaciones', valoracion: 5 },
        { descripcion: 'Realiza actualizaciones parciales a partir de las observaciones', valoracion: 3 },
        { descripcion: 'No realiza actualizaciones', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores10: [
        { descripcion: 'Conserva la estructura del curso sin omitir secciones y agrega información en cada sección', valoracion: 5 },
        { descripcion: 'Omite secciones pero agrega información a las secciones visibles', valoracion: 4 },
        { descripcion: 'Conserva la estructura pero no agrega información en todas las secciones', valoracion: 2 },
        { descripcion: 'No se observa contenido del curso', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    
    //---- Interacción comunicativa en el curso ---->

    descriptores11: [
        { descripcion: 'Deja evidencia de la solución de inquietudes en el tiempo establecido', valoracion: 5 },
        { descripcion: 'Deja evidencia de la solución de inquietudes después del tiempo establecido', valoracion: 3 },
        { descripcion: 'No da respuesta a las solicitudes depositadas en el foro de dudas', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores12: [
        { descripcion: 'Deja evidencia de la solución de inquietudes en el tiempo establecido', valoracion: 5 },
        { descripcion: 'Deja evidencia de la solución de inquietudes después del tiempo establecido', valoracion: 3 },
        { descripcion: 'No da respuesta a las solicitudes depositadas en la mensajería privada', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores13: [
        { descripcion: 'Deja evidencia de la grabación de los encuentros sincrónicos cuando se llevan a cabo de manera virtual', valoracion: 5 },
        { descripcion: 'No deja evidencia de la grabación de los encuentro sincrónicos cuando se llevan a cabo de manera virtual', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //---- Evaluación ---->

    descriptores14: [
        { descripcion: 'Utiliza más de 5 recursos o actividades que ofrece la plataforma para realizar actividades cada semana', valoracion: 5 },
        { descripcion: 'Utiliza entre 1 y 5 recursos o actividades que ofrece la plataforma para realizar actividades cada semana', valoracion: 3 },
        { descripcion: 'No utiliza recursos o actividades que ofrece la plataforma para realizar actividades cada semana', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores15: [
        { descripcion: 'Deja evidencia de la retroalimentación dentro de las actividades tipo tarea después de la fecha de cierre de la actividad o deja evidencia de las asesorías con descripción en enlace del video', valoracion: 5 },
        { descripcion: 'En la retroalimentación se observan los mismos comentario en mas de 2 estudiantes', valoracion: 3 },
        { descripcion: 'Se observan comentarios de retroalimentación sin argumentos', valoracion: 2 },
        { descripcion: 'No se observan comentarios de retroalimentación', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores16: [
        { descripcion: 'Actualiza las notas del 60% en plataforma y SIA dentro de la fecha establecida', valoracion: 5 },
        { descripcion: 'Se evidencia un porcentaje menor al 60% en plataforma y SIA dentro de la fecha establecida', valoracion: 3 },
        { descripcion: 'No actualiza las notas del 60% en plataforma y SIA dentro de la fecha establecida', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores17: [
        { descripcion: 'Actualiza las notas del 40% en plataforma y SIA dentro de la fecha establecida', valoracion: 5 },
        { descripcion: 'Se evidencia un porcentaje menor al 40% en plataforma y SIA dentro de la fecha establecida', valoracion: 3 },
        { descripcion: 'No actualiza las notas del 40% en plataforma y SIA dentro de la fecha establecida', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores18: [
        { descripcion: 'Cumple con la planeación establecida en la Compromiso Académico', valoracion: 5 },
        { descripcion: 'Cumple parcialmente con la planeación establecida en la Compromiso Académico', valoracion: 3 },
        { descripcion: 'No cumple con la planeación establecida en la Compromiso Académico', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],

    //---- Evaluación Final y Habilitaciones ------>

    descriptores19: [
        { descripcion: 'Publica en plataforma fecha, hora y lugar para la realización del examen final en la última unidad', valoracion: 5 },
        { descripcion: 'No publica en plataforma fecha, hora y lugar para la realización del examen final en la última unidad', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores20: [
        { descripcion: 'Publica en plataforma fecha, hora y lugar para la realización del examen de habilitación en la última unidad', valoracion: 5 },
        { descripcion: 'No publica en plataforma fecha, hora y lugar para la realización del examen de habilitación en la última unidad', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
    descriptores21: [
        { descripcion: 'Deja registro en ambas plataforma de la nota de habilitación obtenida por el estudiante', valoracion: 5 },
        { descripcion: 'Deja registro en el SIA pero no en plataforma de la nota de habilitación obtenida por el estudiante', valoracion: 3 },
        { descripcion: 'No hay evidencia de la nota de habilitación obtenida por el estudiante', valoracion: 0 },
        { descripcion: 'N/A', valoracion: 'N/A'}
    ],
}






const observaciones = {
    observaciones1: [
        { observacion: 'Se evidencia participación activa en los momentos de inducción y reinducción propuestos desde la Unidad de Educación Virtual.', valoracion: 'pos' },
        { observacion: 'Encaminados a la mejora continua desde la Unidad de Educación Virtual lo invitamos a visualizar los videos de los momentos de inducción y reinducción.', valoracion: 'neg' }
    ],
    observaciones2: [
        { observacion: 'Se identifica la evaluación previa de su material', valoracion: 'pos' },
        { observacion: 'Sin información relacionada.', valoracion: 'neg' }
    ],
    observaciones3: [
        { observacion: 'Nos alegra evidenciar el envío de la guía didáctica / compromiso académico por medio del formulario destinado para ello y la publicación del documento en plataforma al igual que el microcurrículo.', valoracion: 'pos' },
        { observacion: 'No se evidencia envío de la guía didáctica/compromiso académico, ni se observa el documento publicado en el aula virtual, lo invitamos a completar el ejercicio de envío del documento y la publicación de este en el aula virtual, se observa publicación del microcurrículo.', valoracion: 'int' },
        { observacion: 'Para los estudiantes es importante conocer el microcurrículo y el compromiso académico de la asignatura, por lo tanto, es indispensable que dichos documentos se encuentren actualizados y publicados en el aula.', valoracion: 'neg' }
    ],
    observaciones4: [
        { observacion: 'Gestiona la información de la tabla identificadora del curso, su foto de perfil y video de presentación ayuda a que los estudiantes sepan qué tipo de persona es quien los está acompañando durante su proceso de aprendizaje.', valoracion: 'pos' },
        { observacion: 'Gestiona la información de la tabla identificadora del curso, la invitamos a publicar su foto de perfil y  grabar el video de presentación, es importante elegir una foto que refleje con precisión su personalidad y valores para que los estudiantes sepan qué tipo de persona es quien los está acompañando durante su proceso de aprendizaje.', valoracion: 'int' },
        { observacion: 'Hace falta la información de la tabla identificadora del curso, el video de presentación y la foto del perfil docente.', valoracion: 'neg' }
    ],
    observaciones5: [
        { observacion: 'Se evidencia promoción en plataforma de la lectura y aceptación de la guía didáctica y se observa participación masiva por parte de los estudiantes en el foro de aceptación de la guía didáctica.', valoracion: 'pos' },
        { observacion: 'Para los estudiantes es importante conocer la planeación del curso que se va a desarrollar, por lo tanto es importante que ubiquen de manera agil este documento y acepten las condiciones del curso por medio de el foro de aceptación del compromiso académico, a la fecha no se visualiza participación por parte de los estudiantes.', valoracion: 'neg' }
    ],
    observaciones6: [
        { observacion: 'Se visualizan instrucciones claras y precisas.', valoracion: 'pos' },
        { observacion: 'Hace falta brindar instrucciones más claras para los estudiantes.', valoracion: 'int' },
        { observacion: 'Con la claridad en las instrucciones, se logra que los estudiantes tengan una perspectiva más amplia de los temas y actividades a desarrollar en las semana en curso, por ello es importante la precisión de la información.', valoracion: 'neg' } 
    ],
    observaciones7: [
        { observacion: 'Se visualizan diversos recursos digitales y contenidos de aprendizaje visibles para los estudiantes, esto ayuda a que los estudiantes obtengan mayor conocimiento y el proceso de enseñanza-aprendizaje sea favorecedor.', valoracion: 'pos' },
        { observacion: 'Recuerde que la utilización de diversos recursos digitales motiva al estudiante a consultar los temas, es indispensable para el logro de los objetivos y aporta al proceso de enseñanza aprendizaje mediado por TIC.', valoracion: 'neg' },
    ],
    observaciones8: [
        { observacion: 'Al revisar tanto las unidades descritas como las actividades formuladas en el aula se encuentra articulación entre lo propuesto en el micro y los recursos de enseñanza y actividades de aprendizaje.', valoracion: 'pos' },
        { observacion: 'Al revisar tanto las unidades descritas como las actividades formuladas en el aula consideramos oportuno ampliar el conjunto de recursos de enseñanza y actividades de aprendizaje que estén direccionadas a la competencia descrita en el microcurrículo.', valoracion: 'int' },
        { observacion: 'Si el docente no tiene el micro en el aula: No es posible evidenciar la articulación de las actividades con el área de conocimiento, debido a que no hay microcurrículo en el aula virtual.', valoracion: 'neg' },
    ],
    observaciones9: [
        { observacion: 'Nos alegra evidenciar los ajustes realizados por usted en la plataforma. Esto da razón del paulatino mejoramiento que en términos de las prácticas de la Educación Virtual se van concretando.', valoracion: 'pos' },
        { observacion: 'Lo invitamos a completar el ejercicio de actualización de plataforma que usted ha emprendido. Esto nos lleva consolidar la oferta formativa del ITM.', valoracion: 'neg' }
    ],
    observaciones10: [
        { observacion: 'Conserva todas las secciones del AVA (aula virtual de aprendizaje)', valoracion: 'pos' },
        { observacion: 'Se visualizan alteraciones en la estructura planteada en el aula que desconfiguran la intención pedagógica de las aulas.', valoracion: 'neg' }
    ],
    observaciones11: [
        { observacion: 'La resolución de inquietudes que usted ha realizado en  los tiempos establecidos,  ha permitido que nuestros estudiantes sigan avanzando en su proceso formativo y nuestra institución siga su consolidación en la Ciudad.', valoracion: 'pos' },
        { observacion: 'La UEV desea recordarle que la resolución oportuna  de las inquietudes presentadas por nuestros estudiantes, fortalece el vínculo que ellos tienen con la modalidad de educación virtual. Le solicitamos ser más oportuno (a)  en los tiempos de respuesta a las dudas que los estudiantes formulan. Puede suceder: los estudiantes no han manifestado inquietudes en el foro de dudas/ Sin inquietudes vigentes en el foro de dudas.', valoracion: 'neg' }
    ],
    observaciones12: [
        { observacion: 'El uso correcto del lenguaje en plataforma da cuenta del compromiso docente, tener una respuesta clara y pronta fortalece el proceso de aprendizaje en los estudiantes y garantiza la permanencia de este.', valoracion: 'pos' },
        { observacion: 'La respuesta oportuna que usted debe realizar frente a las inquietudes que se comunican a través de la mensajería privada, es una práctica que debe ser aprovechada ya que es un  componente del AVA que  incide potencialmente en la permanencia escolar.', valoracion: 'neg' }
    ],
    observaciones13: [
        { observacion: 'Los espacios sincrónicos y las grabaciones de estos,  permiten que nuestros estudiantes sigan avanzando en su proceso formativo y nuestra institución avance en la consolidación de su propuesta formativa.', valoracion: 'pos' },
        { observacion: 'La UEV desea recordarle la importancia de acompañar a los estudiantes en espacios de encuentro a partir de los cuales se logre  aclarar aspectos relativos a conceptos, procedimientos o productos asociados al curso. Como parte de su trazabilidad debe quedar evidencia de estos encuentros (entre ellos se hallan las grabaciones) de fácil acceso de todos los estudiantes. Le solicitamos dejar las grabaciones de estos encuentros alojadas en la unidad temática que se haya trabajado.', valoracion: 'neg' }
    ],
    observaciones14: [
        { observacion: 'Se evidencia un apropiado manejo de los recursos  que la plataforma ofrece. Su uso ha ampliado los niveles de interacción de los estudiantes . Le invitamos a seguir  explorando aquellos recursos que aún no haya implementado.', valoracion: 'pos' },
        { observacion: 'Las actividades entregadas en la plataforma al inicio del proceso formativo hacen parte del diseño y adecuación realizado por el docente experto en compañía de la UEV. Le invitamos a identificar, rastrear y aplicar el conjunto de actividades que para efectos de la evaluación trae consigo la plataforma de modo que a través de ellas logre hacer seguimiento a los resultados de aprendizaje asociados  a la competencia definida en su asignatura.', valoracion: 'neg' }
    ],
    observaciones15: [
        { observacion: 'La retroalimentación que usted ha realizado frente a las actividades evaluativas dispuestas en el AVA y aquellas que usted ha diseñado han posibilitado el mejoramiento contínuo de nuestra modalidad. Lo anterior tiene una alta incidencia en la calidad de las producciones de nuestros estudiantes,  en el nivel de obtención de los resultados de aprendizaje y en el grado de permanencia en el ITM.', valoracion: 'pos' },
        { observacion: 'Se sugiere realizar retroalimentación frente  a las actividades de evaluación que han sido resueltas por nuestros estudiantes. Ellas se convierte en un insumo valioso para que se logren mejoras en algunos aspectos que no hayan sido factores de éxito en los productos entregados.La orientación que se hace dentro de la evaluación debe partir de los elementos que se han vinculado en  las actividades y deben estar inspiradas en una intervención clara, aportante y de generación de vínculo con el proceso.', valoracion: 'neg' }
    ],
    observaciones16: [
        { observacion: 'Usted ha garantizado que nuestros estudiantes conozcan los avances en su proceso de formación por vía de las valoraciones que ha consignado en plataforma. La práctica de la autogestión se potencia con los registros que usted ha publicado paulatinamente y con base a la programación del semestre.', valoracion: 'pos' },
        { observacion: 'El registro de las notas del 60% en plataforma y en el SIA de acuerdo con la programación del semestre, no solo es una orientación académica o administrativa. Más allá de ello, el registro de notas  se convierte en un instrumento que permite a nuestros estudiantes, hacer un seguimiento a su propio proceso de modo tal que puedan seguir su ruta de formación o implementar ajustes tempranos. Le invitamos  a identificar esta última dimensión a la par de los compromisos derivados de la programación del semestre.', valoracion: 'neg' }
    ],
    observaciones17: [
        { observacion: 'Usted ha garantizado que nuestros estudiantes conozcan los avances en su proceso de formación por vía de las valoraciones que ha consignado en plataforma. La práctica de la autogestión se potencia con los registros que usted ha publicado paulatinamente y con base a la programación del semestre.', valoracion: 'pos' },
        { observacion: 'El registro de las notas del 40% en plataforma y en el SIA de acuerdo con la programación del semestre, no solo es una orientación académica o administrativa. Más allá de ello, el registro de notas  se convierte en un instrumento que permite a nuestros estudiantes, hacer un seguimiento a su propio proceso de modo tal que puedan seguir su ruta de formación o implementar ajustes tempranos. Le invitamos  a identificar esta última dimensión a la par de los compromisos derivados de la programación del semestre.', valoracion: 'neg' }
    ],
    observaciones18: [
        { observacion: 'Al realizar la revisión a la información contenida en el (compromiso académico-guía didáctica), se logra establecer la relación entre la competencia que se ha fijado como derrotero de la formación, los resultados de aprendizaje que la han concretado y la correspondencia entre estos con la  realización de actividades formativas y evaluativas. Así mismo se observa el cumplimiento  reiterado a las condiciones específicas del curso lo cual ratifica las,posibilidades que existen de avanzar en la implementación de esta modalidad.', valoracion: 'pos' },
        { observacion: 'La Unidad de educación virtual, le recuerda la importancia de concretar cada uno  de los aspectos que han sido demarcados dentro de  (compromiso académico-guía didáctica). Dado que se fijaron un conjunto de actividades de formación, aprendizaje y evaluación, se require que se dé cumplimiento a estos en los términos en los cuales se ha firmado con cada uno de los grupos que usted acompaña. Si bien podría existir algunas eventualidades que inciden en su desarrollo, debemos acercar nuestras prácticas a la consecución de lo allí planteado de modo tal que no se afecten los resultados de aprendizaje establecidos en el semestre así como la perfilación que estos van logrando en nuestros estudiantes.', valoracion: 'neg' }
    ],
    observaciones19: [
        { observacion: 'La correcta divulgación de la información tiene como resultado una comunicación asertiva de los eventos evaluativos finales, lo cual impacta positivamente la planeación y el logro de los objetivos.', valoracion: 'pos' },
        { observacion: 'La Unidad de Educación virtual desea recordarle que en la virtualidad las instrucciones deben ser lo más específicas posible y deben quedar registradas en la plataforma, ya sea en texto, imagen, audio o video, asegurando asi que los estudiantes comprendan la información y tengan presente las actividades próximas e información de interés.', valoracion: 'neg' }
    ],
    observaciones20: [
        { observacion: 'El docente utiliza los canales de comunicación (foro, mensajería privada, correo) para divulgar la información referente a la prueba de habilitaciones  y además hace del aula virtual un espacio estructurado donde las actividades y recursos se muestren de forma ordenada, permitiendo así al alumno encontrar cada proceso en su lugar correspondiente.', valoracion: 'pos' },
        { observacion: 'El docente se comprende como un puente entre el conocimiento y los estudiantes, es importante realizar publicaciones referentes a las habilitaciones, con el fin de hacer del aula virtual un espacio estructurado y organizado, asegurando que los estudiantes tengan conocimiento de la información, esto da cuenta de la reacreditación en alta calidad en educación.', valoracion: 'neg' }
    ],
    observaciones21: [
        { observacion: 'Usted ha garantizado que nuestros estudiantes conozcan los avances en su proceso de formación por vía de las valoraciones que ha consignado en plataforma. La práctica de la autogestión se potencia con los registros que usted ha publicado paulatinamente y con base a la programación del semestre.', valoracion: 'pos' },
        { observacion: 'El registro de las notas de habilitación en plataforma y en el SIA de acuerdo con la programación del semestre, no solo es una orientación académica o administrativa. Más allá de ello, el registro de notas  se convierte en un instrumento que permite a nuestros estudiantes, hacer un seguimiento a su propio proceso de modo tal que puedan seguir su ruta de formación o implementar ajustes tempranos. Le invitamos  a identificar esta última dimensión a la par de los compromisos derivados de la programación del semestre.', valoracion: 'neg' }
    ]
}   

const criteriosAMV = () => {
    return [
        {
            criterio: "Participa activamente en los procesos de inducción y reinducción ofrecidos por la Unidad de Educación Virtual al inicio del semestre.", 
            descriptores: descriptoresAMV.descriptores1,
            observaciones: observaciones.observaciones1
        },
        {
            criterio: "N/A", 
            descriptores: descriptoresAMV.descriptores2,
            observaciones: observaciones.observaciones2

        },
        {
            criterio: "Envía oportunamente la guía didáctica para su revisión y realiza ajustes según las recomendaciones derivadas de la misma y publica en plataforma la versión definitiva de la guía didáctica y el microcurrículo vigente.", 
            descriptores: descriptoresAMV.descriptores3,
            observaciones: observaciones.observaciones3
        },
        {
            criterio: "Gestiona la información requerida en la tabla identificadora del curso, cuenta con video de presentación docente actualizado y actualiza la foto de perfil de usuario en la  plataforma según las indicaciones dadas por el equipo de Gestión Curricular. (Foto tipo documento).", 
            descriptores: descriptoresAMV.descriptores4,
            observaciones: observaciones.observaciones4
        },
        //------ Compromiso Académico ------>

        {
            criterio: "Promueve la lectura, discusión y aprobación de la guía didáctica por parte de los estudiantes en plataforma, dejando evidencia de ello y el estudiante participa de la aceptación de la guía didáctica.", 
            descriptores: descriptoresAMV.descriptores5,
            observaciones: observaciones.observaciones5
        },

        //----- Contenidos de Aprendizaje --->

        {
            criterio: "Brinda instrucciones en la etiqueta de acciones de aprendizaje de cada semana sobre como abordar los contenidos y recursos digitales de apoyo.", 
            descriptores: descriptoresAMV.descriptores6,
            observaciones: observaciones.observaciones6
        },

        {
            criterio: "El aula cuenta con variedad de recursos digitales y contenidos de aprendizaje los cuales están visibles para los estudiantes semanalmente.", 
            descriptores: descriptoresAMV.descriptores7,
            observaciones: observaciones.observaciones7
        },

        {
            criterio: "El contenido y las actividades propuestas en el curso están articuladas con la(s) competencia establecida en el microcurrículo de la asignatura.", 
            descriptores: descriptoresAMV.descriptores8,
            observaciones: observaciones.observaciones8
        },

        {
            criterio: "Realiza actualizaciones a partir de las observaciones del acompañamiento emitidas desde Gestión Curricular.", 
            descriptores: descriptoresAMV.descriptores9,
            observaciones: observaciones.observaciones9
        },

        {
            criterio: "Conserva la estructura del curso y agrega la información relativa a cada sección (etiquetas predeterminadas) según la entrega del aula al inicio de semestre, sin omitir secciones.", 
            descriptores: descriptoresAMV.descriptores10,
            observaciones: observaciones.observaciones10
        },

        //------ Interacción comunicativa en el curso ----->

        {
            criterio: "Deja evidencia en plataforma en el Foro de dudas de la solución de inquietudes en los tiempos establecidos para ello. (Uso adecuado del lenguaje, claridad en las respuestas con un tiempo no mayor a 24 horas de lunes a sábado).", 
            descriptores: descriptoresAMV.descriptores11,
            observaciones: observaciones.observaciones11
        },

        {
            criterio: "Usa la mensajería privada de la plataforma para establecer comunicación con los estudiantes y resolver dudas e inquietudes con los mismos. (Uso adecuado del lenguaje, claridad en las respuestas con un tiempo no mayor a 24 horas de lunes a sábado).", 
            descriptores: descriptoresAMV.descriptores12,
            observaciones: observaciones.observaciones12
        },

        {
            criterio: "Deja evidencia de la programación y grabación de las asesorías o encuentros sincrónicos con los estudiantes cuando se realice a través del uso de plataformas virtuales.", 
            descriptores: descriptoresAMV.descriptores13,
            observaciones: observaciones.observaciones13
        },

        //----- Evaluación ------->

        {
            criterio: "Utiliza los recursos y actividades que brinda la plataforma para realizar actividades formativas y evaluativas semanalmente.", 
            descriptores: descriptoresAMV.descriptores14,
            observaciones: observaciones.observaciones14
        },

        {
            criterio: "Hace retroalimentación de actividades tipo tarea formativas o evaluativas después de la fecha de cierre de la actividad y deja registro en plataforma. En caso de hacerla a través de asesorías, dejar grabación del evento.", 
            descriptores: descriptoresAMV.descriptores15,
            observaciones: observaciones.observaciones15
        },

        {
            criterio: "Actualiza las notas del 60% en plataforma y en el SIA de acuerdo con la programación del semestre entregada por el equipo de Gestión Curricular.", 
            descriptores: descriptoresAMV.descriptores16,
            observaciones: observaciones.observaciones16
        },

        {
            criterio: "Actualiza las notas del 40% en la plataforma y en el SIA de acuerdo con programación del semestre entregada por el equipo de Gestión Curricular.A23", 
            descriptores: descriptoresAMV.descriptores17,
            observaciones: observaciones.observaciones17
        },

        {
            criterio: "Actualiza las notas del 60% en plataforma y en el SIA de acuerdo con la  del semestre entregada por el equipo de Gestión Curricular.A23", 
            descriptores: descriptoresAMV.descriptores18,
            observaciones: observaciones.observaciones18
        },

        {
            criterio: "Publica en plataforma fecha, hora y lugar para la realización del examen final y configura adecuadamente la actividad para la culminación del curso en semana 17.", 
            descriptores: descriptoresAMV.descriptores19,
            observaciones: observaciones.observaciones19
        },

        {
            criterio: "Publica en plataforma fecha, hora y lugar para la realización de la habilitación y configura adecuadamente la actividad correspondiente en la pestaña de habilitación.", 
            descriptores: descriptoresAMV.descriptores20,
            observaciones: observaciones.observaciones20
        },

        {
            criterio: "Deja registro en plataforma y en el SIA de la nota de habilitación obtenida por el estudiante.", 
            descriptores: descriptoresAMV.descriptores21,
            observaciones: observaciones.observaciones21
        },

    ]
}

const criteriosAPV = () => {
    return [
        {
            criterio: "Participa activamente en los procesos de inducción y reinducción ofrecidos por la Unidad de Educación Virtual al inicio del semestre.", 
            descriptores: descriptoresAPV.descriptores1,
            observaciones: observaciones.observaciones1
        },

        {
            criterio: "Realiza la revisión previamente del material que ha sido dispuesto para el desarrollo de la asignatura y confirma por correo electrónico dicha acción con las observaciones encontradas en caso de que se considere necesario.", 
            descriptores: descriptoresAPV.descriptores2,
            observaciones: observaciones.observaciones2
        },

        {
            criterio: "Envía oportunamente del compromiso académico para su revisión y realiza ajustes según las recomendaciones derivadas de la misma y publica en plataforma la versión definitiva de la guía didáctica y el microcurrículo vigente.", 
            descriptores: descriptoresAPV.descriptores3,
            observaciones: observaciones.observaciones3
        },

        {
            criterio: "Gestiona la información requerida en la tabla identificadora del curso, cuenta con video de presentación docente actualizado y actualiza la foto de perfil de usuario y presentación docente en la  plataforma según las indicaciones dadas por el equipo de Gestión Curricular. (Foto tipo documento).", 
            descriptores: descriptoresAPV.descriptores4,
            observaciones: observaciones.observaciones4
        },

        //------ Compromiso Académico --------->

        {
            criterio: "Promueve la lectura, discusión y aprobación del compromiso académico por parte de los estudiantes en plataforma, dejando evidencia de ello y el estudiante participa de la aceptación del compromiso académico.", 
            descriptores: descriptoresAPV.descriptores5,
            observaciones: observaciones.observaciones5
        },

        {
            criterio: "Brinda instrucciones en la etiqueta de presentación de cada unidad sobre como abordar el material de estudio básico.", 
            descriptores: descriptoresAPV.descriptores6,
            observaciones: observaciones.observaciones6
        },

        {
            criterio: "Promueve el uso del OVA y  complementa el material básico de estudio con variedad recursos están visibles para los estudiantes semanalmente.", 
            descriptores: descriptoresAPV.descriptores7,
            observaciones: observaciones.observaciones7
        },

        {
            criterio: "El contenido y las actividades propuestas en el curso están articuladas con la(s) competencia establecida en el microcurrículo de la asignatura.", 
            descriptores: descriptoresAPV.descriptores8,
            observaciones: observaciones.observaciones8
        },

        {
            criterio: "Realiza actualizaciones a partir de las observaciones del acompañamiento emitidas desde Gestión Curricular.", 
            descriptores: descriptoresAPV.descriptores9,
            observaciones: observaciones.observaciones9
        },

        {
            criterio: "Conserva la estructura del curso y agrega la información relativa a cada sección (etiquetas predeterminadas) según la entrega del aula al inicio de semestre, sin omitir secciones.", 
            descriptores: descriptoresAPV.descriptores10,
            observaciones: observaciones.observaciones10
        },

        //------ Interacción comunicativa en el curso ---------->
    
        {
            criterio: "Deja evidencia en plataforma en el Foro de dudas de la solución de inquietudes en los tiempos establecidos para ello. (Uso adecuado del lenguaje, claridad en las respuestas con un tiempo no mayor a 24 horas de lunes a sábado).", 
            descriptores: descriptoresAPV.descriptores11,
            observaciones: observaciones.observaciones11
        },

        {
            criterio: "Usa la mensajería privada de la plataforma para establecer comunicación con los estudiantes y resolver dudas e inquietudes con los mismos. (Uso adecuado del lenguaje, claridad en las respuestas con un tiempo no mayor a 24 horas de lunes a sábado).", 
            descriptores: descriptoresAPV.descriptores12,
            observaciones: observaciones.observaciones12
        },

        {
            criterio: "Deja evidencia de la programación y grabación de los encuentros sincrónicos con los estudiantes.", 
            descriptores: descriptoresAPV.descriptores13,
            observaciones: observaciones.observaciones13
        },

        //-------- Evaluación ----------->

        {
            criterio: "Utiliza los recursos y actividades que brinda la plataforma para realizar actividades formativas y evaluativas semanalmente.", 
            descriptores: descriptoresAPV.descriptores14,
            observaciones: observaciones.observaciones14
        },

        
        {
            criterio: "Hace retroalimentación de actividades tipo tarea formativas o evaluativas después de la fecha de cierre de la actividad y deja registro en plataforma. En caso de hacerla a través de asesorías, dejar grabación del evento.", 
            descriptores: descriptoresAPV.descriptores15,
            observaciones: observaciones.observaciones15
        },

        {
            criterio: "Actualiza las notas del 60% en plataforma y en el SIA de acuerdo con la programación del semestre entregada por el equipo de Gestión Curricular.", 
            descriptores: descriptoresAPV.descriptores16,
            observaciones: observaciones.observaciones16
        },

        {
            criterio: "Actualiza las notas del 40% en la plataforma y en el SIA de acuerdo con programación del semestre entregada por el equipo de Gestión Curricular.", 
            descriptores: descriptoresAPV.descriptores17,
            observaciones: observaciones.observaciones17
        },

        {
            criterio: "Cumple con la planeación establecida en el compromiso académico que fue publicada al inicio de semestre.", 
            descriptores: descriptoresAPV.descriptores18,
            observaciones: observaciones.observaciones18
        },

        //------ Evaluación Final y Habilitaciones -------->

        {
            criterio: "Publica en plataforma fecha, hora y lugar para la realización del examen final y configura adecuadamente la actividad para la culminación del curso en la última unidad.", 
            descriptores: descriptoresAPV.descriptores19,
            observaciones: observaciones.observaciones19
        },

        {
            criterio: "Publica en plataforma fecha, hora para la realización de la habilitación y configura adecuadamente la actividad correspondiente en el aula creada para tal fin.", 
            descriptores: descriptoresAPV.descriptores20,
            observaciones: observaciones.observaciones20
        },

        {
            criterio: "Deja registro en plataforma y en el SIA de la nota de habilitación obtenida por el estudiante.", 
            descriptores: descriptoresAPV.descriptores21,
            observaciones: observaciones.observaciones21
        },
    ]
}


module.exports ={
    criteriosAMV,
    criteriosAPV
}