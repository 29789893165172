<template>
    <div class="container pb-5 mt-5">
        <label>Archivo de usuarios
        <input type="file" id="archivoCargaUsuarios" ref="file" @change="onChangeFileUpload($event)"/>
        </label>
        <button v-on:click="submitForm()" v-if="archivoUsuarios != null">Enviar archivo</button>
        <div class="alert alert-danger mt-4" role="alert" v-if="errorArchivoIncorrecto">
            El archivo a cargar debe ser un archivo con extensión <b>.txt</b>, <b>.csv</b> ó <b>.xls</b>!
        </div>
    </div>
</template>

<script>

import API from '../../../api'

export default {
    name: 'CargaMasivaUsuarios',
    data() {
        return {
            archivoUsuarios: null,
            errorArchivoIncorrecto: false
        }
    },
    methods: {
        onChangeFileUpload: function(event) {

            this.errorArchivoIncorrecto = false;
            const target = event.target;
            
            if(target.files){
                let extension = target.files[0].name.split('.');
                extension = extension[extension.length - 1];
                
                if(extension !== 'xlsx' && extension !== 'XLSX') {
                    this.errorArchivoIncorrecto = true;
                    document.getElementById("archivoCargaUsuarios").value = '';
                    return;
                }
                this.archivoUsuarios = target.files[0];    

            }   

               
        },

        submitForm: function(event) {
            const data = new FormData();
            data.append("archivoUsuarios", this.archivoUsuarios);
            const addInfo = API.cargaMasivaUsuariosMoodle(data);
        }
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }
}
</script>