<template>
    <div class="mt-5 mb-3">
        <footer class="footer row m-0 p-0">
            <div class="col-12 d-flex justify-content-end">
                <img src="img/Logo-Alcaldia.png" alt="Logo Alcaldía de Medellín" class="img-fluid">
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "footer",
}
</script>