import LoginUsuarios from '../views/Auth/Login.vue';
import Inicio from '../views/Inicio/Inicio.vue'; 
import InicioEstudiante from '../views/Inicio/Estudiantes/InicioEstudiantes';
import InicioDocentes from '../views/Inicio/Docentes/InicioDocentes';
import InicioGestores from '../views/Inicio/Gestores/InicioGestores';

export default [
    {
        path: '/',
        name: 'login',
        component: LoginUsuarios,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual',
            hideNavbar: true
        }
    },

    {
        path: '/inicio',
        name: 'inicio',
        component: Inicio,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Inicio',
            
        }
    },

    {
        path: '/estudiante',
        name: 'inicioEstudiante',
        component: InicioEstudiante,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Estudiante',
            allowedRoles: ['manager','student']
        }
    },

    {
        path: '/docente',
        name: 'inicioDocente',
        component: InicioDocentes,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Docente',
            allowedRoles: ['manager','editingteacher']
        }
    },

    {
        path: '/gestor',
        name: 'inicioGestor',
        component: InicioGestores,
        meta: {
            title: 'Gestión Curricular - Unidad Virtual / Gestor',
            allowedRoles: ['manager']
        }
    }
]