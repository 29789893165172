<template>
    <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>Oops!</h1>
			</div>
			<h2>404 - Página no encontrada</h2>
			<p>La página que está buscando ha sido removida, su nombre cambió o está temporalmente inhabilitada.</p>
			<a href="/">Ir al inicio</a>
		</div>
	</div>
</template>

<script>
export default {
    name: 'PaginaNoEncontrada',
	mounted() {
		document.title = this.$route.meta.title // Cambiar el título de la página
	}
}
</script>
