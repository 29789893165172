<template>
    <div class="container pb-5">
      <div class="row">
         <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante los formularios de Estrategias.</p>
          </div>
      </div>
      <div class="row">
            <div class="col-12">
                <select id="selectEstrategia" class="form-select form-select-lg mb-3" v-model.trim="estrategiaSeleccionada" aria-label="form-select-lg example">
                    <option selected value="">Seleccione una opción</option>
                    <option v-for="(estrategia, index) of optionsEstrategias" :key="index" :value="estrategia.value">{{estrategia.text}}</option>
                </select>
            </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
             <div v-if="infoIncripcionEstrategias.length != 0">
              <DataTable v-if="infoIncripcionEstrategias.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoIncripcionEstrategias" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div> 
          </div>  
      </div> 
    </div>
</template>

<script>

import { useGestorStore } from '@/stores/gestores';
import DataTable from '../../../components/DataTable'

export default {
    name: "InscripcionEstrategias",
    data() {
        return {
            gestorStore: null,
            btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
            infoIncripcionEstrategias: [],
            noData: false,
            estrategiaSeleccionada: '',
            optionsEstrategias: [
                { text: 'Citas y referencias en el estilo APA', value: 'citasReferenciasAPA' },
                { text: 'Visibilidad científica', value: 'visibilidadCientifica' },
                { text: 'Estrategias de información científica', value: 'informacionCientifica' },
            ],
            cabeceraDatatable: [
                {text: 'N°'},
                {text: 'Nombres'},
                {text: 'Apellidos'},
                {text: 'Tipo de documento'},
                {text: 'N° Documento'},
                {text: 'Correo'},
                {text: 'Celular'},
                {text: 'Teléfono fijo'},
                {text: 'Programa académico'},
                {text: 'Fecha de Registro'}
            ],
            tituloReporteDataTable: 'Reporte de inscripción a estrategias'
        }
    },
    components: {
        DataTable
    },
    methods: {
        verInfo: async function(nuevoValor) {
            this.noData = false;
            this.gestorStore = useGestorStore();
            this.infoIncripcionEstrategias = await this.gestorStore.fetchInscripcionEstrategia(nuevoValor);
            if(this.infoIncripcionEstrategias.length === 0) return this.noData = true;
        }
    },
    watch: {
        estrategiaSeleccionada: async function (nuevoValor, valorAnterior) {
            this.verInfo(nuevoValor);
        }
    },
    mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
    }

}
</script>