<template>
    <div class="container pb-5">
      <div class="row">
        <div class="pt-4 mt-4">
            <p>En esta sección usted podrá visualizar la información de los estudiantes y su acceso a determinado curso <em><b>APV y AMV</b></em>.</p>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12" v-if="mensajeError !== ''">
          <div class="alert alert-danger" role="alert">
            {{ mensajeError }}
          </div>
        </div>
        <p class="text-center" v-if="loading"><b>Cargando información, por favor espere...</b></p> 
        <div class="d-flex justify-content-center" v-if="loading">
          <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
        </div>
  
        <div class="col-12" v-if="muestraTabla">
          <DataTable v-if="infoEstudiantesEnRiesgo.length !== 0"  @draw="drawCallback" :btnsDisable="btnsDisableToTable" :info="infoEstudiantesEnRiesgo" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
        </div>  
      </div> 
    </div>
  </template>
  <script>

  import DataTable from '../../../components/DataTable'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { useGestorStore } from '@/stores/gestores';

  export default {
    name: 'infoEstudiantesEnRiesgo',
    data() {
      return{
          gestorStore: null,
          loading: false,   
          muestraTabla: false,
          mensajeError: '',
          infoEstudiantesEnRiesgo: [],
          btnsDisableToTable: ['btnCambiarEstado'],
          cabeceraDatatable: [
          {text: 'N°'},
          {text: 'Nombre'},
          {text: 'Apellido'},
          {text: 'Email'},
          {text: 'Cédula'},
          {text: 'Último acceso al curso'},
          {text: 'Nombre del curso'},
          {text: 'Nombre de categoria'},
          {text: 'Rol'},
        ],
        tituloReporteDataTable: 'Reporte de estudiantes en riesgo',
      }
    },
    components: {
      DataTable,
      PulseLoader,
    }, 
    async created() {
      this.loading = true;

      this.gestorStore = useGestorStore();
      const { estudiantesEnRiesgo } = await this.gestorStore.fetchInfoEstudiantesEnRiesgo();
      this.infoEstudiantesEnRiesgo = estudiantesEnRiesgo;
      if(this.infoEstudiantesEnRiesgo.message){
          this.mensajeError = usersInCoursesByCategory.message
          this.muestraTabla = false;
          this.loading = false;
          return;
      }

      this.muestraTabla = true;
      this.loading = false;

    }, 
    mounted() {
      document.title = this.$route.meta.title // Cambiar el título de la página
    },
 
  }
  </script>
  <style>
@import 'datatables.net-bs5';
</style>
  