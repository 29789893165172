import axios from 'axios';

const microservice = axios.create({
  baseURL: 'https://gestionvirtual.itm.edu.co/microservices', 
  //baseURL: 'http://localhost:3000', 
  //baseURL: 'http://localhost:8000', // Localhost with localhost docker(docker desktop) using prod envs
  timeout: 1000000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default microservice;
