<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Generales/Banner-CaracterizacionDigital.png"
                class="img-fluid d-flex mx-auto vw-100" alt="">
        </div>
        <div class="container">
            <div class="row mt-3">
                <p>El ITM está comprometido con ofrecer una educación de alta calidad y promover el desarrollo humano
                    con igualdad de oportunidades, diversidad, equidad e inclusión, es por esta razón que se
                    caracterizará el acceso, el uso y la apropiación de las TIC de los estudiantes de modalidad virtual
                    para determinar su grado de inclusión o exclusión digital. Los resultados de esta caracterización
                    permitirán a la institución identificar las fortalezas o carencias tecnológicas de los estudiantes
                    de modalidad virtual para ofrecer los apoyos que estén al alcance de la institución como una forma
                    de incidir favorablemente a la permanencia estudiantil de la modalidad virtual.
                </p>
                <p>Los invitamos a dedicar 8 minutos a responder estas preguntas que nos ayudarán a conocerte mejor.</p>
            </div>

            <hr class='mt-3 mb-3'>

            <div class="row mt-3 mb-3">
                <label for="autorizacionTratamientoDatos">Autorizo a la Unidad de Educación Virtual el tratamiento
                    de
                    mis datos conforme la Ley 1581 de 2012, los cuales serán utilizados con fines de caracterización
                    digital de la institución universitaria ITM y no serán comercializados ni suministrados a
                    otras entidades<b class="text-danger">*</b></label> <br>
                <label>
                    <input type="radio" name="terminos" value="Sí" class='form-check-input'
                        v-model="datosBasicos.autorizacionTratamientoDatos" />
                    Sí
                </label>
                <label>
                    <input type="radio" name="terminos" value="No" class='form-check-input'
                        v-model="datosBasicos.autorizacionTratamientoDatos" />
                    No
                </label>
            </div>

            <div v-if="datosBasicos.autorizacionTratamientoDatos === 'Sí'" class='mb-4 pb-4'>
                <div class="container mt-3 mb-3 pb-3">
                    <b class='titulo2'>Datos personales.</b>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtNombreCompleto" class='pregunta'>Nombres y apellidos <b
                                    class="text-danger">*</b></label> <br>
                            <input
                                :oninput="() => (datosBasicos.nombreCompleto = datosBasicos.nombreCompleto.toUpperCase())"
                                class="form-control" type="text" id="txtNombreCompleto"
                                v-model.trim="datosBasicos.nombreCompleto">
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlTipoDocumento" class='pregunta'>Tipo de documento <b
                                    class="text-danger">*</b></label> <br>
                            <select id="ddlTipoDocumento" class="form-select  "
                                v-model.trim="datosBasicos.tipoDocumento" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(tipoDocumento, index) of tiposDocumento" :key="index">{{
                                    tipoDocumento.value
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtNumDocumento" class='pregunta'>Número de documento de identidad <b
                                    class="text-danger">*</b></label> <br>
                            <input class="form-control" type="text" id="txtNumDocumento"
                                v-model.trim="datosBasicos.numeroDocumento">
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtNumTelefono" class='pregunta'>Telefono de contacto <b
                                    class="text-danger">*</b></label> <br>
                            <input class="form-control" type="number" id="txtNumTelefono"
                                v-model.trim="datosBasicos.telefonoContacto">
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtCorreo" class='pregunta'>Correo electronico <b
                                    class="text-danger">*</b></label>
                            <br>
                            <input class="form-control" type="text" id="txtCorreo"
                                v-model.trim="datosBasicos.correoElectronico">
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlRangoEdad" class='pregunta'>Edad <b class="text-danger">*</b></label> <br>
                            <select id="ddlRangoEdad" class="form-select  " v-model.trim="datosBasicos.edad"
                                aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(rangoEdad, index) of rangoEdades" :key="index">{{
                                    rangoEdad.value
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlProgramasAcademicos" class='pregunta'>Programa académico en el que se
                                encuentra matriculado <b class="text-danger">*</b></label> <br>
                            <select id="ddlProgramasAcademicos" class="form-select  "
                                v-model.trim="datosBasicos.programaAcademico" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(programaAcademico, index) of programasAcademicos" :key="index">{{
                                    programaAcademico.value
                                }}</option>
                            </select>
                        </div>
                        <div v-if="datosBasicos.programaAcademico === 'Otro'" class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtroPrograma" class='pregunta'>Otro, ¿Cuál? <b
                                    class="text-danger">*</b></label> <br>
                            <textarea
                                :oninput="() => (datosBasicos.programaAcademicoEspecificar = datosBasicos.programaAcademicoEspecificar.toUpperCase())"
                                class="form-control" id="txtOtroPrograma"
                                v-model.trim="datosBasicos.programaAcademicoEspecificar"></textarea>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlGenero" class='pregunta'>De acuerdo con su identidad de género, ¿Usted se
                                considera? <b class="text-danger">*</b></label> <br>
                            <select id="ddlGenero" class="form-select  " v-model.trim="datosBasicos.genero"
                                aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(genero, index) of generos" :key="index">{{
                                    genero.value
                                }}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlURegion" class='pregunta'>¿Perteneces a uno de los grupos de la U en mi
                                Región?<b class="text-danger">*</b></label> <br>
                            <select id="ddlURegion" class="form-select  " v-model.trim="datosBasicos.URegion"
                                aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(region, index) of regiones" :key="index">{{
                                    region.value
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlEstrato" class='pregunta'>Según la factura de servicios públicos, la vivienda
                                en
                                la que vives es de estrato socioeconómico: <b class="text-danger">*</b></label> <br>
                            <select id="ddlEstrato" class="form-select  "
                                v-model.trim="datosBasicos.estratoSocioeconomico" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(estrato, index) of estratos" :key="index">{{
                                    estrato.value
                                }}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlDiscapacidad" class='pregunta'>¿Posees alguna condición de discapacidad?
                                Resolución 113 de
                                2020
                                expedida por el Ministerio de Salud <b class="text-danger">*</b></label> <br>
                            <select id="ddlDiscapacidad" class="form-select  pregunta"
                                v-model.trim="datosBasicos.condicionDiscapacidad" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(discapacidad, index) of discapacidades" :key="index">{{
                                    discapacidad.value
                                }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="container mt-3 mb-3 pb-3">
                    <b class='titulo2'>Acceso a la tecnologia personales.</b>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="equipo" class='pregunta'>¿Cuentas con algún equipo informático propio para
                                acceder a la
                                plataforma de
                                estudio?<b class="text-danger">*</b></label> <br>
                            <label class='p-1'>
                                <input type="radio" name="equipo" value="Sí" class='form-check-input'
                                    v-model="accesoTecnologia.equipoPropio" />
                                Sí
                            </label>
                            <label class='p-1'>
                                <input type="radio" name="equipo" value="No" class='form-check-input'
                                    v-model="accesoTecnologia.equipoPropio" />
                                No
                            </label>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlInternet" class='pregunta'>Cuentas con conexión a internet:<b
                                    class="text-danger">*</b></label> <br>
                            <select id="ddlInternet" class="form-select  "
                                v-model.trim="accesoTecnologia.conexionInternet" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(con, index) of conexion" :key="index">{{
                                    con.value
                                    }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="equipoTecnologico" class='pregunta'>
                                ¿Con cuáles de los siguientes recursos tecnológicos cuentas para estudiar en modalidad
                                virtual? <b class="text-danger">*</b>
                            </label>
                            <div id="equipoTecnologico">
                                <div v-for="(equipo, index) in equipos" :key="index" class="form-check">
                                    <input type="checkbox" class="form-check-input" :id="`equipo-${equipo.value}`"
                                        :value="equipo.value" v-model="accesoTecnologia.tipoEquipoPropio">
                                    <label :for="`equipo-${equipo.value}`" class="form-check-label">
                                        {{ equipo.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="accesoTecnologia.tipoEquipoPropio.includes('Otro')"
                            class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtroEquipo" class='pregunta'>Otro, ¿Cuál? <b
                                    class="text-danger">*</b></label> <br>
                            <textarea
                                :oninput="() => (accesoTecnologia.equipoPropioOtroEspecificar = accesoTecnologia.equipoPropioOtroEspecificar.toUpperCase())"
                                class="form-control" type="text" id="txtOtroEquipo"
                                v-model.trim="accesoTecnologia.equipoPropioOtroEspecificar"></textarea>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="recursoTecnologico" class='pregunta'>
                                ¿Cuál sería el recurso tecnológico para estudiar en modalidad virtual?<b
                                    class="text-danger">*</b>
                            </label>
                            <div id="recursoTecnologico">
                                <div v-for="(recurso, index) in recursos" :key="index" class="form-check">
                                    <input type="checkbox" class="form-check-input" :id="`recurso-${recurso.value}`"
                                        :value="recurso.value" v-model="accesoTecnologia.recursoTecnologicoEstudio">
                                    <label :for="`recurso-${recurso.value}`" class="form-check-label">
                                        {{ recurso.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="camara" class='pregunta'>¿Tu equipo cuenta con cámara de videoconferencia?<b
                                    class="text-danger">*</b></label> <br>
                            <div class="d-flex flex-column">
                                <label class='p-1'>
                                    <input type="radio" name="camara" value="Sí" class='form-check-input'
                                        v-model="accesoTecnologia.camaraDisponible" />
                                    Sí
                                </label>
                                <label class='p-1'>
                                    <input type="radio" name="camara" value="No" class='form-check-input'
                                        v-model="accesoTecnologia.camaraDisponible" />
                                    No (utilizo una cámara externa)
                                </label>
                            </div>

                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="conexionMediante" class='pregunta'>
                                Cuentas con conexión a internet a través de: <b class="text-danger">*</b>
                            </label>
                            <div id="conexionMediante">
                                <div v-for="(conexion, index) in conexiones" :key="index" class="form-check">
                                    <input type="checkbox" class="form-check-input" :id="`conexion-${conexion.value}`"
                                        :value="conexion.value" v-model="accesoTecnologia.tipoConexionInternet">
                                    <label :for="`conexion-${conexion.value}`" class="form-check-label">
                                        {{ conexion.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="accesoTecnologia.tipoConexionInternet.includes('Otro')"
                            class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtraConexion" class='pregunta'>Otro, ¿Cuál? <b
                                    class="text-danger">*</b></label> <br>
                            <textarea
                                :oninput="() => (accesoTecnologia.tipoConexionInternetEspecificar = accesoTecnologia.tipoConexionInternetEspecificar.toUpperCase())"
                                class="form-control" type="text" id="txtOtraConexion"
                                v-model.trim="accesoTecnologia.tipoConexionInternetEspecificar"></textarea>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="lugarEstudio" class='pregunta'>
                                En el lugar donde eliges estudiar cuentas con: <b class="text-danger">*</b>
                            </label>
                            <div id="lugarEstudio">
                                <div v-for="(estudio, index) in lugarEstudio" :key="index" class="form-check">
                                    <input type="checkbox" class="form-check-input"
                                        :id="`lugarEstudio-${estudio.value}`" :value="estudio.value"
                                        v-model="accesoTecnologia.condicionesEstudio">
                                    <label :for="`lugarEstudio-${estudio.value}`" class="form-check-label">
                                        {{ estudio.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="accesoTecnologia.condicionesEstudio.includes('Otro')"
                            class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtraLugar" class='pregunta'>Otro, ¿Cuál? <b class="text-danger">*</b></label>
                            <br>
                            <textarea
                                :oninput="() => (accesoTecnologia.condicionesEstudioEspecificar = accesoTecnologia.condicionesEstudioEspecificar.toUpperCase())"
                                class="form-control" type="text" id="txtOtraLugar"
                                v-model.trim="accesoTecnologia.condicionesEstudioEspecificar"></textarea>
                        </div>
                    </div>
                </div>

                <div class="container mt-3 mb-3 pb-3">
                    <b class='titulo2'>Uso de la tecnología.</b>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlUsoInternet" class='pregunta'>¿Con qué frecuencia usas el internet?<b
                                    class="text-danger">*</b></label> <br>
                            <select id="ddlUsoInternet" class="form-select  "
                                v-model.trim="usoTecnologia.frecuenciaUsoInternet" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(frec, index) of frecuencia" :key="index">{{
                                    frec.value
                                    }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-12 mb-lg-0 mb-3">
                            <label for="" class='pregunta'>¿Con qué frecuencia usas el internet para estos fines?<b
                                    class="text-danger">*</b></label> <br>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover">
                                    <thead class="thead-dark">
                                        <tr>
                                            <td scope="col" class='text-center fw-bold bg-light'>Actividad</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Nunca</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Casi nunca</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Al menos una vez al mes
                                            </td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Al menos una vez a la
                                                semana</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Diariamente menos de 5
                                                horas</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Diariamente más de 5
                                                horas</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(actividad, key) in actividadesLabels" :key="key">
                                            <td>{{ actividad }}</td>
                                            <td v-for="opcion in opciones" :key="opcion">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" :name="key"
                                                        :value="opcion"
                                                        v-model="usoTecnologia.actividadesInternet[key]" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12 mb-lg-0 mb-3">
                            <label for="txtOtroEquipo" class='pregunta'>Otro, ¿Cuál? </label> <br>
                            <textarea
                                :oninput="() => (usoTecnologia.actividadesInternet.otro_actividad = usoTecnologia.actividadesInternet.otro_actividad.toUpperCase())"
                                class="form-control" type="text" id="txtOtroEquipo"
                                v-model.trim="usoTecnologia.actividadesInternet.otro_actividad"></textarea>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-12 mb-lg-0 mb-3">
                            <label for="" class='pregunta'>¿Con qué frecuencia has utilizado las siguientes herramientas
                                digitales?<b class="text-danger">*</b></label> <br>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover">
                                    <thead class="thead-dark">
                                        <tr>
                                            <td scope="col" class='text-center fw-bold bg-light'>Actividad</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Nunca</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Casi nunca</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Al menos una vez al mes
                                            </td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Al menos una vez a la
                                                semana</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Diariamente menos de 5
                                                horas</td>
                                            <td scope="col" class='text-center fw-bold bg-light'>Diariamente más de 5
                                                horas</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(actividad, key) in actividades2Labels" :key="key">
                                            <td>{{ actividad }}</td>
                                            <td v-for="opcion in opciones" :key="opcion">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" :name="key"
                                                        :value="opcion"
                                                        v-model="usoTecnologia.frecuenciaHerramientasDigitales[key]" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-3 mb-3 pb-3">
                    <b class='titulo2'>Apropiación de la tecnología.</b>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddlNivel" class='pregunta'>¿Cómo te describirías en cuanto al nivel de
                                conocimiento sobre el
                                uso de tecnologías?<b class="text-danger">*</b></label> <br>
                            <select id="ddlNivel" class="form-select  "
                                v-model.trim="apropiacionTecnologia.nivelConocimientoTecnologia"
                                aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(nivel, index) of niveles" :key="index">{{
                                    nivel.value
                                    }}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="utilidad" class='pregunta'>¿Qué tan útil te parece la tecnología?<b
                                    class="text-danger">*</b></label> <br>
                            <div class="d-flex flex-column">
                                <label class='p-1' v-for="(utilidad, index) of utilidades" :key="index">
                                    <input type="radio" name="utilidad" :value="utilidad.value" class='form-check-input'
                                        v-model="apropiacionTecnologia.percepcionUtilidadTecnologia" />
                                    {{ utilidad.value }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="confiable" class='pregunta'>¿Qué tan confiable consideras que es la
                                tecnología?<b class="text-danger">*</b></label> <br>
                            <div class="d-flex flex-column">
                                <label class='p-1' v-for="(confiable, index) of confiabilidad" :key="index">
                                    <input type="radio" name="confiable" :value="confiable.value"
                                        class='form-check-input'
                                        v-model="apropiacionTecnologia.confiabilidadTecnologia" />
                                    {{ confiable.value }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="capacidad" class='pregunta'>
                                En este momento usted es capaz de:<b class="text-danger">*</b>
                            </label>
                            <div id="capacidad">
                                <div v-for="(capacidad, index) in capacidades" :key="index" class="form-check">
                                    <input type="checkbox" class="form-check-input" :id="`capacidad-${capacidad.value}`"
                                        :value="capacidad.value"
                                        v-model="apropiacionTecnologia.capacidadesTecnologicas">
                                    <label :for="`capacidad-${capacidad.value}`" class="form-check-label">
                                        {{ capacidad.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="apropiacionTecnologia.capacidadesTecnologicas.includes('Otro')"
                            class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtroCApacidad" class='pregunta'>Otro, ¿Cuál? <b
                                    class="text-danger">*</b></label> <br>
                            <textarea
                                :oninput="() => (apropiacionTecnologia.otrasCapacidadesEspecificar = apropiacionTecnologia.otrasCapacidadesEspecificar.toUpperCase())"
                                class="form-control" type="text" id="txtOtroCApacidad"
                                v-model.trim="apropiacionTecnologia.otrasCapacidadesEspecificar"></textarea>
                        </div>
                    </div>
                </div>

                <div class="container mt-3 mb-3 pb-3">
                    <b class='titulo2'>Hábitos de estudio.</b>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddltiempoSinEstudio" class='pregunta'>¿Hace cuánto tiempo no estudias?<b
                                    class="text-danger">*</b></label> <br>
                            <select id="ddltiempoSinEstudio" class="form-select  "
                                v-model.trim="habitosEstudio.tiempoSinEstudiar" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(tiempo, index) of tiempos" :key="index">{{
                                    tiempo.value
                                }}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="ddltiempoDedicacion" class='pregunta'>Con respecto al tiempo de dedicación al
                                estudio, tienes:¿<b class="text-danger">*</b></label> <br>
                            <select id="ddltiempoDedicacion" class="form-select  "
                                v-model.trim="habitosEstudio.dedicacionEstudio" aria-label=".form-select-lg example">
                                <option selected value="">Seleccione una opción</option>
                                <option v-for="(tiempo, index) of TiempoDedicacion" :key="index">{{
                                    tiempo.value
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="eleccion" class='pregunta'>
                                Elegiste educación virtual por:<b class="text-danger">*</b>
                            </label>
                            <div id="eleccion">
                                <div v-for="(eleccion, index) in motivoEleccion" :key="index" class="form-check">
                                    <input type="checkbox" class="form-check-input" :id="`eleccion-${eleccion.value}`"
                                        :value="eleccion.value" v-model="habitosEstudio.motivoEducacionVirtual">
                                    <label :for="`eleccion-${eleccion.value}`" class="form-check-label">
                                        {{ eleccion.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="habitosEstudio.motivoEducacionVirtual.includes('Otro')"
                            class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtroMotivo" class='pregunta'>Otro, ¿Cuál? <b
                                    class="text-danger">*</b></label> <br>
                            <textarea
                                :oninput="() => (habitosEstudio.motivoEspecificar = habitosEstudio.motivoEspecificar.toUpperCase())"
                                class="form-control" type="text" id="txtOtroMotivo"
                                v-model.trim="habitosEstudio.motivoEspecificar"></textarea>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="preferencia" class='pregunta'>
                                Elegiste educación virtual por:<b class="text-danger">*</b>
                            </label>
                            <div id="preferencia">
                                <div v-for="(preferencia, index) in preferenciasEstudio" :key="index"
                                    class="form-check">
                                    <input type="checkbox" class="form-check-input"
                                        :id="`preferencia-${preferencia.value}`" :value="preferencia.value"
                                        v-model="habitosEstudio.preferenciaEstudio">
                                    <label :for="`preferencia-${preferencia.value}`" class="form-check-label">
                                        {{ preferencia.value }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="habitosEstudio.preferenciaEstudio.includes('Otro')"
                            class="col-lg-6 col-12 mb-lg-0 mb-3">
                            <label for="txtOtroPreferencia" class='pregunta'>Otro, ¿Cuál? <b
                                    class="text-danger">*</b></label> <br>
                            <textarea
                                :oninput="() => (habitosEstudio.preferenciaEspecificar = habitosEstudio.preferenciaEspecificar.toUpperCase())"
                                class="form-control" type="text" id="txtOtroPreferencia"
                                v-model.trim="habitosEstudio.preferenciaEspecificar"></textarea>
                        </div>
                    </div>

                    <div class="row mt-lg-3 mt-0">
                        <div class="col-12">
                            <div v-if="faltantes" class="alert alert-danger" role="alert">
                                <b>Todos los campos son de carácter obligatorio</b>
                            </div>
                        </div>
                        <div class="col-12">
                            <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                                <b>{{ mensajeError }}</b>
                            </div>
                        </div>
                        <div class="col-12">
                            <div v-if="errorRespuestaAPI" class="alert alert-danger" role="alert">
                                <b>{{ mensajeErrorRespuestaAPI }}</b>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="text-center" v-if="isLoading"><b>Enviando información, por favor espere...</b></p>
                            <div class="d-flex justify-content-center" v-if="isLoading">
                                <pulse-loader :loading="isLoading" :color="color" :size="size"></pulse-loader>
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-enviar d-flex mx-auto" @click="enviarEncuesta">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="datosBasicos.autorizacionTratamientoDatos === 'No'" class='d-flex justify-content-center'>
                <p class="alert alert-danger">No podemos continuar si no acepta el tratamiento de datos.</p>
            </div>

        </div>
    </div>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert';
import { validarEmail, verificarEmail } from '../../../helpers/functions';
import { useGeneralStore } from '../../../stores/generales';

export default {
    name: 'formulario encuesta de caracterizacion digital',

    data() {
        return {
            generalStore: useGeneralStore(),
            mensajeErrorRespuestaAPI: null,
            errorRespuestaAPI: false,
            isLoading: false,
            faltantes: false,
            mensajeError: null,

            //Estrucura para enviar 
            datosBasicos: {
                autorizacionTratamientoDatos: '',
                nombreCompleto: '',
                tipoDocumento: '',
                numeroDocumento: '',
                telefonoContacto: '',
                correoElectronico: '',
                edad: '',
                programaAcademico: '',
                programaAcademicoEspecificar: null,
                genero: '',
                estratoSocioeconomico: '',
                URegion: '',
                condicionDiscapacidad: '',
                nivelCriticidad: null
            },
            accesoTecnologia: {
                equipoPropio: '',
                conexionInternet: '',
                tipoEquipoPropio: [],
                equipoPropioOtroEspecificar: null,
                recursoTecnologicoEstudio: [],
                camaraDisponible: '',
                tipoConexionInternet: [],
                tipoConexionInternetEspecificar: null,
                condicionesEstudio: [],
                condicionesEstudioEspecificar: null,
                nivelCriticidad: null
            },
            usoTecnologia: {
                frecuenciaUsoInternet: '',
                actividadesInternet: {
                    comunicacion: '',
                    entretenimiento: '',
                    tramites_administrativos: '',
                    busqueda_informacion: '',
                    trabajo_colaborativo: '',
                    educacion: '',
                    lectura: '',
                    publicacion_opiniones: '',
                    ventas_compras: '',
                    otro_actividad: null
                },
                frecuenciaHerramientasDigitales: {
                    correo_electronico: '',
                    biblioteca_digital: '',
                    plataformas_educativas: '',
                    suite_ofimatica: '',
                    almacenamiento_nube: '',
                    videoconferencia: '',
                    motores_busqueda: '',
                    office_365: '',
                    aplicaciones_graficos: '',
                    youtube: '',
                    otro_herramienta: null
                },
                nivelCriticidad: null
            },
            apropiacionTecnologia: {
                nivelConocimientoTecnologia: '',
                percepcionUtilidadTecnologia: '',
                confiabilidadTecnologia: '',
                capacidadesTecnologicas: [],
                otrasCapacidadesEspecificar: null,
                nivelCriticidad: null
            },
            habitosEstudio: {
                tiempoSinEstudiar: '',
                motivoEducacionVirtual: [],
                motivoEspecificar: null,
                preferenciaEstudio: [],
                preferenciaEspecificar: null,
                dedicacionEstudio: '',
                nivelCriticidad: null
            },

            //Selects
            tiposDocumento: [
                { value: 'Cédula de ciudadanía' },
                { value: 'Tarjeta de identidad' },
                { value: 'Cédula de extranjería o identificación de extranjería' },
                { value: 'Permiso de permanencia' },
                { value: 'Pasaporte' },
            ],

            rangoEdades: [
                { value: '15 - 25 años' },
                { value: '26 - 35 años' },
                { value: '36 -  45 años' },
                { value: 'Más de 45 años' },
            ],

            programasAcademicos: [
                { value: 'Tecnología en Gestión Administrativa' },
                { value: 'Tecnología en Análisis de Costos y Presupuesto' },
                { value: 'Tecnología en Desarrollo de Aplicaciones para Dispositivos Móviles - VIRTUAL' },
                { value: 'Tecnología en Diseño y Programación de Soluciones de Software como Servicio – SaaS' },
                { value: 'Tecnología en Gestión Comercial' },
                { value: 'Interpretación y Traducción Lengua de Señas Colombiana - español' },
                { value: 'Maestría en Estudios de Ciencia, Tecnología, Sociedad e Innovación' },
                { value: 'Especialización en Ciberseguridad' },
                { value: 'Especialización en Ingeniería de Software' },
                { value: 'Especialización en Gestión del Mantenimiento Industrial' },
                { value: 'Especialización en Infraestructura hospitalaria' },
                { value: 'Otro' },
            ],

            generos: [
                { value: 'Femenino' },
                { value: 'Masculino' },
                { value: 'No binario' },
                { value: 'Transgénero' },
                { value: 'Otra identidad / ninguna de las anteriores' },
                { value: 'Prefiero no responder' },
            ],

            estratos: [
                { value: '1', text: 'Uno (1)' },
                { value: '2', text: 'Dos (2)' },
                { value: '3', text: 'Tres (3)' },
                { value: '4', text: 'Cuatro (4)' },
                { value: '5', text: 'Cinco (5)' },
            ],

            regiones: [
                { value: 'Maceo' },
                { value: 'Bello' },
                { value: 'Briceño' },
                { value: 'Belmira' },
                { value: 'Gómez Plata' },
                { value: 'Carolina del Príncipe' },
                { value: 'El Peñol' },
                { value: 'Betania' },
                { value: 'Hispania' },
                { value: 'Andes' },
                { value: 'Jardín ' },
                { value: 'Tarso ' },
                { value: 'Ninguna' },
            ],

            discapacidades: [
                { value: 'Discapacidad física' },
                { value: 'Discapacidad auditiva' },
                { value: 'Sordoceguera' },
                { value: 'Discapacidad intelectual' },
                { value: 'Discapacidad psicosocial (mental)' },
                { value: 'Discapacidad múltiple' },
                { value: 'Discapacidad visual' },
                { value: 'Ninguna' },
            ],

            conexion: [
                { value: 'Todos los días a todas las horas' },
                { value: 'Solo en las noches o en las mañanas' },
                { value: 'Solo los fines de semana' },
                { value: 'Solo durante la semana' },
                { value: 'No cuento con internet' },
            ],

            equipos: [
                { value: 'Computador de escritorio' },
                { value: 'Computador portátil' },
                { value: 'Teléfono inteligente' },
                { value: 'Tablet' },
                { value: 'Televisor inteligente' },
                { value: 'Otro' },
            ],

            recursos: [
                { value: 'En la casa de un familiar o amigo' },
                { value: 'En un café internet' },
                { value: 'En las salas de cómputo de las sedes del ITM' },
                { value: 'En mi lugar de trabajo' },
                { value: 'Todavía no sé cómo voy a estudiar' },
            ],

            conexiones: [
                { value: 'Plan de internet fijo hogar' },
                { value: 'Plan de datos para teléfono móvil ' },
                { value: 'Módem con plan de internet' },
                { value: 'Wifi público o de otra persona' },
                { value: 'Ninguno' },
                { value: 'Otro' },
            ],

            lugarEstudio: [
                { value: 'Escritorio' },
                { value: 'Mesa adaptada' },
                { value: 'Silla de escritorio' },
                { value: 'Silla estática' },
                { value: 'Iluminación específica' },
                { value: 'Iluminación del hogar' },
                { value: 'Ventilación' },
                { value: 'Luz Natural' },
                { value: 'Habitación de estudio' },
                { value: 'Ninguna' },
                { value: 'Otro' },
            ],

            frecuencia: [
                { value: 'Casi nunca uso internet' },
                { value: 'Al menos una vez al mes' },
                { value: 'Al menos una vez a la semana' },
                { value: 'Diariamente menos de 5 horas ' },
                { value: 'Diariamente más de 5 horas ' },
            ],

            actividadesLabels: {
                comunicacion: "Comunicarse con otras personas (chats, videollamadas)",
                entretenimiento: "Entretenimiento, noticias, música, videos, juegos, redes sociales",
                tramites_administrativos: "Trámites administrativos, citas, transferencias bancarias",
                busqueda_informacion: "Buscar información",
                trabajo_colaborativo: "Trabajar de manera colaborativa",
                educacion: "Formación, capacitación, educación",
                lectura: "Lectura de libros",
                publicacion_opiniones: "Emitir opiniones, publicar información en redes",
                ventas_compras: "Comprar, vender o alquilar productos y servicios"
            },

            actividades2Labels: {
                correo_electronico: 'Correo Electrónico ',
                biblioteca_digital: 'Biblioteca Digital',
                plataformas_educativas: 'Plataformas educativas como: Platzi, Coursera, Doméstika, etc.',
                suite_ofimatica: 'Word, Excel o Power Point',
                almacenamiento_nube: 'OneDrive o DropBox',
                videoconferencia: 'Zoom, Meet o Teams',
                motores_busqueda: 'Google Académico o Motores de Búsqueda',
                office_365: 'Office 365',
                aplicaciones_graficos: 'Aplicativos específicos para mapas, tablas y gráficos',
                youtube: 'YouTube'
            },

            opciones: [
                "Nunca",
                "Casi nunca",
                "Al menos una vez al mes",
                "Al menos una vez a la semana",
                "Diariamente menos de 5 horas",
                "Diariamente más de 5 horas"
            ],

            niveles: [
                { value: 'No tengo ningún conocimiento' },
                { value: 'Tengo conocimiento básico' },
                { value: 'Soy principiante' },
                { value: 'Tengo conocimiento intermedio' },
                { value: 'Tengo conocimiento avanzado' },
                { value: 'Soy experto' },
            ],

            utilidades: [
                { value: 'Nos facilita la vida ' },
                { value: 'Nos dificulta la vida ' },
                { value: 'Soy principiante' },
                { value: 'Ha traído beneficios para la sociedad' },
                { value: 'Impide el desarrollo social ' },
            ],

            confiabilidad: [
                { value: 'No confío en la tecnología, puede ocasionar daños graves' },
                { value: 'Confío plenamente en la tecnología, es útil para el desarrollo social ' },
                { value: 'Confío en la tecnología si es usada con ética por los hombres' },
            ],

            capacidades: [
                { value: 'Enviar, buscar, filtrar correos electrónicos' },
                { value: 'Hacer búsqueda, selección y evaluación de información en Google' },
                { value: 'Gestionar documentos escritos' },
                { value: 'Gestionar varios formatos de archivo .doc, .exe, .pptx, .pdf, .jpg' },
                { value: 'Hacer presentaciones estáticas' },
                { value: 'Descargar e instalar aplicaciones en equipos de cómputo y teléfonos celulares' },
                { value: 'Gestionar archivos en la nube' },
                { value: 'Gestionar los diferentes usuarios y contraseñas de tus aplicativos' },
                { value: 'Programar y ser anfitrión de una videoconferencia en línea' },
                { value: 'Grabar y compartir audios' },
                { value: 'Grabar y compartir videos' },
                { value: 'Participar en chats, foros y debates en línea' },
                { value: 'Hacer presentaciones animadas' },
                { value: 'Diseñar mapas conceptuales' },
                { value: 'Diseñar infografías' },
                { value: 'Editar audio y videos' },
                { value: 'Crear cuestionarios/encuestas en línea' },
                { value: 'Hacer consultas a chats de inteligencia artificial' },
                { value: 'Utilizar aplicativos de inteligencia artificial para diseñar, planificar o predecir asuntos de su interés' },
                { value: 'Ninguno' },
                { value: 'Otro' },
            ],

            tiempos: [
                { value: 'Menos de un año' },
                { value: 'De 1 a 3 años' },
                { value: 'De 4 a 9 años ' },
                { value: 'Más de 10 años' },
            ],

            TiempoDedicacion: [
                { value: 'Más de 5 horas diarias' },
                { value: 'Entre 2 y 4 horas diarias' },
                { value: 'Entre 5 y 10 horas a la semana' },
                { value: 'Entre 10 y 15 horas el fin de semana' },
                { value: 'Menos de 4 horas el fin de semana' },
            ],

            motivoEleccion: [
                { value: 'El programa sólo está en modalidad virtual' },
                { value: 'Flexibilidad en los horarios' },
                { value: 'Autonomía en el tiempo de aprendizaje' },
                { value: 'Me gusta la modalidad virtual' },
                { value: 'Compatibilidad con los horarios laborales' },
                { value: 'Para evitar la movilidad a una sede física' },
                { value: 'Trabajo desde casa y es más cómodo estudiar virtual' },
                { value: 'Madre/padre cabeza de familia' },
                { value: 'Desempleado' },
                { value: 'Motivos económicos' },
                { value: 'Otro' },
            ],

            preferenciasEstudio: [
                { value: 'Leer a solas' },
                { value: 'Que alguien te lea' },
                { value: 'Escuchar audios' },
                { value: 'Ver videos' },
                { value: 'Ver a tu profesor en videos' },
                { value: 'Participar en videoconferencias' },
                { value: 'Conversar en línea con tu profesor y compañeros' },
                { value: 'Dejar mensajes y contestar más tarde' },
                { value: 'Otro' },
            ],
        };
    },

    methods: {
        enviarEncuesta: function () {
            this.faltantes = false;
            this.mensajeError = null;

            const camposVacios = [
                this.datosBasicos,
                this.accesoTecnologia,
                this.usoTecnologia,
                this.apropiacionTecnologia,
                this.habitosEstudio
            ];
            const tieneCamposVacios = (obj) => {
                return Object.values(obj).some(value => value === '');
            };
            for (let obj of camposVacios) {
                if (tieneCamposVacios(obj)) {
                    this.faltantes = true;
                    return;
                }
            }

            const validEmail = validarEmail(this.datosBasicos.correoElectronico);
            if (!validEmail) return this.mensajeError = `El correo electrónico debe ser un correo válido.`;

            sweetAlertConfirm(
                '¿Desea enviar la información diligenciada?',
                'Los datos diligenciados se enviarán a la base de datos.',
                'warning',
                'Enviar'
            ).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;

                    const datos = {
                        datosBasicos: this.datosBasicos,
                        accesoTecnologia: this.accesoTecnologia,
                        usoTecnologia: this.usoTecnologia,
                        apropiacionTecnologia: this.apropiacionTecnologia,
                        habitosEstudio: this.habitosEstudio
                    }

                    const encuestaCreada = await this.generalStore.crearEncuestaCaracterizacionDigital(datos);

                    if (encuestaCreada.error) {
                        this.isLoading = false;
                        this.errorRespuestaAPI = true;
                        this.mensajeErrorRespuestaAPI = encuestaCreada.message;
                    } else {
                        this.isLoading = false;
                        sweetAlertSuccess(
                            '¡Datos enviados!',
                            'Los datos diligenciados se han enviado de manera exitosa',
                            'success'
                        ).then(() => {
                            location.reload();
                        });
                    }
                } else (
                    result.dismiss === Swal.DismissReason.cancel
                );
            });
        }
    },

    mounted() {
        document.title = this.$route.meta.title;
    },

    components: {
        PulseLoader
    },
};
</script>

<style>
.pregunta {
    font-size: 17px;
    font-weight: 501;
}

.titulo2 {
    font-size: 26px;
    font-weight: 700;
}
</style>