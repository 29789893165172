<template>
    <div class="container-fluid backloguin">
        <div class="container">
            <div class="row mt-lg-0 mt-4">
                <div class="col-lg-4 col-md-6 col-12 mb-4" v-for="role in roles" :key="role">                
                    <div class="indexuser card text-white card-has-bg click-col ImgCard01User animated fadeInUp timeanime1">
                        <img v-if="role === 'student'" class="card-img" src="../../../public/img/Estudiante.jpg" alt="Imagen Estudiante">
                        <img v-if="role === 'editingteacher'" class="card-img" src="../../../public/img/Docente.jpg" alt="Imagen Docente">
                        <img v-if="role === 'manager'" class="card-img" src="../../../public/img/Gestor.jpg" alt="Imagen Estudiante">
                        


                        <div class="card-img-overlay d-flex flex-column">
                            <div class="card-body"></div>
                            <div class="card-footer">
                                <div class="media">
                                    <div class="d-flex justify-content-center"><img class="mr-3 rounded-circle img-fluid" src="../../../public/img/UserIconSelect.png" alt="Foto Estudiante"></div>                    
                                    <div class="media-body">
                                        <p class="my-0 text-white d-block text-center">Ingresar como</p>
                                        <div class="d-flex justify-content-center">
                                            <a href="#" class="btnPerfiles mt-2" @click="seteaRoleActualyRedirecciona(role)">
                                                {{ 
                                                    role === 'manager' ? 'Gestor' : 
                                                    role === 'editingteacher' ? 'Docente' : 
                                                    role === 'student' ? 'Estudiante' :
                                                    'Sin role'
                                                }}
                                            </a>
                                        </div>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useAuthStore } from '@/stores/auth';
    import { pathsLogin } from '@/commons/pathsLogin';

    export default {
        name: "Inicio",
        data () {
            return {
                authStore: null,
                userInfo: {},
                roles: []
            }
        },
        methods: {
            seteaRoleActualyRedirecciona: function(role) {
                this.authStore.setCurrentRole(role);
                this.$router.push({path: pathsLogin[role]});
            }   
        },
        mounted () {
            this.authStore = useAuthStore();
            this.userInfo = this.authStore.userDetail;
            const { roles } = this.userInfo;
            this.roles = roles;

            //Si el role es solo 1, setea el store con ese role
            if(this.roles.length === 1) this.authStore.setCurrentRole(this.roles[0]);
       
            document.title = this.$route.meta.title;
        }
    }
</script>