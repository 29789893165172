import { defineStore } from "pinia";
import axios from "axios";
import microservice from "@/services/axiosConectionMicroservice";

export const useAdministrationStore = defineStore('admin', {
    state: () => ({
        backupCategorySectionSelectedOptions: {},
        backupCourseSectionSelectedOptions: {},
        backupSelectedCategoriesWithOwnCourses: {}
    }),

    getters: {
        selectedOptionsCategoryBackupSection: 
             state => Object.keys(state.backupCategorySectionSelectedOptions).length !== 0 ? state.backupCategorySectionSelectedOptions : {},
        selectedOptionsCourseBackupSection: 
            state => Object.keys(state.backupCourseSectionSelectedOptions).length !== 0 ? state.backupCourseSectionSelectedOptions : {},
        selectedCategoriesWithOwnCourses: state => Object.keys(state.backupSelectedCategoriesWithOwnCourses).length !== 0 ? state.backupSelectedCategoriesWithOwnCourses : {},
    },

    actions: {
        async addOrUpdateBackupCategorySectionOption(options) {
            try {
                const { serverName, categoryId, categoryName, backupType } = options;

                const categoryBackupSection = this.backupCategorySectionSelectedOptions;
                const categoryBackupSectionkeys = Object.keys(categoryBackupSection);

                if(categoryBackupSectionkeys.length === 0 || !categoryBackupSectionkeys.includes(serverName) || categoryBackupSection[serverName].length === 0) 
                    {
                    categoryBackupSection[serverName] = [];
                    categoryBackupSection[serverName] = [{ categoryId, categoryName, backupType }]
                } else {
                    const existingCategoryIndex = categoryBackupSection[serverName].findIndex(category => category.categoryId === categoryId);

                    if(existingCategoryIndex !== -1) {
                         categoryBackupSection[serverName][existingCategoryIndex].backupType = backupType;
                    } else {
                         categoryBackupSection[serverName] = [ ...categoryBackupSection[serverName], { categoryId, categoryName, backupType }];
                    }
                }
                   
            } catch (error) {
                throw error.message
            }
        },

        async deleteBackupCategorySectionOption (options) {
            try {
                const { serverName, categoryId } = options;

                let categoryBackupSection = this.backupCategorySectionSelectedOptions;

                if(categoryBackupSection[serverName]) {
                    categoryBackupSection = {
                        ...categoryBackupSection,
                        [serverName]: categoryBackupSection[serverName].filter(category => category.categoryId !== categoryId)
                    }

                    if (categoryBackupSection[serverName].length === 0) {
                      delete categoryBackupSection[serverName];
                    }
                }

                this.backupCategorySectionSelectedOptions = categoryBackupSection;

            } catch (error) {
                throw error.message;
            }
        },

        setSelectedCategoriesWithOwnCourses(SelectedCategoriesWithOwnCourses) {
            this.backupSelectedCategoriesWithOwnCourses = SelectedCategoriesWithOwnCourses;
            console.log(this.backupSelectedCategoriesWithOwnCourses)
        }

    },
    // persist: true,
})