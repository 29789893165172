import monolith from "@/services/axiosConectionMonolith";
import { defineStore } from "pinia";

export const useDocenteStore = defineStore('docente', {

    state: () => ({

    }),

    actions: {
        async createInduccionDocente(induccionDocente) {
            try {
                const asistenciaInduccionCreada = await monolith.post('/asistenciaInduccionDocente', induccionDocente, {
                    withCredentials: true
                });
                return asistenciaInduccionCreada.data;
            } catch (error) {
                throw error.message
            }
        },

        async createSolicitudAulaVirtual(solicitudAulaVirtual) {
            try {
                const solicitudAulaVirtualCreada = await monolith.post('/solicitudAulaVirtual', solicitudAulaVirtual, {
                    withCredentials: true
                });
                return solicitudAulaVirtualCreada.data;
            } catch (error) {
                throw error.message
            }
        }
    },
    persist: true,
})