<template>
    <div class="container pb-5">
      <div class="row">
          <div class="p-4 mt-4">
              <p>En esta sección usted podrá visualizar todos los registros realizados por los estudiantes mediante el formulario de inscripción al curso 
              <em><b>Herramientas básicas para estar en la virtualidad</b></em>.</p>
          </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-6 col-12">
          <label for="ddlSemestre">Seleccione semestre:</label>
          <select name="" class="form-control" id="ddlSemestre" v-model.trim="semestre">
            <option value="0" selected>Selecione una opción</option>
            <option value="2023-1">2023-1</option>
            <option value="2023-2">2023-2</option>
            <option value="2024-1">2024-1</option>
            <option value="2024-2">2024-2</option>
            <option value="2025-1">2025-1</option>
          </select>
        </div>
      </div>
      <div class="row" v-if="sinRegistros">
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            <b>No existen registros para el semestre {{ semestre }}</b>
          </div>
        </div>
      </div>
      <div class="row" v-if="!sinRegistros">
        <div class="col-12">
          <div v-if="infoInscripcionesHBV.length != 0">
              <DataTable v-if="infoInscripcionesHBV.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoInscripcionesHBV" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
          </div>
        </div>  
      </div> 
    </div>
  </template>
  <script>
  // @ is an alias to /src
  
  //Importando API que conecta front con back
  import API from '../../../api'
  import DataTable from '../../../components/DataTable'

  export default {
    name: 'infoInscripcionHBV',
  
    data() {
      return{
        semestre: 0,
        sinRegistros: false,
        btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail'],
        infoInscripcionesHBV: [],
        cabeceraDatatable: [
          {text: 'N°'},
          {text: 'Nombre estudiante'},
          {text: 'Cédula'},
          {text: 'Correo'},
          {text: 'Teléfono'},
          {text: 'Programa académico'},
          {text: 'Horario'},
          {text: 'Fecha registro'},
        ],
        tituloReporteDataTable: 'Reporte de inscripciones HBV'
      }
    },
    created() {
      // this.verInfo()
    },
     methods: {
   
          verInfo: async function(value) {
            this.sinRegistros = false;
            const infoInscripcionesHBV = await API.fetchInfoInscripcionesHBV({ semestre: value });
            if(infoInscripcionesHBV.length === 0) return this.sinRegistros = true;

            this.infoInscripcionesHBV = infoInscripcionesHBV.map(inscripcion => {
                return {
                  nombreApellido: inscripcion.nombreApellido,
                  numDocumento: inscripcion.numDocumento,
                  correo: inscripcion.correo,
                  telefono: inscripcion.telefono,
                  programaAcademico: inscripcion.programaAcademico,
                  horario: inscripcion.horario,
                  fechaRegistro: inscripcion.fechaRegistro.split('T')[0],
                  
                }
            })
          }
      },
      watch: {
        semestre (value) {
          this.verInfo(value)
        }
      },
      components: {
        DataTable
      },
      mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
      },
  }
  </script>
  