<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Banner_AsistenciaInduccionDocente.png" class="img-fluid d-flex mx-auto vw-100" alt=""> 
        </div>
        <div class="container mb-5">
            <div class="row">
                <div class="col-12 mt-3">
                    <h4 class="mt-3">Apreciado docente:</h4>
                    <p>En articulación con el Sistema de Formación para el Desarrollo Profesional Docente, la Unidad de Educación Virtual realiza, por semestre, 5 momentos de inducción docente, que le brindarán herramientas para asegurar el mejoramiento continuo e impartir educación superior de calidad, tal como la Institución lo tiene propuesto. Por esta razón, es esencial que usted, como uno de los actores principales de la educación en modalidad virtual del ITM, participe en todos los momentos de inducción programados y entre todos logremos este objetivo. Por lo anterior, lo invitamos a diligenciar el siguiente registro de acuerdo con los momentos a los que asistió.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtNombre">Nombre<b class="text-danger">*</b></label> <br>
                    <input :oninput="() => (asistenciaInduccion.nombreDocente = asistenciaInduccion.nombreDocente.toUpperCase())" class="form-control" type="text" id="txtNombre" v-model.trim="asistenciaInduccion.nombreDocente">
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtApellido">Apellido<b class="text-danger">*</b></label> <br>
                    <input :oninput="() => (asistenciaInduccion.apellidoDocente = asistenciaInduccion.apellidoDocente.toUpperCase())" class="form-control" type="text" id="txtApellido" v-model.trim="asistenciaInduccion.apellidoDocente">
                </div>
            </div>
            <div class="row mt-lg-3 mt-0">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtCorreo">Correo institucional docente <b class="text-danger">*</b></label> <br>
                    <input class="form-control" type="text" id="txtCorreo" v-model.trim="asistenciaInduccion.correoDocente">
                </div>
            </div>
            <hr class="my-lg-5 my-4">
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="ddlTipoModalidad">Modalidad <b class="text-danger">*</b></label> <br>
                    <select id="ddlTipoModalidad" class="form-select form-select-lg mb-3" v-model.trim="asistenciaInduccion.modalidad" aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(tipo, index) of tiposAsignatura" :key="index">{{tipo.text}}</option>
                    </select>
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtProgramaAcademico">Programa académico<b class="text-danger">*</b></label> <br>
                    <input :oninput="() => (asistenciaInduccion.programaAcademico = asistenciaInduccion.programaAcademico.toUpperCase())" class="form-control" type="text" id="txtProgramaAcademico" v-model.trim="asistenciaInduccion.programaAcademico">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtAsignatura">Asignatura<b class="text-danger">*</b></label> <br>
                    <input :oninput="() => (asistenciaInduccion.asignatura = asistenciaInduccion.asignatura.toUpperCase())" class="form-control" type="text" id="txtAsignatura" v-model.trim="asistenciaInduccion.asignatura">               
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="ddlFacultad">Facultad <b class="text-danger">*</b></label> <br>
                    <select id="ddlFacultad" class="form-select form-select-lg mb-3" v-model.trim="asistenciaInduccion.facultad" aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(facultad, index) of facultades" :key="index">{{facultad.text}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtMomentoInduccion">Momento de inducción al que asistió <b class="text-danger">*</b></label> <br>
                    <select id="ddlTipoDocente" class="form-select form-select-lg mb-3" v-model.trim="asistenciaInduccion.momentoInduccion" aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(momento, index) of momentosInduccion" :key="index">{{momento.text}}</option>
                    </select>
                    <!-- <input :oninput="() => (asistenciaInduccion.momentoInduccion = asistenciaInduccion.momentoInduccion.toUpperCase())" class="form-control" type="text" id="txtMomentoInduccion" v-model.trim="asistenciaInduccion.momentoInduccion">                -->
                </div>
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="ddlTipoDocente">Usted es un docente: <b class="text-danger">*</b></label> <br>
                    <select id="ddlTipoDocente" class="form-select form-select-lg mb-3" v-model.trim="asistenciaInduccion.tipoDocente" aria-label=".form-select-lg example">
                        <option selected value="">Seleccione una opción</option>
                        <option v-for="(tipo, index) of tiposDocente" :key="index">{{tipo.text}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-center" v-if="loading"><b>Enviando información, por favor espere...</b></p> 
                    <div class="d-flex justify-content-center" v-if="loading">
                        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    </div>
                    <div v-if="faltantes" class="alert alert-danger" role="alert">
                        <b>Todos los campos son de carácter obligatorio</b>
                    </div>
                    <div v-if="!esEmailValido" class="alert alert-danger" role="alert">
                        <b>El correo electrónico del docente debe ser el correo institucional <b>'ejemplo@correo.itm.edu.co'</b> ó <b>'ejemplo@itm.edu.co'</b></b>
                    </div>
                    <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
                        <b>{{mensajeError}}</b>
                    </div>
                    <button class="btn btn-enviar d-flex mx-auto mt-3" @click="crearRegistro">Realizar registro</button>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>

import { useDocenteStore } from '@/stores/docentes';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {sweetAlertConfirm, sweetAlertSuccess} from '../../../commons/SweetAlert'
import { obtenerFacultades, validarEmailInstitucional } from '../../../helpers/functions';

export default {
    name: 'formularioInduccionDocente',
    data () {
        return {
            docenteStore: null,
            loading: false,
            faltantes: false,
            esEmailValido: true,
            asistenciaInduccion: {
                nombreDocente: '',
                apellidoDocente: '',
                correoDocente: '',
                modalidad: '',
                programaAcademico: '',
                asignatura: '',
                facultad: '',
                momentoInduccion: '',
                tipoDocente: ''
            },
            tiposAsignatura: [
                {value: 'AMV', text: 'AMV'},            
                {value: 'APV', text: 'APV'},            
            ],
            tiposDocente: [
                {value: 'Antiguo', text: 'Antiguo'},            
                {value: 'Nuevo', text: 'Nuevo'},            
            ],
            momentosInduccion: [
                {value: 'Momento de inducción 1', text: 'Momento de inducción 1'},
                {value: 'Momento de inducción 2', text: 'Momento de inducción 2'},
                {value: 'Momento de inducción 3', text: 'Momento de inducción 3'},
                {value: 'Momento de inducción 4', text: 'Momento de inducción 4'},
                {value: 'Momento de inducción 5', text: 'Momento de inducción 5'},
                {value: 'Virtualidad saludable - Conferencia', text: 'Virtualidad saludable - Conferencia'},
                {value: 'La ética en la construcción de contenidos académicos - Taller', text: 'La ética en la construcción de contenidos académicos - Taller'},
                {value: 'Gamifica tu clase - Taller', text: 'Gamifica tu clase - Taller'},
                {value: 'Explora el libro de calificaciones - Taller', text: 'Explora el libro de calificaciones - Taller'},
                {value: 'Contenido interactivo - Taller', text: 'Contenido interactivo - Taller'},
                {value: 'Rúbricas efectivas para evaluar en la virtualidad - Taller', text: 'Rúbricas efectivas para evaluar en la virtualidad - Taller'},
                {value: 'Diseño de preguntas - Taller', text: 'Diseño de preguntas - Taller'},
                {value: 'Habilidades para conectar - Taller', text: 'Habilidades para conectar - Taller'},
                {value: 'EdiCrea - Taller', text: 'EdiCrea - Taller'},
                {value: 'Ruta de progreso en Moodle - Taller', text: 'Ruta de progreso en Moodle - Taller'},
                {value: 'Diseño accesible - Taller', text: 'Diseño accesible - Taller'},
            ],
            // facultades: [
            //     {value: 'Facultad de Ingenierías', text: 'Facultad de Ingenierías'},            
            //     {value: 'Facultad de Artes y Humanidades', text: 'Facultad de Artes y Humanidades'},       
            //     {value: 'Facultad de Ciencias Económicas y Administrativas', text: 'Facultad de Ciencias Económicas y Administrativas'},       
            //     {value: 'Faculta de Ciencias Exactas y Aplicadas', text: 'Faculta de Ciencias Exactas y Aplicadas'},       
            // ],
            facultades: obtenerFacultades()
            
        }
    },
    methods: {
        crearRegistro: async function() {
            this.faltantes = false;
            this.esEmailValido = true;

            Object.keys(this.asistenciaInduccion).map(key => this.asistenciaInduccion[key] === '' && (this.faltantes = true))
            if(this.faltantes) return;

            if(!validarEmailInstitucional(this.asistenciaInduccion.correoDocente)) return this.esEmailValido = false;

            sweetAlertConfirm(
                '¿Desea enviar la información diligenciada?',
                'Los datos diligenciados se enviarán para su respectiva revisión.',
                'warning',
                'Enviar'
              ).then(async (result) => {
                if (result.isConfirmed) {

                this.loading = true;

                const asistenciaInduccionCreada = await this.docenteStore.createInduccionDocente(this.asistenciaInduccion)
    
                if(asistenciaInduccionCreada.message){
                    this.mensajeError = asistenciaInduccionCreada.message;
                }else{
                    this.loading = false;
                    sweetAlertSuccess(
                    '¡Datos enviados!',
                    'Los datos diligenciados se han enviado de manera exitosa',
                    'success'
                    ).then(() => {
                    location.reload();
                    });
                }
                } else  (
                    result.dismiss === Swal.DismissReason.cancel
                ) 
            })
           
        }
    },
    mounted () {
        this.docenteStore = useDocenteStore();
        document.title = this.$route.meta.title // Cambiar el título de la página
    },
    components: {
        PulseLoader
    }
}
</script>