import InicioEstrategias from '../views/Inicio/Estrategias/InicioEstrategias.vue';
import Estrategia from '../views/Formularios/Estrategias/Estrategia.vue';

export default [
    
    {
        path: '/estrategias',
        name: 'inicioEstrategias',
        component: InicioEstrategias,
        meta : {
            title: 'Gestión Virtual / Estrategias',
            hideNavbar: true
        }
    },

    {
        path: '/estrategias/:estrategia',
        name: 'FormularioEstrategia',
        component: Estrategia,
        meta : {
            title: 'Gestión Virtual / Cátedras',
            hideNavbar: true
        }
    }
]