import { defineStore } from "pinia";
import { criteriosAMV, criteriosAPV } from "../helpers/criteriosEvaluacionDocente";
import { apiRoutesPrefixes } from "@/commons/apiRoutesPrefixes";
import monolith from "@/services/axiosConectionMonolith";
import microservice from "@/services/axiosConectionMicroservice";

export const useGestorStore = defineStore('gestor', {

    state: () => ({
        registrosAsistenciaInduccionDocente: [],
        registrosInscripcionCatedras: [],
        registrosInscripcionEstrategias: [],
        registrosAcompanamientoDocente: [],
        registrosSolicitudAulaVirtual: [],
        codigosAgignaturas: []
    }),

    actions: {

        async fetchCodigosAsignaturas(semestreActual) {
            try {
                const codigosAsignaturas = await monolith.post('/obtenerCodigosAsignaturas', { semestreActual }, {
                    withCredentials: true
                });

                this.codigosAgignaturas = codigosAsignaturas.data.info;
                return codigosAsignaturas.data.info;
                
            } catch (error) {
                throw error.message
            }
        },

        async fetchInduccionDocente() {
            try {
                const asistenciaInduccionDocente = await monolith.get('/obtenerInfoAsistenciaInduccionDocente', {}, {
                    withCredentials: true
                });
                
                this.registrosAsistenciaInduccionDocente = asistenciaInduccionDocente.data.info;
                return this.registrosAsistenciaInduccionDocente;
            } catch (error) {
                throw error.message
            }
        },

        async fetchInscripcionCatedra(nombreCatedra) {
            try {
                const inscripcionCatedra = await monolith.get(`/obtenerInfoInscripcionCatedra/${nombreCatedra}`, {} ,{
                    withCredentials: true
                });
                
                const { data: { info } } = inscripcionCatedra;

                this.registrosInscripcionCatedras = info.map(inscripcion => {
                    const { catedra, ...resto } = inscripcion
                    return resto
                })
                return this.registrosInscripcionCatedras;
            } catch (error) {
                throw error.message
            }
        },

        async fetchInscripcionEstrategia(nombreEstrategia) {
            try {
                const inscripcionEstrategia = await monolith.get(`/obtenerInfoInscripcionEstrategia/${nombreEstrategia}`, {} ,{
                    withCredentials: true
                });
                
                const { data: { info } } = inscripcionEstrategia;

                this.registrosInscripcionEstrategias = info.map(inscripcion => {
                    const { estrategia, ...resto } = inscripcion
                    return resto
                })
                return this.registrosInscripcionEstrategias;
            } catch (error) {
                throw error.message
            }
        },

        async fetchCriteriosAcompanamientoDocente(tipoAsignatura) {
            try {
                const criterios =  tipoAsignatura === 'AMV' ? criteriosAMV() : criteriosAPV();
                return criterios;
            } catch (error) {
                throw error.message
            }
        },

        async fetchAcompanamientoDocente(nombreFacultad) {
            try {
                const AcompanamientoDocente = await monolith.get(`/obtenerInfoAcompanamientoDocente/${nombreFacultad}` , {
                    withCredentials: true
                });
                this.registrosAcompanamientoDocente = AcompanamientoDocente.data.info;
                return this.registrosAcompanamientoDocente;
            } catch (error) {
                throw error.message
            }
        },

        async createAcompanamientoDocente(datosAcompanamientoDocente) {
            try {
                const registrocreado = await monolith.post('/acompanamientoDocente', datosAcompanamientoDocente, {
                    withCredentials: true,
                });

                return registrocreado;

            } catch (error) {
                throw error.message
            }
        },

        async fetchSolicitudAulaVirtual() {
            try {
                const solicitudAulaVirtual = await monolith.get('/obtenerInfoSolicitudAulaVirtual', {}, {
                    withCredentials: true
                });
                
                this.registrosSolicitudAulaVirtual = solicitudAulaVirtual.data.info;
                return this.registrosSolicitudAulaVirtual;
            } catch (error) {
                throw error.message
            }
        },

        async fetchSolicitudesUDEV( estado ) {
            try {
                const solicitudesUDEV = await monolith.get(`${ apiRoutesPrefixes.gestor }/obtenerInfoSolicitudesUDEVPorEstado/${ estado }`, {}, {
                    withCredentials: true
                });

                return solicitudesUDEV.data;
            } catch (error) {
                throw error.message;
            }
        },

        async fetchInfoEncuestaSatisfaccion () {
            try {
                const respEncuestasSatisfaccion = await monolith.get( `${ apiRoutesPrefixes.gestor }/obtenerInfoEncuestasSatisfaccion`);
                return respEncuestasSatisfaccion.data;

            } catch (error) {
                throw error.message;
            }
        },

        async fetchInfoEstudiantesEnRiesgo() {
            try {
                const respEstudiantesEnRiesgo = await monolith.get(`${ apiRoutesPrefixes.gestor }/obtenerInfoEstudiantesEnRiesgo`);
                return respEstudiantesEnRiesgo.data;
            } catch (error) {
                throw error.message;
            }
        },

        async fetchEncuestaCaracterizacionDigital() {
            try {
                return await microservice.get(`${apiRoutesPrefixes.generalMicroservice}/survey/getAllDigitalCharacterizationSurvey`);
            } catch (error) {
                throw error.message
            }
        },

        async fetchDepartamentoDePersonal() {
            try {
                const respDepartamentoPersonal =  await microservice.get(`${apiRoutesPrefixes.generalMicroservice}/form/getAllPersonalDepartmentRegistry`);
                return respDepartamentoPersonal.data;
            } catch (error) {
                throw error.message
            }
        },
    
        async fetchInfoDocenteCurso() {
            try {
                const respDocenteCurso =  await microservice.get(`${apiRoutesPrefixes.gestorMicroservice}/manageTeacherSubject`);
                
                return respDocenteCurso.data;
            } catch (error) {
                throw error.response.data.error
            }
        },
    },
    persist: true,
})