import { createRouter, createWebHistory } from 'vue-router';

// llamando store de auth - pinia
import { useAuthStore } from '../stores/auth';
import { pathsLogin } from '../commons/pathsLogin';

import Error from './Error';
import routerAuth from './Auth';
import routerGestores from './Gestores';
import routerDocentes from './Docentes';
import routerEstudiantes from './Estudiantes';
import routerCatedras from './Catedras';
import routerEstrategias from './Estrategias';
import routerSolicitudesUDEV from './Generales';
import routerAdministration from './Administration';

const independentRoutes = [
  'inicioCatedras',
  'inicioEstrategias',
  'encuestaDocente',
  'FormularioCatedra',
  'FormularioEstrategia',
  'inscripcionHerramientasBasicasVirtualidad',
  'RecepcionGuiasDidacticasCompromisoAcademico',
  'induccionDocentes',
  'rutaDeGraduacion',
  'solicitudAulaVirtual',
  'deporteCultura',
  'solicitudUDEV',
  "notariaPorParteDelDocente",
  "encuestaSatisfaccion",
  "encuestaCaracterizacionDigital",
  "departamentoDePersonal",
  // 'formularioCatedraCine',
  // 'formularioDeporte',
  // 'FormularioPaz',
  // 'formularioConversemos',
];

const routes = [
  ...Error,
  ...routerAuth,
  ...routerGestores,
  ...routerDocentes,
  ...routerEstudiantes,
  ...routerCatedras,
  ...routerEstrategias,
  ...routerSolicitudesUDEV,
  ...routerAdministration,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation Guards
router.beforeEach((to, from, next) => {
  
  window.scrollTo(0, 0);
  const authStore = useAuthStore();
  const currentRole = authStore.userCurrentRole;
  const authenticated = authStore.isAuthenticated;
  const refresh_token = $cookies.isKey('refresh_token');

  if (!to.name) {
     next({name: 'PaginaNoEncontrada'});
  } else if (independentRoutes.includes(to.name)) {
    next();
  } else if (to.name === 'login') {
    if (!refresh_token || !authenticated) {
      next();
    } else {
      if(currentRole === null) {
        next({ name: 'inicio' })
      }else {
        next({ path: pathsLogin[currentRole] })
      }
    }
  } else if ((to.name !== 'login' && to.name !== 'inicio' && to.name !== 'PaginaNoEncontrada' )) {
    if ((!refresh_token || refresh_token === null)) {
        next({name: 'login'});
    } else {
      if (to.meta.allowedRoles.includes(currentRole)) {
        next()
      }
      else {
        (currentRole === null) ? next({ path: '/inicio' }) : next({ path: pathsLogin[currentRole] });
      }
    }
  } else {
    next();
  }
});

export default router
