import monolith from "./services/axiosConectionMonolith";

export default class API {

    //Funcion para traer todos los codigossss
    static async fetchCodigos(){
        const res = await monolith.get('/obtenerCodigos');
        return res.data.codigos;
    }

    static async fetchCodigosAsignaturas(semestreActual){
        const res = await monolith.post('/obtenerCodigosAsignaturas', {semestreActual});
        return res.data.info;
    }

    //Función para obtener toda la info de habilitaciones
    static async fetchInfoHabilitaciones(){
        const res = await monolith.get('/obtenerInfoHabilitaciones')
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Función para obtener toda la info de cancelaciones
    static async fetchInfoCancelaciones(){
        const res = await monolith.get('/obtenerInfoCancelaciones')
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Función para obtener toda la info de incripciones HBV
    static async fetchInfoInscripcionesHBV(data){
        const res = await monolith.post('/obtenerInfoInscripcionesHBV', data)
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Función para obtener información de recepciones guias didacticas y compromisos academicos
    static async fetchInfoRecepcionesGDCA(data){
        const res = await monolith.post('/obtenerInfoRecepcionesGDCA', data)
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Función para obtener información de apoyo a examenes de habilitaciones
    static async fetchInfoApoyoExamenesHabilitaciones(){
        const res = await monolith.get('/obtenerInfoApoyoExamenHabilitacion')
        .then(resp => {
            return resp.data;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }
    
    //Función para obtener información de requisitos de graduación para programas de modalidad virtual 
    static async fetchInfoRutasGraduaciones(){
        const res = await monolith.get('/obtenerInfoRutaGraduacion')
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Función para obtener información de usuarios por curso (Moodle)
    static async fetchInfoUsuariosCurso(data){
        const res = await monolith.post('/obtenerInfoUsuariosCurso', data)
        .then(resp => {
            return resp.data;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Función para obtener información de usuarios de todos los cursos en 1 categoria (Moodle)
    static async fetchInfoUsuariosCursosCategoria(data){
        const res = await monolith.post('/obtenerInfoUsuariosCursosCategoria', data);
        return res.data;
    }

    //Función para obtener cursos desde la API de Moodle
    static async fetchCursosMoodle(data) {
        const res = await monolith.post('/obtenerCursosMoodle', data)
        .then(resp => {
            return resp.data;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    // //Función para obtener categoria desde la API de Moodle
    // static async fetchCategoriasMoodle() {
    //     const res = await monolith.post('/obtenerCategoriasMoodle')
    //     .then(resp => {
    //         return resp.data.categorias;
    //     }).catch(function(e) {
    //         console.log(e); 
    //     })
    //     return res;
    // } 

    //Función para obtener información de Notaría Docentes
    static async fetchInfoNotariaDocente(data){
        const res = await monolith.post('/obtenerInfoNotariaDocente', data)
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }
    
    // Función para obtener información de encuestas
    static async fetchEncuestasFiltradasTipoAsignatura(data){
        const res = await monolith.post('/obtenerEncuestasFiltradasTipoAsignatura', data)
        .then(resp => {
            return resp.data["encuestasFiltradas"];
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    // Función para obtener informacion de cátedras
    static async fetchInfoCatedraDeLaPaz(){
        const res = await monolith.get('/obtenerInfoCatedraDeLaPaz')
        .then(resp => {
            return resp.data.info;
        }).catch(function(e) {
            console.log(e); 
        })
        return res;
    }

    //Funcion que guarda la info en la BD para habilitaciones
    static async createHabilitacion(data){
        const res = await monolith({
            method: 'post',
            url: '/crearHabilitacion',
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(resp => {
            return resp.data;
        }).catch(function(e) {
            console.log(e); 
        })

        return res;
    }

    //Funcion que guarda la info en la BD para Cancelaciones
    static async createCancelacion(data){
        const res = await monolith({
            method: 'post',
            url: '/crearCancelacion',
            data: data,
            headers: {'Content-Type': 'application/json'}
        })

        return res;
    }

    //Funcion para crear una encuesta
    static async createEncuesta(data){
        const res = await monolith.post('/crearEncuesta', data);
        return res.data;
    }

    //Funcion para inscripción a cursos de herramientas basicas para la virtualidad
    static async inscripcionCursoHBV(data){
        const res = await monolith({
            method: 'post',
            url: '/crearInscripcionHBV',
            data: data,
            headers: {'Content-Type': 'application/json'}
        });

        return res.data;
    }


    //Función para recepción de guias didacticas y compromiso academico
    static async registroRecepcionGDCA(data){

        const res = await monolith({
            method: 'post',
            url: '/recepcionGDCA',
            data: data,
            timeout: 500000,
            headers: {'Content-Type': 'application/json'}
        })

        return res;
    }

     //Funcion para crear registro de apoyo para examenes de habilitacion 
     static async apoyoExamenHabilitacion(data){

        const res = await monolith({
            method: 'post',
            url: '/apoyoExamenHabilitacion',
            data: data,
            headers: {'Content-Type': 'application/json'}
        })

        return res.data;
    }

    //Función para crear registro de ruta de graduación
    static async crearRutaGraduacion (data){

        const res = await monolith({
            method: 'post',
            url: '/rutaGraduacion',
            data: data,
            headers: {'Content-Type': 'application/json'}
        })

        return res.data;
    }

    //Función para crear registro de notaria docentes
    static async registroNotariaDocente(data){

        const res = await monolith({
            method: 'post',
            url: '/notariaDocente',
            data: data,
            headers: {'Content-Type': 'application/json'}
        })

        return res;
    }

    //Función para cargar masivamente usuarios en Moodle
    static async cargaMasivaUsuariosMoodle(data) {
        const res = await monolith({
            method: 'post',
            url: '/cargaMasivaUsuariosMoodle',
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
        })
    }    
}