<template>
  <div>
    <!-- banner -->
    <div class="container-fluid m-0 p-0">
      <img src="../../../../public/img/Generales/Banner_Encuesta_de_Satisfaccion.png" class="img-fluid d-flex mx-auto vw-100"
        alt="">
    </div>
    <div class="container mb-5">
      <!-- Enunciado del formulario -->
      <div class="row">
        <div class="col-12">
          <h4 class="mt-3">Estimado usuario: </h4>
          <p>Queremos conocer su opinión acerca de nuestros servicios.</p>
          <p>El objetivo de esta encuesta es conocer su grado de satisfacción en relación con la atención prestada por
            el equipo de Gestión Curricular de la Unidad de Educación Virtual (UEV).</p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
          <label for="txtNombreCompleto">Nombres y apellidos <b class="text-danger">*</b></label> <br>
          <input 
            :oninput="() => (usuario.nombreCompleto = usuario.nombreCompleto.toUpperCase())"
            class="form-control" type="text" id="txtNombreCompleto"
            v-model.trim="usuario.nombreCompleto">
        </div>
        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
          <label for="txtNumDocumento">Número de documento de identidad <b class="text-danger">*</b></label> <br>
          <input class="form-control" type="number" id="txtNumDocumento" v-model.trim="usuario.numDocumento">
        </div>
      </div>

      <div class="row mt-lg-3 mt-0">
        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
          <label for="txtCorreo">Correo institucional o personal <b class="text-danger">*</b></label> <br>
          <input class="form-control" type="text" id="txtCorreo" v-model.trim="usuario.correo">
        </div>

        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
          <label for="txtCorreo">Confirmacion del correo institucional o personal <b class="text-danger">*</b></label>
          <br>
          <input class="form-control" type="text" id="txtCorreo" v-model.trim="usuario.correo2">
        </div>
      </div>

      <div class="row mt-lg-3 mt-0">
        <div class="col-lg-6 col-12 mb-lg-0 mb-3">
          <label for="ddlTipoUsuario">Usted es: <b class="text-danger">*</b></label> <br>
          <select id="ddlTipoUsuario" class="form-select  " v-model.trim="usuario.tipoUsuario"
            aria-label=".form-select-lg example">
            <option selected value="">Seleccione una opción</option>
            <option v-for="(tipoUsuario, index) of tiposUsuarios" :key="index">{{ tipoUsuario.text }}</option>
          </select>
        </div>
        <div class="col-md-6 col-12 mb-md-0 mb-3">
          <label for="selectFechaAtencion">Fecha de atención <b class="text-danger">*</b></label> <br>
          <input class="form-control" type="date" id="selectFechaAtencion" v-model="usuario.fechaAtencion">
        </div>
      </div>

      <hr>

      <div class="row mt-lg-3 mt-0">
        <div class="col-lg-8 col-12 mb-lg-0 mb-3">
          <label>1. ¿Cómo calificaría la atención recibida por parte del equipo? <b class="text-danger">*</b></label> <br>
          <div class="rating">
            <input type="radio" id="star5" name="rating" value="5" v-model="usuario.rating">
            <label for="star5" title="5 estrellas">
              <i class="fa fa-star"></i>
            </label>

            <input type="radio" id="star4" name="rating" value="4" v-model="usuario.rating">
            <label for="star4" title="4 estrellas">
              <i class="fa fa-star"></i>
            </label>

            <input type="radio" id="star3" name="rating" value="3" v-model="usuario.rating">
            <label for="star3" title="3 estrellas">
              <i class="fa fa-star"></i>
            </label>

            <input type="radio" id="star2" name="rating" value="2" v-model="usuario.rating">
            <label for="star2" title="2 estrellas">
              <i class="fa fa-star"></i>
            </label>

            <input type="radio" id="star1" name="rating" value="1" v-model="usuario.rating">
            <label for="star1" title="1 estrella">
              <i class="fa fa-star"></i>
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-lg-3 mt-0">
        <div class="col-lg-8 col-12 mb-lg-0 mb-3">
          <label>2. ¿En qué medida el equipo resolvió su solicitud?  <b class="text-danger">*</b></label> <br>
          <div class=" btn-group-lg" role="group" aria-label="Customer Service RatingAtencion">
            <input type="radio" class="btn-check" name="ratingAtencion" id="btnradio1" autocomplete="off" value="1"
              v-model="usuario.ratingAtencion">
            <label class="btn btn btn-light mx-2" for="btnradio1">1</label>

            <input type="radio" class="btn-check " name="ratingAtencion" id="btnradio2" autocomplete="off" value="2"
              v-model="usuario.ratingAtencion">
            <label class="btn btn btn-light mx-2" for="btnradio2">2</label>

            <input type="radio" class="btn-check" name="ratingAtencion" id="btnradio3" autocomplete="off" value="3"
              v-model="usuario.ratingAtencion">
            <label class="btn btn btn-light mx-2" for="btnradio3">3</label>

            <input type="radio" class="btn-check" name="ratingAtencion" id="btnradio4" autocomplete="off" value="4"
              v-model="usuario.ratingAtencion">
            <label class="btn btn btn-light mx-2" for="btnradio4">4</label>

            <input type="radio" class="btn-check" name="ratingAtencion" id="btnradio5" autocomplete="off" value="5"
              v-model="usuario.ratingAtencion">
            <label class="btn btn btn-light mx-2" for="btnradio5">5</label>
          </div>
        </div>
      </div>

      <div class="row mt-lg-3 mt-0">
        <div class="col-xl-8 col-12 mb-lg-0 mb-3">
          <label>3. ¿Cuál es su grado de satisfacción con los tiempos de respuesta? Escala de respuestas de 0 a 10 (muy
            insatisfecho a muy satisfecho). <b class="text-danger">*</b></label> <br>
          <div class="btn-group" role="group" aria-label="RatingSatisfaccion Scale">
            <!-- Valores de 0 a 6 (Rojo Frío) -->
            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion0" autocomplete="off"
              value="0" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion0">0</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion1" autocomplete="off"
              value="1" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion1">1</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion2" autocomplete="off"
              value="2" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion2">2</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion3" autocomplete="off"
              value="3" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion3">3</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion4" autocomplete="off"
              value="4" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion4">4</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion5" autocomplete="off"
              value="5" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion5">5</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion6" autocomplete="off"
              value="6" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red" for="ratingSatisfaccion6">6</label>

            <!-- Valores de 7 a 8 (Amarillo Frío) -->
            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion7" autocomplete="off"
              value="7" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-yellow" for="ratingSatisfaccion7">7</label>

            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion8" autocomplete="off"
              value="8" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-yellow" for="ratingSatisfaccion8">8</label>

            <!-- Valores de 9 (Verde Frío) -->
            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion9" autocomplete="off"
              value="9" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-green" for="ratingSatisfaccion9">9</label>

            <!-- Valor de 10 (Rojo Frío) -->
            <input type="radio" class="btn-check" name="ratingSatisfaccion" id="ratingSatisfaccion10" autocomplete="off"
              value="10" v-model="usuario.ratingSatisfaccion">
            <label class="btn rating-red-light" for="ratingSatisfaccion10">10</label>
          </div>
        </div>
      </div>

      <div class="row mt-lg-3 mt-0">
        <div class="col-xl-8 col-12 mb-lg-0 mb-3">
          <label>4. ¿Cuál es su grado de satisfacción con los tiempos de respuesta? Escala de respuestas de 0 a 10 (muy
            insatisfecho a muy satisfecho). <b class="text-danger">*</b></label> <br>
          <div class="btn-group" role="group" aria-label="RatingRespuesta Scale">
            <!-- Valores de 0 a 6 (Rojo Frío) -->
            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta0" autocomplete="off"
              value="0" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta0">0</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta1" autocomplete="off"
              value="1" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta1">1</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta2" autocomplete="off"
              value="2" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta2">2</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta3" autocomplete="off"
              value="3" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta3">3</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta4" autocomplete="off"
              value="4" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta4">4</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta5" autocomplete="off"
              value="5" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta5">5</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta6" autocomplete="off"
              value="6" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red" for="ratingRespuesta6">6</label>

            <!-- Valores de 7 a 8 (Amarillo Frío) -->
            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta7" autocomplete="off"
              value="7" v-model="usuario.ratingRespuesta">
            <label class="btn rating-yellow" for="ratingRespuesta7">7</label>

            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta8" autocomplete="off"
              value="8" v-model="usuario.ratingRespuesta">
            <label class="btn rating-yellow" for="ratingRespuesta8">8</label>

            <!-- Valores de 9 (Verde Frío) -->
            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta9" autocomplete="off"
              value="9" v-model="usuario.ratingRespuesta">
            <label class="btn rating-green" for="ratingRespuesta9">9</label>

            <!-- Valor de 10 (Rojo Frío) -->
            <input type="radio" class="btn-check" name="ratingRespuesta" id="ratingRespuesta10" autocomplete="off"
              value="10" v-model="usuario.ratingRespuesta">
            <label class="btn rating-red-light" for="ratingRespuesta10">10</label>
          </div>
        </div>
      </div>

      <div class="row mt-lg-3 mt-0">
        <div class="col-xl-8 col-12 mb-lg-0 mb-3">
          <label>5. ¿Qué tan fácil fue establecer contacto con los gestores? <b class="text-danger">*</b></label> <br>
          <div class="btn-group-lg" role="group" aria-label="RatingContacto Scale">
            <!-- Opción 1: Muy difícil -->
            <input type="radio" class="btn-check" name="ratingContacto" id="ratingContacto1" autocomplete="off"
              value="Muy difícil" v-model="usuario.ratingContacto">
            <label class="btn emoji-btn mx-2" for="ratingContacto1">
              <span>😢</span>
              <div>Muy difícil</div>
            </label>

            <!-- Opción 2: Difícil -->
            <input type="radio" class="btn-check" name="ratingContacto" id="ratingContacto2" autocomplete="off"
              value="Difícil" v-model="usuario.ratingContacto">
            <label class="btn emoji-btn mx-2" for="ratingContacto2">
              <span>😟</span>
              <div>Difícil</div>
            </label>

            <!-- Opción 3: Ni fácil ni difícil -->
            <input type="radio" class="btn-check" name="ratingContacto" id="ratingContacto3" autocomplete="off"
              value="Ni fácil ni difícil" v-model="usuario.ratingContacto">
            <label class="btn emoji-btn mx-2" for="ratingContacto3">
              <span>😐</span>
              <div>Ni fácil ni difícil</div>
            </label>

            <!-- Opción 4: Fácil -->
            <input type="radio" class="btn-check" name="ratingContacto" id="ratingContacto4" autocomplete="off"
              value="Fácil" v-model="usuario.ratingContacto">
            <label class="btn emoji-btn mx-2" for="ratingContacto4">
              <span>😊</span>
              <div>Fácil</div>
            </label>

            <!-- Opción 5: Muy fácil -->
            <input type="radio" class="btn-check" name="ratingContacto" id="ratingContacto5" autocomplete="off"
              value="Muy fácil" v-model="usuario.ratingContacto">
            <label class="btn emoji-btn mx-2" for="ratingContacto5">
              <span>😁</span>
              <div>Muy fácil</div>
            </label>
          </div>
        </div>
      </div>

      <!-- Faltan -->
      <div class="row mt-lg-3 mt-0">
        <div class="col-xl-8 col-12 mb-lg-0 mb-3">
          <label>6. Teniendo en cuenta su experiencia con respecto a la atención ofrecida, ¿con cuál de las siguientes
            emociones se identificaría? <b class="text-danger">*</b></label> <br>
          <div class="d-flex gap-2">

            <div class="col-6 ">
              <div class="btn-group-vertical w-100" role="group" aria-label="Emotion Scale Left">
                <input type="radio" class="btn-check" name="emotion" id="emotion1" autocomplete="off" value="feliz"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion1">
                  <span>😊</span>
                  <div>Feliz</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion2" autocomplete="off" value="satisfecho"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion2">
                  <span>😌</span>
                  <div>Satisfecho</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion3" autocomplete="off" value="tranquilo"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion3">
                  <span>😌</span>
                  <div>Tranquilo</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion4" autocomplete="off" value="confiado"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion4">
                  <span>😌</span>
                  <div>Confiado</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion9" autocomplete="off" value="ninguna"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion9">
                  <div>Ninguna</div>
                </label>
              </div>
            </div>

            <div class="col-6">
              <div class="btn-group-vertical w-100" role="group" aria-label="Emotion Scale Right">
                <input type="radio" class="btn-check" name="emotion" id="emotion5" autocomplete="off"
                  value="decepcionado" v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion5">
                  <span>😞</span>
                  <div>Decepcionado</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion6" autocomplete="off" value="frustrado"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion6">
                  <span>😰</span>
                  <div>Frustrado</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion7" autocomplete="off" value="molesto"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion7">
                  <span>😠</span>
                  <div>Molesto</div>
                </label>

                <input type="radio" class="btn-check" name="emotion" id="emotion8" autocomplete="off" value="enojado"
                  v-model="usuario.emotion">
                <label class="btn emoji-btn" for="emotion8">
                  <span>😡</span>
                  <div>Enojado</div>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Falta cuadro de texto -->
      <div class="row mt-lg-3 mt-0">
        <div class="col-xl-8 col-12 mb-lg-0 mb-3">
          <label for="inputTextarea" class="form-label">7. ¿Qué le gustaría que la UEV implemente para mejorar la atención?
            <b class="text-danger">*</b></label>
          <textarea class="form-control" id="inputTextarea" rows="6" maxlength="300"
            placeholder="Déjenos sus sugerencias aquí." v-model="usuario.sugerencia"></textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div v-if="faltantes" class="alert alert-danger" role="alert">
            <b>Todos los campos son de carácter obligatorio</b>
          </div>
        </div>
        <div class="col-12">
          <div v-if="mensajeError != null" class="alert alert-danger" role="alert">
            <b>{{ mensajeError }}</b>
          </div>
        </div>
        <div class="col-12">
          <div v-if="errorRespuestaAPI" class="alert alert-danger" role="alert">
            <b>{{ mensajeErrorRespuestaAPI }}</b>
          </div>
        </div>
        <div class="col-12">
          <p class="text-center" v-if="isLoading"><b>Enviando información, por favor espere...</b></p>
          <div class="d-flex justify-content-center" v-if="isLoading">
            <pulse-loader :loading="isLoading" :color="color" :size="size"></pulse-loader>
          </div>
        </div>
        <div class="col-12">
          <button class="btn btn-enviar d-flex mx-auto" @click="crearRegistro">Registrarme</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

//Importando API que conecta front con back
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert';
import { validarEmail, verificarEmail } from '../../../helpers/functions';
import { useGeneralStore } from '../../../stores/generales';

export default {
  name: 'formularioEncuesta de sastisfacción',

  data() {
    return {
      generalStore: useGeneralStore(),
      mensajeErrorRespuestaAPI: null,
      errorRespuestaAPI: false,
      isLoading: false,
      verificarEmail: verificarEmail,
      aceptaTratamientoDatos: false,
      usuario: {
        nombreCompleto: "",
        numDocumento: "",
        correo: "",
        correo2: "",
        tipoUsuario: "",
        fechaAtencion: "",
        rating: "",
        ratingAtencion: "",
        ratingSatisfaccion: "",
        ratingRespuesta: "",
        ratingContacto: "",
        emotion: "",
        sugerencia: ""

      },
      tiposUsuarios: [
        { value: 'ESTUDIANTE', text: 'ESTUDIANTE' },
        { value: 'DOCENTE', text: 'DOCENTE' },
        { value: 'ASPIRANTE', text: 'ASPIRANTE' },
        { value: 'EGRESADO', text: 'EGRESADO' },

      ],
      faltantes: false,
      mensajeError: null
    };
  },
  methods: {
    crearRegistro: function () {
      this.faltantes = false;
      this.mensajeError = null;

      if (
        this.usuario.nombreCompleto === "" ||
        this.usuario.numDocumento === "" ||
        this.usuario.correo === "" ||
        this.usuario.correo2 === "" ||
        this.usuario.tipoUsuario === "" ||
        this.usuario.fechaAtencion === "" ||
        this.usuario.rating === "" ||
        this.usuario.ratingAtencion === "" ||
        this.usuario.ratingSatisfaccion === "" ||
        this.usuario.ratingRespuesta === "" ||
        this.usuario.ratingContacto === "" ||
        this.usuario.emotion === "" ||
        this.usuario.sugerencia === ""
      ) return this.faltantes = true;

      const validEmail = validarEmail(this.usuario.correo);
      const verificarEmail = this.verificarEmail(this.usuario.correo, this.usuario.correo2);

      if (!validEmail) return this.mensajeError = `El correo electrónico debe ser un correo válido.`;
      if (!verificarEmail) return this.mensajeError = `El correo electrónico debe coincidir en ambos campos`;

      sweetAlertConfirm(
        '¿Desea enviar la información diligenciada?',
        'Los datos diligenciados se enviarán a la base de datos.',
        'warning',
        'Enviar'
      ).then(async (result) => {
        if (result.isConfirmed) {

          this.isLoading = true;

          const datosEncuestaFormData = new FormData();

          datosEncuestaFormData.append('infoSolicitud', JSON.stringify(this.usuario));

          const encuestaCreada = await this.generalStore.crearEncuestaSatisfaccion(datosEncuestaFormData);
          console.log(encuestaCreada);
          if (encuestaCreada.message) {
            this.isLoading = false;
            this.errorRespuestaAPI = true;
            this.mensajeErrorRespuestaAPI = encuestaCreada.message;
          } else {
            this.isLoading = false;
            sweetAlertSuccess(
              '¡Datos enviados!',
              'Los datos diligenciados se han enviado de manera exitosa',
              'success'
            ).then(() => {
              location.reload();
            });
          }
        } else (
          result.dismiss === Swal.DismissReason.cancel
        );
      });
    }
  },
  mounted() {
    document.title = this.$route.meta.title; // Cambiar el título de la página
  },
  components: {
    PulseLoader
  },
};
</script>

<style scoped>
.rating {
  direction: rtl;
  unicode-bidi: bidi-override;
  font-size: 2rem;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.rating input {
  display: none;
}

.rating label {
  cursor: pointer;
  color: #ccc;
  /* Color por defecto de las estrellas */
}

.rating input:checked~label i {
  color: #f39c12;
  /* Color de las estrellas seleccionadas */
}

.rating label i {
  transition: color 0.2s;
}

.rating label:hover i,
.rating label:hover~label i {
  color: #f1c40f;
  /* Color de las estrellas al pasar el ratón */
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.btn {
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

@media screen and (max-width: 768px) {
  .btn {
    padding: 8px 11px;
  }
}

.rating-red {
  background-color: #fa8d89;
  /* Rojo frío */
  color: black;
}

.rating-red:hover,
.rating-red:checked {
  background-color: #d72823;
  /* Rojo más oscuro */
}

.rating-red-light {
  background-color: #e62c25;
}

.rating-red-light:hover,
.rating-red-light:checked {
  background-color: #d72823;
  /* Rojo más oscuro */
}

.rating-yellow {
  background-color: #f2da90;
  /* Amarillo frío */
  color: black;
}

.rating-yellow:hover,
.rating-yellow:checked {
  background-color: #f8cf54;
  /* Amarillo más oscuro */
}

.rating-green {
  background-color: #9fc685;
  /* Verde frío */
  color: black;
}

.rating-green:hover,
.rating-green:checked {
  background-color: #449d44;
  /* Verde más oscuro */
}

.emoji-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f7f7;
  color: #333;
  margin-bottom: 5px;
}

.emoji-btn:hover {
  background-color: #e7e7e7;
}

.emoji-btn span {
  font-size: 1.5rem;
  /* Tamaño del emoji */
  margin-right: 10px;
}

.emoji-btn div {
  font-size: 1rem;
}

.btn-check:checked+.emoji-btn {
  background-color: #d1e7dd;
  /* Color de fondo cuando se selecciona */
}

.btn-group-vertical {
  width: 100%;
  /* Asegura que los botones ocupen el ancho completo */
}
</style>