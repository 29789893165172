import InicioCatedras from '../views/Inicio/Catedras/InicioCatedras.vue';
import Catedra from '../views/Formularios/Catedras/Catedra.vue';
import DeporteCultura from '../views/Formularios/Catedras/DeporteCultura.vue';


export default [

    {
        path: '/catedras',
        name: 'inicioCatedras',
        component: Catedra,
        meta : {
            title: 'Gestión Virtual / Cátedras',
            hideNavbar: true
        }
    },

    //** OLD */
    // {
    //     path: '/catedras',
    //     name: 'inicioCatedras',
    //     component: InicioCatedras,
    //     meta : {
    //         title: 'Gestión Virtual / Cátedras',
    //         hideNavbar: true
    //     }
    // },

    // {
    //     path: '/catedras/:catedra',
    //     name: 'FormularioCatedra',
    //     component: Catedra,
    //     meta : {
    //         title: 'Gestión Virtual / Cátedras',
    //         hideNavbar: true
    //     }
    // },

    
    // {
    //     path: '/catedras/DeporteCultura',
    //     name: 'deporteCultura',
    //     component: DeporteCultura,
    //     meta : {
    //         title: 'Gestión Virtual / Cátedra de la paz',
    //         hideNavbar: true
    //     }
    // },

    // {
    //     path: '/catedras/CatedraDeporte',
    //     name: 'formularioDeporte',
    //     component: CatedraDeDeporte,
    //     meta : {
    //       title: ' Gestión Virtual / Cátedra de Deporte',
    //       hideNavbar: true
    //     }
    // },

    // {
    //     path: '/catedras/CatedraDeCine',
    //     name: 'formularioCatedraCine',
    //     component: CatedraDeCine,
    //     meta : {
    //       title: ' Gestión Virtual / Cátedra de Cine',
    //       hideNavbar: true
    //     }
    // },
    // {
    //     path: '/catedras/CatedraConversemos',
    //     name: 'formularioConversemos',
    //     component: CatedraConversemos,
    //     meta : {
    //       title: ' Gestión Virtual / Cátedra Conversemos',
    //       hideNavbar: true
    //     }
    // },
]