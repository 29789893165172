<template>
    <div class="container pb-5">
      <div class="row">
        <div class="p-4 mt-4">
            <p>En esta sección usted podrá visualizar todos los registros realizados por los usuarios mediante el formulario de  
            <em><b>Encuesta Satisfacción</b></em>.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="noData">
            <div class="alert alert-danger" role="alert">
              <b>Aún no se han realizado registros</b>
            </div>
        </div>
        <div class="col-12">
            <div v-if="infoEncuestaSatisfaccion.length != 0">
                  <DataTable v-if="infoEncuestaSatisfaccion.length !== 0" :btnsDisable="btnsDisableToTable" :info="infoEncuestaSatisfaccion" :cabecera="cabeceraDatatable" :titulo="tituloReporteDataTable"/>
            </div>
        </div>  
      </div> 
    </div>
  </template>
  <script>
  
  //Importando API que conecta front con back
  import { useGestorStore } from '@/stores/gestores';
  import DataTable from '../../../components/DataTable'
  
  export default {
    name: 'infoEncuestaSatisfaccion',
  
    data() {
      return{
        gestorStore: null,
        noData: false,

        btnsDisableToTable: ['btnSelectAll', 'btnDisSelectAll', 'btnSendEmail', 'btnCambiarEstado'],
        infoEncuestaSatisfaccion: [],
        cabeceraDatatable: [
          {text: 'N°'},
          {text: 'Nombre'},
          {text: 'N° documento'},
          {text: 'Correo'},
          {text: 'Tipo usuario'},
          {text: 'Fecha atención'},
          {text: 'Calificación atención'},
          {text: 'Calificación resolución'},
          {text: 'Grado de satisfaccion - Tiempo respuesta'},
          {text: 'Grado de recomendación UDEV'},
          {text: 'Facilidad contacto con gestores'},
          {text: 'Emoción'},
          {text: 'Sugerencia'},
          {text: 'Fecha registro'},
        ],
        tituloReporteDataTable: 'Reporte de cancelaciones'
      }
    },
    components: {
        DataTable
    },  
    created() {
        this.verInfo()
    },
    methods: {
      verInfo: async function() {
        this.noData = false;

        this.gestorStore = useGestorStore();
        const { encuestasSatisfaccion } =  await this.gestorStore.fetchInfoEncuestaSatisfaccion();
        this.infoEncuestaSatisfaccion = encuestasSatisfaccion;
        if(this.infoEncuestaSatisfaccion.length === 0) return this.noData = true;
      }
    },
    mounted() {
      document.title = this.$route.meta.title // Cambiar el título de la página
    },
  }
  </script>
  