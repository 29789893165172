import monolith from "@/services/axiosConectionMonolith";
import axios from "axios";
import { defineStore } from "pinia";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: {},
        accessToken: '',
        authReady: false,
        currentRole: null
    }),

    getters: {
        userDetail: state => state.user,
        userCurrentRole: state => !state.currentRole ? null : state.currentRole,
        isAuthenticated: state => state.authReady ? true : false
    },

    actions: {
        async login(userAuthData) {
           try {
                const { data } = await monolith.post('/auth/loginMoodle', userAuthData, {
                    withCredentials: true
                });

                if (!data.message) {
                    const { userInfo: { tokenInfo, ...user } } = data;
                    this.user = user;
                    this.accessToken = tokenInfo.token;
                    this.authReady = true;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenInfo.token}`
                    return;   
                }
                return data;
            
           } catch (error) {
                throw error.message
           }
        },

        async logout() {
            try {
                await monolith.post('/auth/logoutMoodle',{},{
                    withCredentials: true
                });
                this.user = {};
                this.accessToken = '';
                this.authReady = false;
                this.currentRole = null;
                axios.defaults.headers.common['Authorization'] = '';
            } catch (error) {
                throw error.message
            }
        },

        // Aún no se está usando
        async refresh() {
            try {
                const { status, data } = await monolith.post('/auth/refreshToken', {}, {
                    withCredentials: true
                });
                this.accessToken = data.access_token;

                return {status, data}
                
            } catch (error) {
                throw error.message
            }
        },

        async setCurrentRole(role) {
            try {
                this.currentRole = role;
                return this.currentRole;
            } catch (error) {
                throw error.message
            }
        }
    },
    persist: true,
})