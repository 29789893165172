<template>
    <div class="home">
        <div class="row">
         <div class="col-12">
            <img src="../../../../public/img/Banner_Encuesta_desempeno_docente_Educacion_Virtual.png" class="img-fluid w-100 d-flex" alt="">
         </div>
       </div> 
        <!-- <div class="row">
         <div class="col-12">
           <h1 class="text-center mt-3 text-danger">Encuesta Cerrada</h1>
         </div>
       </div>  -->
      <div class="container pb-5 pt-4">
      <div class="row">
        <div class="col-12">
          <p>Lo invitamos a diligenciar la siguiente encuesta de satisfacción frente al desempeño de su docente y la atención que brinda el equipo de Gestión Curricular de la Unidad de Educación Virtual.</p>
          <p>Esta encuesta es <b>anónima</b> y solo pretende mejorar el desarrollo de las asignaturas mediadas por la virtualidad, por esto su opinión es fundamental en el logro de este propósito.</p>
          <p>De manera objetiva y teniendo en cuenta que Deficiente es 1, Regular 2, Aceptable 3, Bueno 4 y Excelente 5, seleccione la calificación que usted considera adecuada para cada uno de los ítems que encuentra a continuación:</p>
        </div>
      </div>
      <hr>
      <div class="row mt-3">
        <div class="col-12">

          <p><b>Código de asignatura:</b></p>
          <Select2 id="select" v-model.trim="codigoSeleccionado" :options="codigosAsignaturas" placeholder="Seleccione una opción"/>

          <!-- <select v-model.trim="codigoSeleccionado" id="select" class="form-select form-select-lg mb-3">
            <option selected value="">Seleccione una opción</option>
            <option v-for="(codigo, index) of codigosAsignaturas" :key="index">{{codigo}}</option>
          </select> -->

          <!-- <v-select id="select" :options="codigosAsignaturas" label="codigoNombre" placeholder="Selecciona un código" v-model="codigoSeleccionado"></v-select> -->
          <div v-if="codigoSeleccionado != ''" class="mt-4">
              
              <p><b>Codigo de asignatura:</b> {{codigoSeleccionado}}</p>
              <p><b>Programa:</b> {{programa}}</p>
              <p><b>Docente:</b> {{docente}}</p>
              <p><b>Tipo de asignatura:</b> {{tipoAsignatura}}</p>
              <p><b>Semestre: </b> {{semestre}}</p>
              <p></p>
          </div>
          <hr>
          <h4>ESTRUCTURA Y CONTENIDOS DEL CURSO</h4>
          <!--SECCION 1-->
           <!--Seccion 1 - pregunta 1-->
            <div class="border p-3 rounded shadow mb-3">
              <b>1.</b> El mensaje de apertura que su docente publica cada semana es claro para la realización de las actividades:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_1" id="radioBtn1_1_1" v-model="respuestaSeccion1_1" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_1_1">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_1" id="radioBtn2_1_1" v-model="respuestaSeccion1_1" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_1_1">
                  Regular
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_1" id="radioBtn3_1_1" v-model="respuestaSeccion1_1" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_1_1">
                  Aceptable
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_1" id="radioBtn4_1_1" v-model="respuestaSeccion1_1" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_1_1">
                  Bueno
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_1" id="radioBtn5_1_1" v-model="respuestaSeccion1_1" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_1_1">
                  Excelente
                </label>
              </div>
              
            </div>
            <!--Fin Seccion 1 - pregunta 1-->
  
            <!--Seccion 1 - pregunta 2-->
            <div class="border p-3 rounded shadow mb-3">
              <b>2.</b> La puntualidad de su docente para el montaje de los materiales básicos y complementarios de estudio es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_2" id="radioBtn1_1_2" v-model="respuestaSeccion1_2" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_1_2">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_2" id="radioBtn2_1_2" v-model="respuestaSeccion1_2" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_1_2">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_2" id="radioBtn3_1_2" v-model="respuestaSeccion1_2" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_1_2">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_2" id="radioBtn4_1_2" v-model="respuestaSeccion1_2" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_1_2">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_2" id="radioBtn5_1_2" v-model="respuestaSeccion1_2" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_1_2">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 1 - pregunta 2-->
  
            <!--Seccion 1 - pregunta 3-->
            <div class="border p-3 rounded shadow mb-3">
              <b>3.</b> La calidad de los materiales básicos y complementarios de estudio que su docente usa para su aprendizaje es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_3" id="radioBtn1_1_3" v-model="respuestaSeccion1_3" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_1_3">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_3" id="radioBtn2_1_3" v-model="respuestaSeccion1_3" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_1_3">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_3" id="radioBtn3_1_3" v-model="respuestaSeccion1_3" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_1_3">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_3" id="radioBtn4_1_3" v-model="respuestaSeccion1_3" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_1_3">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_3" id="radioBtn5_1_3" v-model="respuestaSeccion1_3" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_1_3">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 1 - pregunta 3-->
  
            <!--Seccion 1 - pregunta 4-->
            <div class="border p-3 rounded shadow mb-3">
              <b>4.</b> Qué tan adecuado es el tipo de formatos (archivos de office, videos, audios, enlaces web) de los materiales de estudio que su docente le proporciona:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_4" id="radioBtn1_1_4" v-model="respuestaSeccion1_4" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_1_4">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_4" id="radioBtn2_1_4" v-model="respuestaSeccion1_4" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_1_4">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_4" id="radioBtn3_1_4" v-model="respuestaSeccion1_4" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_1_4">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_4" id="radioBtn4_1_4" v-model="respuestaSeccion1_4" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_1_4">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_4" id="radioBtn5_1_4" v-model="respuestaSeccion1_4" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_1_4">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 1 - pregunta 4-->
  
            <!--Seccion 1 - pregunta 5-->
            <div class="border p-3 rounded shadow mb-3">
              <b>5.</b> Los contenidos vistos y las actividades realizadas en el curso fueron acordes con lo planeado en la guía didáctica.
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_5" id="radioBtn1_1_5" v-model="respuestaSeccion1_5" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_1_5">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_5" id="radioBtn2_1_5" v-model="respuestaSeccion1_5" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_1_5">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_5" id="radioBtn3_1_5" v-model="respuestaSeccion1_5" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_1_5">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_5" id="radioBtn4_1_5" v-model="respuestaSeccion1_5" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_1_5">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion1_5" id="radioBtn5_1_5" v-model="respuestaSeccion1_5" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_1_5">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 1 - pregunta 5-->
          <!--FIN SECCION 1-->
  
          <hr>
          <h4>COMUNICACIONES Y RETROALIMENTACIÓN</h4>
  
          <!--SECCION 2-->
            <!--Seccion 2 - pregunta 1-->
            <div class="border p-3 rounded shadow mb-3">
              <b>1.</b> Según el tiempo de respuesta estipulado (1 día), evalúe la agilidad en la resolución a las preguntas expresadas por usted en el foro de dudas:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_1" id="radioBtn1_2_1" v-model="respuestaSeccion2_1" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_2_1">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_1" id="radioBtn2_2_1" v-model="respuestaSeccion2_1" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_2_1">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_1" id="radioBtn3_2_1" v-model="respuestaSeccion2_1" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_2_1">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_1" id="radioBtn4_2_1" v-model="respuestaSeccion2_1" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_2_1">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_1" id="radioBtn5_2_1" v-model="respuestaSeccion2_1" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_2_1">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 2 - pregunta 1-->
  
            <!--Seccion 2 - pregunta 2-->
            <div class="border p-3 rounded shadow mb-3">
              <b>2.</b> La claridad en la resolución a las inquietudes que su docente le brinda por medio de las herramientas de comunicación de la plataforma es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_2" id="radioBtn1_2_2" v-model="respuestaSeccion2_2" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_2_2">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_2" id="radioBtn2_2_2" v-model="respuestaSeccion2_2" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_2_2">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_2" id="radioBtn3_2_2" v-model="respuestaSeccion2_2" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_2_2">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_2" id="radioBtn4_2_2" v-model="respuestaSeccion2_2" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_2_2">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_2" id="radioBtn5_2_2" v-model="respuestaSeccion2_2" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_2_2">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 2 - pregunta 2-->
  
            <!--Seccion 2 - pregunta 3-->
            <div class="border p-3 rounded shadow mb-3">
              <b>3.</b> El lenguaje que su docente usa para establecer comunicaciones con usted a través de la plataforma es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_3" id="radioBtn1_2_3" v-model="respuestaSeccion2_3" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_2_3">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_3" id="radioBtn2_2_3" v-model="respuestaSeccion2_3" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_2_3">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_3" id="radioBtn3_2_3" v-model="respuestaSeccion2_3" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_2_3">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_3" id="radioBtn4_2_3" v-model="respuestaSeccion2_3" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_2_3">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_3" id="radioBtn5_2_3" v-model="respuestaSeccion2_3" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_2_3">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 2 - pregunta 3-->
  
            <!--Seccion 2 - pregunta 4-->
            <div class="border p-3 rounded shadow mb-3">
              <b>4.</b> La retroalimentación de trabajos y tareas es oportuna y le aporta al mejoramiento de su proceso de aprendizaje.
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_4" id="radioBtn1_2_4" v-model="respuestaSeccion2_4" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_2_4">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_4" id="radioBtn2_2_4" v-model="respuestaSeccion2_4" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_2_4">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_4" id="radioBtn3_2_4" v-model="respuestaSeccion2_4" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_2_4">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_4" id="radioBtn4_2_4" v-model="respuestaSeccion2_4" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_2_4">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_4" id="radioBtn5_2_4" v-model="respuestaSeccion2_4" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_2_4">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 2 - pregunta 4-->
  
            <!--Seccion 2 - pregunta 5-->
            <div class="border p-3 rounded shadow mb-3">
              <b>5.</b> La asesoría presencial que el docente le brinda es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_5" id="radioBtn1_2_5" v-model="respuestaSeccion2_5" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_2_5">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_5" id="radioBtn2_2_5" v-model="respuestaSeccion2_5" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_2_5">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_5" id="radioBtn3_2_5" v-model="respuestaSeccion2_5" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_2_5">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_5" id="radioBtn4_2_5" v-model="respuestaSeccion2_5" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_2_5">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion2_5" id="radioBtn5_2_5" v-model="respuestaSeccion2_5" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_2_5">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 2 - pregunta 5-->
          <!--FIN SECCION 2-->
  
          <hr>
          <h4>METODOLOGÍA PARA EVALUAR</h4>
  
          <!--SECCION 3-->
            <!--Seccion 3 - pregunta 1-->
            <div class="border p-3 rounded shadow mb-3">
              <b>1.</b> El tipo de exámenes que su docente plantea para la evaluar los conocimientos adquiridos es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_1" id="radioBtn1_3_1" v-model="respuestaSeccion3_1" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_3_1">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_1" id="radioBtn2_3_1" v-model="respuestaSeccion3_1" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_3_1">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_1" id="radioBtn3_3_1" v-model="respuestaSeccion3_1" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_3_1">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_1" id="radioBtn4_3_1" v-model="respuestaSeccion3_1" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_3_1">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_1" id="radioBtn5_3_1" v-model="respuestaSeccion3_1" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_3_1">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 3 - pregunta 1-->
  
            <!--Seccion 3 - pregunta 2-->
            <div class="border p-3 rounded shadow mb-3">
              <b>2.</b> Los temas evaluados fueron los mismos que se estudiaron durante el curso
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_2" id="radioBtn1_3_2" v-model="respuestaSeccion3_2" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_3_2">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_2" id="radioBtn2_3_2" v-model="respuestaSeccion3_2" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_3_2">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_2" id="radioBtn3_3_2" v-model="respuestaSeccion3_2" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_3_2">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_2" id="radioBtn4_3_2" v-model="respuestaSeccion3_2" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_3_2">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_2" id="radioBtn5_3_2" v-model="respuestaSeccion3_2" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_3_2">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 3 - pregunta 2-->
  
            <!--Seccion 3 - pregunta 3-->
            <div class="border p-3 rounded shadow mb-3">
              <b>3.</b> La retroalimentación que su docente hace de los resultados de las evaluaciones es
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_3" id="radioBtn1_3_3" v-model="respuestaSeccion3_3" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_3_3">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_3" id="radioBtn2_3_3" v-model="respuestaSeccion3_3" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_3_3">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_3" id="radioBtn3_3_3" v-model="respuestaSeccion3_3" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_3_3">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_3" id="radioBtn4_3_3" v-model="respuestaSeccion3_3" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_3_3">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion3_3" id="radioBtn5_3_3" v-model="respuestaSeccion3_3" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_3_3">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 3 - pregunta 3-->
  
            <!--Seccion 3 - pregunta 4-->
            <div class="border p-3 rounded shadow mb-3">
              <b>4.</b>¿Qué aspectos cree que mejorarían la metodología que su docente implementa en su curso?
              <textarea class="form-control" id="txtAreaAspectosMejorar" rows="3" v-model="txtAreaAspectosMejorar"></textarea>
            </div>
            <!--Fin Seccion 3 - pregunta 4-->
          <!--FIN SECCION 3-->
  
          <h3 class="text-center mt-3 mb-3">Importante</h3>
          <h5 class="text-center">Por favor califique los servicios que presta el equipo de Gestión Curricular de la Unidad de Educación Virtual</h5>
  
  
          <hr>
          <h4>ATENCIÓN DEL EQUIPO DE GESTIÓN CURRICULAR DE LA UNIDAD DE EDUCACIÓN VIRTUAL</h4>
  
          <!--SECCION 4-->
            <!--Seccion 4 - pregunta 1-->
            <div class="border p-3 rounded shadow mb-3">
              <b>1.</b> La agilidad en las respuestas dadas por el equipo de Gestión Curricular de la Unidad de Educación Virtual vía telefónica, correo electrónico, la plataforma y las redes sociales es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_1" id="radioBtn1_4_1" v-model="respuestaSeccion4_1" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_4_1">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_1" id="radioBtn2_4_1" v-model="respuestaSeccion4_1" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_4_1">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_1" id="radioBtn3_4_1" v-model="respuestaSeccion4_1" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_4_1">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_1" id="radioBtn4_4_1" v-model="respuestaSeccion4_1" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_4_1">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_1" id="radioBtn5_4_1" v-model="respuestaSeccion4_1" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_4_1">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 4 - pregunta 1-->
  
            <!--Seccion 4 - pregunta 2-->
            <div class="border p-3 rounded shadow mb-3">
              <b>2.</b> La resolución a las preguntas o casos expresados a través de los diferentes medios son:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_2" id="radioBtn1_4_2" v-model="respuestaSeccion4_2" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_4_2">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_2" id="radioBtn2_4_2" v-model="respuestaSeccion4_2" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_4_2">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_2" id="radioBtn3_4_2" v-model="respuestaSeccion4_2" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_4_2">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_2" id="radioBtn4_4_2" v-model="respuestaSeccion4_2" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_4_2">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_2" id="radioBtn5_4_2" v-model="respuestaSeccion4_2" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_4_2">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 4 - pregunta 2-->
  
            <!--Seccion 4 - pregunta 3-->
            <div class="border p-3 rounded shadow mb-3">
              <b>3.</b> La atención presencial en la oficina de la Unidad Virtual es:
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_3" id="radioBtn1_4_3" v-model="respuestaSeccion4_3" v-bind:value="'Deficiente'">
                <label class="form-check-label" for="radioBtn1_4_3">
                  Deficiente
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_3" id="radioBtn2_4_3" v-model="respuestaSeccion4_3" v-bind:value="'Regular'">
                <label class="form-check-label" for="radioBtn2_4_3">
                  Regular
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_3" id="radioBtn3_4_3" v-model="respuestaSeccion4_3" v-bind:value="'Aceptable'">
                <label class="form-check-label" for="radioBtn3_4_3">
                  Aceptable
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_3" id="radioBtn4_4_3" v-model="respuestaSeccion4_3" v-bind:value="'Bueno'">
                <label class="form-check-label" for="radioBtn4_4_3">
                  Bueno
                </label>
              </div>
               <div class="form-check">
                <input class="form-check-input" type="radio" name="radioBtnSeccion4_3" id="radioBtn5_4_3" v-model="respuestaSeccion4_3" v-bind:value="'Excelente'">
                <label class="form-check-label" for="radioBtn5_4_3">
                  Excelente
                </label>
              </div>
            </div>
            <!--Fin Seccion 4 - pregunta 3-->
  
            <!--Seccion 4 - pregunta 4-->
            <div class="border p-3 rounded shadow mb-3">
              <b>4.</b> Escriba las sugerencias y comentarios que considere nos pueden ayudar a mejorar los servicios que presta el equipo de Gestión Curricular de la Unidad de Educación Virtual
              <textarea class="form-control" id="txtAreaSugerenciasGestionCurricular" v-model="txtAreaSugerenciasGestionCurricular" rows="3"></textarea>
            </div>
            <!--Fin Seccion 4 - pregunta 4-->
  
          <!--FIN SECCION 4-->
          <div v-if="faltantes" class="alert alert-danger" role="alert">
            <b>Todos los campos son de carácter obligatorio</b>
          </div>
          
          <button class="btn btn-enviar d-flex mx-auto" @click="validaCampos">Enviar respuestas</button>
          <h3 class="text-center mt-3">¡Muchísimas gracias por su participación. Es muy valiosa para nosotros!</h3>
        </div>
      </div>
      </div>
    </div>
  </template>

  <script>
  
  //Importamos el axios para realizar las peticiones al server
  import API from '../../../api'
  import {sweetAlertConfirm, sweetAlertSuccess} from '../../../commons/SweetAlert'
  import Select2 from 'vue3-select2-component';
  import { obtenerSemestreActual } from '../../../helpers/functions';

  export default {
      name: 'formularioEncuestaDesempenoDocente',
      data() {
        return {
          codigos: [],
          codigosAsignaturas: [],
          codigoSeleccionado: "",
          programa: "",
          docente: "",
          tipoAsignatura: "",
          tipoAsignaturaAbreviado: "",
          semestre: "",
  
          //Respuestas seccion 1
          respuestaSeccion1_1: "",
          respuestaSeccion1_2: "",
          respuestaSeccion1_3: "",
          respuestaSeccion1_4: "",
          respuestaSeccion1_5: "",
  
          //Respuestas seccion 2
          respuestaSeccion2_1: "",
          respuestaSeccion2_2: "",
          respuestaSeccion2_3: "",
          respuestaSeccion2_4: "",
          respuestaSeccion2_5: "",
  
          //Respuestas seccion 3
          respuestaSeccion3_1: "",
          respuestaSeccion3_2: "",
          respuestaSeccion3_3: "",
  
          //Respuestas seccion 4
          respuestaSeccion4_1: "",
          respuestaSeccion4_2: "",
          respuestaSeccion4_3: "",
  
          txtAreaAspectosMejorar: "",
          txtAreaSugerenciasGestionCurricular: "",
  
          faltantes: false
  
        }
      },
      components: {Select2},
      async created(){
        this.codigos = await API.fetchCodigosAsignaturas(obtenerSemestreActual());

        this.codigos = this.codigos.map(function(item) {
          item.codigoAsignatura = item.codigoAsignatura.trim()
          return {
            ...item
          };
        });

        this.codigosAsignaturas = this.codigos.map(elemento => {
            return elemento.codigoAsignatura.trim();
        })

      },
      methods: {
        setSelectValue: function(event){
          alert(event.target.value)
        },
        validaCampos: async function(){
          this.faltantes = false;
  
          if(this.codigoSeleccionado == "" || this.programa == "" || this.docente == "" || this.tipoAsignatura == ""
          || this.respuestaSeccion1_1 == "" || this.respuestaSeccion1_2 == "" || this.respuestaSeccion1_3 == "" || this.respuestaSeccion1_4 == "" || this.respuestaSeccion1_5 == "" 
          || this.respuestaSeccion2_1 == "" || this.respuestaSeccion2_2 == "" || this.respuestaSeccion2_3 == "" || this.respuestaSeccion2_4 == "" || this.respuestaSeccion2_5 == "" 
          || this.respuestaSeccion3_1 == "" || this.respuestaSeccion3_2 == "" || this.respuestaSeccion3_3 == "" || this.txtAreaAspectosMejorar == ""
          || this.respuestaSeccion4_1 == "" || this.respuestaSeccion4_2 == "" || this.respuestaSeccion4_3 == "" || this.txtAreaSugerenciasGestionCurricular == ""){
            this.faltantes = true;
            return;
          }
  

      //Acá va la validacion del formato en caso de que se necesite.      
      sweetAlertConfirm( 
          '¿Desea enviar la información diligenciada?',
          'Sus respuestas dadas en la encuesta serán enviadas.',
          'warning',
          'Enviar'
        ).then(async (result) => {
            if (result.isConfirmed) {
                  //Creamos el objeto a enviar a la bd
              const data = {
                codigoAsignatura: this.codigoSeleccionado,
                programa: this.programa,
                docente: this.docente,
                tipoAsignatura: this.tipoAsignaturaAbreviado,
                ECC1: this.respuestaSeccion1_1,
                ECC2: this.respuestaSeccion1_2,
                ECC3: this.respuestaSeccion1_3,
                ECC4: this.respuestaSeccion1_4,
                ECC5: this.respuestaSeccion1_5,
                CR1: this.respuestaSeccion2_1,
                CR2: this.respuestaSeccion2_2,
                CR3: this.respuestaSeccion2_3,
                CR4: this.respuestaSeccion2_4,
                CR5: this.respuestaSeccion2_5,
                ME1: this.respuestaSeccion3_1,
                ME2: this.respuestaSeccion3_2,
                ME3: this.respuestaSeccion3_3,
                ME4: this.txtAreaAspectosMejorar,
                AMA1: this.respuestaSeccion4_1,
                AMA2: this.respuestaSeccion4_2,
                AMA3: this.respuestaSeccion4_3,
                AMA4: this.txtAreaSugerenciasGestionCurricular,
                semestre: this.semestre
              }
              const encuentaCreada = API.createEncuesta(data);

                if(encuentaCreada.message){
                    this.mensajeError = addInfo.message;
                }else{
                    this.loading = false;
                    sweetAlertSuccess(
                      '¡Datos enviados!',
                      'Los datos diligenciados se han enviado de manera exitosa',
                      'success'
                    ).then(() => {
                        location.reload();
                    });
                }
            } else  (
              result.dismiss === Swal.DismissReason.cancel
            ) 
          })
        }
      },
  
      watch: {
        //Watch que valida el cambio que pueda tener la variable codigoSeleccionado
          codigoSeleccionado: function(value) {

              let filteredData = this.codigos.find(elemento => elemento.codigoAsignatura === value);
            
              this.codigoSeleccionado = filteredData.codigoAsignatura;
              this.programa = filteredData.programaAcademico;
              this.docente = filteredData.docente;
              this.tipoAsignatura = filteredData.tipoAsignatura;
              this.semestre = filteredData.semestre;
  
              this.tipoAsignaturaAbreviado = this.tipoAsignatura;
  
              if(this.tipoAsignatura == "AMV"){
                this.tipoAsignatura = "Asignatura mediada por la virtualidad (AMV)";
              }else{
                this.tipoAsignatura = "Asignatura de programas virtuales (APV)";
              }        
          }
      },
      mounted() {
        document.title = this.$route.meta.title // Cambiar el título de la página
      }
  }
  </script>
  