<template>
    <div>
        <div class="container-fluid m-0 p-0">
            <img src="../../../../public/img/Generales/Encuesta_de_Satisfaccion.png" class="img-fluid d-flex mx-auto vw-100" alt="">
        </div>
        <div class="container">
            <div class="row mt-3">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtNombreCompleto">Nombre completo <b class="text-danger">*</b></label> <br>
                    <input 
                        id="txtNombreCompleto" 
                        type="text" 
                        class="form-control" 
                        v-model.trim="datosSolicitud.nombres"
                        :oninput="() => (datosSolicitud.nombres = datosSolicitud.nombres.toUpperCase())">
                </div>
                <div class="col-lg-6 col-12">
                    <label for="txtApellidos">Apellidos <b class="text-danger">*</b></label> <br>
                    <input 
                        id="txtApellidos" 
                        type="text" 
                        class="form-control"
                        v-model.trim="datosSolicitud.apellidos"
                        :oninput="() => (datosSolicitud.apellidos = datosSolicitud.apellidos.toUpperCase())">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="selectTipoDocumento">Tipo de documento <b class="text-danger">*</b></label> <br>
                    <select 
                        id="selectTipoDocumento" 
                        class="form-control"
                        v-model.trim="datosSolicitud.tipoDocumento">
                        <option v-for="(tipoDocumento, index ) in tiposDocumentos" :key="index" :value="tipoDocumento.value">{{ tipoDocumento.text }}</option>
                    </select>
                </div>
                <div class="col-lg-6 col-12">
                    <label for="txtNumDocuemnto">Número de documento <b class="text-danger">*</b></label> <br>
                    <input 
                        id="txtNumDocuemnto" 
                        type="text" 
                        class="form-control"
                        v-model.trim="datosSolicitud.numDocumento">
                </div>
            </div>
            <div class="row mt-3">
               <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                    <label for="txtCorreoInstitucional">Correo institucional <b class="text-danger">*</b> </label>
                    <input 
                        id="txtCorreoInstitucional" 
                        type="text" 
                        class="form-control"
                        v-model.trim="datosSolicitud.correoInstitucional">
               </div>
               <div class="col-lg-6 col-12">
                    <label for="txtCorreoAlterno">Correo alterno <b class="text-danger">*</b> </label>
                    <input 
                        id="txtCorreoAlterno" 
                        type="text" 
                        class="form-control"
                        v-model.trim="datosSolicitud.correoAlterno">
               </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-6 col-12">
                    <label for="selectTipoUsuario">Tipo de usuario <b class="text-danger">*</b> </label>
                    <select 
                        id="selectTipoUsuario" 
                        class="form-control"
                        v-model="datosSolicitud.tipoUsuario">
                        <option value="" selected disabled>Seleccione opción</option>
                        <option value="Docente">Docente</option>
                        <option value="Estudiante">Estudiante</option>
                    </select>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <label><b>Preguntas frecuentes:</b> <b class="text-danger">*</b> </label>
                    <div v-for="(pregunta, index) in preguntasFrecuentes" :key="index">
                        <p v-if="index === 0" class="mt-3"><b>Académicas:</b></p>
                        <p v-if="index === 13" class="mt-3"><b>Generales:</b></p>
                        <hr v-if="index === preguntasFrecuentes.length - 1">
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                name="rbtnPreguntaFrecuente" 
                                :id="
                                    index !== preguntasFrecuentes.length - 1 
                                        ? `rbtnPreguntaFrecuente_${ index + 1 }`
                                        : `rbtnPreguntaFrecuente_${ index + 1 }_Otra`
                                "
                                :value="(index < preguntasFrecuentes.length - 1) ? preguntasFrecuentes[ index ] : ''"
                                @click="toogleTextArea($event)">
                            <label class="form-check-label" :for="`rbtnPreguntaFrecuente_${ index + 1 }`">
                                {{ pregunta }}
                            </label>
                        </div>
                    </div>
                    <div v-if="txtAreaActivado">
                        <label><b>Otra pregunta:</b> <b class="text-danger">*</b> </label>
                        <textarea class="form-control" id="" cols="30" rows="2" v-model.trim="datosSolicitud.pregunta"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label><b>Adjuntar evidencia: (La evedencia debe ser un archivo tipo imagen)</b> <b class="text-danger">*</b></label><br>
                    <input accept="image/png, image/jpeg, image/jpg" @change="cargaImagenEvidencia($event)" type="file"><br>
                    <small v-if="errorExtensionArchivoErrado"><b class="text-danger">El archivo adjunto debe de ser una imagen </b></small>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <p>La información suministrada en el anterior formulario será tratada conforme la Ley 1581 de 2012 sobre la protección de datos personales. Esta se utilizará con fines de matriculación a las plataformas virtuales de la institución universitaria ITM y no serán comercializados ni suministrados a otras entidades.</p>
                    <div class="form-check">
                        <input v-model="aceptaTratamientoDeDatos" class="form-check-input" type="checkbox" id="flexCheckAutorizacionHabeasData">
                        <label class="form-check-label" for="flexCheckAutorizacionHabeasData">Autorizo a la Unidad de Educación Virtual el tratamiento de mis datos.</label><br>
                        <small v-if="errorAutorizacionTratamientoDatos"><b class="text-danger">Debe autorizar el tratamiento de datos por parte de la Unidad de Educación Virtual</b></small>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div v-if="errorCamposObligatorios" class="alert alert-danger" role="alert">
                        <b>Todos los campos marcados con <b class="text-danger">*</b> son de carácter obligatorio</b>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="errorEmailIntitucionalErrado" class="alert alert-danger" role="alert">
                        <b>El correo intitucional es incorrecto, por favor use un correo como los siguientes <b>'ejemplo@correo.itm.edu.co'</b> ó <b>'ejemplo@itm.edu.co'</b></b>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="errorEmailAlternoErrado" class="alert alert-danger" role="alert">
                        <b>El correo alterno debe ser un correo válido</b>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="errorRespuestaAPI" class="alert alert-danger" role="alert">
                        <b>{{ mensajeErrorRespuestaAPI }}</b>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-center" v-if="isLoading"><b>Enviando información, por favor espere...</b></p> 
                    <div class="d-flex justify-content-center" v-if="isLoading">
                        <pulse-loader :loading="isLoading" :color="color" :size="size"></pulse-loader>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button class="btn btn-enviar d-flex mx-auto mb-5 mt-2" @click="crearRegistro()">Realizar registro</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, ref, getCurrentInstance } from 'vue';
import { useGeneralStore } from "@/stores/generales";
import { 
    comprimirArchivo, 
    generarIdNumericoConsecutivo, 
    obtenerExtensionArchivo, 
    validaExtensionArchivo, 
    validarEmail,
    validarEmailInstitucional } from '@/helpers/functions';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { sweetAlertConfirm, sweetAlertSuccess } from '../../../commons/SweetAlert'

export default {
    setup() {
        const instance = getCurrentInstance();
        let generalStore = ref( null );

        let isLoading = ref( false );
        let txtAreaActivado = ref( false );
        let aceptaTratamientoDeDatos = ref( false );
        const extensionesDeArchivoValidas = ['jpg', 'jpeg', 'png'];

        let errorCamposObligatorios = ref( false ); 
        let errorEmailIntitucionalErrado = ref( false ); 
        let errorEmailAlternoErrado = ref( false ); 
        let errorAutorizacionTratamientoDatos = ref( false ); 
        let errorExtensionArchivoErrado = ref( false );

        let errorRespuestaAPI = ref( false );
        let mensajeErrorRespuestaAPI = ref( null );

        const datosSolicitud = ref({
            idSolicitud: '',
            asunto: 'Solicitud a la Unidad de Educación Virtual',
            nombres: '',
            apellidos: '',
            tipoDocumento: '',
            numDocumento: '',
            correoInstitucional: '',
            correoAlterno: '',
            tipoUsuario: '',
            pregunta: '',
            estado: 'abierto'
        });
        const imgEvidencia = ref(null)

        const tiposDocumentos = [
            { text: 'Seleccione opción', value: '' },
            { text: 'Cédula de ciudadanía', value: 'Cédula de ciudadanía'},
            { text: 'Cédula de extrangería', value: 'Cédula de extrangería'},
            { text: 'Número único de identificación', value: 'Número único de identificación'},
            { text: 'Pasaporte', value: 'Pasaporte' },
            { text: 'Tarjeta de identidad', value: 'Tarjeta de identidad' },
            { text: 'Carnet diplomático', value: 'Carnet diplomático' },
            { text: 'Registro civil', value: 'Registro civil' },
            { text: 'Permiso proteccion temporal - PPT', value: 'Permiso proteccion temporal - PPT' }   
        ];

        const preguntasFrecuentes = [
            '¿Cómo debo ingresar a la plataforma para el desarrollo de las asignaturas AMV o APV?',
            '¿Cómo debo ingresar a la plataforma cvirtual.itm.edu.co en caso de que mi docente la esté utilizando como apoyo a la clase presencial?',
            '¿Debo conectarme en el día y hora que aparece en mi horario o asesoría de matrícula?',
            '¿Cómo puedo cambiar la contraseña de ingreso a la plataforma?',
            '¿Si presento una dificultad técnica en la plataforma cómo puedo reportarla?',
            '¿Si el semestre anterior realicé el curso de inducción virtual lo debo hacer nuevamente para el nuevo semestre?',
            '¿Si el semestre anterior asistí a los momentos de inducción virtual debo asistir nuevamente para el nuevo semestre?',
            '¿A quién debo acudir si el docente de una AMV o APV no responde el foro de dudas ni la mensajería privada?',
            '¿Qué debo hacer si se cae la conexión a internet o se va la luz en medio de la presentación de un examen o entrega de actividad en la plataforma virtual del ITM?',
            '¿Qué debo hacer si estoy incapacitado(a) y no puedo presentar la actividad evaluativa en la fecha programada por el profesor en plataforma?',
            '¿Cuáles son los medios autorizados para hacer la entrega de las tareas y actividades de las AMV y APV?',
            '¿Dónde puedo consultar las notas de las AMV y APV?',
            '¿Puedo perder una AMV o APV por inasistencia?',
            '¿Dónde puedo hacer la solicitud para creación de aulas en la plataforma Cvirtual?',
            'Si me encuentro en un programa virtual y deseo cambiarme a uno presencial, ¿cuál es el proceso que debo realizar?',
            '¿Cómo puedo activar, configurar y restablecer la contraseña del correo electrónico institucional?',
            '¿Cómo puedo solicitar un certificado de estudio o calificaciones?',
            '¿Cómo es el proceso para solicitar el carné institucional?',
            '¿Cómo me puedo inscribir para los cursos de cátedras abiertas y la biblioteca (Estrategias de Información Científica)?',
            'Otra',
        ];

        const toogleTextArea = ({ target }) => {
            const { id, value } = target;
            
            if( !id.split('_').includes('Otra') ) {
                 txtAreaActivado.value = false;
                 datosSolicitud.value.pregunta = value;
                 return;
            };

            txtAreaActivado.value = true;
            datosSolicitud.value.pregunta = '';
        }

        const crearRegistro = async () => {

            const camposValidados = validaCampos();
            if( !camposValidados ) return;

            sweetAlertConfirm(
                '¿Desea enviar la información diligenciada?',
                'Los datos diligenciados se enviarán para su respectiva revisión.',
                'warning',
                'Enviar'
              ).then(async (result) => {
                  if (result.isConfirmed) {

                    isLoading.value = true;

                    const datosSolicitudFormData = new FormData();
            
                    datosSolicitud.value.extensionArchivo = obtenerExtensionArchivo( imgEvidencia.value );
                    
                    datosSolicitudFormData.append('infoSolicitud', JSON.stringify(datosSolicitud.value));
                    datosSolicitudFormData.append('imgEvidencia', imgEvidencia.value);
                  
                    const solicitudUDEVCreada = await generalStore.value.createSolicitudUDEV( datosSolicitudFormData );

                    if( solicitudUDEVCreada.message ) {
                        isLoading.value = false;
                        errorRespuestaAPI.value = true;
                        mensajeErrorRespuestaAPI.value = solicitudUDEVCreada.message;
                        return;
                    }else{
                          isLoading.value = false;
                          sweetAlertSuccess(
                            '¡Datos enviados!',
                            'Los datos diligenciados se han enviado de manera exitosa',
                            'success'
                          ).then(() => {
                              location.reload();
                          });
                      }
                  } else  (
                    result.dismiss === Swal.DismissReason.cancel
                  ) 
            })
        }

        const cargaImagenEvidencia = async ( event ) => {
            const archivo = event.target.files[0];
            const archivoComprimido = await comprimirArchivo(archivo)
            imgEvidencia.value = archivoComprimido;
        }

        const validaCampos = () => {         

            limpiaErrores();
            
            let existenCamposVacios =  false;

            // Valida campos vacios
            Object.keys(datosSolicitud.value).forEach(prop => {
                const valorCampo = datosSolicitud.value[prop];
                if(valorCampo === '' || !valorCampo) existenCamposVacios = true;
            })

            if( !imgEvidencia.value ) existenCamposVacios = true;

            if( existenCamposVacios ) {
                errorCamposObligatorios.value = true;
                return;
            }

            // Valida email válido
            if( !validarEmailInstitucional( datosSolicitud.value.correoInstitucional ) ) {
                errorEmailIntitucionalErrado.value = true;
                return;
            } 
               
            if( !validarEmail( datosSolicitud.value.correoAlterno ) ) {
                errorEmailAlternoErrado.value = true;
                return;
            }

            // Valida extensión del archivo
            if( !validaExtensionArchivo( imgEvidencia.value, extensionesDeArchivoValidas ) ) {
                errorExtensionArchivoErrado.value = true;
                return;
            }

            // Valida aceptacion de tratamiento de datos
            if( !aceptaTratamientoDeDatos.value ) {
                errorAutorizacionTratamientoDatos.value = true;
                return;
            }

            return true;
        }

        const limpiaErrores = () => {
             errorAutorizacionTratamientoDatos.value = false;
             errorCamposObligatorios.value = false; 
             errorEmailIntitucionalErrado.value = false; 
             errorEmailAlternoErrado.value = false; 
             errorAutorizacionTratamientoDatos.value = false; 
             errorExtensionArchivoErrado.value = false;
             errorRespuestaAPI.value = false;
        }

        onMounted(async () => {
            if (instance) {
                const { $route } = instance.appContext.config.globalProperties;
                document.title = $route.meta.title;
            }

            generalStore.value = useGeneralStore();

            const { solicitud } = await generalStore.value.getUltimaSolicitudUDEV();
            if(!solicitud) {
                datosSolicitud.value.idSolicitud = generarIdNumericoConsecutivo( 1, 10 );
                return;
            }

            let proximoConsecutivo = parseInt(solicitud.idSolicitud) + 1;
            datosSolicitud.value.idSolicitud = generarIdNumericoConsecutivo( proximoConsecutivo, 10 );
        })

        return{
            aceptaTratamientoDeDatos,
            cargaImagenEvidencia,
            crearRegistro,
            datosSolicitud,
            errorAutorizacionTratamientoDatos,
            errorCamposObligatorios,
            errorEmailAlternoErrado,
            errorEmailIntitucionalErrado,
            errorExtensionArchivoErrado,
            errorRespuestaAPI,
            isLoading,
            mensajeErrorRespuestaAPI,
            preguntasFrecuentes,
            tiposDocumentos,
            toogleTextArea,
            txtAreaActivado,
        }
    },

    components: {
        PulseLoader 
    },
}

</script>